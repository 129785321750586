import { useLocation, Navigate, Outlet } from "react-router-dom";

const RequireAuth = ({ allowedRoles }) => {
    const location = useLocation();
    const Role = JSON.parse(localStorage.getItem("clinic-u"));
    
    return (
        allowedRoles?.includes(Role?.role?.name)
            ? <Outlet />
            : Role
                ? <Navigate to="/unauthorized" state={{ from: location }} replace />
                : <Navigate to="/login" state={{ from: location }} replace />
    );
}

export default RequireAuth;
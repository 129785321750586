import authHeader from "auth-header";
import httpCommon from "http-common";

const sendReportToGP = (data) => {
    return httpCommon.post("/report-to-gp-email", data, { headers: authHeader() });
};

const GpEmailService = {
    sendReportToGP,
};

export default GpEmailService;
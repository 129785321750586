import { retrieveOneBlog, updateBlog } from 'actions/blogs';
import { createSignedUrl, uploadFile } from 'actions/fileUpload';
import { retrieveOneSlider, updateSlider } from 'actions/slider';
import { Button } from 'components/ui';
import React, { useCallback, useEffect, useState } from 'react'
import { Controller, useForm } from 'react-hook-form';
import { toast } from 'react-hot-toast';
import ReactQuill from "react-quill";

import "react-quill/dist/quill.snow.css";
import { useDispatch } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';

const EditAppSlider = () => {
    const { id } = useParams();
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const [slider, setSlider] = useState({});
    const [loading, setLoading] = useState(false)

    const {
        control,
        register,
        setValue,
        handleSubmit,
        formState: { errors },
    } = useForm();

    const fetchSlider = useCallback(() => {
        dispatch(retrieveOneSlider(id))
            .then((response) => {
                setSlider(response);
                setValue("text", response?.text);
                setValue("image", response?.image);
                setValue("isActive", response?.isActive);
            })
            .catch((error) => {
                console.log(error, "error");
            });
    }, [dispatch, setValue, id]);

    const onSubmit = async (data) => {
        setLoading(true);
        let s3Key
        if (typeof data?.image === "object") {
            const file = data?.image[0];
            if (file.size <= 50 * 1024 * 1024) {
                if (data?.image?.length > 0) {
                    const body = {
                        fileName: data?.image[0]?.name,
                        mimeType: data?.image[0]?.type,
                        directory: "slider"

                    }
                    await dispatch(createSignedUrl(body)).then(async (result) => {
                        s3Key = result?.s3Key
                        dispatch(uploadFile(result?.signedUrl, data?.image[0]));
                        data.image = s3Key;
                    })
                        .catch((error) => {
                            toast.error(error.response.data.errors[0].detail);
                        })
                } else {
                    toast.error("File size exceeds the 50 MB limit");
                }
            } else {
                data.image = slider?.image
            }
        }
        await dispatch(updateSlider(id, data)).then((result) => {
            setLoading(false);
            toast.success("Slider Updated Successfully");
            navigate("/slider")
        })
            .catch((error) => {
                setLoading(false);
                toast.error(error.response.data.errors[0].detail);
            })
    }

    useEffect(() => {
        fetchSlider();
    }, [fetchSlider]);

    return (
        <section className="ml-6 mr-7 min-h-screen items-center justify-center py-6">
            <div>
                <div className="mb-4">
                    <div className="text-xl font-semibold">Edit App Slider</div>
                </div>
                <div>
                    <form className="pt-8 pb-16" onSubmit={handleSubmit(onSubmit)}>
                        <div className="mb-6 w-auto rounded-t-lg bg-white px-4 py-4 lg:px-7 lg:py-7">
                            <div className="flex flex-col mb-3">
                                <div className="mb-1.5 font-medium text-independence">
                                    Name for Slider
                                </div>
                                <input
                                    type="text"
                                    className="bg-alabaster border-none w-auto rounded-md"
                                    {...register("text", { required: true })}
                                />
                                {errors?.text?.type === "required" && (
                                    <div className="mt-1 text-sm text-burnt-sienna">
                                        Name for Slider is required.
                                    </div>
                                )}
                            </div>
                            <div className="flex flex-col mb-3">
                                <div className="mb-1.5 font-medium">
                                    Image For Slider
                                </div>
                                <input
                                    type="file"
                                    placeholder="e.g. Sign Permit"
                                    className="bg-alabaster border-none w-auto rounded-md"
                                    {...register("image", { required: false })}
                                />
                                {errors?.image?.type === "required" && (
                                    <div className="mt-1 text-sm text-burnt-sienna">
                                        Image For Slider is required.
                                    </div>
                                )}
                            </div>
                        </div>
                        <Button
                            type="submit"
                            className="bg-indigo text-white h-12 rounded-lg"
                            text="lg"
                            loading={loading}
                        >
                            Update
                        </Button>
                    </form>
                </div>
            </div>
        </section>
    )
}

export default EditAppSlider

import { Image, StyleSheet, Text, View } from '@react-pdf/renderer'
import React from 'react'

const styles = StyleSheet.create({
    section: {
        flexDirection: "row",
        marginBottom: "20",
        justifyContent: "space-between",
        alignItems: "center"
    },
    image: {
        width: "20%",
        padding: "1"
    },
    text: {
        letterSpacing: 4,
        fontSize: 30,
    }
});

const ReportHeader = ({ clinicName }) => {
    return (
        <View style={styles.section}>
            <Image src='/img/logo.png' style={styles.image} />
            <Text>{clinicName}</Text>
        </View>
    )
}

export default ReportHeader
import { Timeline } from 'components/ui'
import React from 'react'

const WhatIs = () => {

    const timeline = [
        {
            icon: " 01",
            title: null,
            height: 14,
            text: "Ease is achieved showing the pharmacy or clinic most local to you, so you can book appointments or health services at a suitable time."
        },
        {
            icon: "02",
            title: null,
            height: 20,
            text: "Efficiency is achieved by making all of these services available at your fingertips – so you can quickly access them from wherever you are – and on a device, which saves paper and the environment."
        },
        {
            icon: "03",
            title: null,
            height: 12,
            text: "Effectiveness is achieved by providing you with all necessary forms prior to your visit, so you are not wasting your time completing them in the clinic."
        }
    ]

    return (
        <section>
            <div className="lg:flex lg:space-x-32 items-center justify-center mt-28 mb-28">
                <div>
                    <img src="/img/landingPage/chat-screen.png" alt="clinic2go" className="hidden lg:block" />
                </div>
                <div className="max-w-xl px-2">
                    <div className="text-5xl text-elephant font-bold">What Is Clinic2Go?</div>
                    <div className="text-base text-fiord font-normal mt-4">
                        Clinic2Go is a robust service-based platform that aims to make life easier, more efficient and more effective for both patient and healthcare provider.
                    </div>
                    <div className="mt-12 px-2">
                    <Timeline timeline={timeline} />
                </div>
                <div>
                    <div className="flex space-x-5">
                        <img src="/img/landingPage/Google-Play.png" alt="clinic2go" onClick={() => window.open("https://play.google.com/store/apps/details?id=com.clinic2")}/>
                        <img src="/img/landingPage/App-Store.png" alt="clinic2go" onClick={() => window.open("https://apps.apple.com/gb/app/clinic2go/id6448028689")}/>
                    </div>
                </div>
            </div>
        </div>
        </section >
    )
}

export default WhatIs
import { getClinicLogo } from "actions/dashboard";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { toast } from "react-hot-toast";
import { useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import { ROLE } from "utils/roles";

const Header = () => {

  const dispatch = useDispatch();
  const [image, setImage] = useState("");
  const [clinicName, setClinicName] = useState("")

  const auth = JSON.parse(localStorage.getItem("clinic-u"));
  const clinicId = auth?.clinic[0]?.id;
  const clinicImage = JSON.parse(localStorage.getItem("clinic-image"));

  useEffect(() => {
    //call dashboard image api for clinic
    const updateDate = moment(clinicImage?.createdAt).add(604800, 'seconds').format("YYYY/MM/DDD");
    if (!moment(updateDate).isSameOrAfter() || clinicImage?.createdAt === undefined) {
      if (auth?.role?.name === ROLE.CLINIC) {
        dispatch(getClinicLogo(clinicId)).then((result) => {
          localStorage.setItem("clinic-image", JSON.stringify(result));
          setImage(result?.accessUrl)
        })
          .catch((error) => {
            toast.error(error.response.data.errors[0].detail);
          })
      }
    } else {
      //for super admin
      setImage(auth?.profileUrl)
    }

    if (auth?.role?.name === ROLE.ADMIN) {
      //for super admin
      setClinicName(`${auth?.firstName} ${auth?.lastName}`);
      setImage(auth?.profileUrl)
    } else {
      //for clinic
      setClinicName(auth?.clinic[0]?.name);
      setImage(clinicImage?.accessUrl)
    }

  }, [
    auth?.clinic,
    auth?.firstName,
    auth?.lastName,
    auth?.role?.name,
    clinicImage?.accessUrl,
    clinicImage?.createdAt,
    auth?.profileUrl,
    dispatch,
    clinicId
  ])

  return (
    <div className="flex fixed top-0 bg-white z-10 justify-between px-5 py-5 w-full border-b border-alabaster">
      <div className="flex justify-center items-center">
        <Link to="/">
          <img src="/img/logo.png" alt="logo" className="w-32 h-6 object-contain" />
        </Link>
      </div>
      <div className="flex lg:space-x-2 justify-center items-center">
        <div>
          {auth !== null && (
            <img src={image} alt="profile icon" className="inline object-cover w-12 h-12 mr-2 rounded-full" />
          )}
        </div>
        <div>
          {clinicName}
        </div>
      </div>
    </div>
  );
};

export default Header;
import authHeader from "auth-header";
import httpCommon from "http-common";

const uploadImage = (data) => {
    return httpCommon.post("/s3/signedUrl", data);
};

const viewImage = (data) => {
    return httpCommon.post("/s3/accessUrl", data, { headers: authHeader() });
};

const Image = {
    uploadImage,
    viewImage
};

export default Image;
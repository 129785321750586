import React, { useState } from "react";

import clsx from "clsx";
import { NavLink, useNavigate } from "react-router-dom";

import useRole from "hooks/useRole";

import { ROLE } from "utils/roles";
import { Transition } from "@headlessui/react";
import { Button, Modal } from "components/ui";
import { useRef } from "react";

const Sidebar = () => {

  const navigate = useNavigate();

  const [isOpen, setIsOpen] = useState(false);
  const [modelOpen, setModelOpen] = useState(false);
  const [isLoaded, setIsLoaded] = useState(false);
  const ref = useRef();

  function openModel() {
    setModelOpen(true)
  }

  function closeModal() {
    setModelOpen(false);
  }

  const onSignOut = () => {
    setIsLoaded(true);
    localStorage.removeItem("clinicu")
    localStorage.removeItem("clinic-u")
    localStorage.removeItem("clinic-image")
    localStorage.removeItem("clinic-chat")
    localStorage.removeItem("userid")
    localStorage.removeItem("logo1");
    localStorage.removeItem("abc")
    navigate("/");
    setIsLoaded(false);
  }

  const navigation = [
    {
      title: "Home",
      href: "/dashboard",
      permission: useRole([
        ROLE.ADMIN,
        ROLE.CLINIC,
      ]),
    },
    {
      title: "Category",
      href: "/category",
      permission: useRole([
        ROLE.ADMIN,
      ]),
    },
    {
      title: "Services",
      href: "/services",
      permission: useRole([
        ROLE.ADMIN,
      ]),
    },
    {
      title: "Appointments",
      href: "/appointments",
      permission: useRole([
        ROLE.ADMIN,
        ROLE.CLINIC,
      ]),
    },
    {
      title: "Patients",
      href: "/patients",
      permission: useRole([
        ROLE.ADMIN,
        ROLE.CLINIC,
      ]),
    },
    {
      title: "Clinics",
      href: "/clinics",
      permission: useRole([
        ROLE.ADMIN,
      ]),
    },
    {
      title: "NMS",
      href: "/nms",
      permission: useRole([
        ROLE.ADMIN,
        ROLE.CLINIC,
      ]),
    },
    {
      title: "Message",
      href: "/chat",
      permission: useRole([
        ROLE.ADMIN,
        ROLE.CLINIC,
      ]),
    },
    {
      title: "App Slider",
      href: "/slider",
      permission: useRole([
        ROLE.ADMIN,
      ]),
    },
    {
      title: "Blogs",
      href: "/blogs",
      permission: useRole([
        ROLE.ADMIN,
      ]),
    },
    {
      title: "Contact-Us",
      href: "/contact-us",
      permission: useRole([
        ROLE.ADMIN,
      ]),
    },
    {
      title: "Our Clients",
      href: "/our-client",
      permission: useRole([
        ROLE.ADMIN,
      ]),
    },
    {
      title: "Services & Availability",
      href: "/clinic-services",
      permission: useRole([
        ROLE.CLINIC,
      ]),
    },
    {
      title: "Account settings",
      href: "/settings",
      permission: useRole([
        ROLE.ADMIN,
        ROLE.CLINIC,
      ]),
    },
    {
      title: "Subscription",
      href: "/subscription",
      permission: useRole([
        ROLE.CLINIC,
      ]),
    },
  ];

  return (
    <div>
      <aside className="fixed inset-0 top-[89px] w-60 hidden lg:block overflow-y-auto">
        {/* <div className="px-5 pt-5 mb-6"> */}
        {navigation.map((item, i) => {
          return (
            item.permission && (
              <NavLink
                key={i}
                to={item.href}
                title={item.title}
                className={({ isActive }) =>
                  clsx(
                    isActive
                      ? "text-indigo bg-indigo/10 border-l-4 border-l-indigo"
                      : "text-cod-gray border-l-4 border-l-transparent",
                    "flex flex-row text-xl font-semibold px-4 py-6"
                  )
                }
              >
                {item.title}
              </NavLink>
            )
          );
        })}
        <div
          className="text-cod-gray border-l-4 border-l-transparent text-xl font-semibold px-4 py-6"
          onClick={openModel}
        >
          Sign Out
        </div>
        {/* </div> */}
      </aside>
      <div className="-mr-2 mt-[89px] flex lg:hidden">
        <button
          onClick={() => setIsOpen(!isOpen)}
          type="button"
          className="bg-gray-900 inline-flex items-center justify-center p-1.5 rounded-md text-gray-400 hover:text-white hover:bg-gray-800 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-800 focus:ring-white"
          aria-controls="mobile-menu"
          aria-expanded="false"
        >
          <span className="sr-only">Open main menu</span>
          {!isOpen ? (
            <svg
              className="block h-6 w-6"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
              aria-hidden="true"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M4 6h16M4 12h16M4 18h16"
              />
            </svg>
          ) : (
            <svg
              className="block h-6 w-6"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
              aria-hidden="true"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M6 18L18 6M6 6l12 12"
              />
            </svg>
          )}
        </button>
      </div>

      <Transition
        show={isOpen}
        enter="transition ease-out duration-100 transform"
        enterFrom="opacity-0 scale-95"
        enterTo="opacity-100 scale-100"
        leave="transition ease-in duration-75 transform"
        leaveFrom="opacity-100 scale-100"
        leaveTo="opacity-0 scale-95"
      >
        {() => (
          <div className="lg:hidden" id="mobile-menu">
            <div ref={ref} className="px-2 pt-2 pb-3 space-y-1 sm:px-3">
              {navigation.map((item, i) => {
                return (
                  item.permission && (
                    <NavLink
                      key={i}
                      to={item.href}
                      title={item.title}
                      onClick={() => setIsOpen(false)}
                      className={({ isActive }) =>
                        clsx(
                          isActive
                            ? "text-indigo bg-indigo/10 border-l-4 border-l-indigo"
                            : "text-cod-gray border-l-4 border-l-transparent",
                          "flex flex-row text-xl font-semibold px-4 py-5"
                        )
                      }
                    >
                      {item.title}
                    </NavLink>
                  )
                );
              })}
              <div
                className="text-cod-gray border-l-4 border-l-transparent text-xl font-semibold px-4 py-5"
                onClick={openModel}
              >
                Sign Out
              </div>
            </div>
          </div>
        )}
      </Transition>
      <Modal
        title="Sign Out"
        maxWidth="32rem"
        show={modelOpen}
        onClose={closeModal}
        showCancel={false}
      >
        <div className="mb-4 text-center font-light">
          Are you sure you want to Sign out
        </div>
        <div className="flex items-center justify-center space-x-3">
          <Button type="button" className="rounded-lg" color="indigo" outlined onClick={closeModal}>
            Cancel
          </Button>
          <Button
            type="button"
            width="28"
            color="indigo"
            className="rounded-lg text-white"
            loading={isLoaded}
            onClick={onSignOut}
          >
            Sign out
          </Button>
        </div>
      </Modal>
    </div>
  );
};

export default Sidebar;
import { changePassword } from 'actions/user';
import { Button, Modal } from 'components/ui';
import React, { useEffect, useRef, useState } from 'react'
import { useForm } from 'react-hook-form';
import toast from 'react-hot-toast';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';

const ChangePassword = () => {

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [isOpen, setIsOpen] = useState(false);
  const [loading, setLoading] = useState(false);

  const {
    register,
    handleSubmit,
    watch,
    setValue,
    formState: { errors },
  } = useForm();

  const password = useRef({});
  password.current = watch("newPassword", "");

  function closeModal() {
    setIsOpen(false);
    navigate("/settings")
  }

  const onSubmit = (data) => {
    setLoading(true);
    dispatch(changePassword(data))
      .then(() => {
        setValue("oldPassword", "");
        setValue("newPassword", "");
        setValue("confirmationPassword", "");
        toast.success("Password changed successfully.");
        navigate("/settings");
      })
      .catch((error) => {
        setLoading(false);
        console.log(error);
        toast.error(error.response.data.errors[0].detail);
      })
      .finally(() => {
        setLoading(false);
      });
  }

  useEffect(() => {
    setIsOpen(true);
  }, []);

  return (
    <Modal
      title="Change Password"
      maxWidth="32rem"
      show={isOpen}
      onClose={closeModal}
      showCancel={false}
    >
      <div className="mb-4 text-center font-light">
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="mb-4">
            <div className="mb-2">
              <div className="mb-2 text-sm font-medium">Current Password</div>
              <input
                type="password"
                placeholder="Current Password"
                className="bg-alabaster border-none w-full rounded-md"
                {...register("oldPassword", { required: true })}
              />
              {errors?.oldPassword?.type === "required" && (
                <div className="mt-2 text-sm text-burnt-sienna">
                  Current Password is required.
                </div>
              )}
            </div>
            <div className="mb-2">
              <div className="mb-2 text-sm font-medium">New Password</div>
              <input
                type="password"
                placeholder="New Password"
                className="bg-alabaster border-none w-full rounded-md"
                {...register("newPassword", {
                  required: true,
                  minLength: {
                    value: 8,
                    message: "Password must have at least 8 characters",
                  },
                })}
              />
              {errors?.newPassword?.type === "required" && (
                <div className="mt-2 text-sm text-burnt-sienna">
                  New Password is required.
                </div>
              )}
              {errors?.newPassword?.type === "minLength" && (
                <div className="mt-2 text-sm text-burnt-sienna">
                  {errors?.newPassword.message}
                </div>
              )}
            </div>
            <div className="mb-2">
              <div className="mb-2 text-sm font-medium">Re-enter new Password</div>
              <input
                type="password"
                placeholder="Re-enter new Password"
                className="bg-alabaster border-none w-full rounded-md"
                {...register("confirmationPassword", {
                  required: true,
                  validate: (value) =>
                    value === password.current || "Password does not match",
                })}
              />
              {errors?.confirmationPassword?.type === "required" && (
                <div className="mt-2 text-sm text-burnt-sienna">
                  Re-enter new Password is required.
                </div>
              )}
              {errors.confirmationPassword && (
                <div className="mt-2 text-sm text-burnt-sienna">
                  {errors.confirmationPassword.message}
                </div>
              )}
            </div>
          </div>
          <div className="flex items-center justify-center space-x-3">
            <Button
              type="submit"
              className="rounded-lg text-white bg-indigo"
              width="48"
              loading={loading}
            >
              Change Password
            </Button>
            <Button
              type="button"
              className="rounded-lg"
              outlined
              onClick={closeModal}>
              Cancel
            </Button>
          </div>
        </form>
      </div>
    </Modal>
  )
}

export default ChangePassword
import { retriveClinics } from "actions/clinics";
import React, { useCallback, useEffect, useState } from "react";
import DataTable from "react-data-table-component";
import toast from "react-hot-toast";
import { AiOutlineSearch } from "react-icons/ai";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import customStyles from "utils/data-table-style";

const Clinic = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [clinics, setClinics] = useState([]);
  const [page, setPage] = useState(1);
  const [isLoaded, setIsLoaded] = useState(false);
  const [totalRows, setTotalRows] = useState(0);
  const [perPage, setPerPage] = useState(10);
  const [search, setSearch] = useState();

  const handlePageChange = (page) => {
    setPage(page);
  };

  const handlePerRowsChange = async (newPerPage, page) => {
    setPerPage(newPerPage);
  };

  const columns = [
    {
      name: "Logo",
      cell: (row) => (
        <img
          className="mr-2 inline h-12 w-12 rounded-full object-cover"
          src={row?.accessUrl}
          alt="service-logo"
        />
      ),
    },
    {
      name: "Name",
      selector: (row) => row.name,
    },
    {
      name: "Phone",
      selector: (row) => row.phone,
    },
    {
      name: "email",
      selector: (row) => row.emailAddress,
    },
    {
      name: "Owner Name",
      selector: (row) => (
        <div>
          {row?.createdBy?.firstName || "-"} {row?.createdBy?.lastName || "-"}
        </div>
      ),
    },
    {
      name: "Services Privide",
      selector: (row) => <div>{row?.clinicServices?.length}</div>,
    },
    {
      name: "Subscribed",
      selector: (row) => (
        <div>
          {row.isSubscriptionActive ? (
            <div className="bg-malachite px-2 py-2 text-white">Yes</div>
          ) : (
            <div className="bg-burnt-sienna px-2 py-2 text-white">No</div>
          )}
        </div>
      ),
    },
  ];

  const fetchClinics = useCallback(() => {
    const params = {
      page: page,
      limit: perPage,
      sort: "updatedAt",
      order: "desc",
      search: search,
      include:
        "openingDays,openingDays.clinicHours,clinicServices,clinicServices.services,clinicServices.serviceOpeningDays,clinicServices.serviceOpeningDays.serviceHours,createdBy",
    };
    dispatch(retriveClinics(params))
      .then((result) => {
        setIsLoaded(true);
        setClinics(result?.data);
        setTotalRows(result?.meta?.total);
      })
      .catch((error) => {
        toast.error(error.response.data.errors[0].detail);
        setIsLoaded(true);
      })
      .finally(() => {
        setIsLoaded(false);
      });
  }, [dispatch, setClinics, page, perPage, search]);

  useEffect(() => {
    fetchClinics();
  }, [fetchClinics]);

  return (
    <section className="ml-6 mr-7 min-h-screen items-center justify-center py-6">
      <div>
        <div className="flex mb-4 justify-between items-center">
          <div className="text-xl font-semibold">Clinics</div>
          <div className="flex space-x-3 fill-alabaster">
            <div className="relative h-10 w-full xl:w-auto">
              <AiOutlineSearch className="text-storm-gray absolute inset-y-0 left-3 my-auto" />
              <input
                type="text"
                className="w-full rounded-md border-none bg-white pl-9 font-medium focus:ring-0 xl:w-[320px]"
                placeholder="Search"
                onChange={(e) => {
                  setSearch(e.target.value);
                }}
              />
            </div>
            {/* <div>
              <Button
                type="button"
                className="bg-indigo text-white rounded-md"
                width="full"
                text="base"
                onClick={() => navigate("/category/create")}
              >
                Add
              </Button>
            </div> */}
          </div>
        </div>
        <div className="w-full rounded-lg bg-white py-7 px-8">
          <div className="w-auto rounded-lg">
            <DataTable
              columns={columns}
              data={clinics}
              pagination
              paginationServer
              onRowClicked={(row) => navigate(`/clinic/${row.id}/view`)}
              paginationTotalRows={totalRows}
              onChangePage={handlePageChange}
              onChangeRowsPerPage={handlePerRowsChange}
              progressPending={isLoaded}
              responsive={true}
              customStyles={customStyles}
            />
          </div>
        </div>
      </div>
    </section>
  );
};

export default Clinic;

import React from "react";

import { useFormContext } from "react-hook-form";
import usePlacesAutocomplete, {
  getGeocode,
  getLatLng
} from "use-places-autocomplete";

function Search() {
  const { register, setValue } = useFormContext();

  const {
    ready,
    value,
    suggestions: { status, data },
    setValue: setPlacesAutoCompleteValue,
    clearSuggestions
  } = usePlacesAutocomplete({
    debounce: 300,
  });

  const handlePlacesAutocompleteSelect = ({ description }) => () => {
    setPlacesAutoCompleteValue(description, false);
    setValue("address", description)
    clearSuggestions();

    getGeocode({ address: description })
      .then((results) => getLatLng(results[0]))
      .then(({ lat, lng }) => {
        setValue("latitude", lat)
        setValue("longitude", lng)
        console.log("📍 Coordinates: ", { lat, lng });
      })
      .catch((error) => {
        console.log("😱 Error: ", error);
      });
  };

  const renderSuggestions = () =>
    data.map((suggestion) => {
      const {
        place_id,
        structured_formatting: { main_text, secondary_text }
      } = suggestion;

      return (
        <li key={place_id} onClick={handlePlacesAutocompleteSelect(suggestion)}>
          <strong>{main_text}</strong> <small>{secondary_text}</small>
        </li>
      );
    });

  return (
    <div>
      <input
        type="address"
        className="bg-alabaster w-full border-none rounded-md py-2 px-2"
        placeholder="Enter address"
        autoComplete="off"
        {...register("address", {
          required: false,
          onChange: (e) => {
            setPlacesAutoCompleteValue(e.target.value);
          },
          disabled: !ready
        })}
      />
      {status === "OK" && <ul>{renderSuggestions()}</ul>}
    </div>
  );
}
export default Search;

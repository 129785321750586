import { createCategory } from 'actions/category';
import { createSignedUrl, uploadFile } from 'actions/fileUpload';
import { Button } from 'components/ui';
import React, { useState } from 'react'
import { useForm } from 'react-hook-form';
import toast from 'react-hot-toast';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';

const CreateCategory = () => {

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [loading, setLoading] = useState(false);

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const onSubmit = async (data) => {
    setLoading(true);
    let s3Key
    if (data?.image) {
      const body = {
        fileName: data?.image[0]?.name,
        mimeType: data?.image[0]?.type,
        directory: "category"

      }
      await dispatch(createSignedUrl(body)).then(async (result) => {
        s3Key = result?.s3Key
        await dispatch(uploadFile(result?.signedUrl, data?.image[0]));
        data.image = s3Key;
        console.log(result, "sawsijd");
      })
        .catch((error) => {
          toast.error(error.response.data.errors[0].detail);
        })
    }
    await dispatch(createCategory(data)).then((result) => {
      toast.success("Category Create Successfully");
      navigate("/category")
    })
      .catch((error) => {
        toast.error(error.response.data.errors[0].detail);
      })
  }

  return (
    <section className="min-h-screen ml-6 mr-7 py-6 justify-center items-center">
      <div>
        <div className='mb-4'>
          <div className='text-xl font-semibold'>Add a new Category</div>
        </div>
        <div>
          <form
            onSubmit={handleSubmit(onSubmit)}
          >
            <div className='px-8 py-8 rounded-lg bg-white w-80'>
              <div>
                <div className="mb-1 text-sm font-medium">Category Name</div>
                <input
                  type="text"
                  placeholder="Category Name"
                  className="bg-alabaster border-none w-full rounded-md"
                  {...register("name", { required: true })}
                />
                {errors?.name?.type === "required" && (
                  <div className="mt-1 text-sm text-burnt-sienna">
                    Category Name is required.
                  </div>
                )}
              </div>
              <div className="mt-4">
                <div className="mb-1 text-sm font-medium">Category Logo</div>
                <input
                  type="file"
                  className="bg-alabaster w-full border-none rounded-md"
                  {...register("image", { required: true })}
                />
                {errors?.image?.type === "required" && (
                  <div className="mt-1 text-sm text-burnt-sienna">
                    Category Logo is required.
                  </div>
                )}
              </div>
            </div>
            <div className="pt-6">
              <Button
                type="submit"
                className="bg-indigo text-white h-12 rounded-lg"
                text="lg"
                loading={loading}
              >
                Save
              </Button>
            </div>
          </form>
        </div>
      </div>
    </section>
  )
}

export default CreateCategory
import React from "react";

function Tag({ className, onDeleteTag, value, color }) {
    var tag = (
        <div className="bg-white px-2 rounded-md text-lg">
            {value}
            <span
                className="cursor-pointer bg-transparent pl-2 text-burnt-sienna text-2xl" 
                onClick={e => onDeleteTag(e, value)}
            >
                &times;
            </span>
        </div>
    );
    return <React.Fragment>{tag}</React.Fragment>;
}

export default Tag;

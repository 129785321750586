import { Header } from "components/common";
import { Button } from "components/ui";
import React, { useCallback, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import { verifyEmail } from "actions/user";

const Step5VerifyClinicEmail = () => {
  const { token } = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const fetchVerification = useCallback(() => {
    dispatch(verifyEmail(token))
      .then((res) => {
        console.log(res);
      })
      .catch((err) => console.log(err));
  }, [dispatch, token]);

  useEffect(() => {
    fetchVerification();
  }, [fetchVerification]);

  return (
    <>
      <Header />
      <section className="flex min-h-screen flex-col items-center justify-center bg-[#F3F4F7]">
        <div className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
          <div className="mx-auto mb-6 max-w-4xl rounded-xl bg-white">
            <div className="py-8 px-5 text-center lg:pt-9 lg:px-16">
              <div className="mb-4 text-sm font-normal">
                Your account has been verified successfully.
              </div>
              <div className="text-sm font-normal">Please Login.</div>
            </div>
            <div className="flex justify-center space-x-3 pb-9">
              <Button
                className="m-2 rounded bg-indigo font-normal text-white"
                text="lg"
                onClick={() => navigate("/login")}
              >
                Login
              </Button>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Step5VerifyClinicEmail;

import { createBrowserRouter, createRoutesFromElements, Route } from "react-router-dom";

import { Layout } from "components/common";

import Index from "routes";

import Appointments from "pages/Appointments";
import ViewAppointment from "pages/Appointments/view";
import CreateAppointment from "pages/Appointments/create";
import EditAppointment from "pages/Appointments/edit";

import Patients from "pages/Patients";
import CreatePatient from "pages/Patients/create";

import NMS from "pages/NMS";
import ViewNMS from "pages/NMS/view";
import CreateNMS from "pages/NMS/create";
import EditNMS from "pages/NMS/edit";
import SignUp from "pages/Signup";
import CreateServices from "pages/Services/create";
import EditServices from "pages/Services/edit";
import ClinicServices from "pages/Clinic-services";
import Services from "pages/Services";
import Signin from "pages/Signin";
import ForgotPWDReset from "pages/ForgotPWDReset";
import CreateClinic from "pages/Clinic/create";
import WelcomePage from "pages/Clinic/welcomePage";
import EmailVerification from "pages/Clinic/emailVerification";
import VerifyClinicEmail from "pages/StepsOfClinic/VerifyClinicEmail";
import Category from "pages/Category";
import CreateCategory from "pages/Category/create";
import EditCategory from "pages/Category/edit";
import Clinic from "pages/Clinic";
import ViewClinic from "pages/Clinic/view";
import Setting from "pages/AccountSetting/setting";
import ChangePassword from "pages/ChangePassword/changePassword";
import RequireAuth from "RequireAuth";
import { ROLE } from "utils/roles";
import Unauthorized from "components/ui/Unauthorized";
import Subscription from "pages/Subscription";
import App from "App";
import ClinicServicesEdit from "pages/Clinic-services/edit";
import ViewPatient from "pages/Patients/view";
import DragNDrop from "pages/DragNDrop/DragNDrop";
import ViewServices from "pages/Services/view";
import CreateNewClinicService from "pages/Clinic-services/create";
import ErrorPage from "routes/error-page";
import AllSubscription from "pages/Subscription/subscription";
import Success from "pages/Subscription/Payment/success";
import Cancel from "pages/Subscription/Payment/cancel";
import NMSReport from "pages/NMS/NMSReport";
import AppointmentReport from "pages/Appointments/AppointmentReport";
import ContactUs from "pages/ContactUs";
import CreateBlog from "pages/Blogs/create";
import Blogs from "pages/Blogs";
import EditBlog from "pages/Blogs/edit";
import HomePage from "pages/LandingPages/home";
import LandingBlogs from "pages/LandingPages/Blogs";
import BlogsDetails from "pages/LandingPages/BlogsDetails";
import CreateOurClients from "pages/Our-Clients/create";
import OurClients from "pages/Our-Clients";
import EditOurClients from "pages/Our-Clients/edit";
import TermsAndConditions from "pages/PrivacyPolicyAndTerms/TermsAndConditions";
import PrivacyPolicy from "pages/PrivacyPolicyAndTerms/PrivacyPolicy";
import AppSlider from "pages/AppSlider";
import CreateSlider from "pages/AppSlider/create";
import EditAppSlider from "pages/AppSlider/edit";
import HomePageNew from "pages/LandingPages/NewHomePage";
import Chat from "pages/Chat/chat";

// const auth = JSON.parse(localStorage.getItem("clinic-u"));
console.log("route call");
export const router = createBrowserRouter(
  createRoutesFromElements(
    <Route element={<App />}>
      {/* ProtectedRoute   */}
      <Route element={<Layout />} errorElement={<ErrorPage />}>

        <Route element={<RequireAuth allowedRoles={[ROLE.ADMIN, ROLE.CLINIC]} />}>
          <Route path="/dashboard" element={<Index />} />
        </Route>
        <Route element={<RequireAuth allowedRoles={[ROLE.ADMIN]} />}>
          <Route path="services" element={<Services />} />
        </Route>
        <Route element={<RequireAuth allowedRoles={[ROLE.ADMIN]} />}>
          <Route path="services/create" element={<CreateServices />} />
        </Route>
        <Route element={<RequireAuth allowedRoles={[ROLE.ADMIN]} />}>
          <Route path="services/:servicesId/edit" element={<EditServices />} />
        </Route>
        <Route element={<RequireAuth allowedRoles={[ROLE.ADMIN]} />}>
          <Route path="services/:serviceId/view" element={<ViewServices />} />
        </Route>
        <Route element={<RequireAuth allowedRoles={[ROLE.ADMIN]} />}>
          <Route path="category" element={<Category />} />
        </Route>
        <Route element={<RequireAuth allowedRoles={[ROLE.ADMIN]} />}>
          <Route path="category/create" element={<CreateCategory />} />
        </Route>
        <Route element={<RequireAuth allowedRoles={[ROLE.ADMIN]} />}>
          <Route path="category/:categoryId/edit" element={<EditCategory />} />
        </Route>
        <Route element={<RequireAuth allowedRoles={[ROLE.ADMIN, ROLE.CLINIC]} />}>
          <Route path="appointments" element={<Appointments />} />
        </Route>
        <Route element={<RequireAuth allowedRoles={[ROLE.ADMIN, ROLE.CLINIC]} />}>
          <Route path="appointments/:appointmentId" element={<ViewAppointment />} />
        </Route>
        <Route element={<RequireAuth allowedRoles={[ROLE.ADMIN, ROLE.CLINIC]} />}>
          <Route path="appointments/create" element={<CreateAppointment />} />
        </Route>
        <Route element={<RequireAuth allowedRoles={[ROLE.ADMIN, ROLE.CLINIC]} />}>
          <Route path="appointments/:appointmentId/edit" element={<EditAppointment />} />
        </Route>
        <Route element={<RequireAuth allowedRoles={[ROLE.ADMIN, ROLE.CLINIC]} />}>
          <Route path="appointments/report/:appointmentId" element={<AppointmentReport />} />
        </Route>
        <Route element={<RequireAuth allowedRoles={[ROLE.ADMIN, ROLE.CLINIC]} />}>
          <Route path="patients" element={<Patients />} />
        </Route>
        <Route element={<RequireAuth allowedRoles={[ROLE.ADMIN, ROLE.CLINIC]} />}>
          <Route path="patients/create" element={<CreatePatient />} />
        </Route>
        <Route element={<RequireAuth allowedRoles={[ROLE.ADMIN, ROLE.CLINIC]} />}>
          <Route path="patients/:patientId" element={<ViewPatient />} />
        </Route>
        <Route element={<RequireAuth allowedRoles={[ROLE.ADMIN, ROLE.CLINIC]} />}>
          <Route path="nms" element={<NMS />} />
        </Route>
        <Route element={<RequireAuth allowedRoles={[ROLE.ADMIN, ROLE.CLINIC]} />}>
          <Route path="nms/:nmsId" element={<ViewNMS />} />
        </Route>
        <Route element={<RequireAuth allowedRoles={[ROLE.ADMIN, ROLE.CLINIC]} />}>
          <Route path="nms/create" element={<CreateNMS />} />
        </Route>
        <Route element={<RequireAuth allowedRoles={[ROLE.ADMIN, ROLE.CLINIC]} />}>
          <Route path="nms/:nmsId/edit" element={<EditNMS />} />
        </Route>
        <Route element={<RequireAuth allowedRoles={[ROLE.ADMIN, ROLE.CLINIC]} />}>
          <Route path="nms/report/:nmsId" element={<NMSReport />} />
        </Route>
        <Route element={<RequireAuth allowedRoles={[ROLE.ADMIN]} />}>
          <Route path="clinics" element={<Clinic />} />
        </Route>
        <Route element={<RequireAuth allowedRoles={[ROLE.ADMIN]} />}>
          <Route path="clinic/:clinicId/view" element={<ViewClinic />} />
        </Route>
        <Route element={<RequireAuth allowedRoles={[ROLE.ADMIN, ROLE.CLINIC]} />}>
          <Route path="settings" element={<Setting />} />
        </Route>
        <Route element={<RequireAuth allowedRoles={[ROLE.ADMIN, ROLE.CLINIC]} />}>
          <Route path="change-passwrd" element={<ChangePassword />} />
        </Route>
        <Route element={<RequireAuth allowedRoles={[ROLE.CLINIC]} />}>
          <Route path="clinic-services" element={<ClinicServices />} />
        </Route>
        <Route element={<RequireAuth allowedRoles={[ROLE.CLINIC]} />}>
          <Route path="clinic-services/create" element={<CreateNewClinicService />} />
        </Route>
        <Route element={<RequireAuth allowedRoles={[ROLE.CLINIC]} />}>
          <Route path="clinic-services/:serviceId" element={<ClinicServicesEdit />} />
        </Route>
        <Route element={<RequireAuth allowedRoles={[ROLE.CLINIC]} />}>
          <Route path="subscription" element={<Subscription />} />
        </Route>
        <Route element={<RequireAuth allowedRoles={[ROLE.ADMIN]} />}>
          <Route path="contact-us" element={<ContactUs />} />
        </Route>
        <Route element={<RequireAuth allowedRoles={[ROLE.ADMIN]} />}>
          <Route path="blogs" element={<Blogs />} />
        </Route>
        <Route element={<RequireAuth allowedRoles={[ROLE.ADMIN]} />}>
          <Route path="blog/create" element={<CreateBlog />} />
        </Route>
        <Route element={<RequireAuth allowedRoles={[ROLE.ADMIN]} />}>
          <Route path="blog/:id" element={<EditBlog />} />
        </Route>
        <Route element={<RequireAuth allowedRoles={[ROLE.ADMIN]} />}>
          <Route path="slider" element={<AppSlider />} />
        </Route>
        <Route element={<RequireAuth allowedRoles={[ROLE.ADMIN]} />}>
          <Route path="slider/create" element={<CreateSlider />} />
        </Route>
        <Route element={<RequireAuth allowedRoles={[ROLE.ADMIN]} />}>
          <Route path="slider/:id" element={<EditAppSlider />} />
        </Route>
        <Route element={<RequireAuth allowedRoles={[ROLE.ADMIN]} />}>
          <Route path="our-client" element={<OurClients />} />
        </Route>
        <Route element={<RequireAuth allowedRoles={[ROLE.ADMIN]} />}>
          <Route path="our-client/create" element={<CreateOurClients />} />
        </Route>
        <Route element={<RequireAuth allowedRoles={[ROLE.ADMIN]} />}>
          <Route path="our-client/:id" element={<EditOurClients />} />
        </Route>
        <Route element={<RequireAuth allowedRoles={[ROLE.ADMIN, ROLE.CLINIC]} />}>
          <Route path="chat" element={<Chat />} />
        </Route>
      </Route>

      {/* UnProtectedRoute   */}
      <Route>
        <Route path="unauthorized" element={<Unauthorized />} />
        <Route path="login" element={<Signin />} />
        <Route path="clinic/create" element={<CreateClinic />} />
        <Route path="verify/:token" element={<VerifyClinicEmail />} />
        <Route path="clinic/welcomePage" element={<WelcomePage />} />
        <Route path="clinic/emailVerification" element={<EmailVerification />} />
        {/* {!auth && <Route path="register" element={<SignUp />} />} */}
        <Route path="register" element={<SignUp />} />
        <Route path="auth/reset/:token" element={<ForgotPWDReset />} />
        <Route path="drag" element={<DragNDrop />} />
        <Route path="subscription/all" element={<AllSubscription />} />
        <Route path="payment/cancel" element={<Cancel />} />
        <Route path="payment/success/:sessionId" element={<Success />} />
        <Route path="/" element={<HomePageNew />} />
        <Route path="landing-blogs" element={<LandingBlogs />} />
        <Route path="landing-blogs/:id" element={<BlogsDetails />} />
        <Route path="termsandconditions" element={<TermsAndConditions />} />
        <Route path="privacypolicy" element={<PrivacyPolicy />} />
      </Route>
    </Route>
  )
);

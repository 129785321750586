import CategryService from "services/category";
import {
    CREATE_CATEGORY,
    GET_ALL_CATEGORY,
    GET_ONE_CATEGORY,
    REMOVE_CATEGORY,
    UPDATE_CATEGORY
} from "./type";

export const createCategory = (data) => async (dispatch) => {
    try {
        const res = await CategryService.create(data);

        dispatch({
            type: CREATE_CATEGORY,
            payload: res.data,
        });

        return Promise.resolve(res.data);
    } catch (error) {
        return Promise.reject(error);
    }
};

export const updateCategory = (id, data) => async (dispatch) => {
    try {
        const res = await CategryService.update(id, data);

        dispatch({
            type: UPDATE_CATEGORY,
            payload: res.data,
        });

        return Promise.resolve(res.data);
    } catch (error) {
        return Promise.reject(error);
    }
};

export const deleteCategory = (id) => async (dispatch) => {
    try {
        const res = await CategryService.remove(id);

        dispatch({
            type: REMOVE_CATEGORY,
            payload: res.data,
        });

        return Promise.resolve(res.data);
    } catch (error) {
        return Promise.reject(error);
    }
};

export const retrieveOneCategory = (id, params) => async (dispatch) => {
    try {
        const res = await CategryService.getOne(id, params);

        dispatch({
            type: GET_ONE_CATEGORY,
            payload: res.data,
        });

        return Promise.resolve(res.data);
    } catch (error) {
        return Promise.reject(error);
    }
};

export const retriveCategry = (data) => async (dispatch) => {
    try {
        const res = await CategryService.getAll(data);

        dispatch({
            type: GET_ALL_CATEGORY,
            payload: res.data,
        });

        return Promise.resolve(res.data);
    } catch (error) {
        return Promise.reject(error);
    }
};
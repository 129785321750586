import { Button } from 'components/ui';
import React, { useState } from 'react'
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd'
import { AiOutlinePlus } from 'react-icons/ai';

const finalSpaceCharacters = [
  {
    inputType: "",
    questionText: "",
    placeholder: "",
    options: "",
    position: "0"
  }
]

const DragNDrop = () => {
  const [characters, updateCharacters] = useState([]);

  const addTableRowsClinic = () => {
    const rowsInput = {
      inputType: "",
      questionText: "",
      placeholder: "",
      options: "",
      position: `${characters.length + 1}`
    }
    updateCharacters([...characters, rowsInput])
  }

  function handleOnDragEnd(result) {
    if (!result.destination) return;

    const items = Array.from(characters);
    const [reorderedItem] = items.splice(result.source.index, 1);
    items.splice(result.destination.index, 0, reorderedItem);
    console.log(items,"items");
    updateCharacters(items);
  }

  console.log(characters,"characters");
  return (
    <div className="App">
      <header className="App-header">
        <h1>Final Space Characters</h1>
        <Button
          className="bg-indigo rounded-lg"
          type="button"
          onClick={addTableRowsClinic}
        >
          <AiOutlinePlus className='fill-white' />
        </Button>
        <DragDropContext onDragEnd={handleOnDragEnd}>
          <Droppable droppableId="characters">
            {(provided) => (
              <ul className="characters" {...provided.droppableProps} ref={provided.innerRef}>
                {characters.map((data, index) => {
                  return (
                    <Draggable key={`${data.position}`} draggableId={`${data.position}`} index={index}>
                      {(provided) => (
                        <div className='flex' ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps}>
                          <div>Hold me</div>
                          <div>{data.position}: position</div>
                          <select
                            onChange={(e) => console.log(e.target.value)}
                            className="w-36 rounded-md border-none bg-alabaster focus:border-none focus:ring-0"
                          >
                            <option value="sd">Choose an option</option>
                            <option value="text">Text</option>
                            <option value="radio">Radio Button</option>
                            <option value="checkbox">CheckBox</option>
                            <option value="select">Select</option>
                            <option value="multiselect">Multiselect</option>
                            <option value="textarea">Long Text</option>
                          </select>
                        </div>
                      )}
                    </Draggable>
                  );
                })}
                {provided.placeholder}
              </ul>
            )}
          </Droppable>
        </DragDropContext>
      </header>
    </div>
  )
}

export default DragNDrop
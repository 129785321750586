import React from 'react'

const MobileApp = () => {
    return (
        <section className="mt-24 flex flex-col items-center justify-center">
            <div className="max-w-[1620px] bg-[#F3FBFF] rounded-2xl flex space-x-5 justify-center items-center py-24 px-36 ">
                <div>
                    <img src="/img/landingPage/Mockup1.png" alt="clinic2go" />
                </div>
                <div>
                    <div className="flex flex-col justify-start items-stajustify-start mb-7">
                        <div className="font-plus-jakarta-sans font-semibold text-base text-indigo uppercase">Clinic2Go</div>
                        <div className="font-plus-jakarta-sans font-bold text-5xl text-elephant">
                            Mobile app is available Get HealthCare for free!
                        </div>
                        <div className="font-plus-jakarta-sans font-normal text-base text-fiord mt-3">
                            Get on-demand access to a pharmacies on your phone with the free HealthCare mobile app. Download and register on our app for free and feel safe for all your family
                        </div>
                        <div className="space-x-5 mt-7 lg:flex hidden">
                            <img src="/img/landingPage/Google-Play.png" alt="clinic2go" onClick={() => window.open("https://play.google.com/store/apps/details?id=com.clinic2")}/>
                            <img src="/img/landingPage/App-Store.png" alt="clinic2go" onClick={() => window.open("https://apps.apple.com/gb/app/clinic2go/id6448028689")}/>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default MobileApp
import { retrieveClinicSelectPatient } from "actions/clinicSelectPatient";
import { createNMS } from "actions/nms";
import { Button, TagsInput } from "components/ui";
import _ from "lodash";
import moment from "moment";
import React, { useCallback, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import toast from "react-hot-toast";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import Select from "react-select";

const CreateNMS = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const auth = JSON.parse(localStorage.getItem("clinic-u"));
  const clinicId = auth?.clinic[0]?.id;

  const [loading, setLoading] = useState(false);
  const [search, setSearch] = useState("");
  const [patient, setPatient] = useState([]);
  const [selectedPatient, setSelectedPatient] = useState();
  const [tags, setTags] = useState([]);
  const [tagsError, setTagsError] = useState(false);

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const fetchPatient = useCallback(() => {
    const params = {
      page: 1,
      limit: 50,
      sort: "createdAt",
      order: "desc",
      search: search,
      "filter[clinic.id]": clinicId,
      include: "patient,clinic",
    };
    dispatch(retrieveClinicSelectPatient(params))
      .then((result) => {
        setLoading(true);
        setPatient(result?.data);
      })
      .catch((error) => {
        toast.error(error.response.data.errors[0].detail);
        setLoading(true);
      })
      .finally(() => {
        setLoading(false);
      });
  }, [dispatch, setPatient, search, clinicId]);

  const selectOptionsName = (option) => {
    const mappedOptions = _.uniqBy(option, "patient.id")?.map((option) => {
      return {
        value: option?.patient?.id,
        label: `${
          option?.patient?.firstName !== null ? option?.patient?.firstName : ""
        } 
        ${option?.patient?.lastName !== null ? option?.patient?.lastName : ""}`,
        detail: option?.patient,
      };
    });
    return mappedOptions;
  };

  const selectOptionsEmail = (option) => {
    const mappedOptions = _.uniqBy(option, "patient.id")?.map((option) => {
      return {
        value: option?.patient?.id,
        label: option?.patient?.emailAddress,
        detail: option?.patient,
      };
    });
    return mappedOptions;
  };

  const handleInputChange = (inputValue) => {
    setSearch(inputValue);
  };

  const onAddTag = (tag) => {
    setTags([...tags, tag[0]]);
  };

  const onDeleteTag = (tag) => {
    let remainingTags = tags.filter((t) => {
      return t !== tag;
    });
    setTags([...remainingTags]);
  };

  const onSubmit = (data) => {
    if (tags.length <= 0) {
      setTagsError(true);
    } else {
      if (data?.secondFollowUp === "") {
        data.secondFollowUp = null;
      }
      data.medicines = tags;
      data.patient = {
        id: selectedPatient?.id,
      };
      data.clinic = {
        id: clinicId,
      };
      dispatch(createNMS(data))
        .then((response) => {
          setLoading(false);
          window.gtag('event', 'page_view', {
            page_title: "NMS Create",
            page_path: "/nms",
            page_location: "/nms"
          })
          navigate("/nms");
          toast.success("NMS Added successfully!");
        })
        .catch((error) => {
          setLoading(false);
          toast.error(error.response?.data?.errors[0]?.detail);
        });
    }
  };

  useEffect(() => {
    fetchPatient();
  }, [fetchPatient]);

  console.log(tags);

  return (
    <section className="ml-6 mr-7 min-h-screen items-center justify-center py-6">
      <div>
        <div className="mb-4">
          <div className="text-xl font-semibold">Create NMS</div>
          <div className="text-sm">
            I agree that the information obtained during this services can be
            shared with:-
          </div>
          <div className="text-sm">
            My doctor (GP) to help them provide care to me.
          </div>
        </div>
        <div>
          <form onSubmit={handleSubmit(onSubmit)}>
            <div className="mb-6 w-auto rounded-t-lg bg-white px-4 py-4 lg:px-7 lg:py-7">
              <div className="flex justify-end">
                <Button
                  type="button"
                  className="rounded-md bg-indigo text-white"
                  text="base"
                  onClick={() => setSelectedPatient(null)}
                >
                  Clear
                </Button>
              </div>
              <div className="lg:grid lg:grid-cols-3">
                <div className="mb-2 pr-0 lg:pr-16">
                  <div className="mb-1 text-sm font-medium">Patient’s name</div>
                  {selectedPatient && (
                    <div className="w-full rounded-md border-none bg-alabaster px-2 py-2">
                      {selectedPatient?.firstName !== null &&
                        selectedPatient?.firstName}
                      {selectedPatient?.lastName !== null &&
                        selectedPatient?.lastName}
                    </div>
                  )}
                  {!selectedPatient && (
                    <Select
                      options={selectOptionsName(patient)}
                      className="w-full rounded-md border-none bg-alabaster"
                      onInputChange={handleInputChange}
                      onChange={(e) => {
                        setSelectedPatient(e.detail);
                      }}
                    />
                  )}
                </div>
                <div className="mb-2 pr-0 lg:pr-16">
                  <div className="mb-1 text-sm font-medium">Email</div>
                  {selectedPatient && (
                    <div className="w-full rounded-md border-none bg-alabaster px-2 py-2">
                      {selectedPatient?.emailAddress}
                    </div>
                  )}
                  {!selectedPatient && (
                    <Select
                      options={selectOptionsEmail(patient)}
                      className="w-full rounded-md border-none bg-alabaster"
                      onInputChange={handleInputChange}
                      onChange={(e) => {
                        setSelectedPatient(e.detail);
                      }}
                    />
                  )}
                </div>
                <div className="mb-2 pr-0 lg:pr-16">
                  <div className="mb-1 text-sm font-medium">Phone number</div>
                  {!selectedPatient && (
                    <input
                      placeholder="1234567890"
                      name="phone"
                      className="w-full rounded-md border-none bg-alabaster px-2 py-2"
                    />
                  )}
                  {selectedPatient && (
                    <div className="w-full rounded-md border-none bg-alabaster px-2 py-2">
                      {selectedPatient?.phone}
                    </div>
                  )}
                </div>
              </div>
              <div className="col-span-1">
                <div className="mb-1 text-sm font-medium">Address</div>
                {!selectedPatient && (
                  <input
                    placeholder="address"
                    name="address"
                    className="w-1/3 rounded-md border-none bg-alabaster px-2 py-2"
                  />
                )}
                {selectedPatient && (
                  <div className="w-1/3 rounded-md border-none bg-alabaster px-2 py-2">
                    {selectedPatient?.address || "Not Available"}
                  </div>
                )}
              </div>
            </div>
            <div className="w-auto rounded-b-lg bg-white px-4 py-4">
              <div className="grid grid-cols-1 gap-8 px-4 py-4 sm:grid-cols-3">
                <div>
                  <TagsInput
                    label={"Medicines"}
                    placeHolder={"enter medicines seperated by comma e.g medicine name1,"}
                    className="w-full border-none"
                    onAddTag={onAddTag}
                    onDeleteTag={onDeleteTag}
                    defaultTags={tags}
                    apiKey={"medicines"}
                  />
                  {tagsError && (
                    <div className="mt-1 text-sm text-burnt-sienna">
                      Atleast one medicine name is required.
                    </div>
                  )}
                </div>
              </div>
              <div className="grid grid-cols-1 gap-8 px-4 py-4 sm:grid-cols-3">
                <div>
                  <div className="mb-1 text-sm font-medium">
                    First intervention
                  </div>
                  <input
                    type="datetime-local"
                    min={moment().format("YYYY-MM-DD")}
                    className="w-full rounded-md border-none bg-alabaster"
                    {...register("firstIntervention", { required: true })}
                  />
                  {errors?.firstIntervention?.type === "required" && (
                    <div className="mt-1 text-sm text-burnt-sienna">
                      First intervention date is required.
                    </div>
                  )}
                  {errors?.firstIntervention?.type === "min" && (
                    <div className="mt-1 text-sm text-burnt-sienna">
                      You can Not Select Past Date.
                    </div>
                  )}
                </div>
                <div>
                  <div className="mb-1 text-sm font-medium">
                    Second follow up
                  </div>
                  <input
                    type="datetime-local"
                    min={moment().format("YYYY-MM-DD")}
                    className="w-full rounded-md border-none bg-alabaster"
                    {...register("secondFollowUp", {
                      required: false,
                    })}
                  />
                </div>
              </div>
              <div className="flex items-center space-x-1 px-4 py-4">
                <input
                  id="isVerbalConsent"
                  type="checkbox"
                  name="isVerbalConsent"
                  {...register(`isVerbalConsent`, {
                    required: false,
                    checked: true / false,
                  })}
                />
                <label htmlFor="isVerbalConsent">Verbal consent</label>
              </div>
            </div>
            <div className="pt-6">
              <Button
                type="submit"
                className="h-12 rounded-lg bg-indigo text-white"
                text="lg"
                loading={loading}
              >
                Save NMS
              </Button>
            </div>
          </form>
        </div>
      </div>
    </section>
  );
};

export default CreateNMS;

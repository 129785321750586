import { Button, Timeline } from 'components/ui'
import React, { useEffect, useState } from 'react'
import { GoArrowRight } from "react-icons/go";
import { useNavigate } from 'react-router-dom';

const ClinicOperations = () => {

  const navigate = useNavigate();
  const [currentSlide, setCurrentSlide] = useState(0);

  const timeline = [
    {
      icon: " 01",
      title: "Effortless Information",
      height: 20,
      text: "Seamlessly manage vital patient details through our dedicated clinical panel, enhancing accuracy and efficiency."
    },
    {
      icon: "02",
      title: "Enhance Communication",
      height: 20,
      text: "Facilitate seamless communication by effortlessly sharing reports with patients or practitioners through direct downloads or emails."
    },
    {
      icon: "03",
      title: "Smart Inventory",
      height: 12,
      text: "Maintain a well-controlled inventory of vaccines and medications with our intuitive system."
    }
  ]

  const cards = [
    {
      title: 'Find a service',
      image: 'img/landingPage/service.png',
      description:
        'Our user-friendly platform allows you to search for the specific healthcare service you need, whether it\'s a routine check-up or specialised care.',
    },
    {
      title: 'Search Location',
      image: 'img/landingPage/search.png',
      description: 'Our location search feature discovers clinics or healthcare providers nearby.',
    },
    {
      title: 'Book Your Appointment',
      image: 'img/landingPage/appointment-slide.png',
      description: 'Choose the preferred date and time for your visit to the nearest pharmacy.',
    },
    {
      title: 'Complete Your Questionnaire',
      image: 'img/landingPage/questionnaire.png',
      description: 'Clinic2Go also provides a comprehensive questionnaire to gather essential health information before your visit, ensuring you receive the personalised care you deserve.',
    },
  ];

  useEffect(() => {
    // Automatically switch to the next slide every 2 seconds
    const timer = setInterval(() => {
      setCurrentSlide((prevSlide) => (prevSlide + 1) % Math.ceil(cards?.length / 2));
    }, 2000);

    return () => {
      clearInterval(timer);
    };
  }, [cards?.length]);

  return (
    <section className="bg-gradient-to-r from-[#F3FBFF] to-cornflower mt-9 mb-16 px-2">
      <div className="lg:flex lg:justify-between">
        <div className="max-w-xl lg:ml-32 py-16">
          <div className="text-elephant text-4xl font-plus-jakarta-sans font-bold  mb-9">
            Streamline Pharmacy/Clinic Operations
          </div>
          <div className="mb-7">
            <Timeline timeline={timeline} />
          </div>
          <div className="flex space-x-2">
            {cards?.slice(currentSlide * 2, currentSlide * 2 + 2).map((card, index) => (
              <div
                key={index}
                className="rounded-lg shadow-lg px-3 py-5 bg-white/60 flex flex-col items-center justify-center cursor-pointer"
              >
                <img src={card?.image} alt="clinic2go" className="mb-4" />
                <div className="text-elephant text-xl font-plus-jakarta-sans font-bold mb-2 text-center">{card?.title}</div>
                <div className="text-elephant text-base text-center font-plus-jakarta-sans font-normal mb-2 max-w-xs">
                  {card?.description}
                </div>
              </div>
            ))}
          </div>
          {/* <div className="flex space-x-2">
            <div className="rounded-lg shadow-lg px-3 py-5 bg-white/60 flex flex-col items-center justify-center">
              <img src="img/landingPage/service.png" alt="clinic2go" className="mb-4" />
              <div className="text-elephant text-xl font-plus-jakarta-sans font-bold mb-2">Find a service</div>
              <div className="text-elephant text-base text-center font-plus-jakarta-sans font-normal mb-2 max-w-xs">
                Our user-friendly platform allows you to search for the specific healthcare service you need, whether it's a routine check-up or specialised care.
              </div>
            </div>
            <div className="rounded-lg shadow-lg px-3 py-5 bg-white/60 flex flex-col items-center justify-center">
              <img src="img/landingPage/search.png" alt="clinic2go" className="mb-4" />
              <div className="text-elephant text-xl font-plus-jakarta-sans font-bold mb-2">Search Location</div>
              <div className="text-elephant text-base text-center font-plus-jakarta-sans font-normal mb-2 max-w-xs">
                Our location search feature discovers clinics or healthcare providers nearby.
              </div>
            </div>
            <div className="rounded-lg shadow-lg px-3 py-5 bg-white/60 flex flex-col items-center justify-center">
              <img src="img/landingPage/appointment-slide.png" alt="clinic2go" className="mb-4" />
              <div className="text-elephant text-xl font-plus-jakarta-sans font-bold mb-2">Book Your Appointment</div>
              <div className="text-elephant text-base text-center font-plus-jakarta-sans font-normal mb-2 max-w-xs">
                Need to access a pharmacy? Use our hubs to find relevant services and get the help you need
              </div>
            </div>
            <div className="rounded-lg shadow-lg px-3 py-5 bg-white/60 flex flex-col items-center justify-center">
              <img src="img/landingPage/questionnaire.png" alt="clinic2go" className="mb-4" />
              <div className="text-elephant text-xl font-plus-jakarta-sans font-bold mb-2">Complete Your Questionnaire</div>
              <div className="text-elephant text-base text-center font-plus-jakarta-sans font-normal mb-2 max-w-xs">
                Need to access a pharmacy? Use our hubs to find relevant services and get the help you need
              </div>
            </div>
          </div> */}
          <div className="mt-12">
            <Button
              type="button"
              width="56"
              color="indigo"
              className="rounded-lg text-white"
              loading={false}
              onClick={() => navigate("/register")}
            >
              <div className="flex space-x-3 justify-center items-center">
                <div className="text-white text-lg font-plus-jakarta-sans font-medium">Trial For 3 Months</div>
                <GoArrowRight fill="white" />
              </div>

            </Button>
          </div>
        </div>
        <div className="">
          <img src="img/landingPage/Clinic Operations.png" alt="clinic2go" className="mt-8" />
        </div>
      </div>
    </section>
  )
}

export default ClinicOperations
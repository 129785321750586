import ContactUsService from "services/contact-us";
import { CREATE_CONTACT_US, GET_CONTACT_US } from "./type";

export const retriveContactUs = (data) => async (dispatch) => {
  try {
    const res = await ContactUsService.getAllContactUs(data);

    dispatch({
      type: GET_CONTACT_US,
      payload: res.data,
    });

    return Promise.resolve(res.data);
  } catch (error) {
    return Promise.reject(error);
  }
};

export const createContactUs = (data) => async (dispatch) => {
  try {
    const res = await ContactUsService.create(data);

    dispatch({
      type: CREATE_CONTACT_US,
      payload: res.data,
    });

    return Promise.resolve(res.data);
  } catch (error) {
    return Promise.reject(error);
  }
};
import NMSService from "services/nms";
import {
    CREATE_NMS,
    GET_ALL_NMS,
    GET_ONE_NMS,
    REMOVE_NMS,
    UPDATE_NMS
} from "./type";

export const createNMS = (data) => async (dispatch) => {
    try {
        const res = await NMSService.create(data);

        dispatch({
            type: CREATE_NMS,
            payload: res.data,
        });

        return Promise.resolve(res.data);
    } catch (error) {
        return Promise.reject(error);
    }
};

export const updateNMS = (id, data) => async (dispatch) => {
    try {
        const res = await NMSService.update(id, data);

        dispatch({
            type: UPDATE_NMS,
            payload: res.data,
        });

        return Promise.resolve(res.data);
    } catch (error) {
        return Promise.reject(error);
    }
};

export const deleteNMS = (id) => async (dispatch) => {
    try {
        const res = await NMSService.remove(id);

        dispatch({
            type: REMOVE_NMS,
            payload: res.data,
        });

        return Promise.resolve(res.data);
    } catch (error) {
        return Promise.reject(error);
    }
};

export const retrieveOneNMS = (id, params) => async (dispatch) => {
    try {
        const res = await NMSService.getOne(id, params);

        dispatch({
            type: GET_ONE_NMS,
            payload: res.data,
        });

        return Promise.resolve(res.data);
    } catch (error) {
        return Promise.reject(error);
    }
};

export const retriveNMS = (data) => async (dispatch) => {
    try {
        const res = await NMSService.getAll(data);

        dispatch({
            type: GET_ALL_NMS,
            payload: res.data,
        });

        return Promise.resolve(res.data);
    } catch (error) {
        return Promise.reject(error);
    }
};
import { retriveCategry } from 'actions/category';
import { CardSlider } from 'components/ui';
import React, { useCallback, useEffect, useState } from 'react'
import toast from 'react-hot-toast';
import { useDispatch } from 'react-redux';

const OurServices = () => {

    const dispatch = useDispatch();

    const [category, setCategory] = useState([]);
    const [isLoaded, setIsLoaded] = useState(false);

    const fetchCategory = useCallback(
        () => {
            const params = {
                page: 1,
                limit: 1000,
                sort: "updatedAt",
                order: "desc",
                aggregate: "categoryLogo,serviceLogo",
                include: "services",
            }
            dispatch(retriveCategry(params)).then((result) => {
                setIsLoaded(true);
                setCategory(result?.data);
            })
                .catch((error) => {
                    toast.error(error.response.data.errors[0].detail);
                    setIsLoaded(true);
                })
                .finally(() => {
                    setIsLoaded(false);
                });
        },
        [dispatch, setCategory],
    );

    useEffect(() => {
        fetchCategory();
    }, [fetchCategory])

    return (
        <section className="mt-11 px-2">
            <div>
                <div className="flex flex-col justify-center items-center mb-7">
                    <div className="font-plus-jakarta-sans font-semibold text-base text-indigo uppercase">Clinic2Go</div>
                    <div className="font-plus-jakarta-sans font-bold text-5xl text-elephant">Our Services</div>
                    <div className="font-plus-jakarta-sans font-normal text-base text-fiord mt-3 text-center">Finding the Right Pharmacy / Clinic Has Never Been Simpler</div>
                </div>
                <div className="lg:flex lg:justify-center">
                    <CardSlider cards={category}/>
                </div>
            </div>
        </section>
    )
}

export default OurServices
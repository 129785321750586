import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { Link } from "react-router-dom";
import { Button } from "components/ui";
import { useNavigate } from "react-router-dom";
import { signUp } from "actions/user";
import { useDispatch } from "react-redux";
import toast from "react-hot-toast";
import { clearClinicData, updateClinicData } from "features/clinic/clinicSlice";
import { isEmpty } from "lodash";

const Signup = () => {
  const {
    register,
    watch,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const auth = JSON.parse(localStorage.getItem("clinic-u"));

  const onSubmit = (data) => {
    setLoading(true);
    dispatch(clearClinicData());
    data.role = "clinic";
    dispatch(signUp(data))
      .then((response) => {
        setLoading(false);
        dispatch(updateClinicData({ user: { id: response?.id } }));
        navigate("/clinic/create");
      })
      .catch((error) => {
        setLoading(false);
        toast.error(
          error.response?.data?.error?.detail ||
          error.response?.data?.errors[0]?.detail
        );
      });
  };

  useEffect(() => {
    if (!isEmpty(auth)) {
      navigate("/");
    }
  }, [])

  return (
    <section className="flex min-h-screen flex-col items-center justify-center bg-[#F3F4F7]">
      <div className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
        <div className="mx-auto max-w-4xl rounded-xl bg-white">
          <div className="grid lg:grid-cols-2">
            <div className="lg:px-12 px-4">
              <div className="pt-16">
                <Link to="/">
                  <img src="/img/logo/logo.png" alt="Brand Logo" />
                </Link>
              </div>
              <div className="pt-11">
                <form onSubmit={handleSubmit(onSubmit)}>
                  <div>
                    <div className="mb-1 text-sm font-medium">Email</div>
                    <input
                      type="email"
                      placeholder="Email"
                      className="w-full rounded-md border-none bg-alabaster"
                      {...register("emailAddress", {
                        required: true,
                        pattern: /^\S+@\S+$/i,
                      })}
                    />
                    {errors?.emailAddress?.type === "required" && (
                      <div className="mt-1 text-sm text-burnt-sienna">
                        Email is required.
                      </div>
                    )}
                    {errors.emailAddress?.type === "pattern" && (
                      <div className="mt-1 text-sm text-burnt-sienna">
                        Please match the requested format.
                      </div>
                    )}
                  </div>
                  <div className="pt-4">
                    <div className="mb-1 text-sm font-medium">Phone</div>
                    <input
                      type="number"
                      placeholder="Phone Number"
                      className="w-full rounded-md border-none bg-alabaster"
                      {...register("phone", {
                        required: true,
                        minLength: 10,
                        maxLength: 11,
                        pattern: {
                          value: /^[0-9]+$/,
                          message: "Please enter a number.",
                        },
                      })}
                    />
                    {errors?.phone?.type === "required" && (
                      <div className="mt-1 text-sm text-burnt-sienna">
                        Phone is required.
                      </div>
                    )}
                    {(errors?.phone?.type === "minLength" ||
                      errors?.phone?.type === "maxLength") && (
                        <div className="mt-1 text-sm text-burnt-sienna">
                          Phone number must be valid and contain 10 to 11 digits.
                        </div>
                      )}
                    {errors?.phone?.type === "pattern" && (
                      <div className="mt-1 text-sm text-burnt-sienna">
                        {errors?.phone?.message}
                      </div>
                    )}
                  </div>
                  <div className="pt-4">
                    <div className="mb-1 text-sm font-medium">Password</div>
                    <input
                      autoComplete="off"
                      type="password"
                      placeholder="Password"
                      className="w-full rounded-md border-none bg-alabaster"
                      {...register("password", { required: true })}
                    />
                    {errors?.password?.type === "required" && (
                      <div className="mt-1 text-sm text-burnt-sienna">
                        Password is required.
                      </div>
                    )}
                  </div>
                  <div className="pt-4">
                    <div className="mb-1 text-sm font-medium">
                      Confirm Password
                    </div>
                    <input
                      autoComplete="off"
                      type="password"
                      placeholder="Confirm Password"
                      className="w-full rounded-md border-none bg-alabaster"
                      {...register("confirmPassword", {
                        required: true,
                        validate: (val) => {
                          if (watch("password") !== val) {
                            return false;
                          }
                        },
                      })}
                    />
                    {errors?.confirmPassword?.type === "required" && (
                      <div className="mt-1 text-sm text-burnt-sienna">
                        Confirm Password is required.
                      </div>
                    )}
                    {errors?.confirmPassword?.type !==
                      errors?.password?.type && (
                        <div className="mt-1 text-sm text-burnt-sienna">
                          Both passwords should be same.
                        </div>
                      )}
                  </div>
                  {/* <div className="pt-2">
                    <div className="mb-1 text-sm font-medium">Gender</div>
                    <input
                      type="radio"
                      id="male"
                      name="gender"
                      value="male"
                      {...register("gender", { required: true })}
                    />
                      <label for="male">Male</label> {" "}
                    <input
                      type="radio"
                      id="female"
                      name="gender"
                      value="female"
                      {...register("gender", { required: true })}
                    />
                      <label for="female">Female</label>
                    {errors?.gender?.type === "required" && (
                      <div className="mt-1 text-sm text-burnt-sienna">
                        Gender is required.
                      </div>
                    )}
                  </div> */}
                  <div className="pt-4">
                    <Button
                      type="submit"
                      className="h-12 rounded-lg bg-indigo text-white"
                      width="full"
                      text="lg"
                      loading={loading}
                    >
                      Sign up
                    </Button>
                  </div>
                </form>
              </div>
              <div className="pb-10 pt-4 text-dove-gray text-base font-medium">
                Have an Account?
                <Link to="/login" className="text-indigo">
                  {" "}
                  Sign in!
                </Link>
              </div>
            </div>
            <div className="hidden lg:block">
              <img
                src="img/signup.png"
                alt="alt-img"
                className="invisible h-full rounded-r-md lg:visible"
              />
            </div>
          </div>
        </div>
        <div className="mt-5 lg:flex justify-center text-sm">
          By signing up, you agree to our{" "}
          <span className="text-indigo lg:px-1 underline">
            <Link to="/termsandconditions">Terms and Conditions</Link>
          </span>{" "}
          and <span className="text-indigo lg:px-1 underline"> <Link to="/privacypolicy">Privacy Policy</Link></span>
          .
        </div>
      </div>
    </section>
  );
};

export default Signup;

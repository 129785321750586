import React from 'react'

const Day = ({ dayCode, stringLimit, className, htmlFor }) => {
    return (
        <>
            {dayCode === 0 && (<label htmlFor={htmlFor} className={className}>{"Sunday".slice(0, stringLimit)}</label>)}
            {dayCode === 1 && (<label htmlFor={htmlFor} className={className}>{"Monday".slice(0, stringLimit)}</label>)}
            {dayCode === 2 && (<label htmlFor={htmlFor} className={className}>{"Tuesday".slice(0, stringLimit)}</label>)}
            {dayCode === 3 && (<label htmlFor={htmlFor} className={className}>{"Wednesday".slice(0, stringLimit)}</label>)}
            {dayCode === 4 && (<label htmlFor={htmlFor} className={className}>{"Thursday".slice(0, stringLimit)}</label>)}
            {dayCode === 5 && (<label htmlFor={htmlFor} className={className}>{"Friday".slice(0, stringLimit)}</label>)}
            {dayCode === 6 && (<label htmlFor={htmlFor} className={className}>{"Saturday".slice(0, stringLimit)}</label>)}

        </>
    )
}

export default Day
import moment from 'moment/moment';
import React, { Fragment } from 'react'

const OpenDays = ({ days }) => {
    return (
        <div className="lg:flex lg:space-x-2 lg:space-y-0 space-y-2 justify-center items-center">
            {days?.map((data, i) => {
                return (
                    <Fragment key={i}>
                        {(data?.isActive && data?.weekDay === 0) && (<div className="bg-malachite/50 px-2 py-1 rounded-xl">
                            Sunday 
                            <div>{moment(data?.clinicHours[0]?.from,'hh:mm A').format('hh:mm A')} to {moment(data?.clinicHours[0]?.to,'hh:mm A').format('hh:mm A')}</div>
                        </div>)
                        }
                        {(data?.isActive && data?.weekDay === 1) && (<div className="bg-malachite/50 px-2 py-1 rounded-xl">
                            Monday 
                            <div>{moment(data?.clinicHours[0]?.from,'hh:mm A').format('hh:mm A')} to {moment(data?.clinicHours[0]?.to,'hh:mm A').format('hh:mm A')}</div>
                        </div>)
                        }
                        {(data?.isActive && data?.weekDay === 2) && (<div className="bg-malachite/50 px-2 py-1 rounded-xl">
                            Tuesday 
                            <div>{moment(data?.clinicHours[0]?.from,'hh:mm A').format('hh:mm A')} to {moment(data?.clinicHours[0]?.to,'hh:mm A').format('hh:mm A')}</div>
                        </div>)
                        }
                        {(data?.isActive && data?.weekDay === 3) && (<div className="bg-malachite/50 px-2 py-1 rounded-xl">
                            Wednesday 
                            <div>{moment(data?.clinicHours[0]?.from,'hh:mm A').format('hh:mm A')} to {moment(data?.clinicHours[0]?.to,'hh:mm A').format('hh:mm A')}</div>
                        </div>)
                        }
                        {(data?.isActive && data?.weekDay === 4) && (<div className="bg-malachite/50 px-2 py-1 rounded-xl">
                            Thursday 
                            <div>{moment(data?.clinicHours[0]?.from,'hh:mm A').format('hh:mm A')} to {moment(data?.clinicHours[0]?.to,'hh:mm A').format('hh:mm A')}</div>
                        </div>)
                        }
                        {(data?.isActive && data?.weekDay === 5) && (<div className="bg-malachite/50 px-2 py-1 rounded-xl">
                            Friday 
                            <div>{moment(data?.clinicHours[0]?.from,'hh:mm A').format('hh:mm A')} to {moment(data?.clinicHours[0]?.to,'hh:mm A').format('hh:mm A')}</div>
                        </div>)
                        }
                        {(data?.isActive && data?.weekDay === 6) && (<div className="bg-malachite/50 px-2 py-1 rounded-xl">
                            Saturday 
                            <div>{moment(data?.clinicHours[0]?.from,'hh:mm A').format('hh:mm A')} to {moment(data?.clinicHours[0]?.to,'hh:mm A').format('hh:mm A')}</div>
                        </div>)
                        }
                    </Fragment>
                )
            })}
        </div>
    )
}

export default OpenDays
import React, { useState } from 'react'
import Modal from '../Modal';

const Gallary = ({ media }) => {

    const [imagePreview, setImagePreview] = useState(false);
    const [image, setImage] = useState("");

    return (
        <div className="grid grid-cols-6">
            {media?.map((data, i) => {
                return (
                    <div className="flex flex-wrap" key={i}>
                        <div className="w-full p-1 md:p-2">
                            <img
                                alt="gallery"
                                className="block object-cover object-center w-full h-full rounded-lg hover:scale-90"
                                src={data?.accessUrl}
                                onClick={() => { setImagePreview(true); setImage(data?.accessUrl) }}
                            />
                        </div>
                    </div>
                )
            })}

            <Modal
                title=""
                maxWidth="46rem"
                show={imagePreview}
                onClose={() => { setImagePreview(false); setImage("") }}
                onCancel={() => { setImagePreview(false); setImage("") }}
                showCancel={true}
            >
                <div>
                    <img
                        alt="gallery"
                        src={image}
                        className="rounded-lg "
                    />
                </div>
            </Modal>
        </div>
    )
}

export default Gallary
import { retrieveOne } from "actions/services";
import { Button } from "components/ui";
import _ from "lodash";
import React, { useCallback, useEffect, useState } from "react";
import { toast } from "react-hot-toast";
import { useDispatch } from "react-redux";
import { useNavigate, useParams } from "react-router";
import Select from "react-select";
import ArrangeQuestionClinic from "./Arrange/ArrangeQuestionClinic";
import ArrangeQuestionPatient from "./Arrange/ArrangeQuestionPatient";

const ViewServices = () => {
  const { serviceId } = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [service, setService] = useState({});
  const [loading, setLoading] = useState(false);
  const [isPatientQuestionsModalOpen, setIsPatientQuestionsModalOpen] =
    useState(false);
  const [isClinicQuestionsModalOpen, setIsClinicQuestionsModalOpen] =
    useState(false);

  const selectOptions = (option) => {
    const mappedOptions = option.map((option) => {
      return { value: option, label: option };
    });
    return mappedOptions;
  };

  const fetchServiceDetail = useCallback(() => {
    const params = {
      include: "category,questionForPatient,questionForClinic",
    };
    dispatch(retrieveOne(serviceId, params))
      .then((result) => {
        setLoading(true);
        setService(result);
      })
      .catch((error) => {
        toast.error(error.response.data.errors[0].detail);
        setLoading(true);
      })
      .finally(() => {
        setLoading(false);
      });
  }, [dispatch, setService, serviceId]);

  useEffect(() => {
    fetchServiceDetail();
  }, [fetchServiceDetail]);

  return (
    <section className="ml-6 mr-7 min-h-screen items-center justify-center py-6">
      <div>
        <div>
          <div className="mb-6 w-auto rounded-t-lg bg-white px-4 py-4 lg:px-7 lg:py-7">
            <div className="w-auto rounded-lg">
              <div className="mb-4 text-center font-light">
                <div className="flex items-center space-x-2 text-base font-semibold">
                  <div>Icon: </div>
                  <div>
                    <img
                      src={service?.accessUrl}
                      alt="service-icon"
                      className="mr-2 inline h-12 w-12 rounded-full object-cover"
                    />
                  </div>
                </div>
                <div className="flex space-x-2 text-base font-semibold">
                  <div>Service Name: </div>
                  <div>{service?.name}</div>
                </div>
                <div className="flex space-x-2 text-base font-semibold">
                  <div>Category Name: </div>
                  <div>{service?.category?.name}</div>
                </div>
                <div className="flex space-x-2 text-base font-semibold">
                  <div>Description: </div>
                  <div className="text-justify text-base">
                    {service?.description}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="mb-6 w-auto bg-white px-4 py-4 lg:px-7 lg:py-7">
            <div className="mb-4 flex items-center justify-between">
              <div className="text-xl font-semibold">Question For Patient:</div>
              <div className="flex space-x-3 fill-alabaster">
                <div>
                  <Button
                    type="button"
                    className="rounded-md bg-indigo text-white"
                    width="full"
                    text="base"
                    onClick={() => setIsPatientQuestionsModalOpen(true)}
                  >
                    Arrange Order
                  </Button>
                </div>
              </div>
            </div>
            {isPatientQuestionsModalOpen && (
              <ArrangeQuestionPatient
                isOpen={isPatientQuestionsModalOpen}
                details={service}
                closeModal={() => setIsPatientQuestionsModalOpen(false)}
              />
            )}
            <div className="rounded-lg bg-alabaster px-4 py-4 lg:grid lg:grid-cols-3 lg:gap-4">
              {_.sortBy(service?.questionForPatient, "position")?.map(
                (data, i) => {
                  return (
                    <div key={i} className="mb-3 rounded-lg bg-white px-4 py-4">
                      {data.inputType === "text" && (
                        <div className="mb-2 text-sm font-medium">
                          <div className="mb-2">
                            <label>{data?.questionText}</label>
                          </div>
                          <input
                            type={data?.inputType}
                            placeholder={data?.placeholder}
                            className="w-full rounded-md border-none bg-alabaster focus:ring-0"
                          />
                        </div>
                      )}
                      {data.inputType === "textarea" && (
                        <div className="mb-2 text-sm font-medium">
                          <div className="mb-2">
                            <label>{data?.questionText}</label>
                          </div>
                          <textarea
                            rows={3}
                            placeholder={data?.placeholder}
                            className="w-full resize-none rounded-md border-none bg-alabaster focus:ring-0"
                          />
                        </div>
                      )}
                      {data?.inputType === "select" && (
                        <div className="mb-2 text-sm font-medium">
                          <div className="mb-2">
                            <label>{data?.questionText}</label>
                          </div>
                          <select className="w-full rounded-md border-none bg-alabaster focus:ring-0">
                            <option value="">Choose an option</option>
                            {data?.options.map((option, i) => {
                              return (
                                <option key={i} value={option.trim()}>
                                  {option}
                                </option>
                              );
                            })}
                          </select>
                        </div>
                      )}
                      {data?.inputType === "multiselect" && (
                        <div className="mb-2 text-sm font-medium">
                          <div className="mb-2">
                            <label>{data?.questionText}</label>
                          </div>
                          <Select
                            isMulti
                            options={selectOptions(data?.options)}
                            className="w-auto rounded-md border-none bg-alabaster focus:ring-0"
                          />
                        </div>
                      )}
                      {data?.inputType === "checkbox" && (
                        <div className="mb-2 text-sm font-medium">
                          <div className="mb-2">
                            <label>{data?.questionText}</label>
                          </div>
                          <div className="flex flex-col items-start">
                            {data?.options.map((option, i) => {
                              return (
                                <div key={i}>
                                  <input
                                    id={`${data?.id}.${i}`}
                                    name={`${data?.id}`}
                                    value={option}
                                    type="checkbox"
                                    className="mr-2"
                                  />
                                  <label
                                    htmlFor={`${data?.id}.${i}`}
                                    className="select-none font-light"
                                  >
                                    {option}
                                  </label>
                                </div>
                              );
                            })}
                          </div>
                        </div>
                      )}
                      {data?.inputType === "radio" && (
                        <div className="mb-2 text-sm font-medium">
                          <div className="mb-2">
                            <label>{data?.questionText}</label>
                          </div>
                          <div className="flex flex-col items-start">
                            {data?.options.map((option, i) => {
                              return (
                                <div key={i}>
                                  <input
                                    id={`${data?.id}`}
                                    name={`${data?.id}`}
                                    type="radio"
                                    value={option}
                                    className="mr-2"
                                  />
                                  <label
                                    htmlFor={`${data?.id}`}
                                    className="select-none font-light"
                                  >
                                    {option}
                                  </label>
                                </div>
                              );
                            })}
                          </div>
                        </div>
                      )}
                    </div>
                  );
                }
              )}
            </div>
          </div>
          <div className="mb-6 w-auto rounded-b-lg bg-white px-4 py-4 lg:px-7 lg:py-7">
            <div className="mb-4 flex items-center justify-between">
              <div className="text-xl font-semibold">Question For Clinic:</div>
              <div className="flex space-x-3 fill-alabaster">
                <div>
                  <Button
                    type="button"
                    className="rounded-md bg-indigo text-white"
                    width="full"
                    text="base"
                    onClick={() => setIsClinicQuestionsModalOpen(true)}
                  >
                    Arrange Order
                  </Button>
                </div>
              </div>
            </div>

            {isClinicQuestionsModalOpen && (
              <ArrangeQuestionClinic
                isOpen={isClinicQuestionsModalOpen}
                details={service}
                closeModal={() => setIsClinicQuestionsModalOpen(false)}
              />
            )}
            <div className="rounded-lg bg-alabaster px-4 py-4 lg:grid lg:grid-cols-3 lg:gap-4">
              {_.sortBy(service?.questionForClinic, "position")?.map(
                (data, i) => {
                  return (
                    <div key={i} className="mb-3 rounded-lg bg-white px-4 py-4">
                      {data.inputType === "text" && (
                        <div className="mb-2 text-sm font-medium">
                          <div className="mb-2">
                            <label>{data?.questionText}</label>
                          </div>
                          <input
                            type={data?.inputType}
                            placeholder={data?.placeholder}
                            className="w-full rounded-md border-none bg-alabaster focus:ring-0"
                          />
                        </div>
                      )}
                      {data?.inputType === "select" && (
                        <div className="mb-2 text-sm font-medium">
                          <div className="mb-2">
                            <label>{data?.questionText}</label>
                          </div>
                          <select className="w-full rounded-md border-none bg-alabaster focus:ring-0">
                            <option value="">Choose an option</option>
                            {data?.options.map((option, i) => {
                              return (
                                <option key={i} value={option.trim()}>
                                  {option}
                                </option>
                              );
                            })}
                          </select>
                        </div>
                      )}
                      {data?.inputType === "multiselect" && (
                        <div className="mb-2 text-sm font-medium">
                          <div className="mb-2">
                            <label>{data?.questionText}</label>
                          </div>
                          <Select
                            isMulti
                            options={selectOptions(data?.options)}
                            className="w-full rounded-md border-none bg-alabaster focus:ring-0"
                          />
                        </div>
                      )}
                      {data?.inputType === "checkbox" && (
                        <div className="mb-2 text-sm font-medium">
                          <div className="mb-2">
                            <label>{data?.questionText}</label>
                          </div>
                          <div className="flex flex-col items-start">
                            {data?.options.map((option, i) => {
                              return (
                                <div key={i}>
                                  <input
                                    id={`${data?.id}.${i}`}
                                    name={`${data?.id}`}
                                    value={option}
                                    type="checkbox"
                                    className="mr-2"
                                  />
                                  <label
                                    htmlFor={`${data?.id}.${i}`}
                                    className="select-none font-light"
                                  >
                                    {option}
                                  </label>
                                </div>
                              );
                            })}
                          </div>
                        </div>
                      )}
                      {data?.inputType === "radio" && (
                        <div className="mb-2 text-sm font-medium">
                          <div className="mb-2">
                            <label>{data?.questionText}</label>
                          </div>
                          <div className="flex flex-col items-start">
                            {data?.options.map((option, i) => {
                              return (
                                <div key={i}>
                                  <input
                                    id={`${data?.id}`}
                                    name={`${data?.id}`}
                                    type="radio"
                                    value={option}
                                    className="mr-2"
                                  />
                                  <label
                                    htmlFor={`${data?.id}`}
                                    className="select-none font-light"
                                  >
                                    {option}
                                  </label>
                                </div>
                              );
                            })}
                          </div>
                        </div>
                      )}
                      {data.inputType === "textarea" && (
                        <div className="mb-2 text-sm font-medium">
                          <div className="mb-2">
                            <label>{data?.questionText}</label>
                          </div>
                          <textarea
                            rows={3}
                            placeholder={data?.placeholder}
                            className="w-full resize-none rounded-md border-none bg-alabaster focus:ring-0"
                          />
                        </div>
                      )}
                    </div>
                  );
                }
              )}
            </div>
          </div>
        </div>
        <div>
          <Button
            type="button"
            className="h-12 rounded-lg bg-indigo text-white"
            text="lg"
            onClick={() => navigate("/services")}
          >
            Back To List
          </Button>
        </div>
      </div>
    </section>
  );
};

export default ViewServices;

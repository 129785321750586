import { createAppointment, generateAppointmentSlots } from "actions/appointment";
import { retrieveClinicSelectPatient } from "actions/clinicSelectPatient";
import { retriveServices } from "actions/services";
import { Button } from "components/ui";
import _ from "lodash";
import moment from "moment/moment";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import toast from "react-hot-toast";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import Select from "react-select";
import Flatpickr from 'react-flatpickr';
import "flatpickr/dist/flatpickr.css";

const CreateAppointment = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const fp = useRef(null);

  const auth = JSON.parse(localStorage.getItem("clinic-u"));
  const clinicId = auth?.clinic[0]?.id;

  const [appointmentStartDate, setAppointmentStartDate] = useState();
  const [loading, setLoading] = useState(false);
  const [dateLoading, setDateLoading] = useState(false);
  const [timeLoading, setTimeLoading] = useState(false);
  const [services, setServices] = useState([]);
  const [patient, setPatient] = useState([]);
  const [appointmentDates, setAppointmentDates] = useState([]);
  const [appointmentTime, setAppointmentTime] = useState([]);
  const [selectedPatient, setSelectedPatient] = useState();
  const [selectedService, setSelectedService] = useState();
  const [search, setSearch] = useState("");

  const {
    register,
    handleSubmit,
    watch,
    control,
    setValue,
    formState: { errors },
  } = useForm();

  const serviceId = watch("service");

  const fetchPatient = useCallback(() => {
    const params = {
      page: 1,
      limit: 50,
      sort: "createdAt",
      order: "desc",
      search: search,
      "filter[clinic.id]": clinicId,
      include: "patient,clinic",
    }
    dispatch(retrieveClinicSelectPatient(params)).then((result) => {
      setLoading(true);
      setPatient(result?.data);
    })
      .catch((error) => {
        toast.error(error.response.data.errors[0].detail);
        setLoading(true);
      })
      .finally(() => {
        setLoading(false);
      });
  },
    [dispatch, setPatient, search, clinicId],
  )

  const fetchServices = useCallback(() => {
    const params = {
      page: 1,
      limit: 100,
      sort: "updatedAt",
      order: "desc",
      include: "category,questionForPatient,questionForClinic,clinicService,clinicService.clinic",
      "filter[clinicService.clinic.id]": clinicId,
      aggregate: "isServiceProvided",
      clinicId: clinicId
    }
    dispatch(retriveServices(params)).then((result) => {
      const mappedOptions = result?.data
        ?.filter((data) => data?.isServiceProvided)
        ?.map((option) => {
          return { value: option.id, label: option.name, detail: option };
        });
      console.log(mappedOptions, "mappedOptions");
      setServices(mappedOptions);
    })
      .catch((error) => {
        toast.error(error.response.data.errors[0].detail);
      })
  },
    [dispatch, setServices, clinicId],
  )

  const generateSlots = useCallback(
    () => {
      setTimeLoading(true)
      const auth = JSON.parse(localStorage.getItem("clinic-u"));
      const clinicId = auth?.clinic[0]?.id;
      const body = {
        clinic: {
          id: clinicId
        },
        service: {
          id: serviceId
        },
        startDate: appointmentStartDate,
        endDate: moment(appointmentStartDate).add(1, "day")
      };
      dispatch(generateAppointmentSlots(body)).then((result) => {
        if (result?.data[0]?.isActive) {
          const mappedOptions = result?.data[0]?.timeSlots?.map((option) => {
            if (option?.maxLimit <= option?.alreadyBookedSlots) {
              return { value: option.time, label: option.time, disabled: true };
            } else {
              return { value: option.time, label: option.time, disabled: false };
            }
          });
          console.log(mappedOptions, "mappedOptions");
          setAppointmentTime(mappedOptions);
          setTimeLoading(false)
        }
      })
        .catch((error) => {
          toast.error(error.response.data.errors[0].detail);
          setTimeLoading(false);
        })
    },
    [dispatch, serviceId, appointmentStartDate],
  )

  const selectOptionsName = (option) => {
    const mappedOptions = _.uniqBy(option, 'patient.id')?.map((option) => {
      return { value: option?.patient?.id, label: `${option?.patient?.firstName ? option?.patient?.firstName : ""} ${option?.patient?.lastName ? option?.patient?.lastName : ""}`, detail: option?.patient };
    });
    return mappedOptions;
  };

  const selectOptions = (option) => {
    const mappedOptions = option.map((option) => {
      return { value: option, label: option };
    });
    return mappedOptions;
  };

  const selectOptionsEmail = (option) => {
    const mappedOptions = _.uniqBy(option, 'patient.id')?.map((option) => {
      return { value: option?.patient?.id, label: option?.patient?.emailAddress, detail: option?.patient };
    });
    return mappedOptions;
  };

  const handleInputChange = (inputValue) => {
    setSearch(inputValue);
  };

  const onSubmit = (data) => {
    setLoading(true);
    for (const answer of data?.answeredByPatient) {
      if (answer?.type === "multiselect") {
        if (answer.answerText) {
          answer.answerText = answer?.answerText?.map((key) => key.value)
        } else {
          answer.answerText = ""
        }
      }
      if (answer?.type === "checkbox") {
        if (answer.answerText) {
          answer.answerText = answer.answerText.toString()
        } else {
          answer.answerText = ""
        }
      }
    }
    if (data?.service) {
      data.service = {
        id: data?.service
      }
    }
    data.clinic = {
      id: clinicId
    }
    data.paymentMode = "cash"
    data.status = "in_progress"
    data.user = {
      id: selectedPatient.id
    };
    data.answeredByClinic = []
    dispatch(createAppointment(data))
      .then((result) => {
        toast.success("Appointment Create Successfully");
        window.gtag('event', 'page_view', {
          page_title: "Appointment Create",
          page_path: "/appointment",
          page_location: "/appointment"
        })
        navigate("/appointments")
      })
      .catch((error) => {
        toast.error(error.response.data.errors[0].detail);
        setLoading(false);
      })
  }

  useEffect(() => {
    fetchPatient();
    fetchServices();
    if (appointmentStartDate) {
      generateSlots()
    }
  }, [fetchPatient, fetchServices, generateSlots, appointmentStartDate])

  return (
    <section className="min-h-screen ml-6 mr-7 py-6 justify-center items-center">
      <div>
        <div className='mb-4'>
          <div className='text-xl font-semibold'>New appointment</div>
        </div>
        <div>
          <form
            onSubmit={handleSubmit(onSubmit)}
          >
            <div className='lg:px-7 lg:pt-7 px-4 py-4 mb-4 rounded-t-lg bg-white w-auto'>
              <div className="flex justify-end">
                <Button
                  type="button"
                  className="bg-indigo text-white rounded-md"
                  text="base"
                  onClick={() => setSelectedPatient(null)}
                >
                  Clear
                </Button>
              </div>
              <div className="lg:grid lg:grid-cols-3">
                <div className='lg:pr-16 pr-0 mb-2'>
                  <div className="mb-1 text-sm font-medium">Patient’s name</div>
                  {selectedPatient && (
                    <div className="bg-alabaster w-full border-none rounded-md px-2 py-2">
                      {selectedPatient?.firstName} {" "} {selectedPatient?.lastName}
                    </div>
                  )}
                  {!selectedPatient && (
                    <Select
                      options={selectOptionsName(patient)}
                      className="bg-alabaster w-full border-none rounded-md"
                      onInputChange={handleInputChange}
                      onChange={(e) => setSelectedPatient(e.detail)}
                      styles={{
                        control: (baseStyles, state) => ({
                          ...baseStyles,
                          backgroundColor: 'alabaster',
                        }),
                      }}
                    />
                  )}
                </div>
                <div className="lg:pr-16 pr-0">
                  <div className="text-sm font-medium">Email</div>
                  {selectedPatient && (
                    <div className="bg-alabaster w-full border-none rounded-md px-2 py-2">
                      {selectedPatient?.emailAddress}
                    </div>
                  )}
                  {!selectedPatient && (
                    <Select
                      options={selectOptionsEmail(patient)}
                      className="bg-alabaster w-full border-none rounded-md"
                      onInputChange={handleInputChange}
                      onChange={(e) => setSelectedPatient(e.detail)}
                      styles={{
                        control: (baseStyles, state) => ({
                          ...baseStyles,
                          backgroundColor: 'alabaster',
                        }),
                      }}
                    />
                  )}
                </div>
                <div className='lg:pr-16 pr-0'>
                  <div className="mb-1 text-sm font-medium">Phone number</div>
                  {selectedPatient && (
                    <div className="bg-alabaster w-full border-none rounded-md px-2 py-2">
                      {selectedPatient?.phone}
                    </div>
                  )}
                </div>
              </div>
              <div className='col-span-1'>
                <div className="mb-1 text-sm font-medium">Address</div>
                {selectedPatient && (
                  <div className="bg-alabaster w-1/3 border-none rounded-md px-2 py-2">
                    {selectedPatient?.address}
                  </div>
                )}
              </div>
            </div>
            {selectedPatient && (
              <div className='lg:px-7 lg:py-7 px-4 py-4 mb-4 bg-white w-auto'>
                <div className="lg:grid lg:grid-cols-4 gap-4">
                  <div>
                    <div className="text-sm font-medium">Service</div>
                    <Controller
                      render={({ field: { onChange, value, name, ref } }) => (
                        <Select
                          inputRef={ref}
                          options={services}
                          className="bg-alabaster w-full border-none rounded-md"
                          {...register(`service`, { required: true })}
                          value={services.find(c => c.value === value)}
                          onChange={val => {
                            onChange(val.value);
                            setSelectedService(val.detail);
                            setValue("clinicService.id", val.detail?.clinicService[0]?.id);
                            setValue("answeredByPatient", val.detail?.questionForPatient);
                            setValue("paymentAmount", val.detail?.clinicService[0]?.charge);
                          }}
                          styles={{
                            control: (baseStyles, state) => ({
                              ...baseStyles,
                              backgroundColor: 'alabaster',
                            }),
                          }}
                        />
                      )}
                      control={control}
                      name="service"
                    />
                    {errors?.service?.type === "required" && (
                      <div className="mt-1 text-sm text-burnt-sienna">
                        Service is required.
                      </div>
                    )}
                  </div>
                  {watch("service") && (
                    <div>
                      <div className="text-sm font-medium">Choose Appointment Dates</div>
                      <Flatpickr
                        className="bg-alabaster w-full border-none rounded-md"
                        ref={fp}
                        options={{
                          // mode: 'range',
                          minDate: "today",
                          disable: selectedService?.clinicService[0]?.serviceOffDates ? selectedService?.clinicService[0]?.serviceOffDates : []
                        }}
                        onChange={(date) => {
                          setAppointmentStartDate(moment(date[0]).format('YYYY-MM-DD'))
                          setValue("date", moment(date[0]).format('YYYY-MM-DD'))
                        }}
                      />
                    </div>
                  )}
                  {watch("service") && watch("date") && (
                    <div>
                      <div className="text-sm font-medium">Time</div>
                      <Controller
                        render={({ field: { onChange, value, name, ref } }) => (
                          <Select
                            inputRef={ref}
                            options={appointmentTime}
                            isLoading={timeLoading}
                            isOptionDisabled={(appointmentTime) => appointmentTime.disabled}
                            className="bg-alabaster w-full border-none rounded-md"
                            {...register(`time`, { required: true })}
                            value={appointmentDates.find(c => c.value === value)}
                            onChange={val => onChange(val.value)}
                            styles={{
                              control: (baseStyles, state) => ({
                                ...baseStyles,
                                backgroundColor: 'alabaster',
                              }),
                            }}
                          />
                        )}
                        control={control}
                        name="time"
                      />
                      {errors?.time?.type === "required" && (
                        <div className="mt-1 text-sm text-burnt-sienna">
                          Time is required.
                        </div>
                      )}
                    </div>
                  )}
                </div>
              </div>
            )}
            {selectedService && (
              <div className="lg:px-7 lg:py-7 px-4 py-4 mb-4 bg-white w-auto rounded-b-lg">
                {_.sortBy(selectedService?.questionForPatient, "position")?.map((question, i) => {
                  return (
                    <div key={i} className="mb-2">
                      {question.inputType === "text" && (
                        <div className="mb-4 text-sm font-medium">
                          <div>
                            <label>{question?.questionText}</label>
                          </div>
                          <div>
                            <input
                              type="text"
                              hidden
                              value={question?.id}
                              {...register(`answeredByPatient.${i}.questions.id`, { required: false })}
                            />
                            <input
                              type={question?.inputType}
                              placeholder={question?.placeholder}
                              className="bg-alabaster border-none w-full rounded-md focus:ring-0"
                              {...register(`answeredByPatient.${i}.answerText`, { required: false })}
                            />
                          </div>
                        </div>
                      )}
                      {question.inputType === "textarea" && (
                        <div className="mb-4 text-sm font-medium">
                          <div>
                            <label>{question?.questionText}</label>
                          </div>
                          <div>
                            <input
                              type="text"
                              hidden
                              value={question?.id}
                              {...register(`answeredByPatient.${i}.questions.id`, { required: false })}
                            />
                            <textarea
                              rows={3}
                              placeholder={question?.placeholder}
                              className="bg-alabaster border-none resize-none w-full rounded-md focus:ring-0"
                              {...register(`answeredByPatient.${i}.answerText`, { required: false })}
                            />
                          </div>
                        </div>
                      )}
                      {question?.inputType === "select" && (
                        <div className="lg:flex lg:items-center lg:space-x-4 mb-4 text-sm font-medium">
                          <div>
                            <label>{question?.questionText}</label>
                          </div>
                          <div>
                            <input
                              type="text"
                              hidden
                              value={question?.id}
                              {...register(`answeredByPatient.${i}.questions.id`, { required: false })}
                            />
                            <select
                              className="bg-alabaster border-none w-auto rounded-md focus:ring-0"
                              {...register(`answeredByPatient.${i}.answerText`, { required: false })}
                            >
                              <option value="">
                                Choose an option
                              </option>
                              {question?.options.map((option, i) => {
                                return (
                                  <option
                                    key={i}
                                    value={option.trim()}
                                  >
                                    {option}
                                  </option>
                                );
                              })}
                            </select>
                          </div>
                        </div>
                      )}
                      {question?.inputType === "multiselect" && (
                        <div className="lg:flex lg:items-center lg:space-x-4 mb-4 text-sm font-medium">
                          <div>
                            <label>{question?.questionText}</label>
                          </div>
                          <div>
                            <input
                              type="text"
                              hidden
                              value={question?.id}
                              {...register(`answeredByPatient.${i}.questions.id`, { required: false })}
                            />
                            <input
                              type="text"
                              hidden
                              value="multiselect"
                              {...register(`answeredByPatient.${i}.type`, { required: false })}
                            />
                            <Controller
                              render={({ field: { onChange, value, name, ref } }) => (
                                <Select
                                  isMulti
                                  inputRef={ref}
                                  options={selectOptions(question?.options)}
                                  className="bg-alabaster border-none w-auto rounded-md focus:ring-0"
                                  value={selectOptions(question?.options)?.find(c => c.value === value)}
                                  onChange={val => {
                                    onChange(val.value);
                                    setValue(`answeredByPatient.${i}.answerText`, val)
                                  }}
                                />
                              )}
                              control={control}
                              name={`answeredByPatient.${i}.answerText`}
                            />
                          </div>
                        </div>
                      )}
                      {question?.inputType === "checkbox" && (
                        <div className="lg:flex lg:items-center lg:space-x-4 mb-4 text-sm font-medium">
                          <label>{question?.questionText}</label>
                          <div className="grid grid-cols-1 gap-2 sm:grid-cols-2">
                            {question?.options.map((option, index) => {
                              return (
                                <div key={index}>
                                  <input
                                    id={`${question?.id}.${index}`}
                                    name={`${question?.id}`}
                                    value={option}
                                    type="checkbox"
                                    className="mr-2"
                                    {...register(`answeredByPatient.${i}.answerText`, { required: false })}
                                  />
                                  <input
                                    type="text"
                                    hidden
                                    value="checkbox"
                                    {...register(`answeredByPatient.${i}.type`, { required: false })}
                                  />
                                  <input
                                    type="text"
                                    hidden
                                    value={question?.id}
                                    {...register(`answeredByPatient.${i}.questions.id`, { required: false })}
                                  />
                                  <label
                                    htmlFor={`${question?.id}.${index}`}
                                    className="select-none font-light"
                                  >
                                    {option}
                                  </label>
                                </div>
                              );
                            })}
                          </div>
                        </div>
                      )}
                      {question?.inputType === "radio" && (
                        <div className="lg:flex lg:items-center lg:space-x-4 mb-4 text-sm font-medium">
                          <label>{question?.questionText}</label>
                          <div className="grid grid-cols-1 gap-2 sm:grid-cols-2">
                            {question?.options.map((option, index) => {
                              return (
                                <div key={index}>
                                  <input
                                    id={`${question?.id}`}
                                    name={`${question?.id}`}
                                    type="radio"
                                    value={option}
                                    className="mr-2"
                                    {...register(`answeredByPatient.${i}.answerText`, { required: false })}
                                  />
                                  <input
                                    type="text"
                                    hidden
                                    value={question?.id}
                                    {...register(`answeredByPatient.${i}.questions.id`, { required: false })}
                                  />
                                  <label
                                    htmlFor={`${question?.id}`}
                                    className="select-none font-light"
                                  >
                                    {option}
                                  </label>
                                </div>
                              );
                            })}
                          </div>
                        </div>
                      )}
                    </div>
                  )
                })}
              </div>
            )}
            <div className="pt-4">
              <Button
                type="submit"
                className="h-12 rounded-lg bg-indigo text-white"
                text="lg"
                loading={loading}
              >
                Save
              </Button>
            </div>
          </form>
        </div>
      </div>
    </section>
  )
}

export default CreateAppointment;

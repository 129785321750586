
import SliderService from "services/slider";
import {
    GET_ALL_SLIDER,
    CREATE_SLIDER,
    UPDATE_SLIDER,
    REMOVE_SLIDER,
    GET_ONE_SLIDER,
} from "./type";

export const createSlider = (data) => async (dispatch) => {
    try {
        const res = await SliderService.create(data);

        dispatch({
            type: CREATE_SLIDER,
            payload: res.data,
        });

        return Promise.resolve(res.data);
    } catch (error) {
        return Promise.reject(error);
    }
};

export const updateSlider = (id, data) => async (dispatch) => {
    try {
        const res = await SliderService.update(id, data);

        dispatch({
            type: UPDATE_SLIDER,
            payload: res.data,
        });

        return Promise.resolve(res.data);
    } catch (error) {
        return Promise.reject(error);
    }
};

export const deleteSlider = (id) => async (dispatch) => {
    try {
        const res = await SliderService.remove(id);

        dispatch({
            type: REMOVE_SLIDER,
            payload: res.data,
        });

        return Promise.resolve(res.data);
    } catch (error) {
        return Promise.reject(error);
    }
};

export const retrieveOneSlider = (id, params) => async (dispatch) => {
    try {
        const res = await SliderService.getOne(id, params);

        dispatch({
            type: GET_ONE_SLIDER,
            payload: res.data,
        });

        return Promise.resolve(res.data);
    } catch (error) {
        return Promise.reject(error);
    }
};

export const retriveSlider = (data) => async (dispatch) => {
    try {
        const res = await SliderService.getAll(data);

        dispatch({
            type: GET_ALL_SLIDER,
            payload: res.data,
        });

        return Promise.resolve(res.data);
    } catch (error) {
        return Promise.reject(error);
    }
};

import ClinicSelectPatient from "services/clinicSelectPatient";
import {
    GET_ALL_PATIENTS,
    SELECT_PATIENT,
    DELETE_PATIENT_CLINIC,
} from "./type";

export const clinicSelectPatient = (data) => async (dispatch) => {
    try {
        const res = await ClinicSelectPatient.create(data);

        dispatch({
            type: SELECT_PATIENT,
            payload: res.data,
        });

        return Promise.resolve(res.data);
    } catch (error) {
        return Promise.reject(error);
    }
};

export const deleteClinicSelectPatient = (id) => async (dispatch) => {
    try {
      const res = await ClinicSelectPatient.deletePatient(id);
  
      dispatch({
        type: DELETE_PATIENT_CLINIC,
        payload: res.data,
      });
  
      return Promise.resolve(res.data);
    } catch (error) {
      return Promise.reject(error);
    }
  };
  
  export const retrieveClinicSelectPatient = (params) => async (dispatch) => {
    try {
      const res = await ClinicSelectPatient.getAll(params);
  
      dispatch({
        type: GET_ALL_PATIENTS,
        payload: res.data,
      });
  
      return Promise.resolve(res.data);
    } catch (error) {
      return Promise.reject(error);
    }
  };
import React, { useCallback, useEffect, useState } from 'react'
import {
    Page,
    Document,
    StyleSheet,
    PDFViewer,
    View,
    Text,
    PDFDownloadLink,
} from "@react-pdf/renderer";
import { Button, ReportHeader } from 'components/ui';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import { retrieveOneNMS } from 'actions/nms';
import _ from 'lodash';
import moment from 'moment';


const styles = StyleSheet.create({
    page: {
        fontFamily: "Helvetica",
        fontSize: 11,
        paddingTop: 30,
        paddingLeft: 60,
        paddingRight: 60,
        lineHeight: 1.5,
        flexDirection: "column",
    },
    reportTitle: {
        color: "#1A1A1A",
        letterSpacing: 4,
        fontSize: 20,
        textAlign: "center",
        marginBottom: 20,
        marginTop: 20
    },
    answerText: {
        color: "#3968CA"
    }
});

const NMSReport = () => {

    const { nmsId } = useParams();
    const dispatch = useDispatch();
    const [nms, setNms] = useState({});

    const fetchNMS = useCallback(() => {
        const params = {
            include: "patient,nms1STAnswer,nms2NDAnswer,nms1STAnswer.nms1st,nms2NDAnswer.nms2nd,clinic",
        };
        dispatch(retrieveOneNMS(nmsId, params))
            .then((res) => {
                setNms(res);
            })
            .catch((err) => console.log(err));
    }, [dispatch, nmsId]);

    useEffect(() => {
        if (nmsId !== undefined) {
            fetchNMS();
        }
    }, [fetchNMS, nmsId]);

    return (
        <div className="overflow-x-hidden">
            <PDFDownloadLink
                document={
                    <Document>
                        <Page size="A4" style={styles.page}>
                            <ReportHeader clinicName={nms?.clinic?.name}/>
                            <Text>NMS / {nms?.patient?.firstName !== null && nms?.patient?.firstName} {nms?.patient?.lastName !== null && nms?.patient?.lastName} /{" "} (NHS: {nms?.patient?.NHS})</Text>
                            <View style={styles.titleContainer}>
                                <Text style={styles.reportTitle}>First intervetion {moment(nms?.firstIntervention).format("YYYY-MM-DD hh:mm A")}</Text>
                                {_.orderBy(nms?.nms1STAnswer, item => item.nms1st.position, ['asc'])?.map((data, i) => {
                                    return (
                                        <div key={i}>
                                            <Text>{i + 1}. {data?.nms1st?.questionText}</Text>
                                            <Text style={styles.answerText}>{data?.answerText}</Text>
                                        </div>
                                    )
                                }
                                )}
                                <Text style={styles.reportTitle}>Second follow up {nms?.secondFollowUp !== null && moment(nms?.secondFollowUp).format("YYYY-MM-DD hh:mm A")}</Text>
                                {_.orderBy(nms?.nms2NDAnswer, item => item.nms2nd.position, ['asc'])?.map((data, i) => {
                                    return (
                                        <div key={i}>
                                            <Text>{i + 1}. {data?.nms2nd?.questionText}</Text>
                                            <Text style={styles.answerText}>{data?.answerText}</Text>
                                        </div>
                                    )
                                }
                                )}
                            </View>
                        </Page>
                    </Document>
                }
                fileName={"NMSReport.pdf"}
            >
                {({ blob, url, loading, error }) =>
                    loading ? (
                        "Loading document..."
                    ) : (
                        <div className="is-flex align-content is-justify-content-center m-6">
                            <Button
                                className="rounded bg-indigo py-2 px-4 font-medium text-white"
                                text="lg">
                                Download Report
                            </Button>
                        </div>
                    )
                }
            </PDFDownloadLink>

            <PDFViewer height={window.innerHeight} width={window.innerWidth}>
                <Document>
                    <Page size="A4" style={styles.page}>
                    <ReportHeader clinicName={nms?.clinic?.name}/>
                        <Text>NMS / {nms?.patient?.firstName !== null && nms?.patient?.firstName} {nms?.patient?.lastName !== null && nms?.patient?.lastName} /{" "} (NHS: {nms?.patient?.NHS})</Text>
                        <View style={styles.titleContainer}>
                            <Text style={styles.reportTitle}>First intervetion {nms?.firstIntervention}</Text>
                            {_.orderBy(nms?.nms1STAnswer, item => item.nms1st.position, ['asc'])?.map((data, i) => {
                                return (
                                    <div key={i}>
                                        <Text>{i + 1}. {data?.nms1st?.questionText}</Text>
                                        <Text style={styles.answerText}>{data?.answerText}</Text>
                                    </div>
                                )
                            }
                            )}
                            <Text style={styles.reportTitle}>Second follow up {nms?.secondFollowUp !== null && nms?.secondFollowUp}</Text>
                            {_.orderBy(nms?.nms2NDAnswer, item => item.nms2nd.position, ['asc'])?.map((data, i) => {
                                return (
                                    <div key={i}>
                                        <Text>{i + 1}. {data?.nms2nd?.questionText}</Text>
                                        <Text style={styles.answerText}>{data?.answerText}</Text>
                                    </div>
                                )
                            }
                            )}
                        </View>
                    </Page>
                </Document>
            </PDFViewer>
        </div>
    )
}

export default NMSReport
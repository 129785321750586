import authHeader from "auth-header";
import httpCommon from "http-common";

const getAll = () => {
    const config = {
        headers: authHeader(),
    };
    return httpCommon.get(`/stripe/price`, config);
};

const getAllSubscription = (customerId) => {
    const config = {
        headers: authHeader(),
    };
    return httpCommon.get(`/stripe/subscription?customerId=${customerId}`, config);
};

const getAllInvoice = (customerId, subscriptionId) => {
    const config = {
        headers: authHeader(),
    };
    return httpCommon.get(`/stripe/subscription/invoice?customerId=${customerId}&subscriptionId=${subscriptionId}`, config);
};

const checkout = (data) => {
    const config = {
        headers: authHeader(),
    };
    return httpCommon.post(`/stripe/checkout`, data, config);
};

const cancelSubscription = (data) => {
    const config = {
        headers: authHeader(),
    };
    return httpCommon.post(`/stripe/subscription/cancel`, data, config);
};

const SubscriptionService = {
    getAll,
    checkout,
    getAllSubscription,
    getAllInvoice,
    cancelSubscription
};

export default SubscriptionService;
import authHeader from "auth-header";
import httpCommon from "http-common";

const generateSlots = (data) => {
    return httpCommon.post("/generateSlots", data, { headers: authHeader() });
};

const create = (data) => {
    return httpCommon.post("/appointment", data, { headers: authHeader() });
};

const update = (id, data) => {
    return httpCommon.patch(`/appointment/${id}`, data, { headers: authHeader() });
};

const cancel = (id,data) => {
    return httpCommon.patch(`/appointment-cancel/${id}`, data, { headers: authHeader() });
};

const getOne = (id, params) => {
    const config = {
        headers: authHeader(),
        params: params,
    };
    return httpCommon.get(`/appointment/${id}`, config);
};

const getAll = async (params) => {
    const config = {
        headers: authHeader(),
        params: params,
    };

    const response = await httpCommon.get(`/appointments`, config);
    return response;
}

const AppointmentService = {
    generateSlots,
    create,
    update,
    cancel,
    getOne,
    getAll,
};

export default AppointmentService;
import { clinicStripeCreate, clinicStripeCreateFalg } from "actions/clinicStripe";
import { Button } from "components/ui";
import moment from "moment";
import React, { useCallback, useEffect, useState } from "react";
import { toast } from "react-hot-toast";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import SubscriptionDetails from "./subscriptionDetails";

const Subscription = () => {

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [isSubscriptionActive, setIsSubscriptionActive] = useState(false);

  const auth = JSON.parse(localStorage.getItem("clinic-u"));
  const clinicId = auth?.clinic[0]?.id;

  var start = moment(auth?.clinic[0]?.createdAt);
  var end = moment();
  const daysLeft = moment.duration(start.diff(end)).asDays();


  const getclinicStripeCreateFalg = useCallback(() => {
    dispatch(clinicStripeCreateFalg(clinicId))
      .then((response) => {
        setIsSubscriptionActive(response?.isSubscriptionActive)
        if (response?.isCustomerExist !== true) {
          const body = {
            email: auth?.emailAddress,
            name: auth?.clinic[0]?.name,
            clinic: {
              id: clinicId
            }
          }
          dispatch(clinicStripeCreate(body))
            .then((response) => {
              console.log("stripe customer Create");
            }).catch((error) => {
              console.log("stripe customer Create ERROR!!!!");
            })
        }
      })
      .catch((error) => {
        console.log(error, "error");
        toast.error(error.response.data.message);
      })
  }, [dispatch, auth?.clinic, auth?.emailAddress, clinicId])

  useEffect(() => {
    getclinicStripeCreateFalg()
  }, [getclinicStripeCreateFalg])

  return (
    <section className="ml-6 mr-7 min-h-screen items-center justify-center py-6">
      <div>
        <div className="md:flex mb-4 justify-between items-center">
          <div className="text-xl font-semibold">Subscription</div>
        </div>
        {!isSubscriptionActive && (
          <div className='lg:px-7 lg:py-7 px-4 py-4 mb-6 rounded-lg bg-white w-auto'>
            <div className="lg:flex lg:justify-between lg:items-center">
              <div className="mb-2">
                <div className="text-xl text-indigo">60 days free trial</div>
                <div>{daysLeft < 0 ? "0" : daysLeft} days left!</div>
              </div>
              <div>
                <Button
                  type="button"
                  className="h-12 rounded-lg bg-indigo text-white w-52"
                  text="lg"
                  onClick={() => navigate("/subscription/all")}
                >
                  Start subscription
                </Button>
              </div>
            </div>
          </div>
        )}
        {isSubscriptionActive && (
          <SubscriptionDetails />
        )}
        {!isSubscriptionActive && (
          <div className='lg:px-7 lg:py-7 px-4 py-4 mb-6 rounded-lg bg-white w-auto'>
            <div className="text-lg text-indigo">Why Clinic2GO?</div>
            <div>
              We can explain users about the benefits of using Clinic2Go here.
            </div>
          </div>
        )}
      </div>
    </section>
  );
}

export default Subscription;

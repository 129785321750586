import { retrieveOneClinic } from 'actions/clinics';
import { retriveAllSubscription, retriveAllInvoiceOfSubscription, cancelSubscription } from 'actions/subscription';
import { Button, Modal, Tooltip } from 'components/ui';
import moment from 'moment';
import React, { useCallback, useEffect, useState } from 'react'
import DataTable from 'react-data-table-component';
import { toast } from 'react-hot-toast';
import { AiOutlineFileDone, AiOutlineFilePdf } from 'react-icons/ai';
import { useDispatch } from 'react-redux';
import { SubscriptionStatus } from 'utils/roles';
import { BsFillAlarmFill } from "react-icons/bs";
import { isEmpty } from 'lodash';
import customStyles from 'utils/data-table-style';

const SubscriptionDetails = () => {

    const dispatch = useDispatch();

    const [isLoaded, setIsLoaded] = useState(false);
    const [subscription, setSubscription] = useState([]);
    const [invoice, setInvoice] = useState([]);
    const [isShowInvoice, setIsShowInvoice] = useState(false);
    const [isCancelSubscriptionModal, setIsCancelSubscriptionModal] = useState(false);
    const [selectedSubscription, setSelectedSubscription] = useState({});

    const auth = JSON.parse(localStorage.getItem("clinic-u"));
    const clinicId = auth?.clinic[0]?.id;

    const columns = [
        {
            name: 'AMOUNT',
            selector: row => row?.amount_paid,
            cell: row =>
                <div>
                    £ {row?.amount_paid / 10} <span className="uppercase">{row?.currency}</span> {row?.paid ? "PAID" : "UNPAID"}
                </div>
        },
        {
            name: "INVOICE NUMBER",
            selector: row => row?.number,
        },
        {
            name: "CREATED",
            selector: row => row?.created,
            cell: row =>
                <div>
                    {moment.unix(row?.created).format("MMM DD YYYY")}
                </div>
        },
        {
            name: "",
            selector: row => row.action,
            cell: row =>
                <div className='flex'>
                    <Tooltip content={"Download"}>
                        <div>
                            <AiOutlineFilePdf className='h-6 w-6 sm:h-7 sm:w-7' onClick={() => window.open(row?.invoice_pdf)} />
                        </div>
                    </Tooltip>
                    <Tooltip content={"View Invoice"}>
                        <div>
                            <AiOutlineFileDone className='h-6 w-6 sm:h-7 sm:w-7' onClick={() => window.open(row?.hosted_invoice_url)} />
                        </div>
                    </Tooltip>
                </div>,
            show: true,
        },
    ];

    const getAllSubscription = useCallback(() => {
        const params = {
            include: "clinicStripe",
        }
        dispatch(retrieveOneClinic(clinicId, params))
            .then((res) => {
                dispatch(retriveAllSubscription(res?.clinicStripe[0]?.customerId))
                    .then((response) => {
                        setSubscription(response?.data)
                        console.log(response, "all retriveAllSubscription");
                    }).catch((error) => {
                        console.log(error);
                    })
            })
            .catch((err) => console.log(err));

    }, [dispatch, clinicId])

    const getAllInvoice = useCallback(() => {
        dispatch(retriveAllInvoiceOfSubscription(selectedSubscription?.customer, selectedSubscription?.id))
            .then((response) => {
                setInvoice(response?.data)
                console.log(response, "all retriveAllInvoiceOfSubscription");
            }).catch((error) => {
                console.log(error);
            })
    }, [dispatch, selectedSubscription])

    const onCancelSubscription = () => {
        setIsLoaded(true);
        const body = {
            subscriptionId: selectedSubscription?.id
        }
        dispatch(cancelSubscription(body))
            .then((response) => {
                setIsLoaded(false)
                setIsCancelSubscriptionModal(false)
                toast.success("Subscription will cancelled while End of the current period");
            })
            .catch((error) => {
                console.log(error, "error");
                setIsCancelSubscriptionModal(false)
                setIsLoaded(false)
                toast.error(error.response.data.message);
            });
    }

    useEffect(() => {
        getAllSubscription()
        if (!isEmpty(selectedSubscription)) {
            getAllInvoice();
        }
    }, [getAllSubscription, getAllInvoice, selectedSubscription])

    return (
        <div>
            <div className='lg:px-7 lg:py-7 px-4 py-4 mb-6 rounded-lg bg-white w-auto'>
                {subscription?.map((data, i) => {
                    return (
                        <div key={i} className="lg:flex lg:justify-between lg:items-center">
                            <div>
                                <div className="lg:flex lg:space-x-2">
                                    <div>Subscription</div>
                                    {data?.status === SubscriptionStatus.ACTIVE && (<div className="text-malachite">Active</div>)}
                                    {data?.status === SubscriptionStatus.CANCELED && (<div className="text-alto">Canceled</div>)}
                                    {data?.status === SubscriptionStatus.UNPAID && (<div className="text-alto">Unpaid</div>)}
                                    {data?.cancel_at !== null && (
                                        <div className="flex space-x-2 justify-center items-center">
                                            <BsFillAlarmFill className="fill-alto" />
                                            <span className="text-alto">Cancelled on {moment.unix(data?.cancel_at).format("MMM DD YYYY")}</span>
                                        </div>
                                    )}
                                </div>
                                {data?.cancel_at !== null && (
                                    <div>
                                        Billing {data?.plan?.interval} No further invoice"
                                    </div>
                                )}
                                {data?.cancel_at === null && (
                                    <div>
                                        Billing {data?.plan?.interval} Next invoice on {moment.unix(data?.current_period_end).format("MMM DD YYYY")} for £{parseInt(data?.items?.data[0]?.plan?.amount) / 100}
                                    </div>
                                )}

                            </div>
                            <div>
                                <Button
                                    type="button"
                                    className="h-12 rounded-lg bg-indigo text-white w-52"
                                    text="lg"
                                    onClick={() => {
                                        setIsShowInvoice(true);
                                        setSelectedSubscription(data);
                                    }}
                                >
                                    View More details
                                </Button>
                                {data?.cancel_at === null && (
                                    <div className="pt-3">
                                        <Button
                                            type="button"
                                            className="h-12 rounded-lg bg-indigo text-white w-56"
                                            text="lg"
                                            onClick={() => {
                                                setSelectedSubscription(data);
                                                setIsCancelSubscriptionModal(true);
                                            }}
                                        >
                                            Cancel Subscription
                                        </Button>
                                    </div>
                                )}
                            </div>
                        </div>
                    )
                })}
            </div>
            <Modal
                title="Cancel Subscription"
                maxWidth="32rem"
                show={isCancelSubscriptionModal}
                onClose={() => setIsCancelSubscriptionModal(false)}
                showCancel={true}
                onCancel={() => setIsCancelSubscriptionModal(false)}
            >
                <div className="mb-4 text-center font-light">
                    {`Are you sure you want to cancel this Scription`}
                </div>
                <div className="mb-10 text-center font-light text-burnt-sienna">
                    You won't be able to revert this! and Patients can't book appointment also!!
                </div>
                <div className="flex items-center justify-center space-x-3">
                    <Button
                        type="button"
                        className="rounded-lg text-indigo"
                        outlined
                        onClick={() => setIsCancelSubscriptionModal(false)}>
                        Cancel
                    </Button>
                    <Button
                        type="button"
                        width="28"
                        className="rounded-lg text-white bg-indigo"
                        loading={isLoaded}
                        onClick={() => onCancelSubscription()}
                    >
                        Delete
                    </Button>
                </div>
            </Modal>
            {isShowInvoice && (
                <div className='lg:px-7 lg:py-7 px-4 py-4 mb-6 rounded-lg bg-white w-auto'>
                    <div className="text-lg text-indigo mb-3">Invoices</div>
                    <DataTable
                        columns={columns}
                        data={invoice}
                        responsive={true}
                        customStyles={customStyles}
                    />
                </div>
            )}

        </div>
    )
}

export default SubscriptionDetails
import { retriveUsers } from "../../actions/user";
import clsx from "clsx";
import { Avatar, Tooltip } from "../../components/ui";
import _, { isEmpty } from "lodash";
import React, { useCallback, useEffect, useState } from "react";
import toast from "react-hot-toast";
import { useDispatch } from "react-redux";
import io from "socket.io-client";
import useSocketRequestLimit from "../../utils/useSocketRequestLimit";
import moment from "moment";
import { retrieveUserById } from "actions/auth";

const socket = io("https://j6jrztpjhr.eu-west-1.awsapprunner.com"); // Replace with your server address

function Chat() {
  const dispatch = useDispatch();

  const [users, setUsers] = useState([]);
  const [userDetails, setUserDetails] = useState({});
  const [chats, setChat] = useState([]);
  const [messagesList, setMessages] = useState([]);
  const [search, setSearch] = useState();
  const [messageInput, setMessageInput] = useState("");
  const [selectdUserForChat, setSelectedUserForChat] = useState(null);
  const [page, setPage] = useState(1);
  const [roomId, setRoomId] = useState(null);
  const [showMoreRecords, setShowMoreRecords] = useState(false);

  const senderUser = JSON.parse(localStorage.getItem("clinic-u"));

  const requestCount = useSocketRequestLimit("load chats", 10); // Set the limit as needed

  const sendMessage = () => {
    if (messageInput !== "") {
      socket.emit("sendMessage", {
        senderId: senderUser?.id,
        senderUID: senderUser?.id,
        receiverUID: selectdUserForChat?.id
          ? selectdUserForChat?.id
          : selectdUserForChat?.userDetails?.uid,
        senderEmail: senderUser?.emailAddress,
        receiverId: selectdUserForChat?.id
          ? selectdUserForChat?.id
          : selectdUserForChat?.userDetails?.uid,
        firstName: senderUser?.firstName
          ? senderUser?.firstName
          : selectdUserForChat?.userDetails?.firstName,
        lastName: senderUser?.lastName
          ? senderUser?.lastName
          : selectdUserForChat?.userDetails?.lastName,
        receiverEmail: selectdUserForChat?.emailAddress
          ? selectdUserForChat?.emailAddress
          : selectdUserForChat?.userDetails?.email,
        receiverFirstName: selectdUserForChat?.firstName
          ? selectdUserForChat?.firstName
          : selectdUserForChat?.userDetails?.firstName,
        receiverLastName: selectdUserForChat?.lastName
          ? selectdUserForChat?.lastName
          : selectdUserForChat?.userDetails?.lastName,
        message: messageInput,
      });
      console.log("sendMessage", {
        senderId: senderUser?.id,
        senderUID: senderUser?.id,
        receiverUID: selectdUserForChat?.id
          ? selectdUserForChat?.id
          : selectdUserForChat?.userDetails?.uid,
        senderEmail: senderUser?.emailAddress,
        receiverId: selectdUserForChat?.id
          ? selectdUserForChat?.id
          : selectdUserForChat?.userDetails?.uid,
        firstName: senderUser?.firstName
          ? senderUser?.firstName
          : selectdUserForChat?.userDetails?.firstName,
        lastName: senderUser?.lastName
          ? senderUser?.lastName
          : selectdUserForChat?.userDetails?.lastName,
        receiverEmail: selectdUserForChat?.emailAddress
          ? selectdUserForChat?.emailAddress
          : selectdUserForChat?.userDetails?.email,
        receiverFirstName: selectdUserForChat?.firstName
          ? selectdUserForChat?.firstName
          : selectdUserForChat?.userDetails?.firstName,
        receiverLastName: selectdUserForChat?.lastName
          ? selectdUserForChat?.lastName
          : selectdUserForChat?.userDetails?.lastName,
        message: messageInput,
      });
      setMessageInput("");
    }
  };

  const onSelectNewUserForChat = (user) => {
    setSelectedUserForChat(user);
    //register new chat user
    socket.emit("new user", {
      email: user?.emailAddress,
      firstName: user?.firstName ? user?.firstName : user?.clinic[0]?.name,
      lastName: user?.lastName,
      uid: user?.id,
    });
    console.log("register new chat user new user from onSelectNewUserForChat", {
      email: user?.emailAddress,
      firstName: user?.firstName ? user?.firstName : user?.clinic[0]?.name,
      lastName: user?.lastName,
      uid: user?.id,
    });
    //register self
    socket.emit("new user", {
      email: senderUser?.emailAddress,
      firstName: senderUser?.firstName
        ? senderUser?.firstName
        : senderUser?.clinic[0]?.name,
      lastName: senderUser?.lastName,
      uid: senderUser?.id,
    });
    console.log("register self new user from onSelectNewUserForChat", {
      email: senderUser?.emailAddress,
      firstName: senderUser?.firstName
        ? senderUser?.firstName
        : senderUser?.clinic[0]?.name,
      lastName: senderUser?.lastName,
      uid: senderUser?.id,
    });
    socket.emit("join room", {
      senderId: senderUser?.id,
      roomId: "TestRoom",
      receiverId: user?.id,
    });
    console.log("join room from from onSelectNewUserForChat", {
      senderId: senderUser?.id,
      roomId: "TestRoom",
      receiverId: user?.id,
    });
  };

  const onSelectUserForChat = (user) => {
    setSelectedUserForChat(user);
    socket.emit("new user", {
      email: senderUser?.emailAddress,
      firstName: senderUser?.clinic[0]?.name
        ? senderUser?.clinic[0]?.name
        : senderUser?.firstName,
      lastName: senderUser?.lastName,
      uid: senderUser?.id,
    });
    console.log("new user from onSelectUserForChat", {
      email: senderUser?.emailAddress,
      firstName: senderUser?.clinic[0]?.name
        ? senderUser?.clinic[0]?.name
        : senderUser?.firstName,
      lastName: senderUser?.lastName,
      uid: senderUser?.id,
    });
    socket.emit("join room", {
      senderId: senderUser?.id,
      roomId: "TestRoom",
      receiverId: user?.userDetails?.uid,
    });
    console.log("join room from onSelectUserForChat", {
      senderId: senderUser?.id,
      roomId: "TestRoom",
      receiverId: user?.userDetails?.uid,
    });
  };

  const fetchPatient = useCallback(() => {
    const params = {
      page: 1,
      limit: 1000,
      sort: "updatedAt",
      order: "desc",
      search: search,
      "filter[role.name]": "patient",
      include: "role",
    };
    dispatch(retriveUsers(params))
      .then((result) => {
        setUsers(result?.data);
      })
      .catch((error) => {
        toast.error(error.response.data.errors[0].detail);
      });
  }, [dispatch, setUsers, search]);

  const fatchUser = (user) => {
    const params = {
      include: "role,clinic",
    };
    dispatch(retrieveUserById(user?.userDetails?.uid, params))
      .then((response) => {
        setUserDetails(response.data);
      })
      .catch((error) => {
        toast.error(error.response.data.errors[0].detail);
      });
  };

  useEffect(() => {
    if (!isEmpty(search)) {
      fetchPatient();
    } else {
      setUsers([]);
    }
  }, [fetchPatient, search]);

  useEffect(() => {
    // Emit the 'load chats' event with the user's userId
    socket.emit("load chats", { userId: senderUser?.id });

    // Listen for the 'load chats' response from the server
    socket.on("load chats", ({ chats }) => {
      console.log("Received chat data:", chats);
      setChat(chats);
      // Do something with the chat data, e.g., update state or display it in the component
    });
    socket.on(
      "load messages",
      ({ messages, roomId, senderId, receiverId, isFirstTimeLoading }) => {
        // Store the room ID in your React state or wherever you need it
        // You can use the roomId in your React component's state or context
        // or perform any actions you need with it.
        const result = {
          roomId: roomId,
          senderId: senderId,
          receiverId: receiverId,
        };
        localStorage.removeItem("clinic-chat");
        localStorage.setItem("clinic-chat", JSON.stringify(result));
        setRoomId(roomId);
        console.log("Room ID:", roomId);
        console.log("Messages:", messages);
        if (
          !isEmpty(messages?.data) &&
          selectdUserForChat?.messages?.chatId === roomId
        ) {
          // Check for duplicate _id values and merge messages
          const updatedMessagesList = [...messagesList];
          for (const message of messages?.data) {
            const existingMessageIndex = updatedMessagesList.findIndex(
              (existingMessage) => message._id === existingMessage._id
            );
            if (existingMessageIndex === -1) {
              updatedMessagesList.push(message);
            }
          }
          const sortedData = _.sortBy(updatedMessagesList, "createdAt");
          setMessages(sortedData);
          setShowMoreRecords(messages?.moreRecords);
        } else {
          setMessages(messages?.data);
          setShowMoreRecords(messages?.moreRecords);
        }
        console.log("Is First Time Loading:", isFirstTimeLoading);
      }
    );

    // Emit a 'load messages' event
    if (roomId) {
      socket.emit("load messages", { roomId: roomId, n: page });
    }

    const handleNewMessage = (message) => {
      console.log(message, "by newMessage");
      setMessages((prevMessages) => [...prevMessages, message]);
    };

    // Subscribe to the 'newMessage' event
    socket.on("newMessage", handleNewMessage);

    // Cleanup the subscription when the component unmounts
    return () => {
      socket.off("newMessage", handleNewMessage);
    };

  }, [senderUser?.id, requestCount, page, roomId, selectdUserForChat]);

  return (
    <section className="ml-6 mr-7 items-center justify-center py-6">
      <div>
        <div className="text-xl font-semibold">Message</div>
      </div>
      <div className="lg:grid lg:grid-cols-4 lg:gap-4">
        <div className="col-1 mb-4 rounded-lg bg-white px-1 py-4 lg:mb-0">
          <div>
            <div className="mb-3 flex items-center justify-center px-2">
              <input
                type="text"
                className="h-12 w-full rounded-md border-none bg-alabaster pl-9 font-medium focus:ring-0"
                placeholder="Search"
                onChange={(e) => {
                  setSearch(e.target.value);
                }}
              />
              <button className="h-12 rounded-r-lg bg-indigo p-4">
                <img src="/img/logo/search-normal.png" alt="search patient" />
              </button>
            </div>
            <div className="h-[calc(100vh-250px)] overflow-auto px-3">
              {users.length > 0 &&
                users?.map((user, key) => {
                  return (
                    <div
                      key={key}
                      className={clsx(
                        "cursor-pointer border-b-[0.8px] border-alto px-2 py-3"
                      )}
                      onClick={() => onSelectNewUserForChat(user)}
                    >
                      <div
                        className={clsx(
                          selectdUserForChat?.emailAddress ===
                            user?.emailAddress
                            ? "bg-indigo text-white"
                            : "bg-white text-black",
                          "grid grid-cols-4 space-x-2 px-2 py-3"
                        )}
                      >
                        <div
                          className={clsx(
                            selectdUserForChat?.emailAddress ===
                              user?.emailAddress
                              ? "bg-white"
                              : "bg-alabaster",
                            `relative inline-flex h-10 w-10 items-center justify-center overflow-hidden rounded-full`
                          )}
                        >
                          <span className="font-medium text-indigo">
                            {(user.firstName?.[0] || "").toUpperCase() +
                              (user.lastName?.[0] || "").toUpperCase()}
                          </span>
                        </div>
                        <div className="col-span-3">
                          <div
                            className={clsx(
                              selectdUserForChat?.emailAddress ===
                                user?.emailAddress
                                ? "text-white"
                                : "text-black",
                              "text-base font-semibold"
                            )}
                          >
                            {user.firstName} {user.lastName}
                          </div>
                          <div className="flex w-full items-center justify-between">
                            <div
                              className={clsx(
                                selectdUserForChat?.emailAddress ===
                                  user?.emailAddress
                                  ? "text-white"
                                  : "text-black",
                                "truncate text-sm font-normal"
                              )}
                            >
                              {user?.emailAddress}
                            </div>
                            {/* <div
                              className={clsx(
                                selectdUserForChat?.emailAddress ===
                                  user?.emailAddress
                                  ? "text-white"
                                  : "text-black",
                                "text-sm font-normal"
                              )}
                            >
                              time
                            </div> */}
                          </div>
                        </div>
                      </div>
                    </div>
                  );
                })}
              {chats.length > 0 &&
                _.orderBy(chats, ["newMessage"], ["desc"])?.map((user, key) => {
                  return (
                    <div
                      key={key}
                      className={clsx(
                        "cursor-pointer border-b-[0.8px] border-alto px-2 py-3"
                      )}
                      onClick={() => onSelectUserForChat(user)}
                    >
                      <div
                        className={clsx(
                          selectdUserForChat?.userDetails?.email ===
                            user?.userDetails?.email
                            ? "bg-indigo text-white"
                            : "bg-white text-black",
                          "grid grid-cols-5 gap-1 px-2 py-3"
                        )}
                      >
                        <div
                          className={clsx(
                            selectdUserForChat?.userDetails?.email ===
                              user?.userDetails?.email
                              ? "bg-white"
                              : "bg-alabaster",
                            `relative inline-flex h-10 w-10 items-center justify-center overflow-hidden rounded-full`
                          )}
                        >
                          <span className="font-medium text-indigo">
                            {(
                              user?.userDetails?.firstName?.[0] || ""
                            ).toUpperCase() +
                              (
                                user?.userDetails?.lastName?.[0] || ""
                              ).toUpperCase()}
                          </span>
                        </div>
                        <div className="col-span-4">
                          <div
                            className={clsx(
                              selectdUserForChat?.userDetails?.email ===
                                user?.userDetails?.email
                                ? "text-white"
                                : "text-black",
                              "flex items-center justify-between space-x-4 text-base font-semibold"
                            )}
                          >
                            <div
                              className={clsx(
                                selectdUserForChat?.userDetails?.email ===
                                  user?.userDetails?.email
                                  ? "text-white"
                                  : "text-black",
                                "flex items-center space-x-4 text-base font-semibold"
                              )}
                            >
                              {user?.userDetails?.firstName}{" "}
                              {user?.userDetails?.lastName}
                            </div>
                            {user?.newMessage && (
                              <div className="h-3 w-3 rounded-full bg-indigo"></div>
                            )}
                          </div>
                          <div className="flex w-full items-center justify-between">
                            <div
                              className={clsx(
                                selectdUserForChat?.userDetails?.email ===
                                  user?.userDetails?.email
                                  ? "text-white"
                                  : "text-black",
                                "text-sm font-normal line-clamp-2"
                              )}
                            >
                              {user?.messages?.content}
                            </div>
                            <div
                              className={clsx(
                                selectdUserForChat?.userDetails?.email ===
                                  user?.userDetails?.email
                                  ? "text-white"
                                  : "text-black",
                                "text-sm font-normal"
                              )}
                            >
                              {moment(user?.messages?.createdAt).format(
                                "hh:mm A"
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  );
                })}
            </div>
          </div>
        </div>
        <div className="col-2 col-span-3 rounded-lg bg-white px-5 py-7">
          <div>
            {selectdUserForChat && (
              <div className="flex w-full flex-col">
                <div className="flex cursor-pointer items-center justify-between border-b-[0.8px] border-alto">
                  <div className="flex items-center space-x-3 pb-2">
                    <Avatar
                      firstName={
                        selectdUserForChat?.firstName
                          ? selectdUserForChat?.firstName
                          : selectdUserForChat?.userDetails?.firstName
                      }
                      lastName={
                        selectdUserForChat?.lastName
                          ? selectdUserForChat?.lastName
                          : selectdUserForChat?.userDetails?.lastName
                      }
                      image=""
                    />
                    <div className="text-base font-semibold">
                      <div>
                        {selectdUserForChat?.firstName
                          ? selectdUserForChat?.firstName
                          : selectdUserForChat?.userDetails?.firstName}
                        {selectdUserForChat?.lastName
                          ? selectdUserForChat?.lastName
                          : selectdUserForChat?.userDetails?.lastName}
                      </div>
                      {selectdUserForChat?.status ||
                      selectdUserForChat?.userDetails?.active ? (
                        <div className="text-sm font-normal text-[#38CB89]">
                          Online
                        </div>
                      ) : (
                        <div className="text-sm font-normal text-dove-gray">
                          Offline
                        </div>
                      )}
                    </div>
                  </div>
                  <div onClick={() => fatchUser(selectdUserForChat)}>
                    {userDetails && (
                      <Tooltip content={userDetails?.dob}>
                        <div className="text-black">
                          <img src="/img/logo/info-circle.png" alt="info" />
                        </div>
                      </Tooltip>
                    )}
                  </div>
                </div>
                <div className="h-[calc(100vh-350px)] overflow-auto py-1">
                  {showMoreRecords && (
                    <button onClick={() => setPage((prevPage) => prevPage + 1)}>
                      Load more
                    </button>
                  )}
                  {messagesList?.map((message, index) => (
                    <div
                      key={index}
                      className={clsx(
                        message?.senderId?.email === senderUser?.emailAddress
                          ? "justify-end"
                          : "justify-start",
                        "flex w-full flex-row py-2"
                      )}
                    >
                      <div
                        className={clsx(
                          message?.senderId?.email === senderUser?.emailAddress
                            ? "order-2"
                            : "order-1"
                        )}
                      ></div>
                      <div
                        className={clsx(
                          message?.senderId?.email === senderUser?.emailAddress
                            ? "order-1 ml-8 rounded-l-2xl rounded-t-2xl lg:mr-2"
                            : "order-2 mr-8 rounded-r-2xl rounded-t-2xl lg:ml-2",
                          "flex w-fit flex-col bg-indigo px-2 py-3 text-white"
                        )}
                      >
                        <span className="text-md max-w-2xl text-white">
                          {message.content}
                        </span>
                        <span className="mt-2 text-xs text-white">
                        {moment(message?.createdAt).format(
                                "hh:mm A"
                              )}
                          {/* {new Date(message.sentAt).toLocaleTimeString(
                            "en-US",
                            {
                              hour: "2-digit",
                              minute: "2-digit",
                            }
                          )} */}
                        </span>
                      </div>
                    </div>
                  ))}
                </div>
                <div className="flex items-center justify-between">
                  <input
                    type="text"
                    className="h-12 w-full rounded-l-md border-none bg-alabaster"
                    value={messageInput}
                    onChange={(e) => setMessageInput(e.target.value)}
                  />
                  <button
                    type="button"
                    className="h-12 w-56 rounded-r-md bg-indigo px-8 py-4 text-white"
                    onClick={sendMessage}
                  >
                    Send
                  </button>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </section>
  );
}

export default Chat;

import clsx from 'clsx';
import React from 'react'

const Timeline = ({ timeline }) => {

    return (
        <div>
            {timeline?.map((data, i) => {
                return (
                    <div key={i} className="flex space-x-8 mb-14">
                        <div className="rounded-full bg-indigo text-white font-bold font-plus-jakarta-sans px-3 py-2 h-12 flex justify-center items-center">
                            {data?.icon}
                        </div>
                        <div>
                            {data?.title && (<div>
                                <h1 className="text-elephant text-lg font-bold font-plus-jakarta-sans">{data?.title}</h1>
                            </div>)}
                            <div className="text-fiord text-base font-normal font-plus-jakarta-sans">
                                {data?.text}
                            </div>
                        </div>
                        <div className="absolute">
                            <div className={clsx(
                                i !== 0 && i === timeline.length - 1 ?
                                    "border-0" :
                                    "border-[0.5px] border-dashed", `relative top-12 right-3 h-${data?.height} border-fiord`)}>

                            </div>
                        </div>
                    </div>
                )
            }
            )}
        </div>
    );
}
export default Timeline;
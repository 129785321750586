import { useEffect, useState } from "react";
import io from "socket.io-client";

const socket = io("https://j6jrztpjhr.eu-west-1.awsapprunner.com");

function useSocketRequestLimit(event, limit) {
  const [requestCount, setRequestCount] = useState(0);

  useEffect(() => {
    const handleSocketEvent = (data) => {
      if (requestCount < limit) {
        // Perform your action with the data received from the socket
        console.log(data);

        // Update the request count
        setRequestCount(requestCount + 1);
      } else {
        // If the limit is reached, you can choose to unsubscribe from the socket event
        socket.off(event);
      }
    };

    // Add a listener for the socket event
    socket.on(event, handleSocketEvent);

    // Clean up the socket listener when the component unmounts
    return () => {
      socket.off(event, handleSocketEvent);
    };
  }, [requestCount, event, limit]);

  return requestCount;
}

export default useSocketRequestLimit;

import authHeader from "auth-header";
import httpCommon from "http-common";

const create = (data) => {
  return httpCommon.post("/our-client", data, { headers: authHeader() });
};

const update = (id, data) => {
  return httpCommon.patch(`/our-client/${id}`, data, { headers: authHeader() });
};

const remove = (id) => {
  return httpCommon.delete(`/our-client/${id}`, { headers: authHeader() });
};

const getOne = (id, params) => {
  const config = {
    headers: authHeader(),
    params: params,
  };
  return httpCommon.get(`/our-client/${id}`, config);
};

const getAll = async (params) => {
  const config = {
    params: params,
  };

  const response = await httpCommon.get(`/our-clients`, config);
  return response;
};

const OurClientsService = {
  create,
  update,
  remove,
  getOne,
  getAll,
};

export default OurClientsService;

import React from "react";
import { Button } from "components/ui";
import { useNavigate } from "react-router-dom";

const WelcomePage = () => {
  const navigate = useNavigate();

  return (
    <section className="flex min-h-screen flex-col items-center justify-center bg-[#F3F4F7]">
      <div className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
        <div className="mb-6 text-center text-3xl font-bold text-indigo">
          Welcome to Clinic2Go! &#128522;
        </div>
        <div className="mx-auto mb-6 max-w-4xl rounded-xl bg-white">
          <div className="py-8 px-5 text-center lg:py-11 lg:px-32">
            <div className="mb-4 text-base font-semibold">
              Start free trial for 30 days!
            </div>
            {/* <div className="mb-4 text-sm font-normal">
              Lorem Ipsum is simply dummy text of the printing and typesetting
              industry. Lorem Ipsum has been the industry's standard dummy text
              ever since the 1500s, when an unknown printer took a galley of
              type and scrambled it to make a type specimen book.
            </div>
            <div className="text-sm font-normal">
              Lorem Ipsum is simply dummy text of the printing and typesetting
              industry. Lorem Ipsum has been the industry's standard dummy text
              ever since the 1500s
            </div> */}
          </div>
        </div>
        <div className="flex justify-center space-x-3">
          <div>
            <Button 
            className="bg-indigo text-white font-normal py-2 px-4 rounded"
            onClick={() => navigate("/subscription/all")}
            width={44}
            >
              Set up payment
            </Button>
          </div>
          <div>
            <button
              className="bg-white text-black font-normal py-2 px-4 rounded"
              onClick={() => navigate("/clinic/emailVerification")}
            >
              Skip for now
            </button>
          </div>
        </div>
      </div>
    </section>
  );
};

export default WelcomePage;

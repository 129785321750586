import authHeader from "auth-header";
import httpCommon from "http-common";

const create = (data) => {
  return httpCommon.post("/blog", data, { headers: authHeader() });
};

const update = (id, data) => {
  return httpCommon.patch(`/blog/${id}`, data, { headers: authHeader() });
};

const remove = (id) => {
  return httpCommon.delete(`/blog/${id}`, { headers: authHeader() });
};

const getOne = (id, params) => {
  const config = {
    headers: authHeader(),
    params: params,
  };
  return httpCommon.get(`/blog/${id}`, config);
};

const getAll = async (params) => {
  const config = {
    params: params,
  };

  const response = await httpCommon.get(`/blogs`, config);
  return response;
};

const BlogsService = {
  create,
  update,
  remove,
  getOne,
  getAll,
};

export default BlogsService;

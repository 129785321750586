import Footer from 'pages/LandingPages/footer'
import Header from 'pages/LandingPages/header'
import React from 'react'

const PrivacyPolicy = () => {
  return (
    <div className="min-h-screen">
      <Header />
      <section className="mt-6">
        <div className="max-w-7xl mx-auto py-6 px-6">
          <h2 className="font-bold text-2xl">Privacy Policy and GDPR policy.</h2>
          <p className="text-base mt-6 mb-2">1. Introduction</p>
          <p className="text-base mt-2 mb-2">1.1 We are committed to safeguarding the privacy of our website visitors, service users, individual customers and customer personnel.</p>
          <p className="text-base mt-2 mb-2">1.2 This policy applies where we are acting as a data controller with respect to the personal data of such persons; in other words, where we determine the purposes and means of the processing of that personal data.</p>
          <p className="text-base mt-2 mb-2">1.3 Our website incorporates privacy controls which affect how we will process your personal data. By using the privacy controls, you can specify whether you would like to receive direct marketing communications and limit the collection, sharing and publication of your personal data. </p>
          <p className="text-base mt-2 mb-2">1.4 We use cookies on our website. Insofar as those cookies are not strictly necessary for the provision of our website, we will ask you to consent to our use of cookies when you first visit our websites</p>
          <p className="text-base mt-2 mb-2">1.5 In this policy, "we", "us" and "our" refer to CLINIC2GO For more information about us, see Section </p>

          <p className="text-base mt-6 mb-2">2. The personal data that we collect</p>
          <p className="text-base mt-2 mb-2">2.1 In this Section 2 we have set out the general categories of personal data that we process and, in the case of personal data that we did not obtain directly from you, information about the source and specific categories of that data.</p>
          <p className="text-base mt-2 mb-2">2.2 We may process data enabling us to get in touch with you ("<b>contact data</b>").The contact data may include your name, email address, telephone number, postal address and/or social media account identifiers The source of the contact data is you. If you log into our website using a social media account, we will obtain elements of the contact data from the relevant social media account provider.</p>
          <p className="text-base mt-2 mb-2">2.3 As a health provider we may also process health information relating to service offered. These details may include your NHS number, Health screening details and Gender. The primary source of the health data is you and will not be processed without your consent. </p>
          <p className="text-base mt-2 mb-2">2.4 We may process your website user account data ("<b>account data</b>"). The account data may include your account identifier, name, email address, business name, account creation and modification dates, website settings and marketing preferences. The primary source of the account data is you, although some elements of the account data may be generated by our website. If you log into our website using a social media account, we will obtain elements of the account data from the relevant social media account provider.</p>
          <p className="text-base mt-2 mb-2">2.5 We may process information relating to transactions, including purchases of goods and/or services, that you enter into with us and/or through our website ("<b>transaction data</b>"). The transaction data may include your name, your contact details, your payment card details (or other payment details) and the transaction details. The source of the transaction data is you and our payment services provider.</p>
          <p className="text-base mt-2 mb-2">2.6 We may process information contained in or relating to any communication that you send to us or that we send to you ("<b>communication data</b>"). The communication data may include the communication content and metadata associated with the communication. Our website will generate the metadata associated with communications made using the website contact forms.</p>
          <p className="text-base mt-2 mb-2">2.7 We may process data about your use of our website and services ("<b>usage data</b>"). The usage data may include your IP address, geographical location, browser type and version, operating system, referral source, length of visit, page views and website navigation paths, as well as information about the timing, frequency and pattern of your service use]. </p>

          <p className="text-base mt-6 mb-2">3. Purposes of processing and legal bases</p>
          <p className="text-base mt-2 mb-2">3.1 In this Section 3, we have set out the purposes for which we may process personal data and the legal bases of the processing.</p>
          <p className="text-base mt-2 mb-2">3.2 <b>Operations</b> - We may process your personal data for the purposes of operating our website, the processing and fulfillment of orders, providing our services, supplying our goods, generating invoices, bills and other payment-related documentation, and credit control. The legal basis for this processing is our legitimate interests, namely the proper administration of our website, services and business</p>
          <p className="text-base mt-2 mb-2">3.3 <b>Publications</b> - We may process account data for the purposes of publishing such data on our website and elsewhere through our services in accordance with your express instructions. The legal basis for this processing is consent.</p>
          <p className="text-base mt-2 mb-2">3.4 <b>Relationships and communications</b> - We may process contact data, account data, transaction data and/or communication data for the purposes of managing our relationships, communicating with you (excluding communicating for the purposes of direct marketing) by email, SMS, post, fax and/or telephone, providing support services and complaint handling. The legal basis for this processing is our legitimate interests, namely communications with our website visitors, service users, individual customers and customer personnel, the maintenance of relationships, and the proper administration of our website, services and business.</p>
          <p className="text-base mt-2 mb-2">3.5 <b>Direct marketing</b> - We may process contact data, account data and/or transaction data for the purposes of creating, targeting and sending direct marketing communications by email, SMS, post and/or fax and making contact by telephone for marketing-related purposes. The legal basis for this processing is consent.</p>
          <p className="text-base mt-2 mb-2">3.6 <b>Research and analysis</b> - We may process usage data and/or transaction data] for the purposes of researching and analysing the use of our website and services, as well as researching and analysing other interactions with our business. The legal basis for this processing is consent.</p>
          <p className="text-base mt-2 mb-2">3.7 <b>Record keeping</b> - We may process your personal data for the purposes of creating and maintaining our databases, back-up copies of our databases and our business records generally. The legal basis for this processing is our legitimate interests, namely ensuring that we have access to all the information we need to properly and efficiently run our business in accordance with this policy.</p>
          <p className="text-base mt-2 mb-2">3.8 <b>Security</b> - We may process your personal data for the purposes of security and the prevention of fraud and other criminal activity. The legal basis of this processing is our legitimate interests, namely the protection of our website, services and business, and the protection of others.</p>
          <p className="text-base mt-2 mb-2">3.9 <b>Insurance and risk management</b> - We may process your personal data where necessary for the purposes of obtaining or maintaining insurance coverage, managing risks and/or obtaining professional advice. The legal basis for this processing is our legitimate interests, namely the proper protection of our business against risks.</p>
          <p className="text-base mt-2 mb-2">3.10 <b>Legal claims</b> - We may process your personal data where necessary for the establishment, exercise or defence of legal claims, whether in court proceedings or in an administrative or out-of-court procedure. The legal basis for this processing is our legitimate interests, namely the protection and assertion of our legal rights, your legal rights and the legal rights of others].</p>
          <p className="text-base mt-2 mb-2">3.11 <b>Legal compliance and vital interests</b> - We may also process your personal data where such processing is necessary for compliance with a legal obligation to which we are subject or in order to protect your vital interests or the vital interests of another natural person.</p>

          <p className="text-base mt-6 mb-2">4. Providing your personal data to others</p>
          <p className="text-base mt-2 mb-2">4.1 We may disclose your personal data to our partners and/or professional advisers insofar as reasonably necessary for the purposes of obtaining or maintaining insurance coverage, managing risks, obtaining professional advice, or Providing services].</p>
          <p className="text-base mt-2 mb-2">4.2 Your personal data held in our website database will be stored on the servers of our hosting services providers</p>
          <p className="text-base mt-2 mb-2">4.3 We may disclose specific personal data or names to our suppliers or subcontractors as reasonably necessary for specific purposes.</p>
          <p className="text-base mt-2 mb-2">4.4 Financial transactions relating to our website and services will be handled by our payment services provider. We will share transaction data with our payment services providers only to the extent necessary for the purposes of processing your payments, refunding such payments and dealing with complaints and queries relating to such payments and refunds. </p>
          <p className="text-base mt-2 mb-2">4.5 In addition to the specific disclosures of personal data set out in this Section 4, we may disclose your personal data where such disclosure is necessary for compliance with a legal obligation to which we are subject, or in order to protect your vital interests or the vital interests of another natural person. We may also disclose your personal data where such disclosure is necessary for the establishment, exercise, or defence of legal claims, whether in court proceedings or in an administrative or out-of-court procedure.</p>

          <p className="text-base mt-6 mb-2">5. International transfers of your personal data</p>
          <p className="text-base mt-2 mb-2">5.1 In this Section 5, we provide information about the circumstances in which your personal data may be transferred to countries outside the United Kingdom and the European Economic Area (EEA).</p>
          <p className="text-base mt-2 mb-2">5.2 The hosting facilities for our website are situated in the UK. The competent data protection authorities have made an "adequacy decision" with respect to the data protection laws of each of these countries. Transfers to each of these countries will be protected by appropriate safeguards, namely [the use of standard data protection clauses adopted or approved by the competent data protection authorities.</p>
          <p className="text-base mt-2 mb-2">5.3 The competent data protection authorities have made an "adequacy decision" with respect to the data protection laws of each of these countries. Transfers to each of these countries will be protected by appropriate safeguards, namely the use of standard data protection clauses adopted or approved by the competent data protection authorities.</p>
          <p className="text-base mt-2 mb-2">5.4 You acknowledge that personal data that you submit for publication through our website or services may be available, via the internet, around the world. We cannot prevent the use (or misuse) of such personal data by others.</p>

          <p className="text-base mt-6 mb-2">6. Retaining and deleting personal data</p>
          <p className="text-base mt-2 mb-2">6.1 This Section 6 sets out our data retention policies and procedures, which are designed to help ensure that we comply with our legal obligations in relation to the retention and deletion of personal data.</p>
          <p className="text-base mt-2 mb-2">6.2 Personal data that we process for any purpose or purposes shall not be kept for longer than is necessary for that purpose or those purposes.</p>
          <p className="text-base mt-2 mb-2">6.3 We will retain your personal data as follows:</p>
          <p className="text-base mt-2 mb-2">(a) contact data will be retained for a minimum period of 7 years following the date of the most recent contact between you and us, and for a maximum period of 7 Years.</p>
          <p className="text-base mt-2 mb-2">(b) account data will be retained for a minimum period of 7 Years following the date of closure of the relevant account, and for a maximum period of 7 years</p>
          <p className="text-base mt-2 mb-2">(c) transaction data will be retained for a minimum period of 7 Years following the date of the transaction, and for a maximum period of 7 Years</p>
          <p className="text-base mt-2 mb-2">(d) communication data will be retained for a minimum period of 7 Years following the date of the communication in question, and for a maximum period of 7 Years.</p>
          <p className="text-base mt-2 mb-2">(e) usage data will be retained for 7 Years following the date of collection.</p>
          <p className="text-base mt-2 mb-2">6.4 Notwithstanding the other provisions of this Section 6, we may retain your personal data where such retention is necessary for compliance with a legal obligation to which we are subject, or in order to protect your vital interests or the vital interests of another natural person.</p>


          <p className="text-base mt-6 mb-2">7. Your rights</p>
          <p className="text-base mt-2 mb-2">7.1 In this Section 8, we have listed the rights that you have under data protection law.</p>
          <p className="text-base mt-2 mb-2">7.2 Your principal rights under data protection law are:</p>
          <p className="text-base mt-2 mb-2">(a) the right to access - you can ask for copies of your personal data;</p>
          <p className="text-base mt-2 mb-2">(b) the right to rectification - you can ask us to rectify inaccurate personal data and to complete incomplete personal data;</p>
          <p className="text-base mt-2 mb-2">(c) the right to erasure - you can ask us to erase your personal data;</p>
          <p className="text-base mt-2 mb-2">(d) the right to restrict processing - you can ask us to restrict the processing of your personal data;</p>
          <p className="text-base mt-2 mb-2">(e) the right to object to processing - you can object to the processing of your personal data;</p>
          <p className="text-base mt-2 mb-2">(f) the right to data portability - you can ask that we transfer your personal data to another organisation or to you;</p>
          <p className="text-base mt-2 mb-2">(g) the right to complain to a supervisory authority - you can complain about our processing of your personal data; and</p>
          <p className="text-base mt-2 mb-2">(h) the right to withdraw consent - to the extent that the legal basis of our processing of your personal data is consent, you can withdraw that consent.</p>
          <p className="text-base mt-2 mb-2">7.3 These rights are subject to certain limitations and exceptions. You can learn more about the rights of data subjects by visiting https://ico.org.uk/for-organisations/guide-to-data-protection/guide-to-the-general-data-protection-regulation-gdpr/individual-rights/.</p>
          <p className="text-base mt-2 mb-2">7.4 You may exercise any of your rights in relation to your personal data by written notice to us, using the contact details set out below].</p>

          <p className="text-base mt-6 mb-2">8. About cookies</p>
          <p className="text-base mt-2 mb-2">8.1 A cookie is a file containing an identifier (a string of letters and numbers) that is sent by a web server to a web browser and is stored by the browser. The identifier is then sent back to the server each time the browser requests a page from the server.</p>
          <p className="text-base mt-2 mb-2">8.2 Cookies may be either "persistent" cookies or "session" cookies: a persistent cookie will be stored by a web browser and will remain valid until its set expiry date, unless deleted by the user before the expiry date; a session cookie, on the other hand, will expire at the end of the user session, when the web browser is closed.</p>
          <p className="text-base mt-2 mb-2">8.3 Cookies may not contain any information that personally identifies a user, but personal data that we store about you may be linked to the information stored in and obtained from cookies.</p>


          <p className="text-base mt-6 mb-2">9. Cookies that we use</p>
          <p className="text-base mt-2 mb-2">9.1  We use cookies for the following purposes:</p>
          <p className="text-base mt-2 mb-2">(a) <b>authentication and status</b> - we use cookies to identify you when you visit our website and as you navigate our website, and to help us determine if you are logged into our website </p>
          <p className="text-base mt-2 mb-2">(b) <b>shopping cart</b> - we use cookies to maintain the state of your shopping cart as you navigate our website </p>
          <p className="text-base mt-2 mb-2">(c) <b>personalisation</b> - we use cookies to store information about your preferences and to personalise our website for you </p>
          <p className="text-base mt-2 mb-2">(d) <b>security</b> - we use cookies as an element of the security measures used to protect user accounts, including preventing fraudulent use of login credentials, and to protect our website and services generally </p>
          <p className="text-base mt-2 mb-2">(e) <b>advertising</b> - we use cookies to help us to display advertisements that will be relevant to you </p>
          <p className="text-base mt-2 mb-2">(f) <b>analysis</b> - we use cookies to help us to analyse the use and performance of our website and services and</p>
          <p className="text-base mt-2 mb-2">(g) <b>cookie consent</b> - we use cookies to store your preferences in relation to the use of cookies more generally.</p>

          <p className="text-base mt-6 mb-2">10. Cookies used by our service providers</p>
          <p className="text-base mt-2 mb-2">10.1  Our service providers use cookies and those cookies may be stored on your computer when you visit our website.</p>
          <p className="text-base mt-2 mb-2">10.2  We use Google Analytics. Google Analytics gathers information about the use of our website by means of cookies. The information gathered is used to create reports about the use of our website. You can find out more about Google's use of information by visiting <a href="https://www.google.com/policies/privacy/partners/">https://www.google.com/policies/privacy/partners/</a> and you can review Google's privacy policy at <a href="https://policies.google.com/privacy">https://policies.google.com/privacy</a></p>

          <p className="text-base mt-6 mb-2">11. Managing cookies</p>
          <p className="text-base mt-2 mb-2">11.1  Most browsers allow you to refuse to accept cookies and to delete cookies. The methods for doing so vary from browser to browser, and from version to version. You can however obtain up-to-date information about blocking and deleting cookies via these links:</p>
          <p className="text-base mt-2 mb-2">(a) <a href="https://support.google.com/chrome/answer/95647">https://support.google.com/chrome/answer/95647</a> (Chrome);</p>
          <p className="text-base mt-2 mb-2">(b) <a href="https://support.mozilla.org/en-US/kb/enable-and-disable-cookies-website-preferences">https://support.mozilla.org/en-US/kb/enable-and-disable-cookies-website-preferences</a> (Firefox);</p>
          <p className="text-base mt-2 mb-2">(c) <a href="https://help.opera.com/en/latest/security-and-privacy/">https://help.opera.com/en/latest/security-and-privacy/</a> (Opera);</p>
          <p className="text-base mt-2 mb-2">(d) <a href="https://support.microsoft.com/en-gb/help/17442/windows-internet-explorer-delete-manage-cookies">https://support.microsoft.com/en-gb/help/17442/windows-internet-explorer-delete-manage-cookies</a>(Internet Explorer);</p>
          <p className="text-base mt-2 mb-2">(e) <a href="https://support.apple.com/en-gb/guide/safari/manage-cookies-and-website-data-sfri11471/mac">https://support.apple.com/en-gb/guide/safari/manage-cookies-and-website-data-sfri11471/mac</a> (Safari); and</p>
          <p className="text-base mt-2 mb-2">(f) <a href="https://privacy.microsoft.com/en-us/windows-10-microsoft-edge-and-privacy">https://privacy.microsoft.com/en-us/windows-10-microsoft-edge-and-privacy</a> (Edge).</p>
          <p className="text-base mt-2 mb-2">11.2  Blocking all cookies will have a negative impact upon the usability of many websites.</p>
          <p className="text-base mt-2 mb-2">11.3  If you block cookies, you will not be able to use all the features on our website.</p>

          <p className="text-base mt-6 mb-2">12. Amendments</p>
          <p className="text-base mt-2 mb-2">12.1  We may update this policy from time to time by publishing a new version on our website.</p>
          <p className="text-base mt-2 mb-2">12.2  You should check this page occasionally to ensure you are happy with any changes to this policy.</p>
          <p className="text-base mt-2 mb-2">12.3  We may notify you of significant changes to this policy by email.</p>

          <p className="text-base mt-6 mb-2">13. Our details</p>
          <p className="text-base mt-2 mb-2">13.1 This website is owned and operated by CLINIC2GO</p>
          <p className="text-base mt-2 mb-2">13.2 We are registered in England and Wales under 13961194</p>
          <p className="text-base mt-2 mb-2">13.3 Our principal place of business is at 91 sudbury court road, HA1 3SG, Harrow.</p>
          <p className="text-base mt-2 mb-2">13.4 You can contact us:</p>
          <p className="text-base mt-2 mb-2">(a) by post, 91 sudbury court road, HA1 3SG, Harrow.</p>
          <p className="text-base mt-2 mb-2">(b) using our website contact form;</p>
          <p className="text-base mt-2 mb-2">(c) by email, using support@clinic2go.co.uk</p>

          <p className="text-base mt-6 mb-2">14. Data protection officer</p>
          <p>14.1  Our data protection officer's contact details are – <a href="mailto:support@clinic2go.co.uk">support@clinic2go.co.uk</a></p>

          <h2 className="font-bold text-2xl">Privacy Policy and GDPR policy.</h2>
          <p className="text-base mt-2 mb-2">About this cookie policy</p>
          <p className="text-base mt-2 mb-2">This Cookie Policy explains what cookies are and how we use them, the types of cookies we use i.e, the information we collect using cookies and how that information is used, and how to control the cookie preferences. For further information on how we use, store, and keep your personal data secure, see our Privacy Policy. You can at any time change or withdraw your consent from the Cookie Declaration on our website Learn more about who we are, how you can contact us, and how we process personal data in our Privacy Policy. Your consent applies to the following domains: <a className="underline" href='https://www.clinic2go.co.uk'>www.clinic2go.co.uk</a> Your current state: Consent accepted. Manage your consent</p>
          <p className="text-base mt-4 mb-2">What are cookies ?</p>
          <p className="text-base mt-2 mb-2">Cookies are small text files that are used to store small pieces of information. They are stored on your device when the website is loaded on your browser. These cookies help us make the website function properly, make it more secure, provide better user experience, and understand how the website performs and to analyze what works and where it needs improvement.</p>
          <p className="text-base mt-4 mb-2">How do we use cookies ?</p>
          <p className="text-base mt-2 mb-2">As most of the online services, our website uses first-party and third-party cookies for several purposes. First-party cookies are mostly necessary for the website to function the right way, and they do not collect any of your personally identifiable data. The third-party cookies used on our website are mainly for understanding how the website performs, how you interact with our website, keeping our services secure, providing advertisements that are relevant to you, and all in all providing you with a better and improved user experience and help speed up your future interactions with our website.</p>
          <p className="text-base mt-4 mb-2">What types of cookies do we use ?</p>
          <p className="text-base mt-2 mb-2">Essential: Some cookies are essential for you to be able to experience the full functionality of our site. They allow us to maintain user sessions and prevent any security threats. They do not collect or store any personal information. For example, these cookies allow you to log-in to your account and add products to your basket, and checkout securely.</p>
          <p className="text-base mt-2 mb-2">Statistics: These cookies store information like the number of visitors to the website, the number of unique visitors, which pages of the website have been visited, the source of the visit, etc. These data help us understand and analyze how well the website performs and where it needs improvement.</p>
          <p className="text-base mt-2 mb-2">Marketing: Our website displays advertisements. These cookies are used to personalize the advertisements that we show to you so that they are meaningful to you. These cookies also help us keep track of the efficiency of these ad campaigns. The information stored in these cookies may also be used by the third-party ad providers to show you ads on other websites on the browser as well.</p>
          <p className="text-base mt-2 mb-2">Functional: These are the cookies that help certain non-essential functionalities on our website. These functionalities include embedding content like videos or sharing content of the website on social media platforms.</p>
          <p className="text-base mt-2 mb-2">Preferences: These cookies help us store your settings and browsing preferences like language preferences so that you have a better and efficient experience on future visits to the website.</p>
          <p className="text-base mt-4 mb-2">How can I control the cookie preferences ?</p>
          <p className="text-base mt-2 mb-2">Should you decide to change your preferences later through your browsing session, you can click on the "Privacy & Cookie Policy" tab on your screen. This will display the consent notice again enabling you to change your preferences or withdraw your consent entirely. In addition to this, different browsers provide different methods to block and delete cookies used by websites. You can change the settings of your browser to block/delete the cookies. To find out more about how to manage and delete cookies, visit wikipedia.org, <a className="underline" href='https://www.allaboutcookies.org'>www.allaboutcookies.org.</a></p>
        </div>
      </section>
      <Footer />
    </div>
  )
}

export default PrivacyPolicy
import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { Button } from "components/ui";
import OpeningHours from "pages/Clinic/openingHours";
import { updateClinicData } from "features/clinic/clinicSlice";
import { useDispatch, useSelector } from "react-redux";

const Step3ofClinic = (props) => {
  const Hours = [
    {
      weekDay: 0,
      name: "Sunday",
      isActive: false,
      clinicHours: [
        {
          from: "00:00",
          to: "00:00",
        },
      ],
    },
    {
      weekDay: 1,
      name: "Monday",
      isActive: false,
      clinicHours: [
        {
          from: "00:00",
          to: "00:00",
        },
      ],
    },
    {
      weekDay: 2,
      name: "Tuesday",
      isActive: false,
      clinicHours: [
        {
          from: "00:00",
          to: "00:00",
        },
      ],
    },
    {
      weekDay: 3,
      name: "Wednesday",
      isActive: false,
      clinicHours: [
        {
          from: "00:00",
          to: "00:00",
        },
      ],
    },
    {
      weekDay: 4,
      name: "Thursday",
      isActive: false,
      clinicHours: [
        {
          from: "00:00",
          to: "00:00",
        },
      ],
    },
    {
      weekDay: 5,
      name: "Friday",
      isActive: false,
      clinicHours: [
        {
          from: "00:00",
          to: "00:00",
        },
      ],
    },
    {
      weekDay: 6,
      name: "Saturday",
      isActive: false,
      clinicHours: [
        {
          from: "00:00",
          to: "00:00",
        },
      ],
    },
  ];
  const step3Data = useSelector(
    (state) => state.persistedReducer.clinic.clinic.openingDays
  );
  let [openingHours, setOpeningHours] = useState(step3Data || Hours);
  const { setSelectedIndex } = props;

  const clinicServices = useSelector(
    (state) => state.persistedReducer.clinic.clinic.clinicServices
  );
  const getDefaultValuesForStep3 = () => {
    let obj = {};
    step3Data?.forEach((s) => {
      if (s.isActive) {
        obj[`to-${s.name}`] = s.clinicHours[0].to;
        obj[`from-${s.name}`] = s.clinicHours[0].from;
      }
    });
    return obj;
  };
  const {
    handleSubmit,
    register,
    formState: { errors },
  } = useForm({
    defaultValues: getDefaultValuesForStep3(),
  });
  const dispatch = useDispatch();
  return (
    <div>
      <form
        onSubmit={handleSubmit(() => {
          setSelectedIndex(3);
          const newKey = "serviceHours";
          const serviceHours = openingHours.map(({ clinicHours, ...rest }) => ({
            [newKey]: clinicHours,
            ...rest,
          }));

          let a = clinicServices.map((c) => {
            return {
              ...c,
              serviceOpeningDays: serviceHours,
            };
          });
          dispatch(
            updateClinicData({
              openingDays: openingHours,
              clinicServices: a,
            })
          );
        })}
        className="mt-20 md:mt-0"
      >
        <OpeningHours
          setOpeningHours={setOpeningHours}
          openingHours={openingHours}
          errors={errors}
          register={register}
        />
        <div className="flex justify-end pt-6">
          <Button
            style={{ backgroundColor: "#e2e2e2", color: "#000000" }}
            className="bg-alabaster text-black font-normal py-2 px-4 rounded-l"
            text="lg"
            onClick={() => {
              setSelectedIndex(1);
            }}
          >
            Previous
          </Button>

          <Button
            className="bg-indigo text-white font-normal py-2 px-4 rounded-r"
            type="submit"
            text="lg"
          >
            Next
          </Button>
        </div>
      </form>
    </div>
  );
};

export default Step3ofClinic;

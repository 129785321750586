import React, { useEffect } from 'react'
import { Link, useLocation, useNavigate } from 'react-router-dom'

const NewFooter = () => {

    const navigate = useNavigate();
    const location = useLocation();
    const onClickContact = () => {
        if (location.pathname !== "/") {
            navigate("/#contact-us")
        } else {
            navigate("#contact-us")
        }
    }

    // Function to scroll to the "Contact us" section
    const scrollToContactUs = () => {
        const contactUsElement = document.getElementById('contact-us');
        if (contactUsElement) {
            // Scroll to the element with smooth behavior
            contactUsElement.scrollIntoView({ behavior: 'smooth' });
        }
    };

    // Listen for changes in the location and scroll to "Contact us" if the hash is present
    useEffect(() => {
        if (location.hash === '#contact-us') {
            scrollToContactUs();
        }
    }, [location])

    return (
        <section className="bg-indigo">
            <div className="max-w-7xl mx-auto py-6 px-6">
                <div className="lg:flex justify-between items-center border-b-[0.3px] border-white">
                    <div className="mb-7">
                        <img src="/img/logo/darkLogo.png" alt="logo" className="h-10 w-44 object-contain" />
                    </div>
                    <div className="lg:flex lg:space-x-8 mb-7 grid grid-cols-2 gap-x-5">
                        <Link to="/" className="text-white font-plus-jakarta-sans text-lg">Home</Link>
                        <Link to="/landing-blogs" className="text-white font-plus-jakarta-sans text-lg">Blogs</Link>
                        {/* <a href="#contact-us" className="text-white font-plus-jakarta-sans text-lg">Contact us</a> */}
                        <div onClick={() => onClickContact()} className="text-white font-plus-jakarta-sans text-lg cursor-pointer">Contact us</div>
                        <Link to="/login" className="text-white font-plus-jakarta-sans text-lg">Sign In</Link>
                        <Link to="/register" className="text-white font-plus-jakarta-sans text-lg">Register Your Clinic</Link>
                    </div>
                </div>
                <div className="lg:flex justify-between items-center mt-5">
                    <div className="mb-7 text-white font-plus-jakarta-sans text-base font-normal">
                        Copyright©Clinic2Go. All right reserved
                    </div>
                    <div className="flex space-x-8 mb-7">
                        <img src="/img/logo/fb.png" alt="logo" className="object-contain" />
                        <img src="/img/logo/google.png" alt="logo" className="object-contain" />
                        <img src="/img/logo/twitter.png" alt="logo" className="object-contain" />
                        <img src="/img/logo/insta.png" alt="logo" className="object-contain" />
                        <img src="/img/logo/linkedin.png" alt="logo" className="object-contain" />
                    </div>
                </div>
            </div>
        </section>
    )
}

export default NewFooter
import React from 'react'

const CloseCircle = ({ ...props }) => {
    return (
        <svg id="Group_19083" data-name="Group 19083" xmlns="http://www.w3.org/2000/svg" width="44" height="44" viewBox="0 0 44 44" {...props}>
            <circle id="Ellipse_9" data-name="Ellipse 9" cx="22" cy="22" r="22" fill="#eb6565" />
            <g id="Group_19068" data-name="Group 19068" transform="translate(17 17)">
                <path id="Path_60" data-name="Path 60" d="M916.05,54.7l-5,5,5,5" transform="translate(-906.05 -54.699)" fill="none" stroke="#fff" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" />
                <path id="Path_15207" data-name="Path 15207" d="M911.05,54.7l5,5-5,5" transform="translate(-911.05 -54.699)" fill="none" stroke="#fff" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" />
            </g>
        </svg>
    )
}

export default CloseCircle
import Service from "services/service";
import {
    CREATE_SERVICES,
    GET_ALL_SERVICES,
    GET_ALL_SERVICES_1,
    GET_ONE_SERVICES,
    REMOVE_SERVICES,
    UPDATE_SERVICES
} from "./type";

export const createService = (data) => async (dispatch) => {
    try {
        const res = await Service.create(data);

        dispatch({
            type: CREATE_SERVICES,
            payload: res.data,
        });

        return Promise.resolve(res.data);
    } catch (error) {
        return Promise.reject(error);
    }
};

export const updateService = (id, data) => async (dispatch) => {
    try {
        const res = await Service.update(id, data);

        dispatch({
            type: UPDATE_SERVICES,
            payload: res.data,
        });

        return Promise.resolve(res.data);
    } catch (error) {
        return Promise.reject(error);
    }
};

export const deleteService = (id) => async (dispatch) => {
    try {
        const res = await Service.remove(id);

        dispatch({
            type: REMOVE_SERVICES,
            payload: res.data,
        });

        return Promise.resolve(res.data);
    } catch (error) {
        return Promise.reject(error);
    }
};

export const retrieveOne = (id, params) => async (dispatch) => {
    try {
        const res = await Service.getOne(id, params);

        dispatch({
            type: GET_ONE_SERVICES,
            payload: res.data,
        });

        return Promise.resolve(res.data);
    } catch (error) {
        return Promise.reject(error);
    }
};

export const retriveServices = (data) => async (dispatch) => {
    try {
        const res = await Service.getAll(data);

        dispatch({
            type: GET_ALL_SERVICES,
            payload: res.data,
        });

        return Promise.resolve(res.data);
    } catch (error) {
        return Promise.reject(error);
    }
};

export const retriveAllServices = (data) => async (dispatch) => {
    try {
        const res = await Service.getAllServices(data);

        dispatch({
            type: GET_ALL_SERVICES_1,
            payload: res.data,
        });

        return Promise.resolve(res.data);
    } catch (error) {
        return Promise.reject(error);
    }
};
import { retriveAppointments } from "actions/appointment";
import { CheckCircle } from "components/icons";
import { Avatar, Button } from "components/ui";
import React, { useCallback, useEffect, useState } from "react";
import DataTable from "react-data-table-component";
import toast from "react-hot-toast";
import { useDispatch } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import customStyles from "utils/data-table-style";
import { AppointmentStatus } from "utils/roles";

const UpComingAppoiment = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const auth = JSON.parse(localStorage.getItem("clinic-u"));
  const clinicId = auth?.clinic[0]?.id;

  const [appointment, setAppointment] = useState([]);
  const [page, setPage] = useState(1);
  const [isLoaded, setIsLoaded] = useState(false);
  const [totalRows, setTotalRows] = useState(0);
  const [perPage, setPerPage] = useState(5);
  const [status, setStatus] = useState(AppointmentStatus.IN_PROGRESS);

  const handlePageChange = (page) => {
    setPage(page);
  };

  const handlePerRowsChange = async (newPerPage, page) => {
    setPerPage(newPerPage);
  };

  const columns = [
    {
      name: "Date",
      cell: (row) => (
        <div>
          <p>{row.date}</p>
          <p className="text-indigo">{row.time}</p>
        </div>
      ),
    },
    {
      name: "Patient’s name",
      cell: (row) => (
        <div className="items-center lg:flex">
          <Avatar
            firstName={row?.user?.firstName}
            lastName={row?.user?.lastName}
            image=""
          />
          <div className="ml-1">
            {row?.user?.firstName} {row?.user?.lastName}
          </div>
        </div>
      ),
      minWidth: "200px",
    },
    {
      name: "Service",
      selector: (row) => (
        <div>
          {row.service?.name && (
            <div>
              <div>{row.service?.name}</div>
            </div>
          )}
          {!row.service?.name && <div>-</div>}
        </div>
      ),
    },
    {
      name: "Status",
      selector: (row) => (
        <div>
          {row.status === AppointmentStatus.COMPLETED && (
            <div className="lg:flex lg:items-center">
              <CheckCircle className="w-h-8 h-8" />
              <div>Completed</div>
            </div>
          )}
          {row.status === AppointmentStatus.CANCEL && (
            <div className="text-burnt-sienna">Cancelled</div>
          )}
          {(row.status === AppointmentStatus.BOOKED ||
            row.status === AppointmentStatus.IN_PROGRESS) && (
            <div className="text-cod-gray/30">NA</div>
          )}
        </div>
      ),
    },
    {
      name: "",
      selector: (row) => row.action,
      cell: (row) => (
        <Button
          className="rounded-md bg-indigo/10 text-indigo w-28"
          type="button"
          onClick={() => navigate(`/appointments/${row?.id}`)}
        >
          View
        </Button>
      ),
      show: true,
    },
  ];

  const fetchAppointment = useCallback(() => {
    const params = {
      page: page,
      limit: perPage,
      sort: "updatedAt",
      order: "desc",
      "filter[clinic.id]": clinicId,
      "filter[status]": status,
      include:
        "clinic,service,clinicService,user,answeredByPatient,answeredByClinic,answeredByClinic.question,answeredByPatient.questions",
    };
    dispatch(retriveAppointments(params))
      .then((result) => {
        setIsLoaded(true);
        setAppointment(result?.data);
        setTotalRows(result?.meta?.total);
      })
      .catch((error) => {
        toast.error(error.response.data.errors[0].detail);
        setIsLoaded(true);
      })
      .finally(() => {
        setIsLoaded(false);
      });
  }, [dispatch, setAppointment, page, perPage, status, clinicId]);

  useEffect(() => {
    fetchAppointment();
  }, [fetchAppointment]);

  return (
    <div>
      <div className="flex items-center justify-between pb-6">
        <h5 className="text-xl font-semibold">Upcoming appointments</h5>
        <Link to="/appointments" className="text-lg text-indigo">
          View All
        </Link>
      </div>
      <div className="w-auto rounded-lg bg-white px-7 py-8 pt-6 pb-9">
        <div className="mb-2 grid grid-cols-2 gap-1 md:grid-cols-4 lg:flex">
          <Button
            className="rounded-md bg-indigo/10 text-indigo"
            width="26"
            type="button"
            onClick={() => setStatus(AppointmentStatus.IN_PROGRESS)}
          >
            In Progress
          </Button>
          <Button
            className="rounded-md bg-indigo/10 text-indigo"
            type="button"
            width="26"
            onClick={() => setStatus(AppointmentStatus.BOOKED)}
          >
            Booking
          </Button>
          <Button
            className="rounded-md bg-indigo/10 text-indigo"
            type="button"
            width="26"
            onClick={() => setStatus(AppointmentStatus.COMPLETED)}
          >
            Completed
          </Button>
          <Button
            className="rounded-md bg-indigo/10 text-indigo"
            type="button"
            width="26"
            onClick={() => setStatus(AppointmentStatus.CANCEL)}
          >
            Cancelled
          </Button>
        </div>
        <DataTable
          columns={columns}
          data={appointment}
          pagination
          paginationServer
          paginationTotalRows={totalRows}
          onChangePage={handlePageChange}
          onChangeRowsPerPage={handlePerRowsChange}
          progressPending={isLoaded}
          responsive={true}
          paginationPerPage={5}
          paginationRowsPerPageOptions={[5, 10, 25, 50, 100]}
          customStyles={customStyles}
        />
      </div>
    </div>
  );
};

export default UpComingAppoiment;

import React, { useState, useEffect, useCallback } from "react";
import { useForm } from "react-hook-form";
import { updateClinicData } from "features/clinic/clinicSlice";
import { Button } from "components/ui";
import ServiceTab from "pages/Clinic/serviceTab";
import { useDispatch, useSelector } from "react-redux";
import { retriveServices } from "actions/services";
import toast from "react-hot-toast";

const Step2ofClinic = (props) => {
  const { setSelectedIndex } = props;

  const step2Data = useSelector(
    (state) => state.persistedReducer.clinic.clinic.clinicServices
  );

  const getDefaultValuesForStep2 = () => {
    let obj = {};
    step2Data?.forEach((s) => {
      obj[`charge-${s.services.id}`] = s.charge;
    });
    return obj;
  };

  const {
    setValue,
    setError,
    register,
    handleSubmit,
    getValues,
    formState: { errors },
  } = useForm({
    defaultValues: getDefaultValuesForStep2(),
    reValidateMode: 'onSubmit',
    mode:'all'
  });

  const dispatch = useDispatch();

  const [services, setServices] = useState([]);
  const [page] = useState(1);
  const [loading, setLoading] = useState(false);
  const valuesForm = getValues();
  const fetchServices = useCallback(() => {
    setLoading(true);
    const params = {
      page: page,
      limit: 10,
      sort: "updatedAt",
      order: "desc",
      search: "",
      include: "category,questionForPatient,questionForClinic",
    };
    dispatch(retriveServices(params))
      .then((result) => {
        let na = result?.data?.map((na) => {
          na.disableSwitch = valuesForm[`charge-${na.id}`] ? true : false;
          na.charge = valuesForm[`charge-${na.id}`] || "0";
          return na;
        });
        setServices(na);
        setLoading(false);
      })
      .catch((error) => {
        toast.error(error.response?.data?.errors[0]?.detail);
        setLoading(false);
      })
      .finally(() => {});
  }, [dispatch, setServices, page]);

  useEffect(() => {
    fetchServices();
  }, [fetchServices]);

  const formvalues = getValues();
  let c = Object.values(formvalues).some((f) => f === "") ||
  Object.keys(formvalues).length <= 0;
  return (
    <div>
      <form
        onSubmit={handleSubmit(() => {
          if(c){
            toast.error('Please select atleast one service.')
          }else {
            const values = getValues();
            setSelectedIndex(2);
            let serviceData = {
              availabeEvery: 15,
              patientAtTime: 1,
              serviceOffDates: [],
            };
            let filteredServices = services
  
              .map((s, index) => {
                let a = {
                  services: {
                    id: s.id,
                  },
                  disableSwitch: s.disableSwitch,
                  charge: values[`charge-${s.id}`],
                  ...serviceData,
                };
                return a;
              })
              .filter((f) => f.disableSwitch === true);
            dispatch(
              updateClinicData({
                clinicServices: filteredServices.map((d) => {
                  delete d.disableSwitch;
                  return d;
                }),
              })
            );
          }
          
        })}
        className="mt-20 md:mt-0"
      >
        <ServiceTab
        setError={setError}
          setValue={setValue}
          loading={loading}
          services={services}
          setServices={setServices}
          register={register}
          errors={errors}
          values={valuesForm}
        />
        <div className="flex justify-end pt-6">
          <Button
            style={{ backgroundColor: "#e2e2e2", color: "#000000" }}
            className="rounded-l bg-alabaster py-2 px-4 font-normal text-black"
            text="lg"
            onClick={() => {
              setSelectedIndex(0);
            }}
          >
            Previous
          </Button>

          <Button
            // disabled={c}
            className="rounded-r bg-indigo py-2 px-4 font-normal text-white"
            type="submit"
            text="lg"
          >
            Next
          </Button>
        </div>
      </form>
    </div>
  );
};

export default Step2ofClinic;

import { retrieveOneClinicService, updateClinicService } from 'actions/clinicService';
import clsx from 'clsx';
import { Button, Day, TagsInput } from 'components/ui';
import _, { isEmpty } from 'lodash';
import React, { useCallback, useEffect, useState } from 'react'
import { useForm } from 'react-hook-form';
import toast from 'react-hot-toast';
import { useDispatch } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';

const ClinicServicesEdit = () => {

    const { serviceId } = useParams();
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const {
        register,
        handleSubmit,
        watch,
        setValue,
        formState: { errors },
    } = useForm();

    const [service, setServices] = useState({});
    const [serviceOpeningDays, setServiceOpeningDays] = useState([]);
    const [loading, setIsLoading] = useState(false);
    const [tags, setTags] = useState([]);

    const fetchClinicService = useCallback(() => {
        const params = {
            include: "clinic,clinic.openingDays,openingDays.clinicHours,services,serviceOpeningDays,serviceOpeningDays.serviceHours",
        }
        dispatch(retrieveOneClinicService(serviceId, params))
            .then((res) => {
                setServices(res);
                const serviceOpeningDays = _.orderBy(res?.serviceOpeningDays, ['weekDay'], ['asc']);
                setServiceOpeningDays(serviceOpeningDays);
                if (!isEmpty(res?.serviceOffDates)) {
                    setTags(res?.serviceOffDates);
                }
            })
            .catch((err) => console.log(err));
    }, [
        dispatch,
        serviceId,
    ]);


    const onAddTag = tag => {
        setTags([...tags, tag[0]]);
    };

    const onDeleteTag = tag => {
        let remainingTags = tags.filter(t => {
            return t !== tag;
        });
        setTags([...remainingTags]);
    };

    const onSubmit = (data) => {
        setIsLoading(true);
        data.serviceOffDates = tags;
        data.isActive = service.isActive;
        data.services = {
            id: service.services.id
        };
        if (!isEmpty(data?.serviceOpeningDays)) {
            for (const serviceDay of data?.serviceOpeningDays) {
                if (isEmpty(serviceDay?.serviceHours)) {
                    const serviceHours = service?.clinic?.openingDays?.filter(day => day?.weekDay === serviceDay?.weekDay);
                    serviceDay.serviceHours = [
                        {
                            to: serviceHours[0]?.clinicHours[0]?.to,
                            from: serviceHours[0]?.clinicHours[0]?.from
                        }
                    ]
                }
            }
        }
        dispatch(updateClinicService(serviceId, data)).then((result) => {
            toast.success("Service Update Successfully");
            navigate("/clinic-services");
        })
            .catch((error) => {
                console.log(error);
                toast.error(error.response.data.errors[0].detail);
                setIsLoading(false);
            })
    }

    useEffect(() => {
        if (serviceId !== undefined) {
            fetchClinicService();
        }
    }, [
        fetchClinicService,
        serviceId,
    ]);

    useEffect(() => {
        setValue("charge", service?.charge);
        setValue("availabeEvery", service?.availabeEvery);
        setValue("patientAtTime", service?.patientAtTime);
        setValue("serviceOffDates", service?.serviceOffDates);
        const serviceOpeningDays = _.orderBy(service?.serviceOpeningDays, ['weekDay'], ['asc']);
        setValue("serviceOpeningDays", serviceOpeningDays);
    }, [
        setValue,
        service?.charge,
        service?.availabeEvery,
        service?.patientAtTime,
        service?.serviceOffDates,
        service?.serviceOpeningDays,
    ]);

    return (
        <section className="min-h-screen ml-6 mr-7 py-6 justify-center items-center">
            <div>
                <div className='mb-4 flex space-x-2 items-center'>
                    <img
                        src={service?.services?.accessUrl}
                        alt="service-logo"
                        className="w-auto h-12"
                    />
                    <div className='text-xl font-semibold'>{service?.services?.name}</div>
                </div>
                <div>
                    <form
                        onSubmit={handleSubmit(onSubmit)}
                    >
                        <div className='px-4 py-4 mb-6 rounded-lg bg-white w-auto'>
                            <div className='grid grid-cols-1 gap-8 sm:grid-cols-3 px-8 py-8'>
                                <div>
                                    <div className="mb-1 text-sm font-medium">Available on</div>
                                    <div className="lg:flex lg:space-x-1">
                                        {serviceOpeningDays?.map((day, i) => {
                                            return (
                                                <div key={i} className="space-y-1">
                                                    <input
                                                        type="text"
                                                        hidden
                                                        className="bg-alabaster w-auto border-none rounded-md"
                                                        {...register(`serviceOpeningDays.${i}.id`, { required: false })}
                                                    />
                                                    <input
                                                        id={`${day?.id}`}
                                                        type="checkbox"
                                                        name={`serviceOpeningDays.${i}.isActive`}
                                                        className="hidden"
                                                        {...register(`serviceOpeningDays.${i}.isActive`, { required: false })}
                                                    />
                                                    <Day
                                                        htmlFor={`${day?.id}`}
                                                        name={`serviceOpeningDays.${i}.isActive`}
                                                        dayCode={day?.weekDay}
                                                        stringLimit={2}
                                                        className={clsx(
                                                            watch('serviceOpeningDays')[i].isActive
                                                                ? "bg-indigo text-white"
                                                                : "bg-[#F7F7F7] text-dove-gray",
                                                            "w-20 h-20 rounded-full flex justify-center items-center")}
                                                    />
                                                    <div className="flex space-x-2">
                                                        <input
                                                            type="time"
                                                            className="hidden"
                                                            {...register(`serviceOpeningDays.${i}.serviceHours.${0}.from`, { required: false })}
                                                        />
                                                        <input
                                                            type="time"
                                                            className="hidden"
                                                            {...register(`serviceOpeningDays.${i}.serviceHours.${0}.to`, { required: false })}
                                                        />
                                                    </div>
                                                </div>
                                            )
                                        })}
                                    </div>
                                </div>
                            </div>
                            <div className='grid grid-cols-1 gap-8 sm:grid-cols-3 px-8 py-8'>
                                <div>
                                    <div className="mb-1 text-sm font-medium">Available every</div>
                                    <select
                                        className="w-full rounded-md border-none bg-alabaster focus:border-none focus:ring-0"
                                        {...register(`availabeEvery`, { required: true })}
                                    >
                                        <option value={service?.availabeEvery ? service?.availabeEvery : ""}>{service?.availabeEvery ? `${service?.availabeEvery} min` : "Choose an option"}</option>
                                        <option value="5">5 min</option>
                                        <option value="10">10 min</option>
                                        <option value="15">15 min</option>
                                        <option value="20">20 min</option>
                                        <option value="30">30 min</option>
                                        <option value="60">60 min</option>
                                        <option value="90">90 min</option>
                                        <option value="120">120 min</option>
                                    </select>
                                    {errors?.availabeEvery?.type === "required" && (
                                        <div className="mt-1 text-sm text-burnt-sienna">
                                            Available every is required.
                                        </div>
                                    )}
                                </div>
                                <div>
                                    <div className="mb-1 text-sm font-medium">How many patients at a time?  </div>
                                    <input
                                        type="number"
                                        min="1"
                                        className="bg-alabaster w-full border-none rounded-md"
                                        {...register("patientAtTime", { required: true })}
                                    />
                                    {errors?.patientAtTime?.type === "required" && (
                                        <div className="mt-1 text-sm text-burnt-sienna">
                                            How many patients at a time is required.
                                        </div>
                                    )}
                                </div>
                                <div>
                                    <div className="mb-1 text-sm font-medium">Fee</div>
                                    <input
                                        type="number"
                                        min={0}
                                        className="bg-alabaster w-full border-none rounded-md"
                                        {...register("charge", { required: true })}
                                    />
                                    {errors?.charge?.type === "required" && (
                                        <div className="mt-1 text-sm text-burnt-sienna">
                                            Charge of Service is required.
                                        </div>
                                    )}
                                </div>
                            </div>
                        </div>

                        <div className='px-8 py-8 rounded-lg bg-white w-auto'>
                            <div>
                                <div className="mb-1 text-sm font-medium">Select dates when this service won’t be available</div>
                                <TagsInput
                                    placeHolder={"enter dates seperated by comma e.g 2023-01-01,"}
                                    inputType="text"
                                    className="w-full border-none"
                                    onAddTag={onAddTag}
                                    onDeleteTag={onDeleteTag}
                                    defaultTags={tags}
                                />
                            </div>
                        </div>

                        <div className="pt-6">
                            <Button
                                type="submit"
                                className="bg-indigo text-white h-12 rounded-lg"
                                text="lg"
                                loading={loading}
                            >
                                Save
                            </Button>
                        </div>
                    </form>
                </div>
            </div >
        </section >
    )
}

export default ClinicServicesEdit
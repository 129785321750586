import "./switch.css";
const Switch = (props) => {
  const {isOn, onToggle} = props
  const activetoggleClass = " transform translate-x-6";
  const inactivetoggleClass = " transform translate-x-1";
  const disableClass = isOn ? "":"disable";
  return (
    <>
      <div
          className={`switch-outer flex lg:h-7 lg:w-13 h-6 w-12 cursor-pointer items-center rounded-full ${disableClass}`}
          onClick={() => {
            onToggle();
          }}
        >
          {/* Switch */}
          <div
            className={
              `switch-inner h-5 w-5 transform rounded-full shadow-md duration-300 ease-in-out ${disableClass}` +
              (isOn ? activetoggleClass:inactivetoggleClass )
            }
          ></div>
        </div>
      
    </>
  );
}

export default Switch;

import { retrieveOneClinic } from 'actions/clinics';
import { createClinicService } from 'actions/clinicService';
import { retriveAllServices } from 'actions/services';
import clsx from 'clsx';
import { Button } from 'components/ui';
import Toggle from 'components/ui/Toggle'
import React, { useCallback, useEffect, useState } from 'react'
import { Controller, useForm } from 'react-hook-form';
import { toast } from 'react-hot-toast';
import { AiOutlineSearch } from 'react-icons/ai';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';

const CreateNewClinicService = () => {

    const dispatch = useDispatch();
    const navigate = useNavigate();

    const {
        register,
        handleSubmit,
        control,
        watch,
        formState: { errors },
    } = useForm();

    const [services, setServices] = useState([]);
    const [isLoaded, setIsLoaded] = useState(false);
    const [search, setSearch] = useState("");

    const auth = JSON.parse(localStorage.getItem("clinic-u"));
    const clinicId = auth?.clinic[0]?.id;

    const fetchServices = useCallback(() => {
        setIsLoaded(true);
        const params = {
            sort: "updatedAt",
            order: "desc",
            search: search,
            include: "category,clinicService,clinicService.clinic,questionForPatient,questionForClinic",
            aggregate: 'isServiceProvided',
            clinicId: clinicId
        };
        dispatch(retriveAllServices(params))
            .then((result) => {
                setIsLoaded(false);
                setServices(result?.data)
            })
            .catch((error) => {
                toast.error(error.response.data.errors[0].detail);
                setIsLoaded(false);
            })
            .finally(() => {
                setIsLoaded(false);
            });
    }, [dispatch, setServices, search, clinicId]);

    const onSubmit = (data) => {
        setIsLoaded(true)
        const params = {
            include: "openingDays,openingDays.clinicHours",
        }
        dispatch(retrieveOneClinic(clinicId, params))
            .then((res) => {
                const serviceOpeningDays = res?.openingDays.map(({ clinicHours, ...rest }) => ({ ...rest, serviceHours: clinicHours }));
                const body = {
                    clinic: {
                        id: clinicId
                    },
                    serviceOpeningDays: serviceOpeningDays,
                    services: data?.services?.filter((data) => data?.isActive)
                }

                dispatch(createClinicService(body)).then((result) => {
                    toast.success("New Service Added Successfully");
                    navigate("/clinic-services");
                })
                    .catch((error) => {
                        console.log(error);
                        toast.error(error.response.data.errors[0].detail);
                        setIsLoaded(false);
                    })
            })
            .catch((err) => console.log(err));
    }

    useEffect(() => {
        fetchServices();
    }, [fetchServices]);

    return (
        <section className="min-h-screen ml-6 mr-7 py-6 justify-center items-center">
            <div className='text-xl font-semibold mb-4'>
                Add New Service at Clinic
            </div>
            <div>
                <div className="w-full rounded-lg bg-white py-7 px-8">
                    <div className="relative h-10 w-full xl:w-auto mb-4">
                        <AiOutlineSearch className="text-storm-gray absolute inset-y-0 left-3 my-auto" />
                        <input
                            type="text"
                            className="w-full rounded-md border-none bg-alabaster pl-9 font-medium focus:ring-0 sm:w-[210px] xl:w-[320px]"
                            placeholder="Search"
                            onChange={(e) => {
                                setSearch(e.target.value);
                            }}
                        />
                    </div>
                    <form onSubmit={handleSubmit(onSubmit)} className="lg:grid lg:grid-cols-2">
                        {services?.map((data, i) => {
                            return (
                                <div key={i} className="mb-4">
                                    <div className="grid grid-cols-3 gap-2">
                                        <div className="lg:flex lg:justify-start lg:items-center">
                                            <img src={data?.accessUrl}
                                                alt="service icon"
                                                className="inline object-cover w-12 h-12 mr-2 rounded-full" />
                                            <div className="mb-2">{data?.name}</div>
                                        </div>
                                        <div className="mb-2">
                                            <Controller
                                                control={control}
                                                name={`services.${i}.isActive`}
                                                render={({ field: { ref, ...field } }) =>
                                                    <Toggle
                                                        inputRef={ref}
                                                        switchStyles={clsx(data?.isServiceProvided ? "cursor-not-allowed" : "cursor-pointer")}
                                                        disabled={data?.isServiceProvided ? true : false}
                                                        {...field}
                                                    />
                                                }
                                            />
                                        </div>
                                        <div className="">
                                            {watch(`services.${i}.isActive`) && (
                                                <div className="mb-2 lg:flex lg:flex-col">
                                                    <div>
                                                        <input
                                                            hidden
                                                            type="text"
                                                            value={data?.id}
                                                            className="w-20 border-alto rounded-lg"
                                                            {...register(`services.${i}.services.id`, { required: false })}
                                                        />
                                                        <input
                                                            type="number"
                                                            min={0}
                                                            className="w-20 border-alto rounded-lg"
                                                            {...register(`services.${i}.charge`, { required: true })}
                                                        />
                                                        {errors[i]?.charge?.type === "required" && (
                                                            <div className="mt-1 text-sm text-burnt-sienna">
                                                                Cost is required.
                                                            </div>
                                                        )}
                                                    </div>
                                                    <div>Note: If You want to Make it Free make price 0</div>
                                                </div>
                                            )}
                                        </div>
                                    </div>
                                </div>
                            )
                        })}
                        <div className="pt-6">
                            <Button
                                type="submit"
                                className="bg-indigo text-white h-12 rounded-lg"
                                text="lg"
                                loading={isLoaded}
                            >
                                Save
                            </Button>
                        </div>
                    </form>
                </div>
            </div>
        </section>
    )
}

export default CreateNewClinicService
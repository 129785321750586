import { updateClinicData } from "features/clinic/clinicSlice";
import React from "react";
import toast from "react-hot-toast";
import { useDispatch } from "react-redux";
import { CloseCircle } from "components/icons";

const PhotosTab = (props) => {
  const { files, setFiles } = props;
  const dispatch = useDispatch();
  const handleFile = (e) => {
    let file = e.target.files;
    let newfiles = [];
    for (let i = 0; i < file.length; i++) {
      if (files.length >= 10) {
        toast.error("You have exceeded maximum photos upload limit of 10!");
        return;
      } else {
        newfiles.push(file[i]);
        setFiles([...files, ...newfiles]);
        dispatch(updateClinicData({ media: [...files, ...newfiles] }));
      }
    }
  };

  const removeImage = (i) => {
    let b = files?.filter((x) => x.name !== i);
    setFiles(b);
  };
  return (
    <>
      <div className="mt-24 grid h-fit grid-cols-1 items-center justify-center px-3 md:mt-0 md:flex">
        <div>
          <div>
            <div className="grid grid-cols-1 lg:grid-cols-3">
              <div className="mt-3 flex h-[160px] w-[300px] items-center justify-center rounded-md bg-alabaster md:m-3 md:mt-0">
                <label
                  htmlFor="add-img"
                  className="material-icons-round cursor-pointer text-6xl text-indigo"
                >
                  add
                </label>
                <input
                  id="add-img"
                  type="file"
                  accept="image/*"
                  onChange={handleFile}
                  className="sr-only"
                  multiple
                />
              </div>
              {files.length > 0 &&
                files?.map((file, key) => {
                  return (
                    <div key={key}>
                      <div className="relative">
                        <CloseCircle
                          className="w-h-7 absolute -right-2 -top-2 h-9"
                          onClick={() => removeImage(file.name)}
                        />
                        <img
                          alt=""
                          className="mt-3 flex h-[160px] w-[300px] items-center justify-center rounded-md md:m-3 md:mt-0"
                          src={URL.createObjectURL(file)}
                        />
                      </div>
                    </div>
                  );
                })}
              {/* <div className="lg:grid lg:grid-cols-6 lg:gap-1">
                {files.length > 0 &&
                  files?.map((file, key) => (
                    <div className="w-full" key={key}>
                      <div className="relative w-full p-1 md:p-2">
                        <CloseCircle
                          className="w-h-7 absolute -right-2 -top-2 h-7"
                          onClick={() => removeImage(file.name)}
                        />
                        <img
                          src={URL.createObjectURL(file)}
                          alt="seleted-file"
                          className="block rounded-lg object-cover object-center"
                        />
                      </div>
                    </div>
                  ))}
              </div> */}
            </div>
            {files.length <= 0 && (
              <div className="m-3 text-sm text-burnt-sienna">
                Please upload one photo of the Clinic.
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default PhotosTab;

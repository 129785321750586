import { clinicSelectPatient } from "actions/clinicSelectPatient";
import { createPatient } from "actions/patient";
import { retriveUsers } from "actions/user";
import { Button } from "components/ui";
import { isEmpty } from "lodash";
import moment from "moment";
import React, { useCallback, useEffect, useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import toast from "react-hot-toast";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import Search from "components/ui/Search";

const CreatePatient = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const auth = JSON.parse(localStorage.getItem("clinic-u"));
  const clinicId = auth?.clinic[0]?.id;

  const [loading, setLoading] = useState(false);
  const [loadingExist, setLoadingExist] = useState(false);
  const [selectedPatient, setSelectedPatient] = useState();
  const [patient, setPatient] = useState([]);
  const [search, setSearch] = useState("");
  const [showError, setShowError] = useState(false);

  const methods = useForm();
  const {
    register,
    handleSubmit,
    control,
    setValue,
    formState: { errors },
  } = methods

  const fetchPatient = useCallback(() => {
    const params = {
      page: 1,
      limit: 50,
      sort: "updatedAt",
      order: "desc",
      search: search,
      "filter[role.name]": "patient",
      include: "role",
    };
    dispatch(retriveUsers(params))
      .then((result) => {
        setLoading(true);
        setPatient(result?.data);
      })
      .catch((error) => {
        toast.error(error.response.data.errors[0].detail);
        setLoading(true);
      })
      .finally(() => {
        setLoading(false);
      });
  }, [dispatch, setPatient, search]);

  const selectOptionsName = (option) => {
    const mappedOptions = option.map((option) => {
      return {
        value: option.id,
        label: `${option?.firstName ? option?.firstName : ""} ${option?.lastName ? option?.lastName : ""
          }`,
        detail: option,
      };
    });
    return mappedOptions;
  };

  const selectOptionsEmail = (option) => {
    const mappedOptions = option.map((option) => {
      return { value: option.id, label: option.emailAddress, detail: option };
    });
    return mappedOptions;
  };

  const handleInputChange = (inputValue) => {
    setSearch(inputValue);
  };

  const onSubmitExistPatient = () => {
    setShowError(false);
    if (!isEmpty(selectedPatient)) {
      setLoadingExist(true);
      const body = {
        patient: {
          id: selectedPatient?.id,
        },
        clinic: {
          id: clinicId,
        },
      };
      dispatch(clinicSelectPatient(body))
        .then((response) => {
          setLoadingExist(false);
          navigate("/patients");
          toast.success("Patient Added successfully!");
        })
        .catch((error) => {
          setLoadingExist(false);
          toast.error(error.response?.data?.errors[0]?.detail);
        });
    } else {
      setShowError(true);
    }
  };

  const onSubmit = (data) => {
    setLoading(true);
    if (data?.name) {
      const name = data?.name.split(" ");
      data.firstName = name[0];
      data.lastName = name[1];
    }
    data.role = "patient";
    data.clinic = {
      id: clinicId,
    };
    data.isClinicAdd = true;
    dispatch(createPatient(data))
      .then((response) => {
        setLoading(false);
        navigate("/patients");
        toast.success("Patient Added successfully!");
      })
      .catch((error) => {
        setLoading(false);
        toast.error(error.response?.data?.errors[0]?.detail);
      });
  };

  useEffect(() => {
    fetchPatient();
  }, [fetchPatient]);

  return (
    <section className="ml-6 mr-7 min-h-screen items-center justify-center py-6">
      <div>
        <div className="mb-4 flex items-center space-x-2">
          <div className="text-xl font-semibold">Add a new patient</div>
        </div>
        <div>
          {/* {useRole([ROLE.CLINIC]) && (
            <div>
              <div className="mb-6 w-auto rounded-lg bg-white px-4 py-4 lg:px-7 lg:py-7">
                <div>Search Patient Exist</div>
                <div className="lg:grid lg:grid-cols-3">
                  <div className="mb-2 pr-0 lg:pr-16">
                    <div className="mb-1 text-sm font-medium">
                      Patient’s name
                    </div>
                    {selectedPatient && (
                      <div className="w-full rounded-md border-none bg-alabaster px-2 py-2">
                        {selectedPatient?.firstName} {selectedPatient?.lastName}
                      </div>
                    )}
                    {!selectedPatient && (
                      <Select
                        options={selectOptionsName(patient)}
                        className="w-full rounded-md border-none bg-alabaster"
                        onInputChange={handleInputChange}
                        onChange={(e) => setSelectedPatient(e.detail)}
                      />
                    )}
                  </div>
                  <div className="mb-2 pr-0 lg:pr-16">
                    <div className="mb-1 text-sm font-medium">Email</div>
                    {selectedPatient && (
                      <div className="w-full rounded-md border-none bg-alabaster px-2 py-2">
                        {selectedPatient?.emailAddress}
                      </div>
                    )}
                    {!selectedPatient && (
                      <Select
                        options={selectOptionsEmail(patient)}
                        className="w-full rounded-md border-none bg-alabaster"
                        onInputChange={handleInputChange}
                        onChange={(e) => setSelectedPatient(e.detail)}
                      />
                    )}
                  </div>
                  <div className="mb-2 pr-0 lg:pr-16">
                    <div className="mb-1 text-sm font-medium">Phone number</div>
                    {!selectedPatient && (
                      <input
                        placeholder="1234567890"
                        name="phone"
                        className="w-full rounded-md border-none bg-alabaster px-2 py-2"
                      />
                    )}
                    {selectedPatient && (
                      <div className="w-full rounded-md border-none bg-alabaster px-2 py-2">
                        {selectedPatient?.phone}
                      </div>
                    )}
                  </div>
                </div>
                <div className="col-span-1">
                  <div className="mb-1 text-sm font-medium">Address</div>
                  {!selectedPatient && (
                    <input
                      placeholder="address"
                      name="address"
                      className="w-1/3 rounded-md border-none bg-alabaster px-2 py-2"
                    />
                  )}
                  {selectedPatient && (
                    <div className="w-1/3 rounded-md border-none bg-alabaster px-2 py-2">
                      {selectedPatient?.address ? selectedPatient?.address : "NA"}
                    </div>
                  )}
                </div>
                {showError && (
                  <div className="text-burnt-sienna">
                    {" "}
                    Please Select Patient First
                  </div>
                )}
              </div>
              <div className="mb-4">
                <Button
                  type="button"
                  className="h-12 rounded-lg bg-indigo text-white"
                  width="40"
                  text="lg"
                  loading={loadingExist}
                  onClick={() => onSubmitExistPatient()}
                >
                  Add To Clinic
                </Button>
              </div>
            </div>
          )} */}
          <FormProvider {...methods} >
            <form onSubmit={handleSubmit(onSubmit)}>
              <div className="mb-6 w-auto rounded-lg bg-white px-4 py-4 lg:px-7 lg:py-7">
                <div className="lg:grid lg:grid-cols-3">
                  <div className="mb-2 pr-0 lg:pr-16">
                    <div className="mb-1 text-sm font-medium">Patient’s name</div>
                    <input
                      type="text"
                      className="w-full rounded-md border-none bg-alabaster"
                      {...register("name", { required: true })}
                    />
                    {errors?.name?.type === "required" && (
                      <div className="mt-1 text-sm text-burnt-sienna">
                        Patient’s name is required.
                      </div>
                    )}
                  </div>
                  <div className="mb-2 pr-0 lg:pr-16">
                    <div className="mb-1 text-sm font-medium">Email</div>
                    <input
                      type="email"
                      className="w-full rounded-md border-none bg-alabaster py-2 px-2"
                      {...register("emailAddress", {
                        required: true,
                        pattern: /^\S+@\S+$/i,
                      })}
                    />
                    {errors?.emailAddress?.type === "required" && (
                      <div className="mt-1 text-sm text-burnt-sienna">
                        Email is required.
                      </div>
                    )}
                    {errors.emailAddress?.type === "pattern" && (
                      <div className="mt-1 text-sm text-burnt-sienna">
                        Please match the requested format.
                      </div>
                    )}
                  </div>
                  <div className="mb-2 pr-0 lg:pr-16">
                    <div className="mb-1 text-sm font-medium">Phone Number</div>
                    <input
                      type="tel"
                      className="w-full rounded-md border-none bg-alabaster"
                      {...register("phone", {
                        required: true,
                        minLength: 10,
                        maxLength: 11,
                        pattern: {
                          value: /^[0-9]+$/,
                          message: "Please enter a number.",
                        },
                      })}
                    />
                    {errors?.phone?.type === "required" && (
                      <div className="mt-1 text-sm text-burnt-sienna">
                        Phone is required.
                      </div>
                    )}
                    {(errors?.phone?.type === "minLength" ||
                      errors?.phone?.type === "maxLength") && (
                        <div className="mt-1 text-sm text-burnt-sienna">
                          Phone number must be valid and contain 10 to 11 digits.
                        </div>
                      )}
                    {errors?.phone?.type === "pattern" && (
                      <div className="mt-1 text-sm text-burnt-sienna">
                        {errors?.phone?.message}
                      </div>
                    )}
                  </div>
                  <div className="mb-2 pr-0 lg:pr-16">
                    <div className="mb-1 text-sm font-medium">Gender</div>
                    <select
                      className="w-full rounded-md border-none bg-alabaster py-2 px-2"
                      {...register("gender", { required: true })}
                    >
                      <option value="">Choose an option</option>
                      <option value="male">Male</option>
                      <option value="female">Female</option>
                    </select>
                    {errors?.gender?.type === "required" && (
                      <div className="mt-1 text-sm text-burnt-sienna">
                        Gender is required.
                      </div>
                    )}
                  </div>
                  <div className="mb-2 pr-0 lg:pr-16">
                    <div className="mb-1 text-sm font-medium">DOB</div>
                    <input
                      type="date"
                      max={moment().format("YYYY-MM-DD")}
                      className="w-full rounded-md border-none bg-alabaster py-2 px-2"
                      {...register("dob", { required: false })}
                    />
                    {errors?.dob?.type === "max" && (
                      <div className="mt-1 text-sm text-burnt-sienna">
                        You can Not Select Future Date.
                      </div>
                    )}
                  </div>
                  <div className="mb-2 pr-0 lg:pr-16">
                    <div className="mb-1 text-sm font-medium">NHS</div>
                    <input
                      type="text"
                      className="w-full rounded-md border-none bg-alabaster py-2 px-2"
                      {...register("NHS", { required: true })}
                    />
                    {errors?.NHS?.type === "required" && (
                      <div className="mt-1 text-sm text-burnt-sienna">
                        NHS Number is required.
                      </div>
                    )}
                  </div>
                </div>
                <div className="col-span-1">
                  <div className="mb-1 text-sm font-medium">Address</div>
                  <Search />
                  {/* <Controller
                  control={control}
                  name={"address"}
                  render={({ field: { ref } }) =>
                    <Autocomplete
                      className="w-full rounded-md border-none bg-alabaster py-3 px-2 lg:w-1/3"
                      apiKey={"AIzaSyAHEwv3vrO1yqBCKEuJI-S23NUSyOQVvKY"}
                      onPlaceSelected={(place) => {
                        setValue("address", place.formatted_address);
                      }}
                    />
                  }
                /> */}
                  {/* <input
                  type="address"
                  className="w-full rounded-md border-none bg-alabaster py-3 px-2 lg:w-1/3"
                  {...register("address", { required: false })}
                /> */}
                </div>
              </div>
              <div>
                <Button
                  type="submit"
                  className="h-12 rounded-lg bg-indigo text-white"
                  text="lg"
                  loading={loading}
                >
                  Save
                </Button>
              </div>
            </form>
          </FormProvider>
        </div>
      </div>
    </section>
  );
};

export default CreatePatient;

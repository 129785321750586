import AppointmentService from "services/appointment";
import {
    CREATE_APPOINTMENT,
    GET_ALL_APPOINTMENT,
    GET_ONE_APPOINTMENT,
    CANCEL_APPOINTMENT,
    UPDATE_APPOINTMENT,
    GENERATE_APPOINTMENT_SLOTS
} from "./type";

export const generateAppointmentSlots = (data) => async (dispatch) => {
    try {
        const res = await AppointmentService.generateSlots(data);

        dispatch({
            type: GENERATE_APPOINTMENT_SLOTS,
            payload: res.data,
        });

        return Promise.resolve(res.data);
    } catch (error) {
        return Promise.reject(error);
    }
};

export const createAppointment = (data) => async (dispatch) => {
    try {
        const res = await AppointmentService.create(data);

        dispatch({
            type: CREATE_APPOINTMENT,
            payload: res.data,
        });

        return Promise.resolve(res.data);
    } catch (error) {
        return Promise.reject(error);
    }
};

export const updateAppointment = (id, data) => async (dispatch) => {
    try {
        const res = await AppointmentService.update(id, data);

        dispatch({
            type: UPDATE_APPOINTMENT,
            payload: res.data,
        });

        return Promise.resolve(res.data);
    } catch (error) {
        return Promise.reject(error);
    }
};

export const cancelAppointment = (id,data) => async (dispatch) => {
    try {
        const res = await AppointmentService.cancel(id,data);

        dispatch({
            type: CANCEL_APPOINTMENT,
            payload: res.data,
        });

        return Promise.resolve(res.data);
    } catch (error) {
        return Promise.reject(error);
    }
};

export const retrieveOneAppointment = (id, params) => async (dispatch) => {
    try {
        const res = await AppointmentService.getOne(id, params);

        dispatch({
            type: GET_ONE_APPOINTMENT,
            payload: res.data,
        });

        return Promise.resolve(res.data);
    } catch (error) {
        return Promise.reject(error);
    }
};

export const retriveAppointments = (data) => async (dispatch) => {
    try {
        const res = await AppointmentService.getAll(data);

        dispatch({
            type: GET_ALL_APPOINTMENT,
            payload: res.data,
        });

        return Promise.resolve(res.data);
    } catch (error) {
        return Promise.reject(error);
    }
};
import { Button, Modal } from 'components/ui';
import _, { isEmpty } from 'lodash';
import React, { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form';
import { toast } from 'react-hot-toast';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { updateNMS } from "actions/nms";

const FirstIntervetion = ({ isOpen, details, closeModal }) => {

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [nms1STAnswer, setNMS1STAnswer] = useState([])
  const [loading, setLoading] = useState(false)

  const {
    register,
    handleSubmit,
    setValue,
  } = useForm();

  const onSubmit = async (data) => {
    const body = {
      ...details,
      isFirstInterventionAnswered: true,
      "nms1STAnswer" : data.nms1STAnswer
    }
    console.log(body);
    setLoading(true)
    await dispatch(updateNMS(details?.id, body)).then((result) => {
      toast.success("NMS Update Successfully");
      navigate("/nms")
    })
      .catch((error) => {
        setLoading(false)
        toast.error(error.response.data.errors[0].detail);
      })
  }

  useEffect(() => {
    setNMS1STAnswer(details?.nms1STAnswer);
  }, [details?.nms1STAnswer])

  useEffect(() => {
    if(!isEmpty(details?.nms1STAnswer)){
      for (const [i, nms1St] of details?.nms1STAnswer.entries()) {
        setValue(`nms1STAnswer.${i}.nms1st.id`,nms1St?.nms1st?.id)
        setValue(`nms1STAnswer.${i}.id`,nms1St?.id)
        setValue(`nms1STAnswer.${i}.answerText`,nms1St?.answerText)
      }
    }
  },[setValue, details?.nms1STAnswer])

  return (
    <Modal
      title=""
      maxWidth="87rem"
      show={isOpen}
      onClose={closeModal}
      showCancel={true}
      onCancel={closeModal}
    >
      <div className="flex items-start justify-start space-x-3">
        <div className="px-4 py-4 rounded-lg w-full">
          <form
            onSubmit={handleSubmit(onSubmit)}
          >
            {_.sortBy(nms1STAnswer, "nms1st.position")?.map((data, i) => {
              return (
                <div key={i}>
                  <h1 className="font-semibold mb-1">{i + 1}. {data?.nms1st?.questionText}</h1>
                  <input
                    tye="text"
                    value={data?.nms1st?.id}
                    hidden
                    {...register(`nms1STAnswer.${i}.nms1st.id`, { required: false })}
                  />
                  <input
                    tye="text"
                    value={data?.id}
                    hidden
                    {...register(`nms1STAnswer.${i}.id`, { required: false })}
                  />
                  <textarea
                    placeholder={data?.nms1st?.placeholder}
                    rows={2}
                    className="bg-alabaster w-full border-none rounded-md mb-2"
                    {...register(`nms1STAnswer.${i}.answerText`, { required: false })}
                  />
                </div>
              )
            }
            )}
            <div className="pt-4 flex space-x-2">
              <div className="pt-4">
                <Button
                  type="submit"
                  className="h-12 rounded-lg bg-indigo px-7 text-white"
                  loading={loading}
                >
                  Save
                </Button>
              </div>
              <div className="pt-4">
                <Button
                  type="button"
                  className="h-12 rounded-lg"
                  onClick={closeModal}
                  color="indigo"
                  outlined
                >
                  Cancel
                </Button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </Modal>
  )
}

export default FirstIntervetion
import { AppContext } from 'context/AppContext';
import React, { useContext } from 'react'
import { ROLE } from 'utils/roles';
import ClinicSetting from './clinicSetting';
import ProfileSetting from './profileSetting';

function RenderDashboard() {
    const { role } = useContext(AppContext);

    switch (role) {
        case ROLE.ADMIN:
            return <ProfileSetting />;
        case ROLE.CLINIC:
            return <ClinicSetting />;
        default:
            return <ProfileSetting />;
    }
}

const Setting = () => {
    return <RenderDashboard />;
}

export default Setting
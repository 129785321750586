import React from "react";

import { useParams } from "react-router-dom";

function EditAppointment() {
  const { appointmentId } = useParams();

  return <div>Edit Appointment {appointmentId}</div>;
}

export default EditAppointment;

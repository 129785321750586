import authHeader from "auth-header";
import httpCommon from "http-common";

const getOne = (id, params) => {
    const config = {
        headers: authHeader(),
        params: params,
    };
    return httpCommon.get(`/clinic-service/${id}`, config);
};

const create = (data) => {
    return httpCommon.post(`/clinic-service`, data, { headers: authHeader() });
};

const update = (id, data) => {
    return httpCommon.patch(`/clinic-service/${id}`, data, { headers: authHeader() });
};

const serviceUpdate = (id, data) => {
    return httpCommon.patch(`/clinic-service/active/${id}`, data, { headers: authHeader() });
};

const ClinicService = {
    update,
    getOne,
    serviceUpdate,
    create,
};

export default ClinicService;
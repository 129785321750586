import clsx from "clsx";
import React from "react";
import { useEffect } from "react";

const Avatar = (props) => {
    const { firstName, lastName, image } = props;
    const getInitials = (firstName, lastName) => {
        if (firstName !== undefined || lastName !== undefined) {
            const first = firstName !== null ? firstName.toUpperCase() : " ";
            const last = lastName !== null ? lastName.toUpperCase() : " ";
            return `${first[0]}${last[0]}`;
        }
    }

    const randomColor = () => {
        let hex = Math.floor(Math.random() * 0xFFFFFF);
        let color = "#" + hex.toString(16);
        return color;
    }

    useEffect(() => {
        randomColor()
    }, [])


    return (
        <div>
            {
                image ?
                    <img src={image} alt="profile" className="mr-2 inline h-12 w-12 rounded-full object-cover" />
                    :
                    <div className={clsx(`inline-flex overflow-hidden relative justify-center items-center w-10 h-10 bg-indigo/10 rounded-full`)}>
                        <span className="font-medium text-indigo">{getInitials(
                            firstName,
                            lastName
                        )}</span>
                    </div>
            }
        </div>
    );
};

export default Avatar;

import authHeader from "auth-header";
import httpCommon from "http-common";

const create = (data) => {
    return httpCommon.post("/patient", data, { headers: authHeader() });
};

const PatientService = {
    create,
};

export default PatientService;
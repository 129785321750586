import { Avatar, Button, Modal } from "components/ui";
import React, { useCallback, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useParams, useNavigate } from "react-router-dom";
import { retrieveOneNMS, deleteNMS } from "actions/nms";
import { format } from "date-fns";
import toast from "react-hot-toast";
import _ from "lodash";
import FirstIntervetion from "./EditAnswers/FirstIntervetion";
import { CheckCircle } from "components/icons";
import SecondFollowup from "./EditAnswers/SecondFollowup";
import moment from "moment";

const ViewNMS = () => {

  const { nmsId } = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [nms, setNms] = useState({});
  const [loading, setLoading] = useState(false);
  const [isDeleteModal, setIsDeleteModal] = useState(false);
  const [isFirstIntervetionModal, setIsFirstIntervetionModel] = useState(false);
  const [isSecondFollowUpModal, setIsSecondFollowUpModel] = useState(false);

  const fetchNMS = useCallback(() => {
    const params = {
      include: "patient,nms1STAnswer,nms2NDAnswer,nms1STAnswer.nms1st,nms2NDAnswer.nms2nd,clinic",
    };
    dispatch(retrieveOneNMS(nmsId, params))
      .then((res) => {
        setNms(res);
      })
      .catch((err) => console.log(err));
  }, [dispatch, nmsId]);

  useEffect(() => {
    if (nmsId !== undefined) {
      fetchNMS();
    }
  }, [fetchNMS, nmsId]);
  let b = nms?.createdAt ? format(new Date(nms?.createdAt), "MM/dd/yyyy") : "";

  const onDeleteNMS = () => {
    setLoading(true);

    dispatch(deleteNMS(nmsId))
      .then(() => {
        toast.success("NMS deleted successfully.");
        navigate("/nms");
      })
      .catch((error) => {
        toast.error(error.response.data.errors[0].detail);
        setLoading(false);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  function handleDelete() {
    openDeleteModal();
  }

  function openDeleteModal() {
    setIsDeleteModal(true);
  }

  function closeDeleteModal() {
    setIsDeleteModal(false);
  }

  return (
    <section className="ml-6 mr-7 min-h-screen items-center justify-center py-6">
      <div>
        <div className="block mb-4 md:flex items-center md:justify-between">
          <div className="items-center xl:flex space-x-2">
            <div className="text-xl">
              {nms?.patient && (
                <div className="flex items-center">
                  <Avatar
                    firstName={nms?.patient?.firstName}
                    lastName={nms?.patient?.lastName}
                    image={nms?.patient?.profilePictureUrl}
                  />
                </div>
              )}
            </div>
            <div className="text-xl font-semibold">
              NMS / {nms?.patient?.firstName !== null && nms?.patient?.firstName} {nms?.patient?.lastName !== null && nms?.patient?.lastName} /{" "}
              {b}
            </div>
            <div className="text-md font-normal">
              (NHS: {nms?.patient?.NHS})
            </div>
          </div>
          <div>
            <div className="mt-1 flex space-x-3 fill-alabaster">
              <div>
                <button
                  className="text-black mr-2 mb-2 rounded bg-white py-2 px-4 font-medium"
                  text="lg"
                  onClick={() => navigate(`/nms/${nmsId}/edit`)}
                >
                  Edit
                </button>

                <button
                  className="text-black mr-2 mb-1 rounded bg-white py-2 px-4 font-medium"
                  text="lg"
                  onClick={handleDelete}
                >
                  Delete
                </button>

                <Button
                  className="rounded bg-indigo py-2 px-4 font-medium text-white w-52"
                  text="lg"
                  onClick={() => navigate(`/nms/report/${nms?.id}`)}
                >
                  Download Report
                </Button>
              </div>
            </div>
          </div>
        </div>

        <div>
          <div className='lg:px-7 lg:py-7 px-4 py-4 mb-4 bg-white w-auto rounded-t-lg'>
            <div className="lg:grid lg:grid-cols-5">
              <div>
                <div className="text-indigo">
                  Name
                </div>
                <div>
                  {nms?.patient?.firstName !== null && nms?.patient?.firstName}
                  {nms?.patient?.lastName !== null && nms?.patient?.lastName}
                </div>
              </div>
              <div>
                <div className="text-indigo">
                  Email
                </div>
                <div>
                  {nms?.patient?.emailAddress}
                </div>
              </div>
              <div>
                <div className="text-indigo">
                  Phone number
                </div>
                <div>
                  {nms?.patient?.phone}
                </div>
              </div>
              <div>
                <div className="text-indigo">
                  Verbal concent
                </div>
                <div>
                  {nms?.isVerbalConsent ? "Yes" : "No"}
                </div>
              </div>
            </div>
            <div className="grid lg:grid-cols-2 mt-2">
              <div>
                <div className="text-indigo">
                  Address
                </div>
                <div>
                  {nms?.address || "Not Available"}
                </div>
              </div>
            </div>
            <div className="grid lg:grid-cols-1 mt-2">
              <div>
                <div className="text-indigo">
                  Medicines
                </div>
                <div className="lg:flex lg:space-x-2">
                  {nms?.medicines?.map((data, i) => {
                    return (
                      <div className="bg-indigo/10 px-2 py-2 rounded-lg mb-2" key={i}>
                        <div className="text-indigo">{data}</div>
                      </div>
                    );
                  })}
                </div>
              </div>
            </div>
          </div>
          <div className='lg:px-7 lg:py-7 px-4 py-4 mb-4 bg-white w-auto rounded-b-lg'>
            <div className="border-2 border-alabaster rounded-lg px-3 py-3 mb-3">
              <div className="px-4 py-2">
                <div className="flex justify-between items-center">
                  <div>
                    <div>First intervetion</div>
                    <div className="text-indigo">{moment(nms?.firstIntervention).format("YYYY-MM-DD hh:mm A")}</div>
                  </div>
                  <div>
                    {nms?.isFirstInterventionAnswered && (
                      <CheckCircle className="h-8 w-h-8" />
                    )}
                  </div>
                </div>
                <div className="pt-2">
                  {_.orderBy(nms?.nms1STAnswer, item => item.nms1st.position, ['asc'])?.map((data, i) => {
                    return (
                      <div key={i}>
                        <h1 className="font-semibold">{i + 1}. {data?.nms1st?.questionText}</h1>
                        <div className="text-indigo">{data?.answerText}</div>
                      </div>
                    )
                  }
                  )}
                </div>
                <div className="pt-2">
                  <Button
                    type="button"
                    className="text-black rounded-md bg-[#F7F7F7]"
                    text="base"
                    onClick={() => setIsFirstIntervetionModel(true)}
                  >
                    Edit
                  </Button>
                </div>
              </div>
            </div>
            <div className="border-2 border-alabaster rounded-lg px-3 py-3">
              <div className="px-4 py-2">
                <div className="flex justify-between items-center">
                  <div>
                    <div>Second follow up</div>
                    <div className="text-indigo">{nms?.secondFollowUp !== null && moment(nms?.secondFollowUp).format("YYYY-MM-DD hh:mm A")}</div>
                  </div>
                  <div>
                    {nms?.isSecondFollowUpAnswered && (
                      <CheckCircle className="h-8 w-h-8" />
                    )}
                  </div>
                </div>
                <div className="pt-2">
                  {_.orderBy(nms?.nms2NDAnswer, item => item.nms2nd.position, ['asc'])?.map((data, i) => {
                    return (
                      <div key={i}>
                        <h1 className="font-semibold">{i + 1}. {data?.nms2nd?.questionText}</h1>
                        <div className="text-indigo">{data?.answerText}</div>
                      </div>
                    )
                  }
                  )}
                </div>
                <div className="pt-2">
                  <Button
                    className="bg-indigo rounded-md text-white"
                    text="base"
                    width="44"
                    onClick={() => !moment(nms?.secondFollowUp).isSameOrAfter(moment()) ? setIsSecondFollowUpModel(true) : toast.error("Today is not Secondfollowup date")}
                  >
                    Take recording
                  </Button>
                </div>
              </div>
            </div>
          </div>
        </div>
        <FirstIntervetion
          isOpen={isFirstIntervetionModal}
          details={nms}
          closeModal={() => setIsFirstIntervetionModel(false)}
        />
        <SecondFollowup
          isOpen={isSecondFollowUpModal}
          details={nms}
          closeModal={() => setIsSecondFollowUpModel(false)}
        />
        <Modal
          title="Delete"
          maxWidth="32rem"
          show={isDeleteModal}
          onClose={openDeleteModal}
          showCancel={false}
        >
          <div className="mb-4 text-center font-light">
            {`Are you sure you want to delete this NMS?`}
          </div>
          <div className="mb-10 text-center font-light">
            You won't be able to revert this!
          </div>
          <div className="flex items-center justify-center space-x-3">
            <Button
              type="button"
              className="rounded-lg"
              outlined
              onClick={closeDeleteModal}
            >
              Cancel
            </Button>
            <Button
              type="button"
              width="28"
              className="rounded-lg text-white"
              color="indigo"
              loading={loading}
              onClick={() => {
                onDeleteNMS();
                closeDeleteModal();
              }}
            >
              Delete
            </Button>
          </div>
        </Modal>
      </div>
    </section>
  );
};

export default ViewNMS;


import BlogsService from "services/blog";
import {
    GET_ALL_BLOGS,
    CREATE_BLOGS,
    UPDATE_BLOGS,
    REMOVE_BLOGS,
    GET_ONE_BLOGS,
} from "./type";

export const createBlog = (data) => async (dispatch) => {
    try {
        const res = await BlogsService.create(data);

        dispatch({
            type: CREATE_BLOGS,
            payload: res.data,
        });

        return Promise.resolve(res.data);
    } catch (error) {
        return Promise.reject(error);
    }
};

export const updateBlog = (id, data) => async (dispatch) => {
    try {
        const res = await BlogsService.update(id, data);

        dispatch({
            type: UPDATE_BLOGS,
            payload: res.data,
        });

        return Promise.resolve(res.data);
    } catch (error) {
        return Promise.reject(error);
    }
};

export const deleteBlog = (id) => async (dispatch) => {
    try {
        const res = await BlogsService.remove(id);

        dispatch({
            type: REMOVE_BLOGS,
            payload: res.data,
        });

        return Promise.resolve(res.data);
    } catch (error) {
        return Promise.reject(error);
    }
};

export const retrieveOneBlog = (id, params) => async (dispatch) => {
    try {
        const res = await BlogsService.getOne(id, params);

        dispatch({
            type: GET_ONE_BLOGS,
            payload: res.data,
        });

        return Promise.resolve(res.data);
    } catch (error) {
        return Promise.reject(error);
    }
};

export const retriveBlogs = (data) => async (dispatch) => {
    try {
        const res = await BlogsService.getAll(data);

        dispatch({
            type: GET_ALL_BLOGS,
            payload: res.data,
        });

        return Promise.resolve(res.data);
    } catch (error) {
        return Promise.reject(error);
    }
};

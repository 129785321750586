import { retrieveUserById } from 'actions/auth';
import { createSignedUrl, uploadFile } from 'actions/fileUpload';
import { updateUsers } from 'actions/user';
import { Button } from 'components/ui';
import moment from 'moment';
import React, { useCallback, useEffect, useState } from 'react'
import { useForm } from 'react-hook-form';
import toast from 'react-hot-toast';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';

const ProfileSetting = () => {

    const navigate = useNavigate();
    const dispatch = useDispatch();
    const {
        register,
        handleSubmit,
        setValue,
        watch,
        formState: { errors },
    } = useForm();

    const [user, setUser] = useState({})
    const [loading, setLoading] = useState(false)
    const [selectdFile, setSelectdFile] = useState()
    const [imageError, setImageError] = useState()

    const auth = JSON.parse(localStorage.getItem("clinic-u"));
    const userId = auth?.id;

    watch((data) => {
        if (data?.profilePictureUrl?.length > 0) {
            setSelectdFile(URL.createObjectURL(data?.profilePictureUrl[0]));
        }
    });

    const fatchUser = useCallback(
        () => {
            setLoading(true)
            const params = {
                include: "role,clinic",
            };
            dispatch(retrieveUserById(userId, params))
                .then((response) => {
                    setUser(response.data);
                    setLoading(false);
                })
                .catch((error) => {
                    toast.error(error.response.data.errors[0].detail);
                    setLoading(false);
                });
        },
        [userId, setUser, dispatch],
    )

    const onSubmit = async (data) => {
        setLoading(true);
        let s3Key
        if (data?.profilePictureUrl?.length > 0) {
            if (!data?.profilePictureUrl[0].name.match(/\.(jpg|jpeg|png|gif)$/)) {
                setImageError("Image Shoud be .png, .jpg, .jpeg");
            } else {
                console.log(data?.profilePictureUrl[0]?.name, "sljd");
                const body = {
                    fileName: data?.profilePictureUrl[0]?.name,
                    mimeType: data?.profilePictureUrl[0]?.type,
                    directory: "profile"

                }
                await dispatch(createSignedUrl(body)).then(async (result) => {
                    s3Key = result?.s3Key
                    dispatch(uploadFile(result?.signedUrl, data?.profilePictureUrl[0]));
                    data.profilePictureUrl = s3Key;
                    //update profile with image Upload
                    dispatch(updateUsers(userId, data))
                        .then((response) => {
                            toast.success("Profile Update Successfully");
                            setLoading(false);
                            navigate("/dashboard")
                        })
                        .catch((error) => {
                            toast.error(error.response.data.errors[0].detail);
                            setLoading(false);
                        });
                })
                    .catch((error) => {
                        toast.error(error.response.data.errors[0].detail);
                    })
            }
            console.log(data?.profilePictureUrl[0]);
        } else {
            //update prfile without image upload
            dispatch(updateUsers(userId, data))
                .then((response) => {
                    toast.success("Profile Update Successfully");
                    setLoading(false);
                    navigate("/dashboard")
                })
                .catch((error) => {
                    toast.error(error.response.data.errors[0].detail);
                    setLoading(false);
                });
        }

    }

    useEffect(() => {
        if (userId !== undefined) {
            fatchUser();
        }
    }, [
        fatchUser,
        userId,
    ]);

    useEffect(() => {
        setValue("firstName", user?.firstName);
        setValue("lastName", user?.lastName);
        setValue("emailAddress", user?.emailAddress);
        setValue("gender", user?.gender);
        setValue("dob", user?.dob);
        setValue("NHS", user?.NHS);
    }, [
        setValue,
        user?.firstName,
        user?.lastName,
        user?.emailAddress,
        user?.gender,
        user?.dob,
        user?.NHS,
    ]);

    return (
        <section className="min-h-screen ml-6 mr-7 py-6 justify-center items-center">
            <div>
                <div className="flex mb-4 justify-between">
                    <div className="text-xl font-semibold">Account Setting</div>
                    <div className="text-lg text-indigo" onClick={() => navigate("/change-passwrd")}>
                        Change password
                    </div>
                </div>
                <form
                    onSubmit={handleSubmit(onSubmit)}
                >
                    <div className="rounded-lg block md:flex">
                        <div className="w-full rounded-lg md:w-2/5 p-4 sm:p-6 lg:p-8 bg-white shadow-md">
                            <div className="flex justify-between">
                                <span className="text-xl font-semibold block">Profile Picture</span>
                                <input
                                    type="file"
                                    {...register("profilePictureUrl", { required: false })}
                                />
                                {imageError && (
                                    <div className="mt-1 text-sm text-burnt-sienna">
                                        {imageError}
                                    </div>
                                )}
                            </div>
                            <div className="w-full p-8 mx-2 flex justify-center">
                                {user?.profileUrl && <img src={user?.profileUrl} alt="profile" />}
                                {selectdFile && <img src={selectdFile} alt="profile" />}
                            </div>
                        </div>

                        <div className="w-full rounded-lg md:w-3/5 p-8 bg-white lg:ml-4 shadow-md">
                            <div className="rounded  shadow p-6">
                                <div className="pb-6">
                                    <label htmlFor="fname" className="font-semibold text-gray-700 block pb-1">First Name</label>
                                    <input
                                        type="text"
                                        placeholder="First Name"
                                        className="bg-alabaster border-none w-full rounded-md"
                                        {...register("firstName", { required: true })}
                                    />
                                    {errors?.firstName?.type === "required" && (
                                        <div className="mt-1 text-sm text-burnt-sienna">
                                            First Name is required.
                                        </div>
                                    )}
                                </div>
                                <div className="pb-6">
                                    <label htmlFor="lname" className="font-semibold text-gray-700 block pb-1">Last Name</label>
                                    <input
                                        type="text"
                                        placeholder="Last Name"
                                        className="bg-alabaster border-none w-full rounded-md"
                                        {...register("lastName", { required: true })}
                                    />
                                    {errors?.lastName?.type === "required" && (
                                        <div className="mt-1 text-sm text-burnt-sienna">
                                            Last Name is required.
                                        </div>
                                    )}
                                </div>
                                <div className="pb-6">
                                    <label htmlFor="email" className="font-semibold text-gray-700 block pb-1">Email</label>
                                    <input
                                        type="email"
                                        placeholder="Email"
                                        disabled
                                        className="bg-alabaster border-none w-full rounded-md"
                                        {...register("emailAddress", { required: true, pattern: /^\S+@\S+$/i, })}
                                    />
                                    {errors?.lastName?.type === "required" && (
                                        <div className="mt-1 text-sm text-burnt-sienna">
                                            Email is required.
                                        </div>
                                    )}
                                    {errors.emailAddress?.type === "pattern" && (
                                        <div className="mt-1 text-sm text-burnt-sienna">
                                            Please match the requested format.
                                        </div>
                                    )}
                                </div>
                                <div className="pb-6">
                                    <label htmlFor="gender" className="font-semibold text-gray-700 block pb-1">Gender</label>
                                    <input
                                        type="radio"
                                        id="male"
                                        name="gender"
                                        value="male"
                                        {...register("gender", { required: true })}
                                    />
                                    <label htmlFor="male">Male</label> {" "}
                                    <input
                                        type="radio"
                                        id="female"
                                        name="gender"
                                        value="female"
                                        {...register("gender", { required: true })}
                                    />
                                    <label htmlFor="female">Female</label>
                                    {errors?.gender?.type === "required" && (
                                        <div className="mt-1 text-sm text-burnt-sienna">
                                            Gender is required.
                                        </div>
                                    )}
                                </div>
                                <div className="pb-6">
                                    <label htmlFor="dob" className="font-semibold text-gray-700 block pb-1">Date of Birth</label>
                                    <input
                                        type="date"
                                        max={moment().format("YYYY-MM-DD")}
                                        placeholder="Last Name"
                                        className="bg-alabaster border-none w-full rounded-md"
                                        {...register("dob", { required: true })}
                                    />
                                    {errors?.dob?.type === "required" && (
                                        <div className="mt-1 text-sm text-burnt-sienna">
                                            Date of Birth is required.
                                        </div>
                                    )}
                                    {errors?.dob?.type === "max" && (
                                        <div className="mt-1 text-sm text-burnt-sienna">
                                            You can Not Select Future Date.
                                        </div>
                                    )}
                                </div>
                                <div className="pb-6">
                                    <label htmlFor="NHS" className="font-semibold text-gray-700 block pb-1">NHS Number</label>
                                    <input
                                        type="text"
                                        placeholder="NHS"
                                        className="bg-alabaster border-none w-full rounded-md"
                                        {...register("NHS", { required: true })}
                                    />
                                    {errors?.NHS?.type === "required" && (
                                        <div className="mt-1 text-sm text-burnt-sienna">
                                            NHS Number is required.
                                        </div>
                                    )}
                                </div>
                                <div className="pb-6">
                                    <Button
                                        type="submit"
                                        className="h-12 rounded-lg bg-indigo text-white"
                                        text="lg"
                                        loading={loading}
                                    >
                                        Update Profile
                                    </Button>
                                </div>
                            </div>
                        </div>
                    </div>
                </form>
                {/* <div className="rounded-lg w-auto">
                    <form
                        onSubmit={handleSubmit(onSubmit)}
                    >
                        <div className='px-8 py-8 rounded-lg bg-white w-auto'>
                            <div>Profile Details</div>
                        </div>
                    </form>
                </div> */}
            </div>

        </section>
    )
}

export default ProfileSetting
import { configureStore, combineReducers } from "@reduxjs/toolkit";
import storage from "redux-persist/lib/storage";
import {
  persistReducer,
  persistStore,
} from "redux-persist";
import thunk from "redux-thunk";
import counterReducer from "features/counter/counterSlice";
import clinicReducer from "features/clinic/clinicSlice";

const persistConfig = {
  key: "root",
  storage,
};

const rootReducer = combineReducers({
  // counter: counterReducer,
  clinic: clinicReducer,
});

const persistedReducer = persistReducer(persistConfig, rootReducer);

export const store = configureStore({
  reducer: {
    persistedReducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }).concat(thunk),
});

export const persistor = persistStore(store);

import { retrieveOneClinic } from 'actions/clinics';
import { checkoutSubscription, retriveAllPrice } from 'actions/subscription';
import { Button } from 'components/ui';
import React, { useEffect, useState } from 'react'
import { toast } from 'react-hot-toast';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';

const AllSubscription = () => {

    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false)
    const [subscription, setSubscription] = useState([])

    const auth = JSON.parse(localStorage.getItem("clinic-u"));
    const clinicId = auth?.clinic[0]?.id

    useEffect(() => {
        dispatch(retriveAllPrice())
            .then((response) => {
                setSubscription(response?.data);
            })
            .catch((error) => {
                console.log(error, "error");
                toast.error(error.response.data.message);
            })
    }, [dispatch])

    const onCheckout = (data) => {
        setLoading(true)
        const params = {
            include: "clinicStripe",
        }
        dispatch(retrieveOneClinic(clinicId, params))
            .then((res) => {
                const body = {
                    customerId: res?.clinicStripe[0]?.customerId,
                    priceId: data?.id
                }
                dispatch(checkoutSubscription(body))
                    .then((res) => {
                        window.location.href = res?.data?.url;
                    }).catch((err) => console.log(err));
            })
            .catch((err) => console.log(err));
    }

    return (
        <section className="flex min-h-screen flex-col items-center justify-center bg-[#F3F4F7]">
            <div className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
                <div className="mx-auto max-w-4xl rounded-xl bg-white mb-6 lg:py-11 lg:px-32 py-8 px-5">
                    <div className="grid grid-cols-1 lg:grid-cols-3 md:grid-cols-2 gap-3">
                        {subscription?.filter(subscriptionDetail => subscriptionDetail?.active)?.map((data, i) => {
                            return (
                                <div key={i}>
                                    <div className="bg-alabaster px-3 py-3 rounded-lg mb-2 h-96">
                                        <div className="text-xl text-indigo">
                                            £ {data?.unit_amount / 100} / {data?.recurring?.interval}
                                        </div>
                                        <p>{data?.nickname}</p>
                                    </div>
                                    <div className="mb-3 flex justify-center items-center">
                                        <Button
                                            type="button"
                                            className="h-12 rounded-lg bg-indigo text-white w-40"
                                            text="lg"
                                            onClick={() => onCheckout(data)}
                                            disabled={loading}
                                        >
                                            Subscribe
                                        </Button>
                                    </div>
                                </div>
                            )
                        })}
                    </div>
                </div>
                <div className="flex justify-center space-x-3">
                    <div>
                        <button
                            className="bg-white text-black font-normal py-2 px-4 rounded"
                            onClick={() => navigate("/subscription")}
                        >
                            Skip for now
                        </button>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default AllSubscription
import { updateService } from 'actions/services';
import { Button, Modal } from 'components/ui';
import React, { useEffect, useState } from 'react';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import { toast } from 'react-hot-toast';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router';
import Select from "react-select";

const ArrangeQuestionClinic = ({ isOpen, details, closeModal }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [questionForClinic, setQuestionForClinic] = useState()
  const [loading, setLoading] = useState(false)

  const selectOptions = (option) => {
    const mappedOptions = option.map((option) => {
      return { value: option, label: option };
    });
    return mappedOptions;
  };

  const onSaveOrder = async() => {
    setLoading(true)
    for (const [i, patientQuestion] of questionForClinic.entries()) {
      patientQuestion.position = i;
    }

    const body = {
      ...details,
      "questionForClinic" : questionForClinic
    }
 
    await dispatch(updateService(details?.id, body)).then((result) => {
      toast.success("Service Update Successfully");
      navigate("/services")
    })
      .catch((error) => {
        setLoading(false)
        toast.error(error.response.data.errors[0].detail);
      })
  }

  function handleOnDragEnd(result) {
    if (!result.destination) return;

    const items = Array.from(questionForClinic);
    const [reorderedItem] = items.splice(result.source.index, 1);
    items.splice(result.destination.index, 0, reorderedItem);
    console.log(items, "items");
    setQuestionForClinic(items);
  }

  useEffect(() => {
    setQuestionForClinic(details?.questionForClinic);
  }, [details?.questionForClinic])

  return (
    <Modal
      title="Clinic Question Reorder"
      maxWidth="40rem"
      show={isOpen}
      onClose={closeModal}
      showCancel={false}
    >

      <div className="flex items-start justify-start space-x-3">
        <DragDropContext onDragEnd={handleOnDragEnd}>
          <Droppable droppableId="characters">
            {(provided) => (
              <div
                className="bg-alabaster px-4 py-4 rounded-lg w-full"
                {...provided.droppableProps}
                ref={provided.innerRef}>
                {questionForClinic?.map((data, i) => {
                  return (
                    <Draggable key={`${i}`} draggableId={`${i}`} index={i}>
                      {(provided) => (
                        <div
                          key={i}
                          className="bg-white px-4 py-4 rounded-lg mb-3"
                          ref={provided.innerRef}
                          {...provided.draggableProps}
                          {...provided.dragHandleProps}
                        >
                          {data.inputType === "text" && (
                            <div className="mb-2 text-sm font-medium">
                              <div className="mb-2">
                                <label>{data?.questionText}</label>
                              </div>
                              <input
                                type={data?.inputType}
                                placeholder={data?.placeholder}
                                className="bg-alabaster border-none w-full rounded-md focus:ring-0"
                              />
                            </div>
                          )}
                          {data.inputType === "textarea" && (
                            <div className="mb-2 text-sm font-medium">
                              <div className="mb-2">
                                <label>{data?.questionText}</label>
                              </div>
                              <textarea
                                rows={3}
                                placeholder={data?.placeholder}
                                className="bg-alabaster border-none resize-none w-full rounded-md focus:ring-0"
                              />
                            </div>
                          )}
                          {data?.inputType === "select" && (
                            <div className="mb-2 text-sm font-medium">
                              <div className="mb-2">
                                <label>{data?.questionText}</label>
                              </div>
                              <select
                                className="bg-alabaster border-none w-full rounded-md focus:ring-0"
                              >
                                <option value="">
                                  Choose an option
                                </option>
                                {data?.options.map((option, i) => {
                                  return (
                                    <option
                                      key={i}
                                      value={option.trim()}
                                    >
                                      {option}
                                    </option>
                                  );
                                })}
                              </select>
                            </div>
                          )}
                          {data?.inputType === "multiselect" && (
                            <div className="mb-2 text-sm font-medium">
                              <div className="mb-2">
                                <label>{data?.questionText}</label>
                              </div>
                              <Select
                                isMulti
                                options={selectOptions(data?.options)}
                                className="bg-alabaster border-none w-auto rounded-md focus:ring-0"
                              />
                            </div>
                          )}
                          {data?.inputType === "checkbox" && (
                            <div className="mb-2 text-sm font-medium">
                              <div className="mb-2">
                                <label>{data?.questionText}</label>
                              </div>
                              <div className="flex flex-col items-start">
                                {data?.options.map((option, i) => {
                                  return (
                                    <div key={i}>
                                      <input
                                        id={`${data?.id}.${i}`}
                                        name={`${data?.id}`}
                                        value={option}
                                        type="checkbox"
                                        className="mr-2"
                                      />
                                      <label
                                        htmlFor={`${data?.id}.${i}`}
                                        className="select-none font-light"
                                      >
                                        {option}
                                      </label>
                                    </div>
                                  );
                                })}
                              </div>
                            </div>
                          )}
                          {data?.inputType === "radio" && (
                            <div className="mb-2 text-sm font-medium">
                              <div className="mb-2">
                                <label>{data?.questionText}</label>
                              </div>
                              <div className="flex flex-col items-start">
                                {data?.options.map((option, i) => {
                                  return (
                                    <div key={i}>
                                      <input
                                        id={`${data?.id}`}
                                        name={`${data?.id}`}
                                        type="radio"
                                        value={option}
                                        className="mr-2"
                                      />
                                      <label
                                        htmlFor={`${data?.id}`}
                                        className="select-none font-light"
                                      >
                                        {option}
                                      </label>
                                    </div>
                                  );
                                })}
                              </div>
                            </div>
                          )}
                        </div>
                      )}
                    </Draggable>
                  );
                })}
                {provided.placeholder}
              </div>
            )}
          </Droppable>
        </DragDropContext>
      </div>
      <div className="pt-4 flex space-x-2">
      <div className="pt-4">
        <Button
          type="button"
          className="h-12 rounded-lg bg-indigo px-7 text-white"
          onClick={() => onSaveOrder()}
          loading={loading}
        >
          Save
        </Button>
      </div>
      <div className="pt-4">
        <Button
          type="button"
          className="h-12 rounded-lg"
          onClick={closeModal}
          color="indigo"
          outlined
        >
          Cancel
        </Button>
      </div>
      </div>
    </Modal>
  )
}

export default ArrangeQuestionClinic
import AuthService from "services/auth";
import {
    FORGOT_PASSWORD,
    FORGOT_PASSWORD_RESET,
    RENEWTOKEN,
    RETRIEVE_USER_BY_ID,
    SIGNIN
} from "./type";

export const signIn = (data) => async (dispatch) => {
    try {
        const res = await AuthService.signIn(data);

        dispatch({
            type: SIGNIN,
            payload: res.data,
        });

        return Promise.resolve(res.data);
    } catch (error) {
        return Promise.reject(error);
    }
};

export const forgotPassword = (data) => async (dispatch) => {
    try {
        const res = await AuthService.forgotPassword(data);

        dispatch({
            type: FORGOT_PASSWORD,
            payload: res.data,
        });

        return Promise.resolve(res.data);
    } catch (error) {
        return Promise.reject(error);
    }
};

export const forgotPasswordReset = (data) => async (dispatch) => {
    try {
        const res = await AuthService.forgotPasswordReset(data);

        dispatch({
            type: FORGOT_PASSWORD_RESET,
            payload: res.data,
        });

        return Promise.resolve(res.data);
    } catch (error) {
        return Promise.reject(error);
    }
};

export const retrieveUserById = (data, params) => async (dispatch) => {
    try {
        const res = await AuthService.getOne(data, params);
        dispatch({
            type: RETRIEVE_USER_BY_ID,
            payload: res.data,
        });
        return Promise.resolve(res);
    } catch (error) {
        return Promise.reject(error);
    }
};

export const renewTokens = (data) => async (dispatch) => {
    try {
        const res = await AuthService.renewTokens(data);

        dispatch({
            type: RENEWTOKEN,
            payload: res.data,
        });

        return Promise.resolve(res.data);
    } catch (error) {
        return Promise.reject(error);
    }
};
import { forgotPasswordReset } from 'actions/auth';
import { Button } from 'components/ui';
import React, { useRef, useState } from 'react'
import { useForm } from 'react-hook-form';
import toast from 'react-hot-toast';
import { useDispatch } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';

const ForgotPWDReset = () => {

    const { token } = useParams();
    const navigate = useNavigate();

    const [loading, setLoading] = useState(false);

    const dispatch = useDispatch();

    const {
        register,
        handleSubmit,
        setValue,
        watch,
        formState: { errors },
    } = useForm();

    const password = useRef({});
    password.current = watch("password", "");

    const onSubmit = (data) => {
        setLoading(true);

        const body = {
            token: token,
            password: data.password,
            confirmPassword: data.confirmPassword,
        };

        dispatch(forgotPasswordReset(body))
            .then(() => {
                toast.success("Password reset successfully.");
                navigate("/login");
                setValue("password", "");
                setValue("confirmPassword", "");
            })
            .catch((error) => {
                toast.error(error.response.data.errors[0].detail);
                setLoading(false);
            })
            .finally(() => {
                setLoading(false);
            });
    };

    return (
        <section className="bg-[#F3F4F7] flex flex-col min-h-screen justify-center items-center">
            <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
                <div className="bg-white max-w-4xl mx-auto rounded-xl">
                    <div>
                        <div className="px-12">
                            <div className="flex pt-16 justify-center items-center">
                                <img src="/img/logo/logo.png" alt="Brand Logo" />
                            </div>
                            <div className="pt-11">
                                <form className="w-full p-6 sm:p-12" onSubmit={handleSubmit(onSubmit)}>
                                    <header className="mb-12 text-center">
                                        <h3 className="mb-4 text-4xl font-bold">
                                            Reset Password
                                        </h3>
                                        <p className="text-sm">
                                            Your temporary password will need to be reset. Welcome to Clinic2Go!
                                        </p>
                                    </header>
                                    <div className="mb-5 space-y-9">
                                        <div className="relative">
                                            <label
                                                htmlFor="password"
                                                className="mb-1 text-sm font-medium"
                                            >
                                                Password
                                            </label>
                                            <input
                                                id="password"
                                                className="bg-alabaster border-none w-full rounded-md"
                                                type="password"
                                                placeholder="Password"
                                                {...register("password", {
                                                    required: true,
                                                    minLength: {
                                                        value: 8,
                                                        message: "Password must have at least 8 characters",
                                                    },
                                                })}
                                            />
                                            {errors?.password?.type === "required" && (
                                                <div className="mt-1 text-sm text-burnt-sienna">
                                                    Password is required.
                                                </div>
                                            )}
                                            {errors.password?.type === "minLength" && (
                                                <div className="mt-1 text-sm text-burnt-sienna">
                                                    {errors.password.message}
                                                </div>
                                            )}
                                        </div>
                                        <div className="relative">
                                            <label
                                                htmlFor="confirmPassword"
                                                className="mb-1 text-sm font-medium"
                                            >
                                                Confirm Password
                                            </label>
                                            <input
                                                id="confirmPassword"
                                                className="bg-alabaster border-none w-full rounded-md"
                                                type="password"
                                                placeholder="Password"
                                                {...register("confirmPassword", {
                                                    required: true,
                                                    validate: (value) =>
                                                        value === password.current || "Password does not match",
                                                })}
                                            />
                                            {errors?.confirmPassword?.type === "required" && (
                                                <div className="mt-1 text-sm text-burnt-sienna">
                                                    Confirm password is required.
                                                </div>
                                            )}
                                            {errors.confirmPassword && (
                                                <div className="mt-1 text-sm text-burnt-sienna">
                                                    {errors.confirmPassword.message}
                                                </div>
                                            )}
                                        </div>
                                    </div>
                                    <Button
                                        type="submit"
                                        className="bg-indigo text-white h-16 rounded-lg"
                                        width="full"
                                        text="lg"
                                        loading={loading}
                                    >
                                        Reset
                                    </Button>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default ForgotPWDReset
import React from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";

const EmailVerification = () => {
  const emailAddress = useSelector((state) => state?.clinic?.clinic?.emailAddress);

  return (
    <section className="flex min-h-screen flex-col items-center justify-center bg-[#F3F4F7]">
      <div className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
        <div className="mx-auto max-w-4xl rounded-xl bg-white mb-6">
          <div className="text-center lg:py-11 lg:px-32 py-8 px-5">
            <div className="text-lg font-normal mb-4">Please verify your email </div>
            <div className="text-lg font-normal mb-4">{emailAddress}</div>
            <div className="text-sm font-normal">Your account has been successfully registered. To complete the verification process, please check your email.</div>
          </div>
        </div>
        <div className="flex space-x-3 justify-center">
          <Link to="/login">
            Return to <span className="text-base text-indigo underline cursor-pointer">Sign in!</span>
          </Link>
        </div>
      </div>
    </section>
    // <div>
    //   <Header />
    //   <CenterCard>
    //     <form>
    //       <div className="p-11">
    //         <div className="block">
    //           <div className="text-center">
    //             <p className="pt-1 text-2xl">Please verify your email</p>
    //             <p className="pt-1 text-xl text-indigo">
    //               {emailAddress || "info@clinic.com"}
    //             </p>
    //             <p className="text-large pt-2">
    //               Your account has been successfully registered. To complete the
    //               verification process, please check your email.
    //             </p>
    //           </div>
    //         </div>
    //       </div>
    //     </form>
    //   </CenterCard>
    //   <div className="flex justify-around py-6">
    //     <div>
    //       <Link to="/login" style={{ color: "#3968ca" }}>
    //         {" "}
    //         Resend Email
    //       </Link>
    //     </div>
    //     <div>
    //       Return to
    //       <Link to="/login" style={{ color: "#3968ca" }}>
    //         {" "}
    //         Sign in!
    //       </Link>
    //     </div>
    //   </div>
    // </div>
  );
};

export default EmailVerification;

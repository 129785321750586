import GpEmailService from "services/sendReportToGP";
import {
    GP_EMAIL_REPORT,
} from "./type";

export const sendEmailReportToGP = (data) => async (dispatch) => {
    try {
        const res = await GpEmailService.sendReportToGP(data);

        dispatch({
            type: GP_EMAIL_REPORT,
            payload: res.data,
        });

        return Promise.resolve(res.data);
    } catch (error) {
        return Promise.reject(error);
    }
};

import authHeader from "auth-header";
import httpCommon from "http-common";

const getAllContactUs = (params) => {
    const config = {
        headers: authHeader(),
        params: params,
    };
    return httpCommon.get(`/contact-us`, config);
};

const create = (data) => {
    return httpCommon.post("/contact-us", data);
  };

const ContactUsService = {
    getAllContactUs,
    create,
};

export default ContactUsService;
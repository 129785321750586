import { Transition } from '@headlessui/react';
import clsx from 'clsx';
import { Button } from 'components/ui';
import React, { useEffect } from 'react'
import { useRef } from 'react';
import { useState } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';

const NewHeader = () => {

    const navigate = useNavigate();
    const location = useLocation();
    const localData = localStorage.getItem("clinicu");
    const [isOpen, setIsOpen] = useState(false);
    const ref = useRef();

    const onClickContact = () => {
        console.log(location.pathname !== "/")
        if (location.pathname !== "/","header") {
            navigate("/#contact-us")
        } else {
            navigate("#contact-us")
        }
    }

    // Function to scroll to the "Contact us" section
    const scrollToContactUs = () => {
        const contactUsElement = document.getElementById('contact-us');
        if (contactUsElement) {
            // Scroll to the element with smooth behavior
            contactUsElement.scrollIntoView({ behavior: 'smooth' });
        }
    };

    // Listen for changes in the location and scroll to "Contact us" if the hash is present
    useEffect(() => {
        if (location.hash === '#contact-us') {
            scrollToContactUs();
        }
    }, [location])

    return (
        <nav className={clsx(
            isOpen &&
            "overflow-hidden",
            "shadow-xl"
        )}>
            <div className="flex justify-between max-w-7xl mx-auto py-6 px-6">
                <Link to="/">
                    <div>
                        <img src="/img/logo/logo.png" alt="logo" className="h-10 w-44 object-contain" />
                    </div>
                </Link>
                <div className="lg:flex lg:space-x-7 lg:items-center hidden">
                    <Link to="/" className="text-elephant font-plus-jakarta-sans text-base">Home</Link>
                    <Link to="/landing-blogs" className="text-elephant font-plus-jakarta-sans text-base">Blogs</Link>
                    {/* <a href="#contact-us" className="text-elephant font-plus-jakarta-sans text-base">Contact us</a> */}
                    <div onClick={() => onClickContact()} className="text-elephant font-plus-jakarta-sans text-base cursor-pointer">Contact us</div>
                    {localData === null && (
                        <>
                            <Link to="/login" className="text-elephant font-plus-jakarta-sans text-base">Sign in</Link>
                            <Link to="/register" className="text-white">
                                <Button
                                    className="bg-indigo text-white rounded-md"
                                    width="56"
                                >
                                    Register your Clinic
                                </Button>
                            </Link>
                        </>
                    )}
                    {localData !== null && (
                        <Link to="/dashboard" className="text-white">
                            <Button
                                className="bg-indigo text-white rounded-md"
                                width="56"
                            >
                                Dashboard
                            </Button>
                        </Link>
                    )}
                </div>
                <div className="flex lg:hidden">
                    <button
                        onClick={() => setIsOpen(!isOpen)}
                        type="button"
                        className="bg-elephant inline-flex items-center justify-center p-1.5 rounded-md text-gray-400 hover:text-white focus:outline-none"
                        aria-controls="mobile-menu"
                        aria-expanded="false"
                    >
                        <span className="sr-only">Open main menu</span>
                        {!isOpen ? (
                            <svg
                                className="block h-6 w-6"
                                xmlns="http://www.w3.org/2000/svg"
                                fill="none"
                                viewBox="0 0 24 24"
                                stroke="white"
                                aria-hidden="true"
                            >
                                <path
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    strokeWidth="2"
                                    d="M4 6h16M4 12h16M4 18h16"
                                />
                            </svg>
                        ) : (
                            <svg
                                className="block h-6 w-6"
                                xmlns="http://www.w3.org/2000/svg"
                                fill="none"
                                viewBox="0 0 24 24"
                                stroke="white"
                                aria-hidden="true"
                            >
                                <path
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    strokeWidth="2"
                                    d="M6 18L18 6M6 6l12 12"
                                />
                            </svg>
                        )}
                    </button>
                </div>
            </div>
            <Transition
                show={isOpen}
                enter="transition ease-out duration-100 transform"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="transition ease-in duration-75 transform"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
            >
                {() => (
                    <div className="lg:hidden flex flex-col items-end text-end" id="mobile-menu">
                        <div ref={ref} className="px-2 pt-2 pb-3 sm:px-3">
                            <div onClick={() => setIsOpen(false)}>
                                <Link to="/" className="text-elephant font-plus-jakarta-sans text-xl">
                                    Home
                                </Link>
                            </div>
                            <div>
                                <Link to="/landing-blogs" className="text-elephant font-plus-jakarta-sans text-xl">
                                    Blogs
                                </Link>
                            </div>
                            <div>
                                {/* <Link to="/contact-us" className="text-elephant font-plus-jakarta-sans text-xl">
                                    Contact us
                                </Link> */}
                                <div onClick={() => onClickContact()} className="text-elephant font-plus-jakarta-sans text-xl cursor-pointer">Contact us</div>
                            </div>
                            {localData === null && (
                                <>
                                    <div>
                                        <Link to="/login" className="text-elephant font-plus-jakarta-sans text-xl">Sign in</Link>
                                    </div>
                                    <div>
                                        <Link to="/register" className="text-elephant font-plus-jakarta-sans">
                                            <Button
                                                className="bg-indigo text-white rounded-md"
                                                width="56"
                                            >
                                                Register your Clinic
                                            </Button>
                                        </Link>
                                    </div>
                                </>
                            )}
                            {localData !== null && (
                                <Link to="/dashboard" className="text-white">
                                    <Button
                                        className="bg-indigo text-white rounded-md"
                                        width="56"
                                    >
                                        Dashboard
                                    </Button>
                                </Link>
                            )}
                        </div>
                    </div>
                )}
            </Transition>
        </nav>
    )
}

export default NewHeader
import { updateAppointment } from 'actions/appointment';
import { Button, Modal } from 'components/ui';
import _ from 'lodash';
import moment from 'moment';
import React, { useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import toast from 'react-hot-toast';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import Select from "react-select";

const EditPatientAnswer = ({ isOpen, appointment, close, closeModal }) => {

    const dispatch = useDispatch();
    const navigate = useNavigate();

    const [loading, setLoading] = useState(false)

    const {
        register,
        handleSubmit,
        control,
        setValue,
        watch
    } = useForm();

    const selectOptions = (option) => {
        const mappedOptions = option.map((option) => {
            return { value: option, label: option };
        });
        return mappedOptions;
    };

    console.log(watch());
    const onSubmit = (data) => {
        setLoading(true)
        for (const answer of data?.answeredByPatient) {
            if (answer?.type === "multiselect") {
                if (answer.answerText?.length > 0) {
                    answer.answerText = answer?.answerText?.map((key) => key.value).toString()
                } else {
                    answer.answerText = ""
                }
            }
            if (answer?.type === "checkbox") {
                if (answer.answerText) {
                    answer.answerText = answer.answerText.toString()
                } else {
                    answer.answerText = ""
                }
            }
        }
        const body = {
            clinic: {
                id: appointment?.clinic?.id
            },
            service: {
                id: appointment?.service?.id
            },
            clinicService: {
                id: appointment?.clinicService?.id
            },
            user: {
                id: appointment?.user?.id
            },
            date: appointment?.date,
            time: appointment?.time,
            status: appointment?.status,
            answeredByPatient: data?.answeredByPatient,
            answeredByClinic: appointment?.answeredByClinic
        }
        dispatch(updateAppointment(appointment.id, body))
            .then((result) => {
                toast.success("Appointment Update Successfully");
                closeModal();
                navigate(`/appointments/${appointment?.id}`);
            })
            .catch((error) => {
                toast.error(error.response.data.errors[0].detail);
            })
    }
    console.log(appointment?.answeredByPatient,"appointment?.answeredByPatient")

    return (
        <Modal
            title=""
            maxWidth={"87rem"}
            show={true}
            onClose={close}
            showCancel={true}
            onCancel={closeModal}
        >
            <div>
                <h1
                    className="text-indigo flex items-start text-xl font-normal mb-4"
                >{
                        `${appointment?.user?.firstName} ${appointment?.user?.lastName} / ${appointment?.service?.name} - ${moment(appointment?.date).format("do MMM YYYY")} - ${moment(appointment?.time, "HH:mm").format("hh:mm A")} `
                    }
                </h1>
                <form onSubmit={handleSubmit(onSubmit)} >
                    <div className="lg:px-7 lg:py-7 px-4 py-4 mb-4 bg-white w-auto rounded-b-lg">
                        <div className="text-xl mb-3 font-semibold">Patient’s form</div>
                        {_.sortBy(appointment?.answeredByPatient, "questions.position")?.map((question, i) => {
                            return (
                                <div key={i} className="mb-2">
                                    {question?.questions?.inputType === "text" && (
                                        <div className="flex flex-col space-y-2 mb-2 text-sm font-medium">
                                            <label>{question?.questions?.questionText}</label>
                                            <input
                                                type="text"
                                                hidden
                                                value={question?.questions?.id}
                                                {...register(`answeredByPatient.${i}.questions.id`, { required: false })}
                                            />
                                            <input
                                                type="text"
                                                hidden
                                                value={question?.id}
                                                {...register(`answeredByPatient.${i}.id`, { required: false })}
                                            />
                                            <input
                                                type={question?.questions?.inputType}
                                                placeholder={question?.placeholder}
                                                defaultValue={question?.answerText}
                                                className="bg-alabaster border-none w-auto rounded-md focus:ring-0"
                                                {...register(`answeredByPatient.${i}.answerText`, { required: false })}
                                            />
                                        </div>
                                    )}
                                    {question?.questions?.inputType === "textarea" && (
                                        <div className="flex flex-col space-y-2 mb-2 text-sm font-medium">
                                            <label>{question?.questions?.questionText}</label>
                                            <input
                                                type="text"
                                                hidden
                                                value={question?.questions?.id}
                                                {...register(`answeredByPatient.${i}.questions.id`, { required: false })}
                                            />
                                            <input
                                                type="text"
                                                hidden
                                                value={question?.id}
                                                {...register(`answeredByPatient.${i}.id`, { required: false })}
                                            />
                                            <textarea
                                                rows={3}
                                                placeholder={question?.placeholder}
                                                defaultValue={question?.answerText}
                                                className="bg-alabaster border-none resize-none w-auto rounded-md focus:ring-0"
                                                {...register(`answeredByPatient.${i}.answerText`, { required: false })}
                                            />
                                        </div>
                                    )}
                                    {question?.questions?.inputType === "select" && (
                                        <div className="flex flex-col space-y-2 mb-2 text-sm font-medium">
                                            <label>{question?.questions?.questionText}</label>
                                            <input
                                                type="text"
                                                hidden
                                                value={question?.questions?.id}
                                                {...register(`answeredByPatient.${i}.questions.id`, { required: false })}
                                            />
                                            <input
                                                type="text"
                                                hidden
                                                value={question?.id}
                                                {...register(`answeredByPatient.${i}.id`, { required: false })}
                                            />
                                            <select
                                                className="bg-alabaster border-none w-auto rounded-md focus:ring-0"
                                                {...register(`answeredByPatient.${i}.answerText`, { required: false })}
                                            >
                                                <option value="">
                                                    {question?.answerText ?? "Choose an option"}
                                                </option>
                                                {question?.questions?.options.map((option, i) => {
                                                    return (
                                                        <option
                                                            key={i}
                                                            value={option.trim()}
                                                        >
                                                            {option}
                                                        </option>
                                                    );
                                                })}
                                            </select>
                                        </div>
                                    )}
                                    {question?.questions?.inputType === "multiselect" && (
                                        <div className="flex flex-col space-y-2 text-sm font-medium">
                                            <label>{question?.questions?.questionText}</label>
                                            <input
                                                type="text"
                                                hidden
                                                value={question?.questions?.id}
                                                {...register(`answeredByPatient.${i}.questions.id`, { required: false })}
                                            />
                                            <input
                                                type="text"
                                                hidden
                                                value={question?.id}
                                                {...register(`answeredByPatient.${i}.id`, { required: false })}
                                            />
                                            <input
                                                type="text"
                                                hidden
                                                value="multiselect"
                                                {...register(`answeredByPatient.${i}.type`, { required: false })}
                                            />
                                            <Controller
                                                render={({ field: { onChange, value, name, ref } }) => (
                                                    <Select
                                                        isMulti
                                                        inputRef={ref}
                                                        options={selectOptions(question?.questions?.options)}
                                                        className="bg-alabaster border-none w-auto rounded-md focus:ring-0"
                                                        value={selectOptions(question?.questions?.options)?.find(c => c.value === value)}
                                                        onChange={val => {
                                                            onChange(val.value);
                                                            setValue(`answeredByPatient.${i}.answerText`, val)
                                                        }}
                                                        defaultValue={question?.answerText
                                                            ? question.answerText.split(',').map(item => ({ value: item.trim(), label: item.trim() }))
                                                            : []
                                                        }
                                                    />
                                                )}
                                                control={control}
                                                name={`answeredByPatient.${i}.answerText`}
                                            />
                                        </div>
                                    )}
                                    {question?.questions?.inputType === "checkbox" && (
                                        <div className="flex flex-col space-y-2 text-sm font-medium">
                                            <label>{question?.questions?.questionText}</label>
                                            <div className="grid grid-cols-1 gap-2 sm:grid-cols-2">
                                                {question?.questions?.options.map((option, index) => {
                                                    const isChecked = question?.answerText?.split(',').map(item => item.trim()).includes(option);
                                                    return (
                                                        <div key={index}>
                                                            <input
                                                                id={`${question?.id}.${index}`}
                                                                name={`${question?.id}`}
                                                                value={option}
                                                                type="checkbox"
                                                                className="mr-2"
                                                                defaultChecked={isChecked}
                                                                {...register(`answeredByPatient.${i}.answerText`, { required: false })}
                                                            />
                                                            <label
                                                                htmlFor={`${question?.id}.${index}`}
                                                                className="select-none font-light"
                                                            >
                                                                {option}
                                                            </label>
                                                            <input
                                                                type="text"
                                                                hidden
                                                                value="checkbox"
                                                                {...register(`answeredByPatient.${i}.type`, { required: false })}
                                                            />
                                                            <input
                                                                type="text"
                                                                hidden
                                                                value={question?.questions?.id}
                                                                {...register(`answeredByPatient.${i}.questions.id`, { required: false })}
                                                            />
                                                            <input
                                                                type="text"
                                                                hidden
                                                                value={question?.id}
                                                                {...register(`answeredByPatient.${i}.id`, { required: false })}
                                                            />
                                                        </div>
                                                    );
                                                })}
                                            </div>
                                        </div>
                                    )}
                                    {/* {question?.questions?.inputType === "checkbox" && (
                                        <div className="flex items-center space-x-4 mb-2 text-sm font-medium">
                                            <label>{question?.questions?.questionText}</label>
                                            <div className="grid grid-cols-1 gap-2 sm:grid-cols-2">
                                                {question?.questions?.options.map((option, index) => {
                                                    return (
                                                        <div key={index}>
                                                            <input
                                                                id={`${question?.id}.${index}`}
                                                                name={`${question?.id}.${index}`}
                                                                value={option}
                                                                type="checkbox"
                                                                className="mr-2"
                                                                {...register(`answeredByPatient.${i}.answerText`, { required: false })}
                                                            />
                                                            <label
                                                                htmlFor={`${question?.id}.${index}`}
                                                                className="select-none font-light"
                                                            >
                                                                {option}
                                                            </label>
                                                            <input
                                                                type="text"
                                                                hidden
                                                                value={question?.questions?.id}
                                                                {...register(`answeredByPatient.${i}.questions.id`, { required: false })}
                                                            />
                                                            <input
                                                                type="text"
                                                                hidden
                                                                value={question?.id}
                                                                {...register(`answeredByPatient.${i}.id`, { required: false })}
                                                            />
                                                            <input
                                                                type="text"
                                                                hidden
                                                                value="checkbox"
                                                                {...register(`answeredByPatient.${i}.type`, { required: false })}
                                                            />
                                                        </div>
                                                    );
                                                })}
                                            </div>
                                        </div>
                                    )} */}
                                    {question?.questions?.inputType === "radio" && (
                                        <div className="flex flex-col space-y-2 mb-2 text-sm font-medium">
                                            <label>{question?.questions?.questionText}</label>
                                            <div className="grid grid-cols-1 gap-2 sm:grid-cols-2">
                                                {question?.questions?.options.map((option, index) => {
                                                    const isChecked = option === question?.answerText;
                                                    return (
                                                        <div key={index}>
                                                            <input
                                                                id={`${index}`}
                                                                name={`${index}`}
                                                                type="radio"
                                                                value={option}
                                                                className="mr-2"
                                                                defaultChecked={isChecked}
                                                                {...register(`answeredByPatient.${i}.answerText`, { required: false })}
                                                            />
                                                            <label
                                                                htmlFor={`${index}`}
                                                                className="select-none font-light"
                                                            >
                                                                {option}
                                                            </label>
                                                            <input
                                                                type="text"
                                                                hidden
                                                                value={question?.questions?.id}
                                                                {...register(`answeredByPatient.${i}.questions.id`, { required: false })}
                                                            />
                                                            <input
                                                                type="text"
                                                                hidden
                                                                value={question?.id}
                                                                {...register(`answeredByPatient.${i}.id`, { required: false })}
                                                            />
                                                        </div>
                                                    );
                                                })}
                                            </div>
                                        </div>
                                    )}
                                </div>
                            )
                        })}
                    </div>
                    <div className="flex items-center justify-start space-x-3">
                        <Button
                            type="submit"
                            className="rounded-lg bg-indigo text-white"
                            width='56'
                            loading={loading}
                        >
                            Update Patient Answer
                        </Button>
                    </div>
                </form>
            </div>
        </Modal>
    )
}

export default EditPatientAnswer
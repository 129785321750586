import { retrieveOneBlog } from 'actions/blogs';
import React, { useCallback, useEffect, useState } from 'react'
import { useDispatch } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import Header from './header';
import { Interweave } from 'interweave';
import NewFooter from './NewFooter';

const BlogsDetails = () => {

    const { id } = useParams();
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const [blog, setBlog] = useState({});

    const fetchBlogs = useCallback(() => {
        dispatch(retrieveOneBlog(id))
            .then((response) => {
                setBlog(response);
            })
            .catch((error) => {
                console.log(error, "error");
                navigate("/login")
            });
    }, [dispatch, id, navigate]);

    useEffect(() => {
        fetchBlogs();
    }, [fetchBlogs]);

    return (
        <div className="min-h-screen overflow-hidden">
            <Header />
            <section>
                <div className="w-full bg-indigo/10 mx-auto pt-16 pb-12 px-4 lg:px-16 sm:px-4 md:px-4 flex flex-col justify-center items-center">
                    <div className="max-w-7xl">
                        <h1 className="text-indigo text-3xl font-bold mb-5">
                            {blog?.title}
                        </h1>
                    </div>
                </div>
            </section>
            <section>
                <div className="max-w-7xl mx-auto items-center py-10 px-4 lg:px-16 sm:px-4 md:px-4">
                    <div className="flex justify-center items-center">
                        <img alt="clinic2go" className="object-cover rounded-lg" src={blog?.accessUrl} />
                    </div>
                    <div className="py-12">
                        <p className="text-lg font-medium">
                            <Interweave content={blog?.description} />
                        </p>
                    </div>
                </div>
            </section>
            <NewFooter />
        </div>
    )
}

export default BlogsDetails
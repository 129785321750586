import { Button } from 'components/ui';
import React from 'react'
import { AiOutlineCheckCircle } from 'react-icons/ai';
import { useNavigate } from 'react-router-dom';

const Success = () => {

    // const { sessionId } = useParams();
    const navigate = useNavigate();

    return (
        <section className="flex min-h-screen flex-col items-center justify-center bg-[#F3F4F7]">
            <div className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
                <div className="mx-auto max-w-4xl rounded-xl bg-white mb-6 lg:py-11 lg:px-32 py-8 px-5">
                        <div className="flex flex-col justify-center items-center">
                            <AiOutlineCheckCircle className="h-8 w-8 text-storm-gray fill-malachite" />
                            <h1 className="text-malachite text-xl font-normal mb-4">Payment Successfull!</h1>
                            <div className="mb-1 text-center font-light text-lg">
                                To View Details go to dashboard
                            </div>
                            <div className="flex items-center justify-center space-x-3">
                                <Button
                                    type="submit"
                                    className="rounded-lg bg-indigo text-white"
                                    onClick={() => navigate("/subscription")}
                                >
                                    View
                                </Button>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default Success
import { Button } from 'components/ui'
import React, { useEffect, useState } from 'react'
import { GoArrowRight } from "react-icons/go";
import { useNavigate } from 'react-router-dom';

const NMS = () => {

    const navigate = useNavigate();
    const [currentImage, setCurrentImage] = useState(1);

    useEffect(() => {
        const interval = setInterval(() => {
            setCurrentImage((prevImage) => (prevImage === "nms-appointment.png" ? "nms-chat.png" : "nms-appointment.png"));
        }, 2000);
        return () => clearInterval(interval);
    }, []);

    return (
        <section className="flex flex-col items-center justify-center">
            <div className="max-w-7xl mt-24">
                <div className="flex flex-col items-center justify-center">
                    <div className="text-5xl text-elephant font-bold text-center">
                        Text Message and NMS! Making your experience at your local pharmacies Effortless.
                    </div>
                    <div className="mt-14">
                        <Button
                            type="button"
                            width="56"
                            color="indigo"
                            className="rounded-lg text-white"
                            loading={false}
                            onClick={() => navigate("/register")}
                        >
                            <div className="flex space-x-3 justify-center items-center">
                                <div className="text-white text-lg font-plus-jakarta-sans font-medium">Try For Free</div>
                                <GoArrowRight fill="white" />
                            </div>

                        </Button>
                    </div>
                    <div>
                        <img src={`/img/landingPage/${currentImage}`} alt="clinic2go" className="animate-fade-in-out" />
                    </div>
                </div>
            </div>
        </section>
    )
}

export default NMS
import { retriveAppointments } from "actions/appointment";
import { retrieveUserById } from "actions/auth";
import { deleteClinicSelectPatient } from "actions/clinicSelectPatient";
import { retriveNMS } from "actions/nms";
import { CheckCircle } from "components/icons";
import { Avatar, Button, Modal, Tabs } from "components/ui";
import useRole from "hooks/useRole";
import moment from "moment";
import React, { useCallback, useEffect, useState } from "react";
import DataTable from "react-data-table-component";
import toast from "react-hot-toast";
import { useDispatch } from "react-redux";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import customStyles from "utils/data-table-style";
import { AppointmentStatus, ROLE } from "utils/roles";

const tabs = [
  {
    id: 0,
    name: "Appointments",
    filter: "appointments",
  },
  {
    id: 1,
    name: "NMS",
    filter: "nms",
  },
];

const ViewPatient = () => {
  const { patientId } = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [searchParams, setSearchParams] = useSearchParams({
    filter: "appointments",
  });
  const filter = searchParams.get("filter");

  const auth = JSON.parse(localStorage.getItem("clinic-u"));
  const clinicId = auth?.clinic[0]?.id;

  const [user, setUser] = useState({});
  const [appointments, setAppointments] = useState([]);
  const [nms, setNms] = useState([]);
  const [loading, setLoading] = useState(false);
  const [page, setPage] = useState(1);
  const [totalRowsAppointments, setTotalRowsAppointments] = useState(0);
  const [totalRowsNMS, setTotalRowsNMS] = useState(0);
  const [perPage, setPerPage] = useState(10);
  const [isDeleteModel, setIsDeleteModel] = useState(false);

  const handlePageChange = (page) => {
    setPage(page);
  };

  const handlePerRowsChange = async (newPerPage, page) => {
    setPerPage(newPerPage);
  };

  const fatchUser = useCallback(() => {
    setLoading(true);
    const params = {
      include: "role,clinic",
    };
    dispatch(retrieveUserById(patientId, params))
      .then((response) => {
        setUser(response.data);
        setLoading(false);
      })
      .catch((error) => {
        toast.error(error.response.data.errors[0].detail);
        setLoading(false);
      });
  }, [patientId, setUser, dispatch]);

  const fetchAppointment = useCallback(() => {
    const params = {
      page: page,
      limit: perPage,
      sort: "updatedAt",
      order: "desc",
      "filter[user.id]": patientId,
      "filter[clinic.id]": clinicId,
      include:
        "clinic,service,clinicService,user,answeredByPatient,answeredByClinic,answeredByClinic.question,answeredByPatient.questions",
    };
    dispatch(retriveAppointments(params))
      .then((result) => {
        setLoading(true);
        setAppointments(result?.data);
        setTotalRowsAppointments(result?.meta?.total);
      })
      .catch((error) => {
        toast.error(error.response.data.errors[0].detail);
        setLoading(true);
      })
      .finally(() => {
        setLoading(false);
      });
  }, [dispatch, setAppointments, page, perPage, patientId, clinicId]);

  const fetchNMS = useCallback(() => {
    const params = {
      page: page,
      limit: perPage,
      sort: "updatedAt",
      order: "desc",
      include: "patient,clinic",
      "filter[patient.id]": patientId,
      "filter[clinic.id]": clinicId,
    };
    dispatch(retriveNMS(params))
      .then((result) => {
        setLoading(true);
        setNms(result?.data);
        setTotalRowsNMS(result?.meta?.total);
      })
      .catch((error) => {
        toast.error(error.response.data.errors[0].detail);
        setLoading(true);
      })
      .finally(() => {
        setLoading(false);
      });
  }, [dispatch, setNms, page, perPage, patientId, clinicId]);

  const onDeletePatient = () => {
    const id = searchParams.get("clinicp");
    setLoading(true);
    dispatch(deleteClinicSelectPatient(id))
      .then(() => {
        toast.success("Patient removed from Clinic");
        navigate("/patients");
        setIsDeleteModel(false);
      })
      .catch((error) => {
        console.log(error, "efji");
        toast.error(error.response.data.errors[0].detail);
        setLoading(false);
      })
      .finally(() => {
        setLoading(false);
      });
  }

  useEffect(() => {
    fatchUser();
    fetchAppointment();
    fetchNMS();
  }, [fatchUser, fetchAppointment, fetchNMS]);

  const AppointmentColumns = [
    {
      name: "Date",
      selector: (row) => row.date,
      sortable: true,
      sortField: 'date',
    },
    {
      name: "Service",
      selector: (row) => row.service.name,
      sortable: true,
      sortField: 'service.name',
    },
    {
      name: "Status",
      selector: (row) => (
        <div>
          {row.status === AppointmentStatus.COMPLETED && (
            <div className="lg:flex lg:items-center">
              <CheckCircle className="w-h-8 h-8" />
              <div>Completed</div>
            </div>
          )}
          {row.status === AppointmentStatus.CANCEL && (
            <div className="text-burnt-sienna">Cancelled</div>
          )}
          {(row.status === AppointmentStatus.BOOKED ||
            row.status === AppointmentStatus.IN_PROGRESS) && (
              <div className="text-cod-gray/30">NA</div>
            )}
        </div>
      ),
    },
    {
      name: "",
      selector: (row) => row.action,
      cell: (row) => (
        <Button
          className="rounded-md bg-indigo/10 text-indigo w-28"
          type="button"
          onClick={() => navigate(`/appointments/${row?.id}`)}
        >
          View
        </Button>
      ),
      show: true,
    },
  ];

  const NmsColumns = [
    {
      name: "Date",
      selector: (row) => moment(row?.createdAt).format("MMM Do YYYY"),
    },
    {
      name: "Medicine",
      selector: (row) => (
        <div>
          {row?.medicines[0]}, {row?.medicines[1]}{" "}
          {row?.medicines?.length - 2 > 0 && (
            <span className="text-indigo">
              +{row?.medicines?.length - 2} more
            </span>
          )}
        </div>
      ),
    },
    {
      name: "1st intervention",
      selector: (row) => (
        <div>
          <div>{moment(row?.firstIntervention).format("DD/MM/YYYY")}</div>
          <div className="text-indigo">
            {moment(row?.firstIntervention).format("hh:mm a")}
          </div>
        </div>
      ),
    },
    {
      name: "2nd follow up",
      selector: (row) => (
        <div>
          {row?.secondFollowUp && (
            <div>
              <div>{moment(row?.secondFollowUp).format("DD/MM/YYYY")}</div>
              <div className="text-indigo">
                {moment(row?.secondFollowUp).format("hh:mm a")}
              </div>
            </div>
          )}
          {!row?.secondFollowUp && <div>-</div>}
        </div>
      ),
    },
    {
      name: "",
      selector: (row) => row?.action,
      cell: (row) => (
        <Button
          className="rounded-md bg-indigo/10 text-indigo w-28"
          type="button"
          onClick={() => navigate(`/nms/${row?.id}`)}
        >
          View
        </Button>
      ),
      show: true,
    },
  ];

  return (
    <section className="ml-6 mr-7 min-h-screen items-center justify-center py-6">
      <div>
        <div className="mb-4 flex items-center justify-between space-x-2">
          <div className="lg:flex lg:items-center lg:justify-center lg:space-x-2">
            <Avatar
              firstName={user?.firstName}
              lastName={user?.lastName}
              image={user?.profileUrl}
            />
            <div className="text-xl font-semibold">
              {user?.firstName} {user?.lastName} {"(NHS: "} {user?.NHS}
              {")"}
            </div>
          </div>
          {useRole([ROLE.CLINIC]) && (
            <div className="space-y-2 md:space-x-2">
              {/* <Button
                color="white"
                className="rounded-lg text-dove-gray"
                type="button"
              >
                Edit
              </Button> */}
              <Button
                color="white"
                className="rounded-lg text-dove-gray"
                type="button"
                onClick={() => setIsDeleteModel(true)}
              >
                Delete
              </Button>
            </div>
          )}
        </div>
        <div>
          <div className="mb-3 w-full rounded-t-lg bg-white px-7 py-7">
            <div>
              <div className="lg:grid lg:grid-cols-5 gap-2">
                <div className="mb-2">
                  <div className="text-indigo">Name</div>
                  <div>
                    {user?.firstName} {user?.lastName}
                  </div>
                </div>
                <div className="mb-2">
                  <div className="text-indigo">Email</div>
                  <div className="max-w-[15rem] break-all">{user?.emailAddress}</div>
                </div>
                <div className="mb-2">
                  <div className="text-indigo">Phone Number</div>
                  <div>{user?.phone}</div>
                </div>
                <div className="mb-2">
                  <div className="text-indigo">Gender</div>
                  <div>{user?.gender}</div>
                </div>
                <div className="mb-2">
                  <div className="text-indigo">Age</div>
                  <div>{moment().diff(`${user?.dob}`, "years")} years</div>
                </div>
              </div>
              <div className="mb-2">
                <div className="text-indigo">Address</div>
                <div>{user?.address || "N/A"}</div>
              </div>
            </div>
          </div>
          <div className="w-full rounded-b-lg bg-white px-7 py-7">
            <div className="space-y-2 lg:flex lg:justify-between">
              <div>
                <div className="rounded-lg bg-alabaster">
                  <Tabs
                    tabs={tabs}
                    filter={filter}
                    setSearchParams={setSearchParams}
                  />
                </div>
              </div>
              <div>
                <Button className="rounded-md bg-indigo text-white" type="button" onClick={() => {
                  if (filter === "appointments") {
                    navigate("/appointments/create")
                  } else if (filter === "nms") {
                    navigate("/nms/create")
                  }
                }}>
                  Create
                </Button>
              </div>
            </div>
            <Modal
              title="Delete"
              maxWidth="32rem"
              show={isDeleteModel}
              onClose={() => setIsDeleteModel(false)}
              showCancel={false}
            >
              <div className="mb-4 text-center font-light">
                {`Are you sure you want to delete this ${user?.emailAddress} from Clinic?`}
              </div>
              <div className="flex items-center justify-center space-x-3">
                <Button
                  type="button"
                  className="rounded-lg text-indigo"
                  outlined
                  onClick={() => setIsDeleteModel(false)}
                  color="indigo"
                >
                  Cancel
                </Button>
                <Button
                  type="button"
                  width="28"
                  className="rounded-lg text-white bg-indigo"
                  loading={loading}
                  onClick={() => onDeletePatient()}
                >
                  Delete
                </Button>
              </div>
            </Modal>
            <div>
              {filter === "appointments" && (
                <DataTable
                  columns={AppointmentColumns}
                  data={appointments}
                  pagination
                  paginationServer
                  paginationTotalRows={totalRowsAppointments}
                  onChangePage={handlePageChange}
                  onChangeRowsPerPage={handlePerRowsChange}
                  progressPending={loading}
                  responsive={true}
                  customStyles={customStyles}
                />
              )}
              {filter === "nms" && (
                <DataTable
                  columns={NmsColumns}
                  data={nms}
                  pagination
                  paginationServer
                  paginationTotalRows={totalRowsNMS}
                  onChangePage={handlePageChange}
                  onChangeRowsPerPage={handlePerRowsChange}
                  progressPending={loading}
                  responsive={true}
                  customStyles={customStyles}
                />
              )}
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default ViewPatient;

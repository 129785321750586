import { Button, Modal } from 'components/ui';
import React, { useCallback, useEffect, useState } from 'react'
import DataTable from 'react-data-table-component';
import toast from 'react-hot-toast';
import { useDispatch } from 'react-redux';
import { AiOutlineDelete, AiOutlineEdit, AiOutlineSearch } from "react-icons/ai";
import { useNavigate } from 'react-router-dom';
import { deleteCategory, retriveCategry } from 'actions/category';
import ViewCategory from './view';
import customStyles from 'utils/data-table-style';

const Category = () => {

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [category, setCategory] = useState([]);
  const [page, setPage] = useState(1);
  const [isLoaded, setIsLoaded] = useState(false);
  const [totalRows, setTotalRows] = useState(0);
  const [perPage, setPerPage] = useState(10);
  const [search, setSearch] = useState();
  const [deleteRow, setDeleteRow] = useState({});
  const [isDeleteModal, setIsDeleteModal] = useState(false);
  const [isViewModalOpen, setIsViewModalOpen] = useState(false);
  const [selectedRow, setSelectedRow] = useState({});

  const handlePageChange = page => {
    setPage(page)
  }

  const handlePerRowsChange = async (newPerPage, page) => {
    setPerPage(newPerPage);
  }

  function handleDelete(row) {
    setDeleteRow(row);
    openDeleteModal();
  }

  const openViewModal = () => {
    setIsViewModalOpen(true)
  }

  const closeViewModal = () => {
    setIsViewModalOpen(false)
  }

  const columns = [
    {
      name: 'Name',
      selector: row => row.name,
    },
    {
      name: "Action",
      selector: row => row.action,
      cell: row =>
        <div className='flex'>
          <div>
            <AiOutlineEdit className='h-6 w-6 sm:h-7 sm:w-7' onClick={() => navigate(`/category/${row?.id}/edit`)} />
          </div>
          <div>
            <AiOutlineDelete className='h-6 w-6 sm:h-7 sm:w-7' onClick={() => handleDelete(row)} />
          </div>
        </div>,
      show: true,
    },
  ];

  const fetchCategory = useCallback(
    () => {
      const params = {
        page: page,
        limit: perPage,
        sort: "updatedAt",
        order: "desc",
        search: search,
        aggregate: "categoryLogo",
        include: "services",
      }
      dispatch(retriveCategry(params)).then((result) => {
        setIsLoaded(true);
        setCategory(result?.data);
        setTotalRows(result?.meta?.total);
      })
        .catch((error) => {
          toast.error(error.response.data.errors[0].detail);
          setIsLoaded(true);
        })
        .finally(() => {
          setIsLoaded(false);
        });
    },
    [dispatch, setCategory, page, perPage, search],
  )

  function openDeleteModal() {
    setIsDeleteModal(true);
  }

  function closeDeleteModal() {
    setIsDeleteModal(false);
  }

  const onDeleteCategory = () => {
    setIsLoaded(true);

    dispatch(deleteCategory(deleteRow.id))
      .then(() => {
        toast.success("Category deleted successfully.");
        fetchCategory();
        closeDeleteModal();
      })
      .catch((error) => {
        toast.error(error.response.data.errors[0].detail);
        setIsLoaded(false);
      })
      .finally(() => {
        setIsLoaded(false);
      });
  };

  useEffect(() => {
    fetchCategory();
  }, [fetchCategory])

  return (
    <section className="min-h-screen ml-6 mr-7 py-6 justify-center items-center">
      <div>
        <div className='md:flex mb-4 justify-between items-center'>
          <div className='text-xl font-semibold'>Category</div>
          <div className='flex space-x-3 fill-alabaster'>
            <div className="relative h-10 w-full xl:w-auto">
              <AiOutlineSearch className="absolute inset-y-0 left-3 my-auto text-storm-gray" />
              <input
                type="text"
                className="w-full pl-9 rounded-md border-none bg-white font-medium focus:ring-0 xl:w-[320px]"
                placeholder="Search"
                onChange={(e) => {
                  setSearch(e.target.value);
                }}
              />
            </div>
            <div>
              <Button
                type="button"
                className="bg-indigo text-white rounded-md"
                width="full"
                text="base"
                onClick={() => navigate("/category/create")}
              >
                Add
              </Button>
            </div>
          </div>
        </div>
        <div className="rounded-lg w-auto">
          <DataTable
            columns={columns}
            data={category}
            pagination
            paginationServer
            onRowClicked={(row) => {
              openViewModal();
              setSelectedRow(row);
            }}
            paginationTotalRows={totalRows}
            onChangePage={handlePageChange}
            onChangeRowsPerPage={handlePerRowsChange}
            progressPending={isLoaded}
            responsive={true}
            customStyles={customStyles}
          />
        </div>

        {isViewModalOpen && (<ViewCategory isOpen={isViewModalOpen} category={selectedRow} closeModal={closeViewModal} />)}

        <Modal
          title="Delete"
          maxWidth="32rem"
          show={isDeleteModal}
          onClose={openDeleteModal}
          showCancel={false}
        >
          <div className="mb-4 text-center font-light">
            {`Are you sure you want to delete this ${deleteRow?.name}?`}
          </div>
          <div className="mb-10 text-center font-light">
            You won't be able to revert this!
          </div>
          <div className="flex items-center justify-center space-x-3">
            <Button type="button" className="rounded-lg text-indigo" outlined onClick={closeDeleteModal}>
              Cancel
            </Button>
            <Button
              type="button"
              width="28"
              className="rounded-lg text-white bg-indigo"
              loading={isLoaded}
              onClick={onDeleteCategory}
            >
              Delete
            </Button>
          </div>
        </Modal>
      </div>

    </section>
  );
}

export default Category
import DashboardService from "services/dasboard";
import {
    CLINIC_LOGO,
    DASHBOARD_COUNTS
} from "./type";

export const getAppointmentCounts = (id, data) => async (dispatch) => {
    try {
        const res = await DashboardService.getCounts(id, data);

        dispatch({
            type: DASHBOARD_COUNTS,
            payload: res.data,
        });

        return Promise.resolve(res.data);
    } catch (error) {
        return Promise.reject(error);
    }
};

export const getClinicLogo = (id) => async (dispatch) => {
    try {
        const res = await DashboardService.getClinicLogo(id);

        dispatch({
            type: CLINIC_LOGO,
            payload: res.data,
        });

        return Promise.resolve(res.data);
    } catch (error) {
        return Promise.reject(error);
    }
};
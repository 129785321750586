import OurClientsService from "services/our-clients";
import {
    GET_ALL_OUR_CLINETS,
    CREATE_OUR_CLINET,
    UPDATE_OUR_CLINET,
    REMOVE_OUR_CLINET,
    GET_ONE_OUR_CLINET,
} from "./type";

export const createOurClient = (data) => async (dispatch) => {
    try {
        const res = await OurClientsService.create(data);

        dispatch({
            type: CREATE_OUR_CLINET,
            payload: res.data,
        });

        return Promise.resolve(res.data);
    } catch (error) {
        return Promise.reject(error);
    }
};

export const updateOurClient = (id, data) => async (dispatch) => {
    try {
        const res = await OurClientsService.update(id, data);

        dispatch({
            type: UPDATE_OUR_CLINET,
            payload: res.data,
        });

        return Promise.resolve(res.data);
    } catch (error) {
        return Promise.reject(error);
    }
};

export const deleteOurClient = (id) => async (dispatch) => {
    try {
        const res = await OurClientsService.remove(id);

        dispatch({
            type: REMOVE_OUR_CLINET,
            payload: res.data,
        });

        return Promise.resolve(res.data);
    } catch (error) {
        return Promise.reject(error);
    }
};

export const retrieveOneOurClient = (id, params) => async (dispatch) => {
    try {
        const res = await OurClientsService.getOne(id, params);

        dispatch({
            type: GET_ONE_OUR_CLINET,
            payload: res.data,
        });

        return Promise.resolve(res.data);
    } catch (error) {
        return Promise.reject(error);
    }
};

export const retriveOurClients = (data) => async (dispatch) => {
    try {
        const res = await OurClientsService.getAll(data);

        dispatch({
            type: GET_ALL_OUR_CLINETS,
            payload: res.data,
        });

        return Promise.resolve(res.data);
    } catch (error) {
        return Promise.reject(error);
    }
};

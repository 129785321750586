import { retriveNMS } from "actions/nms";
import { Avatar, Button } from "components/ui";
import moment from "moment";
import React, { useCallback, useEffect, useState } from "react";
import DataTable from "react-data-table-component";
import toast from "react-hot-toast";
import { useDispatch } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import customStyles from "utils/data-table-style";

const NMS = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const auth = JSON.parse(localStorage.getItem("clinic-u"));
  const clinicId = auth?.clinic[0]?.id;

  const [nms, setNms] = useState([]);
  const [page, setPage] = useState(1);
  const [isLoaded, setIsLoaded] = useState(false);
  const [totalRows, setTotalRows] = useState(0);
  const [perPage, setPerPage] = useState(5);

  const handlePageChange = (page) => {
    setPage(page);
  };

  const handlePerRowsChange = async (newPerPage, page) => {
    setPerPage(newPerPage);
  };

  const columns = [
    {
      name: "Date",
      selector: (row) => moment(row?.createdAt).format("MMM Do YYYY"),
    },
    {
      name: "Patient’s name",
      cell: (row) => (
        <div className="lg:flex items-center">
          <Avatar
            firstName={row?.patient?.firstName}
            lastName={row?.patient?.lastName}
            image=""
          />
          <div className="ml-1">
            {row?.patient?.firstName} {row?.patient?.lastName}
          </div>
        </div>
      ),
      minWidth: "200px"
    },
    {
      name: "Medicine",
      selector: (row) => (
        <div>
          {row?.medicines[0]}, {row?.medicines[1]}{" "}
          {row?.medicines?.length - 2 > 0 && (
            <span className="text-indigo">
              +{row?.medicines?.length - 2} more
            </span>
          )}
        </div>
      ),
    },
    {
      name: "1st intervention",
      selector: (row) => (
        <div>
          <div>{moment(row?.firstIntervention).format("DD/MM/YYYY")}</div>
          <div className="text-indigo">
            {moment(row?.firstIntervention).format("hh:mm a")}
          </div>
        </div>
      ),
    },
    {
      name: "2nd follow up",
      selector: (row) => (
        <div>
          {row?.secondFollowUp && (
            <div>
              <div>{moment(row?.secondFollowUp).format("DD/MM/YYYY")}</div>
              <div className="text-indigo">
                {moment(row?.secondFollowUp).format("hh:mm a")}
              </div>
            </div>
          )}
          {!row?.secondFollowUp && <div>-</div>}
        </div>
      ),
    },
    {
      name: "",
      selector: (row) => row?.action,
      cell: (row) => (
        <Button
          className="rounded-md bg-indigo/10 text-indigo w-28"
          type="button"
          onClick={() => navigate(`/nms/${row?.id}`)}
        >
          View
        </Button>
      ),
      show: true,
    },
  ];

  const fetchNMS = useCallback(() => {
    const params = {
      page: page,
      limit: perPage,
      sort: "updatedAt",
      order: "desc",
      include: "patient,clinic",
      "filter[clinic.id]": clinicId,
    };
    dispatch(retriveNMS(params))
      .then((result) => {
        setIsLoaded(true);
        setNms(result?.data);
        setTotalRows(result?.meta?.total);
      })
      .catch((error) => {
        toast.error(error.response.data.errors[0].detail);
        setIsLoaded(true);
      })
      .finally(() => {
        setIsLoaded(false);
      });
  }, [dispatch, setNms, page, perPage, clinicId]);

  useEffect(() => {
    fetchNMS();
  }, [fetchNMS]);

  return (
    <div>
      <div className="flex items-center justify-between pb-6">
        <h5 className="text-xl font-semibold">
          Upcoming NMS interventions / Follow ups
        </h5>
        <Link t0="/nms" className="text-lg text-indigo">
          View All
        </Link>
      </div>
      <div className="w-auto rounded-lg bg-white px-7 py-8 pt-6 pb-9">
        <DataTable
          columns={columns}
          data={nms}
          pagination
          paginationServer
          paginationTotalRows={totalRows}
          onChangePage={handlePageChange}
          onChangeRowsPerPage={handlePerRowsChange}
          progressPending={isLoaded}
          responsive={true}
          paginationPerPage={5}
          paginationRowsPerPageOptions={[5, 10, 25, 50, 100]}
          customStyles={customStyles}
        />
      </div>
    </div>
  );
};

export default NMS;

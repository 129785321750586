import React, { useState } from "react";
import { Input, Switch } from "components/common";
import "./openingHoursWidget.css";
const OpeningHoursWidget = (props) => {
  const { onOpeningHoursUpdate, register, errors } = props;
  const { name, isActive } = props.openingHours;
  const [available, setAvailable] = useState(isActive);
  const onInputClick = (event) => {
    event.target.value = "";
  };

  return (
    <div className="grid grid-cols-1 md:grid-cols-2">
    
    <div className="mb-2 grid grid-cols-3 md:grid-cols-3 md:items-center">
      <div className="font-medium">{name}</div>
      <Switch
        isOn={available}
        onToggle={() => {
          let openingHoursTobeUpdated = { ...props.openingHours };
          openingHoursTobeUpdated.isActive = !available;
          onOpeningHoursUpdate(openingHoursTobeUpdated);
          setAvailable(!available);
        }}
      />
      <div>{available ? "open" : "closed"}</div>
      {/* <div className="grid w-28 flex-initial grid-cols-2 md:flex">
          <Switch
            isOn={available}
            onToggle={() => {
              let openingHoursTobeUpdated = { ...props.openingHours };
              openingHoursTobeUpdated.isActive = !available;
              onOpeningHoursUpdate(openingHoursTobeUpdated);
              setAvailable(!available);
            }}
          />
        <div className="flex-initial md:flex">
          <label className="pl-2">{available ? "open" : "closed"}</label>
        </div>
      </div> */}
    </div>
      {available && (
        <>
          <div className="mb-2 md:flex md:items-center grid grid-cols-1">
            <div>FROM</div>
            <Input
              register={register}
              onChange={(e) => {
                const { value } = e.target;
                let openingHoursTobeUpdated = { ...props.openingHours };
                openingHoursTobeUpdated.clinicHours[0].from = value;
                onOpeningHoursUpdate(openingHoursTobeUpdated);
              }}
              onClick={onInputClick}
              errors={errors}
              apiKey={`from-${name}`}
              type="time"
              containerClassName={"p-0"}
              className="w-auto border-none bg-alabaster"
              errorMsg={"From time is required"}
            />
            <div>TO</div>
            <Input
              register={register}
              type="time"
              onChange={(e) => {
                const { value } = e.target;
                let openingHoursTobeUpdated = { ...props.openingHours };
                openingHoursTobeUpdated.clinicHours[0].to = value;
                onOpeningHoursUpdate(openingHoursTobeUpdated);
              }}
              onClick={onInputClick}
              errors={errors}
              apiKey={`to-${name}`}
              containerClassName={"p-0"}
              className="w-auto border-none bg-alabaster"
              errorMsg={"To time is required"}
            />
          </div>
        </>
      )}
    </div>
  );
};

export default OpeningHoursWidget;

export const ROLE = {
  ADMIN: "admin",
  CLINIC: "clinic",
};

export const weekDayLabel = [
  "Sunday",
  "Monday",
  "Tuesday",
  "Wednesday",
  "Thursday",
  "Friday",
  "Saturday",
];

export const AppointmentStatus = {
  IN_PROGRESS: 'in_progress',
  COMPLETED: 'completed',
  CANCEL: 'Cancelled',
  BOOKED: 'booked',
}

export const SubscriptionStatus = {
  ACTIVE: 'active',
  PAST_DUE: 'past_due',
  UNPAID: 'unpaid',
  CANCELED: 'canceled',
  INCOMPLETE: 'incomplete',
  INCOMPLETE_EXPIRED: 'incomplete_expired',
  TRIALING: 'trialing',
}

import React from 'react'
import { Link } from 'react-router-dom'

const Footer = () => {
    return (
        <section className="mt-6 bg-indigo">
            <div className="max-w-7xl mx-auto py-7 px-16">
                <div className="lg:flex lg:justify-between items-center">
                    <Link to="/">
                        <div className="flex items-center mb-3">
                            <img src="/img/logo/darkLogo.png" alt="logo" className="h-10 w-44 object-contain" />
                        </div>
                    </Link>
                    <div className="lg:flex lg:space-x-7 lg:items-center">
                        <div className="text-white text-lg font-medium">
                            © 2023 Clinic2Go. All rights reserved!
                        </div>
                        <Link to="/termsandconditions">
                            <div className="text-white text-lg font-medium underline">
                                Terms of use
                            </div>
                        </Link>
                        <Link to="/privacypolicy">
                            <div className="text-white text-lg font-medium underline">
                                Privacy Policy
                            </div>
                        </Link>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default Footer

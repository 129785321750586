import React from "react";
import './centerCard.css'
const CenterCard = (props) => {
  return (
    <section className="md:flex bg-[#F3F4F7] md:items-center justify-center outer-container min-h-screen">
        <div className="md:inner-container bg-white rounded-lg">
          {props.children}
        </div>
    </section>
  );
}

export default CenterCard;

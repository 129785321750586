import React, { useState } from "react";
import { Header, CenterCard } from "components/common";
import ProgressBar from "components/common/ProgressBar/ProgressBar";
import Step1ofClinic from "pages/StepsOfClinic/step1OfClinic";
import Step2ofClinic from "pages/StepsOfClinic/step2OfClinic";
import Step3ofClinic from "pages/StepsOfClinic/step3OfClinic";
import Step4ofClinic from "pages/StepsOfClinic/step4OfClinic";

function CreateClinic() {
  const [selectedIndex, setSelectedIndex] = useState(0);
  const [logoObject, setLogoObject] = useState({});

  return (
    <div>
      <Header />
      <CenterCard>
        <div>
          <ProgressBar selectedIndex={selectedIndex} />
          <div className="my-2 rounded-md bg-white px-4 py-2">
            {selectedIndex === 0 && (
              <Step1ofClinic setLogoObject={setLogoObject} setSelectedIndex={setSelectedIndex} />
            )}
            {selectedIndex === 1 && (
              <Step2ofClinic setSelectedIndex={setSelectedIndex} />
            )}
            {selectedIndex === 2 && (
              <Step3ofClinic setSelectedIndex={setSelectedIndex} />
            )}
            {selectedIndex === 3 && (
              <Step4ofClinic logoObject={logoObject} setSelectedIndex={setSelectedIndex} />
            )}
          </div>
        </div>
      </CenterCard>
    </div>
  );
}

export default CreateClinic;

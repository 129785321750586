// AUTH
export const SIGNUP = "SIGNUP";
export const SIGNIN = "SIGNIN";
export const RETRIEVE_USER_BY_ID = "RETRIEVE_USER_BY_ID";
export const UPDATE_USER = "UPDATE_USER";
export const RENEWTOKEN = "RENEWTOKEN";
export const FORGOT_PASSWORD = "FORGOT_PASSWORD";
export const FORGOT_PASSWORD_RESET = "FORGOT_PASSWORD_RESET";
export const GET_ALL_USER = "GET_ALL_USER";
export const CHANGE_PASSWORD = "CHANGE_PASSWORD";

//SERVICE
export const GET_ALL_SERVICES = "GET_ALL_SERVICES";
export const GET_ALL_SERVICES_1 = "GET_ALL_SERVICES_1";
export const CREATE_SERVICES = "CREATE_SERVICES";
export const UPDATE_SERVICES = "UPDATE_SERVICES";
export const REMOVE_SERVICES = "REMOVE_SERVICES";
export const GET_ONE_SERVICES = "GET_ONE_SERVICES";

//CLINIC
export const GET_ALL_CLINICS = "GET_ALL_CLINICS";
export const CREATE_CLINICS = "CREATE_CLINICS";
export const UPDATE_CLINICS = "UPDATE_CLINICS";
export const REMOVE_CLINICS = "REMOVE_CLINICS";
export const GET_ONE_CLINICS = "GET_ONE_CLINICS";
export const GET_SIGNED_URL_FOR_CLINIC = "GET_SIGNED_URL_FOR_CLINIC";
export const GET_ACCESS_URL_FOR_CLINIC = "GET_ACCESS_URL_FOR_CLINIC";
export const GET_VERIFIED = "GET_VERIFIED";

//CLINIC-SERVICE
export const UPDATE_CLINICS_SERVICE = "UPDATE_CLINICS_SERVICE";
export const CREATE_CLINICS_SERVICE = "CREATE_CLINICS_SERVICE";
export const GET_ONE_CLINICS_SERVICE = "GET_ONE_CLINICS_SERVICE";
export const UPDATE_ACTIVE_CLINICS_SERVICE = "UPDATE_ACTIVE_CLINICS_SERVICE";

//CATEGORY
export const GET_ALL_CATEGORY = "GET_ALL_CATEGORY";
export const CREATE_CATEGORY = "CREATE_CATEGORY";
export const UPDATE_CATEGORY = "UPDATE_CATEGORY";
export const REMOVE_CATEGORY = "REMOVE_CATEGORY";
export const GET_ONE_CATEGORY = "GET_ONE_CATEGORY";

//FILE UPLOAD
export const GET_SIGNED_URL = "GET_SIGNED_URL";
export const FILE_UPLOAD = "FILE_UPLOAD";

//PATIENT
export const CREATE_PATIENT = "CREATE_PATIENT";

//NMS
export const GET_ALL_NMS = "GET_ALL_NMS";
export const CREATE_NMS = "CREATE_NMS";
export const UPDATE_NMS = "UPDATE_NMS";
export const REMOVE_NMS = "REMOVE_NMS";
export const GET_ONE_NMS = "GET_ONE_NMS";

//APPOINTMENT
export const GET_ALL_APPOINTMENT = "GET_ALL_APPOINTMENT";
export const CREATE_APPOINTMENT = "CREATE_APPOINTMENT";
export const UPDATE_APPOINTMENT = "UPDATE_APPOINTMENT";
export const CANCEL_APPOINTMENT = "CANCEL_APPOINTMENT";
export const GET_ONE_APPOINTMENT = "GET_ONE_APPOINTMENT";
export const GENERATE_APPOINTMENT_SLOTS = "GENERATE_APPOINTMENT_SLOTS";

//GP-EMAIL-REPORT
export const GP_EMAIL_REPORT = "GP_EMAIL_REPORT";

//CLINIC_SELECT_PATIENT
export const GET_ALL_PATIENTS = "GET_ALL_PATIENTS";
export const SELECT_PATIENT = "SELECT_PATIENT";
export const DELETE_PATIENT_CLINIC = "DELETE_PATIENT_CLINIC";

//CLINIC_STRIPE
export const GET_CLINIC_STRIPE_FLAGE = "GET_CLINIC_STRIPE_FLAGE";
export const CLINIC_STRIPE_CREATE = "CLINIC_STRIPE_CREATE";

//SUBSCRIPTION
export const GET_ALL_PRICE = "GET_ALL_PRICE";
export const SUBSCRIPTION_CHECK_OUT = "SUBSCRIPTION_CHECK_OUT";
export const GET_ALL_SUBSCRIPTION = "GET_ALL_SUBSCRIPTION";
export const GET_ALL_SUBSCRIPTION_INVOICE = "GET_ALL_SUBSCRIPTION_INVOICE";
export const SUBSCRIPTION_CANCEL = "SUBSCRIPTION_CANCEL";

//DASHBOARD_COUNTS
export const DASHBOARD_COUNTS = "DASHBOARD_COUNTS";
export const CLINIC_LOGO = "CLINIC_LOGO";

//CONTACT_US
export const GET_CONTACT_US = "GET_CONTACT_US";
export const CREATE_CONTACT_US = "CREATE_CONTACT_US";

//BLOG
export const GET_ALL_BLOGS = "GET_ALL_BLOGS";
export const CREATE_BLOGS = "CREATE_BLOGS";
export const UPDATE_BLOGS = "UPDATE_BLOGS";
export const REMOVE_BLOGS = "REMOVE_BLOGS";
export const GET_ONE_BLOGS = "GET_ONE_BLOGS";

//OUR_CLINET
export const GET_ALL_OUR_CLINETS = "GET_ALL_OUR_CLINETS";
export const CREATE_OUR_CLINET = "CREATE_OUR_CLINET";
export const UPDATE_OUR_CLINET = "UPDATE_OUR_CLINET";
export const REMOVE_OUR_CLINET = "REMOVE_OUR_CLINET";
export const GET_ONE_OUR_CLINET = "GET_ONE_OUR_CLINET";

//APP_SLIDER
export const GET_ALL_SLIDER = "GET_ALL_SLIDER";
export const CREATE_SLIDER = "CREATE_SLIDER,";
export const UPDATE_SLIDER = "UPDATE_SLIDER,";
export const REMOVE_SLIDER = "REMOVE_SLIDER,";
export const GET_ONE_SLIDER = "GET_ONE_SLIDER";
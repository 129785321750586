import { createSignedUrl, uploadFile } from 'actions/fileUpload';
import { createOurClient } from 'actions/our-clients';
import { Button } from 'components/ui';
import React, { useState } from 'react'
import { useForm } from 'react-hook-form';
import { toast } from 'react-hot-toast';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';

const CreateOurClients = () => {

    const dispatch = useDispatch();
    const navigate = useNavigate();

    const [loading, setLoading] = useState(false)

    const {
        register,
        handleSubmit,
        formState: { errors },
    } = useForm();

    const onSubmit = async (data) => {
        setLoading(true)
        let s3Key
        if (data?.image) {
            const body = {
                fileName: data?.image[0]?.name,
                mimeType: data?.image[0]?.type,
                directory: "document"

            }
            await dispatch(createSignedUrl(body)).then(async (result) => {
                s3Key = result?.s3Key
                dispatch(uploadFile(result?.signedUrl, data?.image[0]));
                data.image = s3Key;
            })
                .catch((error) => {
                    toast.error(error.response.data.errors[0].detail);
                })
        }
        await dispatch(createOurClient(data)).then((result) => {
            setLoading(false);
            toast.success("OurClient Create Successfully");
            navigate("/our-client")
        })
            .catch((error) => {
                setLoading(false);
                toast.error(error.response.data.errors[0].detail);
            })
    }

    return (
        <section className="ml-6 mr-7 min-h-screen items-center justify-center py-6">
            <div>
                <div className="mb-4">
                    <div className="text-xl font-semibold">Create OurClient</div>
                </div>
                <div>
                    <form className="pt-8 pb-16" onSubmit={handleSubmit(onSubmit)}>
                        <div className="mb-6 w-auto rounded-t-lg bg-white px-4 py-4 lg:px-7 lg:py-7">
                            <div className="flex flex-col mb-3">
                                <div className="mb-1.5 font-medium text-independence">
                                    Client Name
                                </div>
                                <input
                                    type="text"
                                    className="bg-alabaster border-none w-auto rounded-md"
                                    {...register("name", { required: true })}
                                />
                                {errors?.name?.type === "required" && (
                                    <div className="mt-1 text-sm text-burnt-sienna">
                                        Name Of client is required.
                                    </div>
                                )}
                            </div>
                            <div className="flex flex-col mb-3">
                                <div className="mb-1.5 font-medium">
                                    Image For Client
                                </div>
                                <input
                                    type="file"
                                    placeholder="e.g. Sign Permit"
                                    className="bg-alabaster border-none w-auto rounded-md"
                                    {...register("image", { required: true })}
                                />
                                {errors?.image?.type === "required" && (
                                    <div className="mt-1 text-sm text-burnt-sienna">
                                        Image For Client is required.
                                    </div>
                                )}
                            </div>
                        </div>
                        <Button
                            type="submit"
                            className="bg-indigo text-white h-12 rounded-lg"
                            text="lg"
                            loading={loading}
                        >
                            Save
                        </Button>
                    </form>
                </div>
            </div>
        </section>
    )
}

export default CreateOurClients
import authHeader from "auth-header";
import httpCommon from "http-common";

const create = (data) => {
  return httpCommon.post("/slider", data, { headers: authHeader() });
};

const update = (id, data) => {
  return httpCommon.patch(`/slider/${id}`, data, { headers: authHeader() });
};

const remove = (id) => {
  return httpCommon.delete(`/slider/${id}`, { headers: authHeader() });
};

const getOne = (id, params) => {
  const config = {
    headers: authHeader(),
    params: params,
  };
  return httpCommon.get(`/slider/${id}`, config);
};

const getAll = async (params) => {
  const config = {
    params: params,
  };

  const response = await httpCommon.get(`/sliders`, config);
  return response;
};

const SliderService = {
  create,
  update,
  remove,
  getOne,
  getAll,
};

export default SliderService;

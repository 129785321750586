import { retriveContactUs } from "actions/contact-us";
import { Button, Modal } from "components/ui";
import moment from "moment";
import React, { useCallback, useEffect, useState } from "react";
import DataTable from "react-data-table-component";
import toast from "react-hot-toast";
import { useDispatch } from "react-redux";
import customStyles from "utils/data-table-style";

const ContactUs = () => {
    const dispatch = useDispatch();

    const [contactUs, setContactUs] = useState([]);
    const [page, setPage] = useState(1);
    const [isLoaded, setIsLoaded] = useState(false);
    const [isViewModalOpen, setIsViewModalOpen] = useState(false);
    const [selectedRow, setSelectedRow] = useState({});
    const [totalRows, setTotalRows] = useState(0);
    const [perPage, setPerPage] = useState(10);

    const handlePageChange = (page) => {
        setPage(page);
    };

    const handlePerRowsChange = async (newPerPage, page) => {
        setPerPage(newPerPage);
    };

    const columns = [
        {
            name: "Email",
            selector: (row) => row?.email,
        },
        {
            name: "Message",
            cell: (row) => (
                <div className="line-clamp-3">
                    {row?.message}
                </div>
            )
        },
        {
            name: "Created On",
            selector: (row) => moment(row?.createdAt).format("MMM Do YYYY"),
        },
    ];

    const fetchContactUs = useCallback(() => {
        const params = {
            page: page,
            limit: perPage,
            sort: "createdAt",
            order: "desc",
        };
        dispatch(retriveContactUs(params))
            .then((result) => {
                setIsLoaded(true);
                setContactUs(result?.data);
                setTotalRows(result?.meta?.total);
            })
            .catch((error) => {
                toast.error(error.response.data.errors[0].detail);
                setIsLoaded(true);
            })
            .finally(() => {
                setIsLoaded(false);
            });
    }, [dispatch, setContactUs, page, perPage]);

    useEffect(() => {
        fetchContactUs();
    }, [fetchContactUs]);

    return (
        <section className="ml-6 mr-7 min-h-screen items-center justify-center py-6">
            <div>
                <div className="flex mb-4 justify-between items-center">
                    <div className="text-xl font-semibold">Contact Us</div>
                </div>
                <div className="w-full rounded-lg bg-white py-7 px-8">
                    <div className="w-auto rounded-lg">
                        <DataTable
                            columns={columns}
                            data={contactUs}
                            pagination
                            paginationServer
                            paginationTotalRows={totalRows}
                            onChangePage={handlePageChange}
                            onChangeRowsPerPage={handlePerRowsChange}
                            progressPending={isLoaded}
                            responsive={true}
                            onRowClicked={(row) => {
                                setSelectedRow(row)
                                setIsViewModalOpen(true);
                            }}
                            customStyles={customStyles}
                        />
                    </div>
                </div>
                <Modal
                    title="View Details"
                    maxWidth="32rem"
                    show={isViewModalOpen}
                    onClose={() => setIsViewModalOpen(false)}
                    showCancel={false}
                >
                    <div className="mb-4 font-light">
                        <div>
                            <div className="font-semibold">Email:</div>
                            <div>{selectedRow?.email}</div>
                        </div>
                        <div>
                            <div className="font-semibold">Messgae:</div>
                            <div className="overflow-scroll">{selectedRow?.message}</div>
                        </div>
                    </div>
                    <div className="flex items-center justify-center">
                        <Button
                            type="button"
                            className="rounded-lg text-indigo"
                            outlined
                            onClick={() => setIsViewModalOpen(false)}
                        >
                            Cancel
                        </Button>
                    </div>
                </Modal>
            </div>
        </section>
    );
};

export default ContactUs
import { AppContext } from "context/AppContext";
import { useContext, useEffect } from "react";

const useRole = (roles) => {
  const { setRole } = useContext(AppContext);
  const token = JSON.parse(localStorage.getItem("clinic-u"));

  useEffect(() => {
    setRole(token?.role?.name);
  }, [setRole, token?.role?.name]);

  const role = roles.includes(token?.role?.name);

  return role;
};

export default useRole;

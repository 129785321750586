import Image from "services/image";
import { GET_SIGNED_URL_FOR_CLINIC, GET_ACCESS_URL_FOR_CLINIC } from "./type";

export const uploadImage = (data) => async (dispatch) => {
  try {
    const res = await Image.uploadImage(data);

    

    return Promise.resolve(res.data);
  } catch (error) {
    return Promise.reject(error);
  }
};

export const viewImage = (data) => async (dispatch) => {
  try {
    const res = await Image.viewImage(data);

    dispatch({
      type: GET_ACCESS_URL_FOR_CLINIC,
      payload: res.data,
    });

    return Promise.resolve(res.data);
  } catch (error) {
    return Promise.reject(error);
  }
};

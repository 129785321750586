import { cancelAppointment, retrieveOneAppointment } from "actions/appointment";
import { sendEmailReportToGP } from "actions/gpEmailReport";
import clsx from "clsx";
import { Avatar, Button, Modal } from "components/ui";
import _ from "lodash";
import React, { useCallback, useEffect, useState } from "react";
import toast from "react-hot-toast";
import { useDispatch } from "react-redux";

import { useNavigate, useParams } from "react-router-dom";
import { AppointmentStatus } from "utils/roles";
import CompleteAppontment from "./completeAppointment";
import EditPatientAnswer from "./editPatientAnswer";

const ViewAppointment = () => {
  const { appointmentId } = useParams();

  const [appointment, setAppointment] = useState();
  const [isCancelModal, setIsCancelModal] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [cancelReason, setCancelReason] = useState("");
  const [cancelReasonError, setCancelReasonError] = useState(false);
  const [completeAppointmentModel, setCompleteAppointmentModel] =
    useState(false);
  const [editPatientAnswerModel, setEditPatientAnswerModel] = useState(false);
  const [isGPReportModal, setIsGPReportModal] = useState(false);
  const [gpEmail, setGPEmail] = useState("");
  const [gpEmailError, setGPEmailError] = useState(false);
  const [gpEmailConsent, setGPEmailConsent] = useState(false);
  const [gpEmailConsentError, setGPEmailConsentError] = useState(false);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const fetchAppointment = useCallback(() => {
    const params = {
      include:
        "clinic,service,clinicService,user,answeredByPatient,answeredByClinic,answeredByClinic.question,answeredByPatient.questions,appointmentCancel,service.questionForClinic",
    };
    dispatch(retrieveOneAppointment(appointmentId, params))
      .then((result) => {
        setAppointment(result);
      })
      .catch((error) => {
        toast.error(error.response.data.errors[0].detail);
      });
  }, [dispatch, setAppointment, appointmentId]);

  function openCancelModal() {
    setIsCancelModal(true);
  }

  function closeCancelModal() {
    setIsCancelModal(false);
  }

  const onSendEmailToGP = () => {
    if (gpEmailConsent) {
      setGPEmailConsentError(false)
      setIsLoading(true);
      if (gpEmail === "") {
        setGPEmailError(true);
        setIsLoading(false);
      } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(gpEmail)) {
        setGPEmailError(true);
      } else {
        setGPEmailError(false);
        const body = {
          emailAddress: gpEmail,
          appointment: appointment,
        };
        dispatch(sendEmailReportToGP(body))
          .then((result) => {
            setIsLoading(false);
            toast.success("Report Send to GP Successfully");
            navigate("/appointments");
          })
          .catch((error) => {
            toast.error(error.response.data.errors[0].detail);
            setIsLoading(false);
          });
      }
    }else{
      setGPEmailConsentError(true)
    }
  };

  const onCancelAppointment = () => {
    setIsLoading(true);
    if (cancelReason === "") {
      setCancelReasonError(true);
      setIsLoading(false);
    } else {
      const body = {
        cancelReason: cancelReason,
      };
      dispatch(cancelAppointment(appointmentId, body))
        .then((result) => {
          setIsLoading(false);
          toast.success("Appointment Cancelled Successfully");
          navigate("/appointments");
        })
        .catch((error) => {
          toast.error(error.response.data.errors[0].detail);
          setIsLoading(false);
        });
    }
  };
  useEffect(() => {
    fetchAppointment();
  }, [fetchAppointment]);

  return (
    <section className="ml-6 mr-7 min-h-screen items-center justify-center py-6">
      <div>
        {/* <div className="mb-4 lg:flex lg:justify-between">
          <div className="items-center text-xl font-semibold lg:flex">
            {auth !== null && (
              <div className="flex items-center">
                <Avatar
                  firstName={appointment?.user?.firstName}
                  lastName={appointment?.user?.lastName}
                  image={appointment?.user?.profilePictureUrl}
                />
              </div>
            )}
            <h1 className="ml-1">
              {appointment?.user?.firstName} {appointment?.user?.lastName}
            </h1>
            <div className="px-1">/</div>
            <h1>
              {appointment?.service?.name} (NHS: {appointment?.user?.NHS})
            </h1>
          </div>
          <div className="lg:flex lg:items-center lg:space-x-3">
            <h1 className="text-indigo">Fee: £{appointment?.paymentAmount}</h1>
            {appointment?.status === AppointmentStatus.COMPLETED && (
              <div className="lg:flex lg:items-center lg:space-x-3">
                <div className="mb-2 lg:mb-0">
                  <Button
                    type="button"
                    outlined
                    color="indigo"
                    className="h-16 rounded-lg px-7 py-4 text-base lg:h-12"
                    text="lg"
                  >
                    Download report
                  </Button>
                </div>
                <div className="mb-2 lg:mb-0">
                  <Button
                    type="button"
                    color="white"
                    className="h-16 rounded-lg bg-indigo lg:h-12"
                    text="lg"
                    onClick={() => setIsGPReportModal(true)}
                  >
                    Send report to GP
                  </Button>
                </div>
              </div>
            )}
          </div>
        </div> */}
        <div className="mb-4 flex items-center justify-between">
          <div className="items-center xl:flex">
            <div className="text-xl">
              {appointment?.user && (
                <div className="flex items-center">
                  <Avatar
                    firstName={appointment?.user?.firstName}
                    lastName={appointment?.user?.lastName}
                  />
                </div>
              )}
            </div>
            <div className="text-xl font-semibold md:ml-1">
              {appointment?.user?.firstName} {appointment?.user?.lastName} /
              {appointment?.service?.name}
            </div>
            <div className="text-md font-normal md:ml-1">
              (NHS: {appointment?.user?.NHS})
            </div>
          </div>
          <div>
            <div className="lg:flex lg:items-center lg:space-x-3">
              <h1 className="text-indigo">
                Fee: £{appointment?.paymentAmount}
              </h1>
              {appointment?.status === AppointmentStatus.COMPLETED && (
                <div className="lg:flex lg:items-center lg:space-x-3">
                  <div className="mb-2 lg:mb-0">
                    <Button
                      type="button"
                      outlined
                      color="indigo"
                      className="h-12 rounded-lg text-base w-40 lg:w-52"
                      text="lg"
                      onClick={() => navigate(`/appointments/report/${appointmentId}`)}
                    >
                      Download report
                    </Button>
                  </div>
                  <div className="mb-2 lg:mb-0">
                    <Button
                      type="button"
                      color="indigo"
                      className="h-12 rounded-lg text-base w-40 lg:w-52 bg-indigo text-white"
                      text="lg"
                      onClick={() => setIsGPReportModal(true)}
                    >
                      Send report to GP
                    </Button>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
        <div>
          <Modal
            title=""
            maxWidth="32rem"
            show={isGPReportModal}
            onClose={() => setIsGPReportModal(false)}
            onCancel={() => setIsGPReportModal(false)}
            showCancel={true}
          >
            <div className="flex justify-center">
              <h1 className="mb-3 text-xl text-indigo">Send report to GP</h1>
            </div>
            <div className="mb-4 text-center text-lg font-light">
              Please enter the email of {appointment?.user?.firstName ? appointment?.user?.firstName : ""} {appointment?.user?.lastName ? appointment?.user?.lastName : ""}’s GP and we’ll send this report.
            </div>
            <input
              type="email"
              onChange={(e) => setGPEmail(e.target.value)}
              placeholder="Enter email"
              className="mb-4 w-full resize-none rounded-md border-none bg-alabaster focus:ring-0"
            />
            {gpEmailError && (
              <div className="mt-1 text-sm text-burnt-sienna">
                GP Email is Invalid.
              </div>
            )}
            <input
              id="consent-check"
              name="consent-check"
              type="checkbox"
              className="mr-2"
              onClick={(e) => setGPEmailConsent(e.target.checked)}
            />
            <label
              htmlFor="consent-check"
              className="select-none font-light"
            >
              I consent to share this document with my GP.
            </label>
            {gpEmailConsentError && (
              <div className="mt-1 text-sm text-burnt-sienna">
                Please Check this
              </div>
            )}
            <div className="flex items-center justify-center pt-4">
              <Button
                type="button"
                width="52"
                className="h-12 rounded-lg px-12 text-white"
                color="indigo"
                loading={isLoading}
                onClick={() => onSendEmailToGP()}
              >
                Send report
              </Button>
            </div>
          </Modal>
          <div className="mb-4 w-full rounded-t-lg bg-white px-4 py-4 lg:px-7 lg:pt-7">
            <div className="md:grid-cols-3 lg:grid lg:grid-cols-4 xl:grid-cols-6">
              <div className="mb-2">
                <div className="text-indigo">Name</div>
                <div>
                  {appointment?.user?.firstName} {appointment?.user?.lastName}
                </div>
              </div>
              <div className="mb-2">
                <div className="text-indigo">Email</div>
                <div className="break-all">{appointment?.user?.emailAddress}</div>
              </div>
              <div className="mb-2">
                <div className="text-indigo">Phone number</div>
                <div>{appointment?.user?.phone}</div>
              </div>
              <div className="mb-2">
                <div className="text-indigo">Gender</div>
                <div>{appointment?.user?.gender}</div>
              </div>
              <div className="mb-2">
                <div className="text-indigo">Date/time</div>
                <div className="break-all">
                  {appointment?.date}{" "}
                  {appointment?.time}
                </div>
              </div>
              <div className="mb-2">
                <div className="text-indigo">Status</div>
                <div>{appointment?.status}</div>
              </div>
            </div>
          </div>
          <div
            className={clsx(
              appointment?.status === AppointmentStatus.CANCEL ||
                appointment?.status === AppointmentStatus.COMPLETED
                ? "rounded-none"
                : "rounded-b-lg",
              "mb-4 w-full bg-white px-4 py-4 lg:px-7 lg:pt-7 "
            )}
          >
            <div className="flex items-center justify-between">
              <div className="text-lg font-bold">Patient’s form</div>
              {(appointment?.status === AppointmentStatus.IN_PROGRESS ||
                appointment?.status === AppointmentStatus.BOOKED) && (
                  <div>
                    <Button
                      className="rounded-md bg-indigo/10 text-indigo"
                      type="button"
                      onClick={() => setEditPatientAnswerModel(true)}
                    >
                      Edit
                    </Button>
                  </div>
                )}
            </div>
            <div>
              {_.sortBy(appointment?.answeredByPatient, "questions.position")?.map((question, i) => {
                return (
                  <div key={i}>
                    <h1>{question?.questions?.questionText}</h1>
                    <h1 className="text-indigo">
                      Answer:{" "}
                      {question?.answerText ? question?.answerText : "NA"}
                    </h1>
                  </div>
                );
              })}
            </div>
          </div>
          {appointment?.answeredByClinic.length > 0 && (
            <div className="mb-4 w-full rounded-b-lg bg-white px-4 py-4 lg:px-7 lg:pt-7">
              <div className="text-lg font-bold">Pharmacist’s form</div>
              {_.sortBy(appointment?.answeredByClinic, "question.position")?.map((question, i) => {
                return (
                  <div key={i}>
                    <h1>{question?.question?.questionText}</h1>
                    {question?.question?.inputType === "multiselect" ? (
                      <h1 className="mb-2 text-indigo">
                        Answer:{" "}
                        {question?.answerText ? question?.answerText : "NA"}
                      </h1>
                    ) : (
                      <h1 className="mb-2 text-indigo">
                        Answer:{" "}
                        {question?.answerText ? question?.answerText : "NA"}
                      </h1>
                    )}
                  </div>
                );
              })}
            </div>
          )}
          {appointment?.status === AppointmentStatus.CANCEL && (
            <div className="mb-4 w-full rounded-b-lg bg-white px-4 py-4 lg:px-7 lg:pt-7">
              <h2 className="text-base text-burnt-sienna">
                Appointment cancelled by patient OR you!
              </h2>
              <h2 className="text-base text-burnt-sienna">
                Reason: {appointment?.appointmentCancel[0]?.cancelReason}
              </h2>
            </div>
          )}
          {editPatientAnswerModel && (
            <EditPatientAnswer
              isOpen={editPatientAnswerModel}
              appointment={appointment}
              closeModal={() => setEditPatientAnswerModel(false)}
              close={() => setEditPatientAnswerModel(false)}
            />
          )}
          {completeAppointmentModel && (
            <CompleteAppontment
              isOpen={completeAppointmentModel}
              appointment={appointment}
              closeModal={() => setCompleteAppointmentModel(false)}
              close={() => setCompleteAppointmentModel(false)}
            />
          )}
          <Modal
            title=""
            maxWidth="32rem"
            show={isCancelModal}
            onClose={openCancelModal}
            onCancel={closeCancelModal}
            showCancel={true}
          >
            <div className="flex justify-center">
              <h1 className="mb-3 text-xl text-burnt-sienna">
                Cancel appointment
              </h1>
            </div>
            <div className="mb-4 text-center font-light">
              Are you sure you want to cancel the appointment? Please provide a
              reason & we’ll send it to the patient.
            </div>
            <textarea
              rows={3}
              onChange={(e) => setCancelReason(e.target.value)}
              placeholder="Enter the reason"
              className="w-full resize-none rounded-md border-none bg-alabaster focus:ring-0"
            />
            {cancelReasonError && (
              <div className="mt-1 text-sm text-burnt-sienna">
                CancelReason is required.
              </div>
            )}
            <div className="flex items-center justify-center pt-4">
              <Button
                type="button"
                className="rounded-lg px-12 text-white w-40 lg:w-72"
                color="burnt-sienna"
                loading={isLoading}
                onClick={() => onCancelAppointment()}
              >
                Cancel appointment
              </Button>
            </div>
          </Modal>
          {(appointment?.status === AppointmentStatus.BOOKED ||
            appointment?.status === AppointmentStatus.IN_PROGRESS) && (
              <div className="lg:flex lg:items-center lg:justify-between pt-2">
                <div className="mb-2">
                  <Button
                    type="button"
                    className="h-14 rounded-lg bg-indigo text-white w-40 lg:w-56"
                    text="lg"
                    onClick={() => setCompleteAppointmentModel(true)}
                  >
                    Mark as completed
                  </Button>
                </div>
                <div>
                  <h1
                    className="cursor-pointer text-burnt-sienna"
                    onClick={() => openCancelModal()}
                  >
                    Cancel appointment
                  </h1>
                </div>
              </div>
            )}
        </div>
      </div>
    </section>
  );
};

export default ViewAppointment;

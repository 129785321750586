import { deleteBlog, retriveBlogs, updateBlog } from "actions/blogs";
import { Button, Modal } from "components/ui";
import Toggle from "components/ui/Toggle";
import { isEmpty } from "lodash";
import moment from "moment";
import React, { useCallback, useEffect, useState } from "react";
import DataTable from "react-data-table-component";
import toast from "react-hot-toast";
import { AiOutlineDelete, AiOutlineEdit } from "react-icons/ai";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import customStyles from "utils/data-table-style";

const Blogs = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const [blogs, setBlogs] = useState([]);
    const [page, setPage] = useState(1);
    const [isLoaded, setIsLoaded] = useState(false);
    const [totalRows, setTotalRows] = useState(0);
    const [perPage, setPerPage] = useState(10);
    const [isModelOpen, setIsModelOpen] = useState(false);
    const [selectedBlogs, setSelectedBlogs] = useState();
    const [deleteRow, setDeleteRow] = useState();
    const [isDeleteModal, setIsDeleteModal] = useState(false);

    const handlePageChange = (page) => {
        setPage(page);
    };

    const handlePerRowsChange = async (newPerPage, page) => {
        setPerPage(newPerPage);
    };

    function handleDelete(row) {
        setDeleteRow(row);
        setIsDeleteModal(true);
    }

    const columns = [
        {
            name: "Date",
            selector: (row) => moment(row?.createdAt).format("MMM Do YYYY"),
        },
        {
            name: "Title",
            cell: (row) => row?.title,
            minWidth: "200px"
        },
        {
            name: 'Published',
            selector: row =>
                <div className="flex space-x-1 items-center">
                    <Toggle
                        value={row?.isPublished
                        }
                        onChange={() => {
                            setIsModelOpen(!isModelOpen);
                            setSelectedBlogs(row);
                        }}
                    />
                    <div>{row?.isPublished ? "Published" : "Not Published"}</div>
                </div>,
            minWidth: "180px"
        },
        {
            name: "",
            selector: (row) => row?.action,
            cell: (row) => (
                <div className="flex">
                    <div>
                        <AiOutlineEdit
                            className="h-6 w-6 sm:h-7 sm:w-7"
                            onClick={() => navigate(`/blog/${row?.id}`)}
                        />
                    </div>
                    <div>
                        <AiOutlineDelete
                            className="h-6 w-6 sm:h-7 sm:w-7"
                            onClick={() => handleDelete(row)}
                        />
                    </div>
                </div>
            ),
            show: true,
        },
    ];

    const fetchBlogs = useCallback(() => {
        const params = {
            page: page,
            limit: perPage,
            sort: "updatedAt",
            order: "desc",
        };
        dispatch(retriveBlogs(params))
            .then((result) => {
                setIsLoaded(true);
                setBlogs(result?.data);
                setTotalRows(result?.meta?.total);
            })
            .catch((error) => {
                toast.error(error.response.data.errors[0].detail);
                setIsLoaded(true);
            })
            .finally(() => {
                setIsLoaded(false);
            });
    }, [dispatch, setBlogs, page, perPage]);

    const onDeleteBlog = () => {
        dispatch(deleteBlog(deleteRow?.id)).then((result) => {
            setIsDeleteModal(false);
            fetchBlogs();
            toast.success("Blog Deleted Successfully");
        })
            .catch((error) => {
                console.log(error);
                toast.error(error.response.data.errors[0].detail);
                setIsLoaded(false);
            })
    }

    const onBlogUpdate = () => {
        const body = {
            title: selectedBlogs?.title,
            image: selectedBlogs?.image,
            description: selectedBlogs?.description,
            shortDescription: selectedBlogs?.shortDescription,
            isPublished: !selectedBlogs?.isPublished,
        }
        dispatch(updateBlog(selectedBlogs?.id, body)).then((result) => {
            setIsModelOpen(false);
            fetchBlogs();
            toast.success("Blog Update Successfully");
        })
            .catch((error) => {
                console.log(error);
                toast.error(error.response.data.errors[0].detail);
                setIsLoaded(false);
            })
    }

    useEffect(() => {
        fetchBlogs();
    }, [fetchBlogs]);

    return (
        <section className="ml-6 mr-7 min-h-screen items-center justify-center py-6">
            <div>
                <div className="flex mb-4 justify-between items-center">
                    <div className="text-xl font-semibold">Blogs</div>
                    <div className="flex space-x-3 fill-alabaster">
                        <div>
                            <Button
                                type="button"
                                className="rounded-md bg-indigo text-white"
                                width="full"
                                text="base"
                                onClick={() => navigate("/blog/create")}
                            >
                                Create
                            </Button>
                        </div>
                    </div>
                </div>
                <div className="w-full rounded-lg bg-white py-7 px-8">
                    <div className="w-auto rounded-lg">
                        <DataTable
                            columns={columns}
                            data={blogs}
                            pagination
                            paginationServer
                            paginationTotalRows={totalRows}
                            onChangePage={handlePageChange}
                            onChangeRowsPerPage={handlePerRowsChange}
                            progressPending={isLoaded}
                            responsive={true}
                            customStyles={customStyles}
                        />
                    </div>
                </div>
                <Modal
                    title="Delete"
                    maxWidth="32rem"
                    show={isDeleteModal}
                    onClose={() => setIsDeleteModal(false)}
                    showCancel={false}
                >
                    <div className="mb-4 text-center font-light">
                        {`Are you sure you want to delete this ${deleteRow?.title}?`}
                    </div>
                    <div className="mb-10 text-center font-light text-burnt-sienna">
                        You won't be able to revert this!
                    </div>
                    <div className="flex items-center justify-center space-x-3">
                        <Button
                            type="button"
                            className="rounded-lg text-indigo"
                            outlined
                            onClick={() => setIsDeleteModal(false)}
                            color="indigo"
                        >
                            Cancel
                        </Button>
                        <Button
                            type="button"
                            width="28"
                            className="rounded-lg text-white bg-indigo"
                            loading={isLoaded}
                            onClick={onDeleteBlog}
                        >
                            Delete
                        </Button>
                    </div>
                </Modal>
                <Modal
                    title="Blog Publish"
                    maxWidth="32rem"
                    show={isModelOpen}
                    onClose={() => setIsModelOpen(false)}
                    showCancel={false}
                >
                    <div className="mb-4 text-center font-light">
                        Are you sure you want to {!selectedBlogs?.isPublished && ("Pubish")} {selectedBlogs?.isPublished && ("Un-Publish")} this  {selectedBlogs?.title}?
                    </div>
                    <div className="flex items-center justify-center space-x-3">
                        <Button type="button" className="rounded-lg" outlined onClick={() => setIsModelOpen(false)} color="indigo">
                            No
                        </Button>
                        <Button
                            type="button"
                            className="bg-indigo rounded-lg text-white"
                            loading={isLoaded}
                            onClick={() => {
                                if (!isEmpty(selectedBlogs)) {
                                    onBlogUpdate(selectedBlogs)
                                }
                            }}
                        >
                            Yes
                        </Button>
                    </div>
                </Modal>
            </div>
        </section>
    );
}

export default Blogs
import React from "react";

const ProgressBar = (props) => {
  const { selectedIndex } = props;
  const stepsMapper = [
    "1. Clinic Details",
    "2. Services",
    "3. Opening Hours",
    "4. Photos",
  ];
  return (
    <div className="hidden border-b border-alabaster sm:flex">
      {stepsMapper.map((name, index) => {
        return (
          <>
            <span
              className="flex px-12 lg:px-20 text-sm justify-center items-center"
              style={{
                backgroundColor: selectedIndex >= index ? "#3968ca" : "white",
                color: selectedIndex >= index ? "white" : "black",
              }}
            >
              {name}
            </span>
            {selectedIndex === index && (
              <div className="border-l-[20px] border-r-0 border-solid border-y-transparent border-l-indigo md:border-y-[30px]"></div>
            )}
          </>
        );
      })}
    </div>
  );
};

export default ProgressBar;

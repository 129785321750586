import { deleteService, retriveServices } from "actions/services";
import { Button, Modal } from "components/ui";
import React, { useCallback, useEffect, useState } from "react";
import DataTable from "react-data-table-component";
import toast from "react-hot-toast";
import { useDispatch } from "react-redux";
import {
  AiOutlineDelete,
  AiOutlineEdit,
  AiOutlineSearch,
} from "react-icons/ai";
import { useNavigate } from "react-router-dom";
import customStyles from "utils/data-table-style";

const Services = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [services, setServices] = useState([]);
  const [page, setPage] = useState(1);
  const [isLoaded, setIsLoaded] = useState(false);
  const [totalRows, setTotalRows] = useState(0);
  const [perPage, setPerPage] = useState(10);
  const [search, setSearch] = useState();
  const [deleteRow, setDeleteRow] = useState({});
  const [isDeleteModal, setIsDeleteModal] = useState(false);

  const handlePageChange = (page) => {
    setPage(page);
  };

  const handlePerRowsChange = async (newPerPage, page) => {
    setPerPage(newPerPage);
  };

  function handleDelete(row) {
    setDeleteRow(row);
    openDeleteModal();
  }

  const columns = [
    {
      name: "Icon",
      cell: (row) => (
        <img
          className="mr-2 inline h-12 w-12 rounded-full object-cover"
          src={row?.accessUrl}
          alt="service-logo"
        />
      ),
    },
    {
      name: "Name",
      selector: (row) => row.name,
    },
    {
      name: "Action",
      selector: (row) => row.action,
      cell: (row) => (
        <div className="flex">
          <div>
            <AiOutlineEdit
              className="h-6 w-6 sm:h-7 sm:w-7"
              onClick={() => navigate(`/services/${row?.id}/edit`)}
            />
          </div>
          <div>
            <AiOutlineDelete
              className="h-6 w-6 sm:h-7 sm:w-7"
              onClick={() => handleDelete(row)}
            />
          </div>
        </div>
      ),
      show: true,
    },
  ];

  const fetchServices = useCallback(() => {
    const params = {
      page: page,
      limit: perPage,
      sort: "updatedAt",
      order: "desc",
      search: search,
      include: "category,questionForPatient,questionForClinic",
    };
    dispatch(retriveServices(params))
      .then((result) => {
        setIsLoaded(true);
        setServices(result?.data);
        setTotalRows(result?.meta?.total);
      })
      .catch((error) => {
        toast.error(error.response.data.errors[0].detail);
        setIsLoaded(true);
      })
      .finally(() => {
        setIsLoaded(false);
      });
  }, [dispatch, setServices, page, perPage, search]);

  function openDeleteModal() {
    setIsDeleteModal(true);
  }

  function closeDeleteModal() {
    setIsDeleteModal(false);
  }

  const onDeleteService = () => {
    setIsLoaded(true);

    dispatch(deleteService(deleteRow.id))
      .then(() => {
        toast.success("Service deleted successfully.");
        fetchServices();
        closeDeleteModal();
      })
      .catch((error) => {
        toast.error(error.response.data.errors[0].detail);
        setIsLoaded(false);
      })
      .finally(() => {
        setIsLoaded(false);
      });
  };

  useEffect(() => {
    fetchServices();
  }, [fetchServices]);

  return (
    <section className="ml-6 mr-7 min-h-screen items-center justify-center py-6">
      <div>
        <div className="md:flex mb-4 justify-between items-center">
          <div className="text-xl font-semibold">Services</div>
          <div className="flex space-x-3 fill-alabaster">
            <div className="relative h-10 w-full xl:w-auto">
              <AiOutlineSearch className="text-storm-gray absolute inset-y-0 left-3 my-auto" />
              <input
                type="text"
                className="w-full rounded-md border-none bg-white pl-9 font-medium focus:ring-0 xl:w-[320px]"
                placeholder="Search"
                onChange={(e) => {
                  setSearch(e.target.value);
                }}
              />
            </div>
            <div>
              <Button
                type="button"
                className="rounded-md bg-indigo text-white"
                width="full"
                text="base"
                onClick={() => navigate("/services/create")}
              >
                Add
              </Button>
            </div>
          </div>
        </div>
        <div className="w-full rounded-lg bg-white py-7 px-8">
          <div className="w-auto rounded-lg">
            <DataTable
              columns={columns}
              data={services}
              onRowClicked={(row) => navigate(`/services/${row.id}/view`)}
              pagination
              paginationServer
              paginationTotalRows={totalRows}
              onChangePage={handlePageChange}
              onChangeRowsPerPage={handlePerRowsChange}
              progressPending={isLoaded}
              responsive={true}
              customStyles={customStyles}
            />
          </div>
        </div>
        <Modal
          title="Delete"
          maxWidth="32rem"
          show={isDeleteModal}
          onClose={openDeleteModal}
          showCancel={false}
        >
          <div className="mb-4 text-center font-light">
            {`Are you sure you want to delete this ${deleteRow?.name}?`}
          </div>
          <div className="mb-10 text-center font-light">
            You won't be able to revert this!
          </div>
          <div className="flex items-center justify-center space-x-3">
            <Button
              type="button"
              className="rounded-lg text-indigo"
              outlined
              onClick={closeDeleteModal}
              color="indigo"
            >
              Cancel
            </Button>
            <Button
              type="button"
              width="28"
              className="rounded-lg text-white bg-indigo"
              loading={isLoaded}
              onClick={onDeleteService}
            >
              Delete
            </Button>
          </div>
        </Modal>
      </div>
    </section>
  );
};

export default Services;

import { retrieveClinicSelectPatient } from "actions/clinicSelectPatient";
import { Avatar, Button } from "components/ui";
import useRole from "hooks/useRole";
import React, { useCallback, useEffect, useState } from "react";
import DataTable from "react-data-table-component";
import toast from "react-hot-toast";
import { AiOutlineSearch } from "react-icons/ai";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import customStyles from "utils/data-table-style";
import { ROLE } from "utils/roles";
import AllPatients from "./allPatients";

function Patients() {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const auth = JSON.parse(localStorage.getItem("clinic-u"));
  const clinicId = auth?.clinic[0]?.id;

  const [services, setServices] = useState([]);
  const [page, setPage] = useState(1);
  const [isLoaded, setIsLoaded] = useState(false);
  const [totalRows, setTotalRows] = useState(0);
  const [perPage, setPerPage] = useState(10);
  const [search, setSearch] = useState();

  const handlePageChange = (page) => {
    setPage(page);
  };

  const handlePerRowsChange = async (newPerPage, page) => {
    setPerPage(newPerPage);
  };

  const columns = [
    {
      name: "Name",
      cell: (row) => (
        <div className="lg:flex items-center">
          <Avatar
            firstName={row?.patient?.firstName}
            lastName={row?.patient?.lastName}
            image=""
          />
          <div className="ml-1">
            {row?.patient?.firstName !== null ? row?.patient?.firstName : ""} {row?.patient?.lastName !== null ? row?.patient?.lastName : ""}
          </div>
        </div>
      ),
      minWidth: "200px"
    },
    {
      name: "NHS",
      selector: (row) => row?.patient?.NHS,
    },
    {
      name: "Email",
      selector: (row) => row?.patient?.emailAddress,
    },
    {
      name: "Phone",
      selector: (row) => row?.patient?.phone,
    },
    {
      name: "",
      selector: (row) => row?.patient?.action,
      cell: (row) => (
        <Button
          className="rounded-md bg-indigo/10 text-indigo w-28"
          type="button"
          onClick={() => navigate(`/patients/${row?.patient?.id}?clinicp=${row?.id}`)}
        >
          View
        </Button>
      ),
      show: true,
    },
  ];

  const fetchPatient = useCallback(() => {
    const params = {
      page: page,
      limit: perPage,
      sort: "createdAt",
      order: "desc",
      search: search,
      "filter[clinic.id]": clinicId,
      include: "patient,clinic",
    };
    dispatch(retrieveClinicSelectPatient(params))
      .then((result) => {
        setIsLoaded(true);
        setServices(result?.data);
        setTotalRows(result?.meta?.total);
      })
      .catch((error) => {
        toast.error(error.response.data.errors[0].detail);
        setIsLoaded(true);
      })
      .finally(() => {
        setIsLoaded(false);
      });
  }, [dispatch, setServices, page, perPage, search, clinicId]);

  useEffect(() => {
    fetchPatient();
  }, [fetchPatient]);

  return (
    <section className="ml-6 mr-7 min-h-screen items-center justify-center py-6">
      <div>
        {useRole([ROLE.ADMIN]) && <AllPatients />}
        {useRole([ROLE.CLINIC]) && (
          <div>
            <div className="md:flex mb-4 justify-between items-center">
              <div className="text-xl font-semibold">Patients</div>
              <div className="flex space-x-3 fill-alabaster">
                <div className="relative h-10 w-full xl:w-auto">
                  <AiOutlineSearch className="text-storm-gray absolute inset-y-0 left-3 my-auto" />
                  <input
                    type="text"
                    className="w-full rounded-md border-none bg-white pl-9 font-medium focus:ring-0 xl:w-[320px]"
                    placeholder="Search"
                    onChange={(e) => {
                      setSearch(e.target.value);
                    }}
                  />
                </div>
                <div>
                  <Button
                    type="button"
                    className="rounded-md bg-indigo text-white"
                    width="full"
                    text="base"
                    onClick={() => navigate("/patients/create")}
                  >
                    Add
                  </Button>
                </div>
              </div>
            </div>
            <div className="w-full rounded-lg bg-white py-7 px-8">
              <div className="w-auto rounded-lg">
                <DataTable
                  columns={columns}
                  data={services}
                  pagination
                  paginationServer
                  paginationTotalRows={totalRows}
                  onChangePage={handlePageChange}
                  onChangeRowsPerPage={handlePerRowsChange}
                  progressPending={isLoaded}
                  responsive={true}
                  customStyles={customStyles}
                />
              </div>
            </div>
          </div>
        )}
      </div>
    </section>
  );
}

export default Patients;

import ClinicService from "services/clinicService";
import {
    CREATE_CLINICS_SERVICE,
    GET_ONE_CLINICS_SERVICE,
    UPDATE_ACTIVE_CLINICS_SERVICE,
    UPDATE_CLINICS_SERVICE
} from "./type";

export const updateClinicService = (id, data) => async (dispatch) => {
    try {
        const res = await ClinicService.update(id, data);

        dispatch({
            type: UPDATE_CLINICS_SERVICE,
            payload: res.data,
        });

        return Promise.resolve(res.data);
    } catch (error) {
        return Promise.reject(error);
    }
};

export const createClinicService = (data) => async (dispatch) => {
    try {
        const res = await ClinicService.create(data);

        dispatch({
            type: CREATE_CLINICS_SERVICE,
            payload: res.data,
        });

        return Promise.resolve(res.data);
    } catch (error) {
        return Promise.reject(error);
    }
};


export const updateActiveClinicService = (id, data) => async (dispatch) => {
    try {
        const res = await ClinicService.serviceUpdate(id, data);

        dispatch({
            type: UPDATE_ACTIVE_CLINICS_SERVICE,
            payload: res.data,
        });

        return Promise.resolve(res.data);
    } catch (error) {
        return Promise.reject(error);
    }
};

export const retrieveOneClinicService = (id, params) => async (dispatch) => {
    try {
        const res = await ClinicService.getOne(id, params);

        dispatch({
            type: GET_ONE_CLINICS_SERVICE,
            payload: res.data,
        });

        return Promise.resolve(res.data);
    } catch (error) {
        return Promise.reject(error);
    }
};
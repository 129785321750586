import { retrieveOneClinic } from 'actions/clinics'
import { Button } from 'components/ui'
import Gallary from 'components/ui/Gallary'
import Loading from 'components/ui/Loading'
import OpenDays from 'components/ui/OpenDays'
import React, { useCallback, useEffect, useState } from 'react'
import DataTable from 'react-data-table-component'
import toast from 'react-hot-toast'
import { useDispatch } from 'react-redux'
import { useNavigate, useParams } from 'react-router-dom'
import customStyles from 'utils/data-table-style'

const ViewClinic = () => {

    const { clinicId } = useParams();
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const [clinic, setClinics] = useState({});
    const [loading, setLoading] = useState(false);

    const fetchClinicsDetail = useCallback(
        () => {
            const params = {
                include: "openingDays,openingDays.clinicHours,clinicServices,clinicServices.services,clinicServices.serviceOpeningDays,serviceOpeningDays.serviceHours",
            }
            dispatch(retrieveOneClinic(clinicId, params)).then((result) => {
                setLoading(true);
                setClinics(result);
            })
                .catch((error) => {
                    toast.error(error.response.data.errors[0].detail);
                    setLoading(true);
                })
                .finally(() => {
                    setLoading(false);
                });
        },
        [dispatch, setClinics, clinicId],
    )

    const columns = [
        {
            name: "Service Name",
            selector: (row) => row?.services?.name,
        },
        {
            name: "Charge",
            selector: (row) => row?.charge,
        },
        {
            name: "Patient At Time",
            selector: (row) => row?.patientAtTime,
        },
        {
            name: "Availabe Every",
            selector: (row) => row?.availabeEvery,
        },
    ];

    useEffect(() => {
        fetchClinicsDetail()
    }, [fetchClinicsDetail])

    return (
        <section className="min-h-screen ml-6 mr-7 py-6 justify-center items-center">
            <div>
                <div className='mb-4'>
                    <div className='text-xl font-semibold'>Clinic Detail</div>
                </div>
                {loading ?
                    <Loading />
                    :
                    <div>
                        <div className="flex justify-start mb-6">
                            <div className="flex flex-col md:flex-row w-full rounded-t-lg bg-white shadow-lg">
                                <img className="px-3 py-3 w-full h-96 md:h-auto object-contain md:w-48 rounded-t-lg md:rounded-none md:rounded-l-lg" src={clinic?.accessUrl} alt="clinic-logo" />
                                <div className="p-6 flex flex-col justify-start">
                                    <h5 className="text-xl font-medium mb-2">Basic Details</h5>
                                    <div className="lg:flex lg:space-x-2 mb-1 text-lg font-medium">
                                        <div>Clinic Name: </div>
                                        <div className="text-indigo">{clinic?.name}</div>
                                    </div>
                                    <div className="lg:flex lg:space-x-2 mb-1 text-lg font-medium">
                                        <div>Email: </div>
                                        <div className="text-indigo break-all">{clinic?.emailAddress}</div>
                                    </div>
                                    <div className="lg:flex lg:space-x-2 mb-1 text-lg font-medium">
                                        <div>Phone: </div>
                                        <div className="text-indigo">{clinic?.phone}</div>
                                    </div>
                                    <div className="lg:flex lg:space-x-2 mb-1 text-lg font-medium">
                                        <div>Address: </div>
                                        <div className="text-indigo">{clinic?.address}</div>
                                    </div>
                                    <div className="lg:flex lg:space-x-2 mb-1 text-lg font-medium">
                                        <div>PostalCode: </div>
                                        <div className="text-indigo">{clinic?.postCode}</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="mb-6">
                            <div className="px-4 py-4 flex flex-col md:flex-row w-full bg-white shadow-lg">
                                <div>
                                    <h5 className="text-xl font-medium mb-2">Clinic Open Days: </h5>
                                    <OpenDays days={clinic?.openingDays} />
                                </div>
                            </div>
                        </div>
                        <div className="mb-6">
                            <div className="px-4 py-4 flex flex-col w-full bg-white shadow-lg">
                                <div>
                                    <h5 className="text-xl font-medium mb-2">Clinic Services: </h5>
                                    <div>
                                        <DataTable
                                            columns={columns}
                                            data={clinic?.clinicServices}
                                            responsive={true}
                                            customStyles={customStyles}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="flex justify-start mb-6">
                            <div className="px-4 py-4 flex flex-col md:flex-row w-full rounded-b-lg bg-white shadow-lg">
                                <div>
                                    <h5 className="text-xl font-medium mb-2">Media: </h5>
                                    <Gallary media={clinic?.media} />
                                </div>
                            </div>
                        </div>
                    </div>
                }
                <div className="pt-6">
                    <Button
                        type="button"
                        className="bg-indigo text-white rounded-md"
                        width="36"
                        text="base"
                        onClick={() => navigate("/clinics")}
                    >
                        Back To List
                    </Button>
                </div>
            </div>
        </section>
    )
}

export default ViewClinic
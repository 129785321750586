import React, { useEffect, useRef, useState } from 'react'
import { useNavigate } from 'react-router-dom';

const BlogSlider = ({ cards }) => {

    const navigate = useNavigate();
    const [startIndex, setStartIndex] = useState(0);
    const containerRef = useRef(null);
    const [cardsPerPage, setCardsPerPage] = useState(1);
    const [activeDot, setActiveDot] = useState(0);

    const handleNext = () => {
        const newIndex = Math.min(
            startIndex + cardsPerPage,
            cards.length - cardsPerPage
        );
        setStartIndex(newIndex);
        scrollToIndex(newIndex);
        setActiveDot(newIndex);
    };

    const handlePrevious = () => {
        const newIndex = Math.max(startIndex - cardsPerPage, 0);
        setStartIndex(newIndex);
        scrollToIndex(newIndex);
        setActiveDot(newIndex);
    };

    const scrollToIndex = (index) => {
        const cardWidth = containerRef.current.children[0].offsetWidth;
        containerRef.current.scrollLeft = index * cardWidth;
    };

    const handleDotClick = (dotIndex) => {
        setActiveDot(dotIndex);
        setStartIndex(dotIndex * cardsPerPage);
        scrollToIndex(dotIndex * cardsPerPage);
    };

    useEffect(() => {
        const handleResize = () => {
            if (window.innerWidth >= 768) {
                setCardsPerPage(2); // Set to 2 for larger screens
            } else {
                setCardsPerPage(1); // Set to 1 for smaller screens
            }
        };

        // Initial setup
        handleResize();

        // Add event listener for window resize
        window.addEventListener('resize', handleResize);

        // Clean up the event listener on component unmount
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    return (
        <div>
            <div className="flex items-center justify-center max-w-7xl">
                <img
                    onClick={handlePrevious}
                    src="/img/landingPage/previous.png"
                    alt="clinic2go"
                />
                <div className="flex w-full overflow-x-hidden" ref={containerRef}>
                    {cards.map((card, index) => (
                        <div
                            key={index}
                            className={`flex-none lg:w-1/2 w-full p-4 ${index >= startIndex && index < startIndex + cardsPerPage
                                ? 'opacity-100'
                                : 'opacity-50'
                                }`}
                        >
                            <div className="bg-white shadow-lg rounded-lg lg:flex flex-row space-x-4 justify-center items-center max-w-xl">
                                {card?.accessUrl && (
                                    <div>
                                        <img src={card?.accessUrl} alt="clinic2go" className="h-80 w-[46rem] rounded-l-lg object-cover" />
                                    </div>
                                )}
                                <div className="max-w-md pr-2">
                                    <h2 className="text-2xl text-elephant font-bold font-plus-jakarta-sans mb-4">
                                        {card?.title}
                                    </h2>
                                    <div className="text-base text-elephant font-normal font-plus-jakarta-sans mb-12">
                                        {card?.shortDescription}
                                    </div>
                                    <div
                                        className="text-base text-indigo font-normal font-plus-jakarta-sans cursor-pointer"
                                        onClick={() => navigate(`/landing-blogs/${card?.id}`)}
                                    >
                                        Read more
                                    </div>
                                </div>
                            </div>
                        </div>
                    ))}
                </div>

                <img
                    onClick={handleNext}
                    src="/img/landingPage/next.png"
                    alt="clinic2go"
                />
            </div>
            <div className="flex justify-center mt-4">
                {Array.from({ length: Math.ceil(cards.length / cardsPerPage) }).map((_, index) => (
                    <span
                        key={index}
                        onClick={() => handleDotClick(index)}
                        className={`w-2 h-2 rounded-full mx-2 cursor-pointer ${activeDot === index ? "bg-indigo w-9" : "bg-fiord"
                            }`}
                    ></span>
                ))}
            </div>

        </div>
    );
}

export default BlogSlider
import React, { useCallback, useEffect, useRef, useState } from "react";

import UpComingAppoiment from "features/dashboard/UpComingAppoiment";
import NMS from "features/dashboard/NMS";
import { useDispatch } from "react-redux";
import { toast } from "react-hot-toast";
import { getAppointmentCounts } from "actions/dashboard";
import { isEmpty } from "lodash";
import { ROLE } from "utils/roles";
import Flatpickr from 'react-flatpickr';
import "flatpickr/dist/flatpickr.css";
import moment from "moment";

function Index() {

  const dispatch = useDispatch();
  const fp = useRef(null);

  const [counts, setCounts] = useState({});
  const [totalPatientCounts, setTotalPatientCounts] = useState(0);
  const [startDate, setStartDate] = useState(moment().subtract(21, "days"));
  const [endDate, setEndDate] = useState(moment());

  const auth = JSON.parse(localStorage.getItem("clinic-u"));
  const clinicId = auth?.clinic[0]?.id;

  const fetchCounts = useCallback(
    () => {
      const body = {
        startDate: startDate,
        endDate: endDate
      }
      dispatch(getAppointmentCounts(clinicId, body)).then((result) => {
        setCounts(result);
        if (result) {
          setTotalPatientCounts(result?.totalPatientsBookedAppointment[0]?.totalappoinements)
        }
      })
        .catch((error) => {
          toast.error(error.response.data.errors[0].detail);
        })
    },
    [dispatch, clinicId, setCounts, startDate, endDate],
  )

  useEffect(() => {
    if (auth?.role?.name === ROLE.CLINIC) {
      if (startDate !== "" && endDate !== "") {
        fetchCounts();
      }
    }
  }, [fetchCounts, auth?.role?.name, startDate, endDate])


  return (
    <>
      <div className="min-h-screen">
        <div className="px-4 py-4">
          <h5 className="text-xl font-semibold">Dashboard</h5>
          <div className="pt-6">
            <div className="px-7 py-8 bg-white rounded-lg w-auto">
              <div>
                <div>Select Date To View Counts</div>
                <Flatpickr
                  className="bg-alabaster w-auto border-none rounded-md mb-3"
                  ref={fp}
                  options={{
                    mode: 'range',
                    defaultDate: [`${moment(startDate).format("YYYY-MM-DD")}`, `${moment(endDate).format("YYYY-MM-DD")}`]
                  }}
                  onChange={(date) => {
                    setStartDate(moment(date[0]))
                    setEndDate(moment(date[1]))
                  }}
                />
              </div>
              {!isEmpty(counts) && (
                <div>
                  <div className="lg:flex lg:space-x-2">
                    <div className="bg-indigo/5 text-base font-medium px-4 py-2 rounded w-fit mb-3">
                      {totalPatientCounts} Patients Booked Appointments With You
                    </div>
                    <div className="bg-indigo/5 text-base font-medium px-4 py-2 rounded w-fit mb-3">
                      You Have Total {counts?.totalPatients} Patients
                    </div>
                  </div>
                  <div className="flex flex-wrap gap-x-6">
                    {counts?.totalServiceWiseAppointments?.map((data, i) => {
                      return (
                        <div key={i} className="flex space-x-2 items-center mb-2 bg-indigo/10 px-4 py-2 rounded w-fit">
                          <img className="inline-block h-8 w-8" src={data?.accessUrl} alt="service-img" />
                          <span className="text-base font-medium">{data?.totalcount} {data?.name}</span>
                        </div>
                      )
                    })}
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
        <div className="px-4">
          <UpComingAppoiment />
        </div>
        <div className="px-4 py-7">
          <NMS />
        </div>
      </div>
    </>
  );
}

export default Index;

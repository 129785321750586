import { retriveCategry } from 'actions/category';
import { createSignedUrl, uploadFile } from 'actions/fileUpload';
import { createService } from 'actions/services';
import { CloseCircle } from 'components/icons';
import { Button } from 'components/ui';
import { isEmpty } from 'lodash';
import React, { useCallback, useEffect, useState } from 'react'
import { useForm } from 'react-hook-form';
import toast from 'react-hot-toast';
import { AiOutlinePlus } from 'react-icons/ai';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';

const CreateServices = () => {

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [errorShow, setErrorShow] = useState(false);
  const [errorShowClinic, setErrorShowClinic] = useState(false);
  const [categories, setCategories] = useState([]);
  const [rowsData, setRowsData] = useState([]);
  const [rowsDataClinic, setRowsDataClinic] = useState([]);

  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm();

  watch((data) => {
    if (data?.questionForPatient?.length > 0) {
      setErrorShow(false);
    }
  });

  watch((data) => {
    if (data?.questionForClinic?.length > 0) {
      setErrorShowClinic(false);
    }
  });

  const addTableRows = () => {
    const rowsInput = {
      inputType: "",
      questionText: "",
      placeholder: "",
      options: ""
    }
    setRowsData([...rowsData, rowsInput])
  }
  const deleteTableRows = (index) => {
    const rows = [...rowsData];
    rows.splice(index, 1);
    setRowsData(rows);
  }

  const addTableRowsClinic = () => {
    const rowsInput = {
      inputType: "",
      questionText: "",
      placeholder: "",
      options: ""
    }
    setRowsDataClinic([...rowsDataClinic, rowsInput])
  }
  const deleteTableRowsClinic = (index) => {
    const rows = [...rowsDataClinic];
    rows.splice(index, 1);
    setRowsDataClinic(rows);
  }

  const onSubmit = async (data) => {
    if (data?.questionForPatient === undefined) {
      setErrorShow(true)
    } else if (data?.questionForClinic === undefined) {
      setErrorShowClinic(true)
    } else {
      setLoading(true)
      if (!isEmpty(data?.questionForPatient)) {
        for (const [i, patientQuestion] of data?.questionForPatient.entries()) {
          patientQuestion.position = i;
          if (!isEmpty(patientQuestion.options)) {
            patientQuestion.options = patientQuestion.options?.split(',').filter(element => element !== '');
          }
        }
      }
      if (!isEmpty(data?.questionForClinic)) {
        for (const [i, clinicQuestion] of data?.questionForClinic.entries()) {
          clinicQuestion.position = i;
          if (!isEmpty(clinicQuestion.options)) {
            clinicQuestion.options = clinicQuestion.options?.split(',').filter(element => element !== '');
          }
        }
      }
      data.category = {
        id: data?.category
      }
      let s3Key
      if (data?.image) {
        const body = {
          fileName: data?.image[0]?.name,
          mimeType: data?.image[0]?.type,
          directory: "service"

        }
        await dispatch(createSignedUrl(body)).then(async (result) => {
          s3Key = result?.s3Key
          dispatch(uploadFile(result?.signedUrl, data?.image[0]));
          data.image = s3Key;
          console.log(result, "sawsijd");
        })
          .catch((error) => {
            toast.error(error.response.data.errors[0].detail);
          })
      }
      await dispatch(createService(data)).then((result) => {
        toast.success("Service Create Successfully");
        navigate("/services")
      })
        .catch((error) => {
          toast.error(error.response.data.errors[0].detail);
        })
    }
  }

  const fetchCategories = useCallback(
    () => {
      const params = {
        page: 1,
        limit: 1000,
        order: "desc",
        include: "services",
      }
      dispatch(retriveCategry(params)).then((result) => {
        setCategories(result?.data);
      })
        .catch((error) => {
          toast.error(error.response.data.errors[0].detail);
        })
    },
    [dispatch, setCategories],
  )

  useEffect(() => {
    fetchCategories();
  }, [fetchCategories])

  return (
    <section className="min-h-screen ml-6 mr-7 py-6 justify-center items-center">
      <div>
        <div className='mb-4'>
          <div className='text-xl font-semibold'>Add a new Patient Service</div>
        </div>
        <div>
          <form
            onSubmit={handleSubmit(onSubmit)}
            className="mx-auto max-w-6xl py-12 px-4 sm:px-6 lg:px-8"
          >
            <div className='rounded-lg bg-white'>
              <div className='grid grid-cols-1 gap-8 sm:grid-cols-3 px-8 py-8'>
                <div>
                  <div className="mb-1 text-sm font-medium">Service Name</div>
                  <input
                    type="text"
                    placeholder="Service Name"
                    className="bg-alabaster border-none w-full rounded-md"
                    {...register("name", { required: true })}
                  />
                  {errors?.name?.type === "required" && (
                    <div className="mt-1 text-sm text-burnt-sienna">
                      Service Name is required.
                    </div>
                  )}
                </div>
                <div>
                  <div className="mb-1 text-sm font-medium">Description</div>
                  <input
                    type="text"
                    placeholder="Description"
                    className="bg-alabaster w-full border-none rounded-md"
                    {...register("description", { required: true })}
                  />
                  {errors?.description?.type === "required" && (
                    <div className="mt-1 text-sm text-burnt-sienna">
                      Description is required.
                    </div>
                  )}
                </div>
                <div>
                  <div className="mb-1 text-sm font-medium">Service Logo</div>
                  <input
                    type="file"
                    className="bg-alabaster w-full border-none rounded-md"
                    {...register("image", { required: true })}
                  />
                  {errors?.image?.type === "required" && (
                    <div className="mt-1 text-sm text-burnt-sienna">
                      Service Logo is required.
                    </div>
                  )}
                </div>
                <div>
                  <div className="mb-1 text-sm font-medium">Category</div>
                  <select
                    className="bg-alabaster w-full border-none rounded-md focus:border-none focus:ring-0"
                    {...register("category", { required: true })}
                  >
                    <option value="">Choose an option</option>
                    {categories.map((category) => {
                      const { id, name } = category;
                      return (
                        <option key={id} value={id}>
                          {name}
                        </option>
                      );
                    })}
                  </select>
                  {errors?.category?.type === "required" && (
                    <div className="mt-1 text-sm text-burnt-sienna">
                      Category is required.
                    </div>
                  )}
                </div>
              </div>
              <div className='px-8 py-8'>
                <div>
                  <div>
                    <div>
                      <div className="mb-1 text-sm font-medium">Question For Patient</div>
                      {errorShow && (
                        <div className="my-1 text-sm text-burnt-sienna">
                          Question For Patient is required. Click On Plus Icon and Add Question.
                        </div>
                      )}
                    </div>
                    <Button
                      className="bg-indigo rounded-lg"
                      type="button"
                      onClick={addTableRows}
                    >
                      <AiOutlinePlus className='fill-white' />
                    </Button>
                  </div>
                  <div>
                    <div>
                      {rowsData.map((data, index) => {
                        return (
                          <div key={index} className="lg:flex lg:space-x-2 lg:justify-items-center lg:items-center">
                            <div>
                              <div className="mb-1 text-sm font-medium">Question Type</div>
                              <select
                                className="w-full rounded-md border-none bg-alabaster focus:border-none focus:ring-0"
                                {...register(`questionForPatient.${index}.inputType`, { required: true })}
                              >
                                <option value="">Choose an option</option>
                                <option value="text">Text</option>
                                <option value="radio">Radio Button</option>
                                <option value="checkbox">CheckBox</option>
                                <option value="select">Select</option>
                                <option value="multiselect">Multiselect</option>
                                <option value="textarea">Long Text</option>
                              </select>
                              {(errors?.questionForPatient !== undefined &&
                                errors?.questionForPatient[index]?.inputType?.type === "required") && (
                                  <div className="mt-1 text-sm text-burnt-sienna">
                                    Question Type is required.
                                  </div>
                                )}
                            </div>
                            <div>
                              <div className="mb-1 text-sm font-medium">Question Text</div>
                              <input
                                type="text"
                                placeholder="Question Text"
                                className="bg-alabaster border-none w-full rounded-md"
                                {...register(`questionForPatient.${index}.questionText`, { required: true })}
                              />
                              {(errors?.questionForPatient !== undefined &&
                                errors?.questionForPatient[index]?.questionText?.type === "required") && (
                                  <div className="mt-1 text-sm text-burnt-sienna">
                                    Question Text is required.
                                  </div>
                                )}
                            </div>
                            <div>
                              <div className="mb-1 text-sm font-medium">Placeholder</div>
                              <input
                                type="text"
                                placeholder="Placeholder"
                                className="bg-alabaster border-none w-full rounded-md"
                                {...register(`questionForPatient.${index}.placeholder`, { required: true })}
                              />
                              {(errors?.questionForPatient !== undefined &&
                                errors?.questionForPatient[index]?.placeholder?.type === "required") && (
                                  <div className="mt-1 text-sm text-burnt-sienna">
                                    Question Text is required.
                                  </div>
                                )}
                            </div>
                            <div>
                              <div className="mb-1 text-independence">Options</div>
                              <input
                                type="text"
                                placeholder="Enter options separate by ,"
                                className="bg-alabaster border-none w-full rounded-md"
                                {...register(`questionForPatient.${index}.options`, { required: false })}
                              />
                            </div>
                            <div>
                              <button onClick={() => (deleteTableRows(index))}>
                                <CloseCircle className="h-6 w-6" />
                              </button>
                            </div>
                          </div>
                        )
                      })}
                    </div>
                  </div>
                </div>
                <div className='mt-8'>
                  <div>
                    <div>
                      <div className="mb-1 text-sm font-medium">Question For Clinic</div>
                      {errorShowClinic && (
                        <div className="my-1 text-sm text-burnt-sienna">
                          Question For Clinic is required. Click On Plus Icon and Add Question.
                        </div>
                      )}
                    </div>
                    <Button
                      className="bg-indigo rounded-lg"
                      type="button"
                      onClick={addTableRowsClinic}
                    >
                      <AiOutlinePlus className='fill-white' />
                    </Button>
                  </div>
                  <div>
                    <div>
                      {rowsDataClinic.map((data, index) => {
                        return (
                          <div key={index} className="lg:flex lg:space-x-2 lg:justify-items-center lg:items-center">
                            <div>
                              <div className="mb-1 text-sm font-medium">Question Type</div>
                              <select
                                className="w-full rounded-md border-none bg-alabaster focus:border-none focus:ring-0"
                                {...register(`questionForClinic.${index}.inputType`, { required: true })}
                              >
                                <option value="">Choose an option</option>
                                <option value="text">Text</option>
                                <option value="radio">Radio Button</option>
                                <option value="checkbox">CheckBox</option>
                                <option value="select">Select</option>
                                <option value="multiselect">Multiselect</option>
                                <option value="textarea">Long Text</option>
                              </select>
                              {(errors?.questionForClinic !== undefined &&
                                errors?.questionForClinic[index]?.inputType?.type === "required") && (
                                  <div className="mt-1 text-sm text-burnt-sienna">
                                    Question Type is required.
                                  </div>
                                )}
                            </div>
                            <div>
                              <div className="mb-1 text-sm font-medium">Question Text</div>
                              <input
                                type="text"
                                placeholder="Question Text"
                                className="bg-alabaster border-none w-full rounded-md"
                                {...register(`questionForClinic.${index}.questionText`, { required: true })}
                              />
                              {(errors?.questionForClinic !== undefined &&
                                errors?.questionForClinic[index]?.questionText?.type === "required") && (
                                  <div className="mt-1 text-sm text-burnt-sienna">
                                    Question Text is required.
                                  </div>
                                )}
                            </div>
                            <div>
                              <div className="mb-1 text-sm font-medium">Placeholder</div>
                              <input
                                type="text"
                                placeholder="Placeholder"
                                className="bg-alabaster border-none w-full rounded-md"
                                {...register(`questionForClinic.${index}.placeholder`, { required: true })}
                              />
                              {(errors?.questionForClinic !== undefined &&
                                errors?.questionForClinic[index]?.placeholder?.type === "required") && (
                                  <div className="mt-1 text-sm text-burnt-sienna">
                                    Question Text is required.
                                  </div>
                                )}
                            </div>
                            <div>
                              <div className="mb-1 text-independence">Options</div>
                              <input
                                type="text"
                                placeholder="Enter options separate by ,"
                                className="bg-alabaster border-none w-full rounded-md"
                                {...register(`questionForClinic.${index}.options`, { required: false })}
                              />
                            </div>
                            <div>
                              <button onClick={() => (deleteTableRowsClinic(index))}>
                                <CloseCircle className="h-6 w-6" />
                              </button>
                            </div>
                          </div>
                        )
                      })}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="pt-6">
              <Button
                type="submit"
                className="bg-indigo text-white h-12 rounded-lg"
                text="lg"
                loading={loading}
              >
                Save
              </Button>
            </div>
          </form>
        </div>
      </div>
    </section>
  )
}

export default CreateServices
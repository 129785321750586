import authHeader from "auth-header";
import httpCommon from "http-common";

const create = (data) => {
    return httpCommon.post("/select/patient", data, { headers: authHeader() });
};

const deletePatient = (id) => {
    return httpCommon.delete(`/select/patient/${id}`, { headers: authHeader() });
};

const getAll = async (params) => {
    const config = {
      headers: authHeader(),
      params: params,
    };
  
    const response = await httpCommon.get(`/select/patients`, config);
    return response;
  };

const ClinicSelectPatient = {
    create,
    deletePatient,
    getAll
};

export default ClinicSelectPatient;

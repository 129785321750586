import React, { useState } from "react";
import { Input, Switch } from "components/common";
import "./serviceWidget.css";
const ServiceWidget = (props) => {
  const { accessUrl, name, disableSwitch, id } = props.service;
  const { onServiceUpdate } = props;
  const [available, setAvailable] = useState(disableSwitch);

  const { register, errors, setValue } = props;
  return (
    <div key={id} className="mb-4">
      <div className="grid grid-cols-4 items-center">
        <div className="md:flex items-center">
          <img
            src={accessUrl}
            alt="service icon"
            className="mr-2 inline h-12 w-12 rounded-full object-cover"
          />
          <div>{name}</div>
        </div>
        <div className="mx-4 lg:mx-16">
          <Switch
            isOn={available}
            onToggle={() => {
              let serviceTobeUpdated = { ...props.service };
              serviceTobeUpdated.disableSwitch = !available;
              onServiceUpdate(serviceTobeUpdated);
              if(available) {
                setValue(`charge-${id}`, '')
              // } else {
              //   setError(`charge-${id}`, { type:'required', message:'' })
              }
              setAvailable(!available);
              
            }}
          />
        </div>
        <div>
          <div>{available ? "Available" : "Not available"}</div>
        </div>

        {available && (
          <div className="flex items-center">
            &pound;
            <Input
              register={register}
              onChange={(e) => {
                let serviceTobeUpdated = { ...props.service };
                serviceTobeUpdated.charge = e.target.value;
                onServiceUpdate(serviceTobeUpdated);
              }}
              errors={errors}
              type="number"
              min="0"
              step="0.01"
              apiKey={`charge-${id}`}
              containerClassName={"p-0"}
              className="w-20 rounded-lg border-alto"
              errorMsg={"Charge is required"}
            ></Input>
          </div>
        )}
      </div>
    </div>
  );
};

export default ServiceWidget;

import authHeader from "auth-header";
import httpCommon from "http-common";

const getCounts = (id, data) => {
    const config = {
        headers: authHeader(),
    };
    return httpCommon.post(`/dashboard/counts/${id}`, data, config);
};

const getClinicLogo = (id) => {
    const config = {
        headers: authHeader(),
    };
    return httpCommon.get(`/dashboard/clinic-logo/${id}`, config);
};

const DashboardService = {
    getCounts,
    getClinicLogo
};

export default DashboardService;
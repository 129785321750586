import { retrieveUserById } from 'actions/auth';
import { retrieveOneClinic, updateClinic } from 'actions/clinics';
import { createSignedUrl, uploadFile } from 'actions/fileUpload';
import { CloseCircle } from 'components/icons';
import { Button } from 'components/ui';
import Toggle from 'components/ui/Toggle';
import React, { useCallback, useEffect, useState } from 'react'
import Dropzone from 'react-dropzone';
import { Controller, FormProvider, useForm } from 'react-hook-form';
import toast from 'react-hot-toast';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { dayCodeToDayName } from 'utils/dayName';
import Search from 'components/ui/Search';

const ClinicSetting = () => {

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [loading, setLoading] = useState(false);
  const [clinic, setClinic] = useState({});
  const [media, setMedia] = useState([]);
  const [existMedia, setExistMedia] = useState([]);
  const [mediaImageError, setMediaImageError] = useState();
  const [logoImageError, setLogoImageError] = useState();

  const auth = JSON.parse(localStorage.getItem("clinic-u"));
  const clinicId = auth?.clinic[0]?.id

  const methods = useForm();

  const {
    register,
    handleSubmit,
    setValue,
    control,
    watch,
    formState: { errors },
  } = methods

  const removeMedia = (img) => {
    setExistMedia(current =>
      current.filter(imageKey => {
        return imageKey.key !== img.key;
      }),
    );
  };

  const fetchClinic = useCallback(() => {
    const params = {
      include: "openingDays,openingDays.clinicHours,clinicServices,clinicServices.services,clinicServices.serviceOpeningDays,serviceOpeningDays.serviceHours",
    }
    dispatch(retrieveOneClinic(clinicId, params))
      .then((res) => {
        setClinic(res);
        setExistMedia(res?.media);
      })
      .catch((err) => console.log(err));
  }, [
    dispatch,
    clinicId,
  ]);

  watch((data) => {
    if (!data?.logo[0]?.name.match(/\.(jpg|jpeg|png)$/)) {
      setLogoImageError("Image Shoud be .png, .jpg, .jpeg");
    }
  });

  const onSubmit = async (data) => {
    setLoading(true);
    let s3Key
    if (data?.logo?.length > 0) {
      //upload logo 
      const body = {
        fileName: data?.logo[0]?.name,
        mimeType: data?.logo[0]?.type,
        directory: "clinic"

      }
      await dispatch(createSignedUrl(body)).then(async (result) => {
        s3Key = result?.s3Key
        await dispatch(uploadFile(result?.signedUrl, data?.logo[0])).then((res) => {
          data.logo = s3Key;
          console.log("logo uploaded");
          localStorage.removeItem("clinic-image");
        });
      })
        .catch((error) => {
          toast.error(error.response.data.errors[0].detail);
        })
    } else {
      data.logo = clinic.logo;
    }
    if (data?.media?.length > 0) {
      //check each file is image or not
      for (const img of data?.media) {
        if (!img.name.match(/\.(jpg|jpeg|png)$/)) {
          setMediaImageError("Image Shoud be .png, .jpg, .jpeg");
        }
      }
    }
    let media = []
    if (data?.media?.length > 0) {
      //upload media
      for (const image of data?.media) {
        const body = {
          fileName: image?.name,
          mimeType: image?.type,
          directory: "clinic"
        }
        await dispatch(createSignedUrl(body)).then(async (result) => {
          await dispatch(uploadFile(result?.signedUrl, image))
          media.push(result?.s3Key);
        })
          .catch((error) => {
            console.log(error, "error");
            toast.error(error.response.data.errors.detail);
          })
      }
      //add already exist image
      existMedia?.map((img) => {
        media.push(img?.key)
      })
    } else {
      //add already exist image
      existMedia?.map((img) => {
        media.push(img?.key)
      })
    }
    data.media = media;
    data.clinicServices = clinic?.clinicServices?.filter(service => service?.services !== null);
    for (const service of clinic?.clinicServices) {
      const serviceOpeningDays = clinic?.openingDays.map(({ clinicHours, ...rest }) => ({ ...rest, serviceHours: clinicHours }));
      const updatedData = serviceOpeningDays.map(({ id, nested, ...rest }) => ({
        ...rest,
        nested: { ...nested }
      }));
      
      if(service?.serviceOpeningDays <= 0){
        service.serviceOpeningDays = updatedData
      }
      for (const serviceOpenDay of service?.serviceOpeningDays) {
        if(serviceOpenDay?.serviceHours?.length <= 0){
          // console.log("serviceHours",service?.services?.name)
          service.serviceOpeningDays = updatedData
        }
      }
    }
    // const nullDefault = ?.filter(service => service?.serviceOpeningDays?.filter(serviceOpenDays => console.log(serviceOpenDays?.serviceHours?.length,"condition")));
    dispatch(updateClinic(clinicId, data))
      .then((response) => {
        localStorage.removeItem("clinic-u");
        const params = {
          include: "role,clinic",
        };
        dispatch(retrieveUserById(auth?.id, params))
          .then((response) => {
            if (response.status === 200) {
              setLoading(false);
              localStorage.setItem("clinic-u", JSON.stringify(response.data));
              toast.success("Clinic Update Successfully");
              window.location.href = "/dashboard";
            }
          })
      })
      .catch((error) => {
        toast.error(error.response.data.errors[0].detail);
        setLoading(false);
      })
  }

  useEffect(() => {
    if (clinicId !== undefined) {
      fetchClinic();
    }
  }, [
    fetchClinic,
    clinicId,
  ]);

  useEffect(() => {
    setValue("name", clinic?.name);
    setValue("address", clinic?.address);
    setValue("emailAddress", clinic?.emailAddress);
    setValue("phone", clinic?.phone);
    setValue("postCode", clinic?.postCode);
    setValue("landlineNumber", clinic?.landlineNumber);
    clinic?.openingDays?.map((data, i) => {
      setValue(
        `openingDays.${i}.id`, data?.id
      );
      setValue(
        `openingDays.${i}.weekDay`, data?.weekDay
      );
      setValue(
        `openingDays.${i}.isActive`, data?.isActive
      );
      data?.clinicHours?.map((time, ind) => {
        setValue(
          `openingDays.${i}.clinicHours.${ind}.from`, time?.from
        );
        setValue(
          `openingDays.${i}.clinicHours.${ind}.to`, time?.to
        );
      })
    });
  }, [
    setValue,
    clinic?.openingDays,
    clinic?.name,
    clinic?.address,
    clinic?.emailAddress,
    clinic?.phone,
    clinic?.postCode,
    clinic?.landlineNumber,
  ]);

  return (
    <section className="min-h-screen ml-6 mr-7 py-6 justify-center items-center">
      <div>
        <div className="flex mb-4 justify-between">
          <div className="text-xl font-semibold">Account Setting</div>
          <div className="text-lg text-indigo" onClick={() => navigate("/change-passwrd")}>
            Change password
          </div>
        </div>
        <div className="rounded-lg w-auto">
          <FormProvider {...methods} >
            <form onSubmit={handleSubmit(onSubmit)}>
              <div className='px-8 py-8 mb-4 rounded-t-lg bg-white w-auto'>
                <div className="text-xl font-bold">Clinic Details</div>
                <div className="lg:grid lg:grid-cols-3">
                  <div className='lg:grid lg:grid-cols-1'>
                    <div className='lg:pr-16 pr-0'>
                      <div className="mb-1 text-sm font-medium">Name</div>
                      <input
                        type="text"
                        className="bg-alabaster w-full border-none rounded-md"
                        {...register("name", { required: true })}
                      />
                      {errors?.name?.type === "required" && (
                        <div className="mt-1 text-sm text-burnt-sienna">
                          Clinic Name is required.
                        </div>
                      )}
                    </div>
                    <div className='lg:pr-16 pr-0'>
                      <div className="mb-1 text-sm font-medium">Phone Number</div>
                      <input
                        type="text"
                        className="bg-alabaster w-full border-none rounded-md"
                        {...register("phone", {
                          required: true,
                          minLength: 10,
                          maxLength: 11,
                          pattern: {
                            value: /^[0-9]+$/,
                            message: "Please enter a number.",
                          },
                        })}
                      />
                      {errors?.phone?.type === "required" && (
                        <div className="mt-1 text-sm text-burnt-sienna">
                          Phone is required.
                        </div>
                      )}
                      {(errors?.phone?.type === "minLength" ||
                        errors?.phone?.type === "maxLength") && (
                          <div className="mt-1 text-sm text-burnt-sienna">
                            Phone number must be valid and contain 10 to 11 digits.
                          </div>
                        )}
                      {errors?.phone?.type === "pattern" && (
                        <div className="mt-1 text-sm text-burnt-sienna">
                          {errors?.phone?.message}
                        </div>
                      )}
                    </div>
                    <div className="lg:pr-16 pr-0">
                      <div className="mb-1 text-sm font-medium">Post Code</div>
                      <input
                        type="text"
                        className="bg-alabaster w-full border-none rounded-md"
                        {...register("postCode", { required: false })}
                      />
                    </div>
                  </div>
                  <div className='lg:col-span-2'>
                    <div className='lg:grid grid-cols-2'>
                      <div className='lg:grid grid-cols-1'>
                        <div className="lg:pr-16 pr-0">
                          <div className="text-sm font-medium">Email</div>
                          <input
                            type="email"
                            className="bg-alabaster w-full border-none rounded-md py-2 px-2"
                            {...register("emailAddress", { required: true, pattern: /^\S+@\S+$/i, })}
                          />
                          {errors?.emailAddress?.type === "required" && (
                            <div className="mt-1 text-sm text-burnt-sienna">
                              Email is required.
                            </div>
                          )}
                          {errors.emailAddress?.type === "pattern" && (
                            <div className="mt-1 text-sm text-burnt-sienna">
                              Please match the requested format.
                            </div>
                          )}
                        </div>
                        <div className="lg:pr-16 pr-0">
                          <div className="mb-1 text-sm font-medium">Landline Number</div>
                          <input
                            type="landlineNumber"
                            className="bg-alabaster w-full border-none rounded-md py-2 px-2"
                            {...register("landlineNumber", { required: false })}
                          />
                        </div>
                      </div>
                      <div>
                        {clinic?.accessUrl &&
                          (
                            <img src={clinic?.accessUrl} alt="logo" className="inline object-cover w-24 h-24 mr-2 rounded-full" />
                          )
                        }
                        <div>
                          <input
                            id="logo"
                            type="file"
                            name="logo"
                            hidden
                            // className="bg-white w-full border-none rounded-md py-2 px-2"
                            {...register("logo", { required: false })}
                          />
                          <label htmlFor="logo">
                            <img src="/img/category/upload.png" alt="upload" name="logo" />
                          </label>
                          {logoImageError && <span className="mt-1 text-sm">Image Should be .img, .jpg or .jpeg</span>}
                        </div>
                      </div>
                      <div className='col-span-2'>
                        <div className="mb-1 text-sm font-medium">Address</div>
                        <Search />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className='px-8 py-8 mb-4 bg-white w-auto'>
                <div className="text-xl font-bold">Opening hours</div>
                <div>
                  {clinic?.openingDays?.map((days, i) => {
                    return (
                      <div key={i} className="mb-2 lg:grid lg:grid-cols-3 lg:items-center">
                        <Controller
                          control={control}
                          name={`openingDays.${i}.isActive`}
                          render={({ field: { ref, ...field } }) =>
                            <Toggle
                              {...field}
                              lable={dayCodeToDayName(days.weekDay)}
                            />
                          }
                        />
                        <input
                          type="text"
                          hidden
                          className="bg-alabaster w-auto border-none rounded-md"
                          {...register(`openingDays.${i}.id`, { required: false })}
                        />
                        {watch('openingDays')[i].isActive && (
                          <div className="lg:flex lg:space-x-2 lg:items-center">
                            <input
                              type="time"
                              className="bg-alabaster w-auto border-none rounded-md"
                              {...register(`openingDays.${i}.clinicHours.${0}.from`, { required: false })}
                            />
                            <div>TO</div>
                            <input
                              type="time"
                              className="bg-alabaster w-auto border-none rounded-md"
                              {...register(`openingDays.${i}.clinicHours.${0}.to`, { required: false })}
                            />
                          </div>
                        )}
                      </div>
                    )
                  })}
                </div>
              </div>
              <div className='px-8 py-8 rounded-b-lg bg-white w-auto'>
                <div className="text-xl font-bold">Photos</div>
                <div>
                  <div className="mb-6">
                    <div className="bg-alabaster w-auto py-10 flex justify-center text-xl font-semibold">
                      <Dropzone onDrop={acceptedFiles => {
                        setMedia(acceptedFiles);
                        setValue("media", acceptedFiles);
                      }}>
                        {({ getRootProps, getInputProps }) => (
                          <section>
                            <div {...getRootProps()}>
                              <input {...getInputProps()} />
                              <p>Drag 'n' drop some files here, or click to select files</p>
                            </div>
                          </section>
                        )}
                      </Dropzone>
                      {mediaImageError && <span className="mt-1 text-sm text-burnt-sienna">Image Should be .img, .jpg or .jpeg</span>}
                    </div>
                  </div>
                  <div className="lg:grid lg:grid-cols-6 lg:gap-1">
                    {media?.map((url, i) => (
                      <div className="w-full" key={i}>
                        <div className="w-full p-1 md:p-2">
                          <img src={URL.createObjectURL(url)} alt="seleted-file" className="block object-cover object-center rounded-lg" />
                        </div>
                      </div>
                    ))}
                  </div>
                  <div className="lg:grid lg:grid-cols-6 lg:gap-1">
                    {existMedia?.map((img, i) => (
                      <div className="w-full" key={i}>
                        <div className="w-full p-1 md:p-2 relative">
                          <CloseCircle
                            className="absolute -right-2 -top-2 h-7 w-h-7"
                            onClick={() => removeMedia(img)}
                          />
                          <img src={img?.accessUrl} alt="seleted-file" className="block object-cover object-center rounded-lg" />
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
              <div className="pt-6">
                <Button
                  type="submit"
                  className="h-12 rounded-lg bg-indigo text-white"
                  text="lg"
                  loading={loading}
                >
                  Save
                </Button>
              </div>
            </form>
          </FormProvider>
        </div>
      </div>

    </section>
  )
}

export default ClinicSetting
import { Switch } from "@headlessui/react";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

const Toggle = ({ value, onChange, lable, disabled = false, switchStyles }) => {
  return (
    <Switch.Group as="div" className="grid grid-cols-2">
      {lable && (
        <span>
          <Switch.Label
            as="span"
            className="text-sm font-medium text-gray-900"
            passive
          >
            {lable}
          </Switch.Label>
        </span>
      )}
      <Switch
        checked={value}
        onChange={onChange}
        disabled={disabled}
        className={classNames(
          value ? "bg-indigo" : "bg-dove-gray",
          "relative inline-flex flex-shrink-0 h-6 w-11 border-2 border-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-200", switchStyles
        )}
      >
        <span
          aria-hidden="true"
          className={classNames(
            value ? "translate-x-5" : "translate-x-0",
            "pointer-events-none inline-block h-5 w-5 rounded-full bg-white shadow transform ring-0 transition ease-in-out duration-200"
          )}
        />
      </Switch>
    </Switch.Group>
  );
}

export default Toggle;
import { retriveNMS } from "actions/nms";
import { CheckCircle } from "components/icons";
import { Avatar, Button } from "components/ui";
import moment from "moment";
import React, { useCallback, useEffect, useState } from "react";
import DataTable from "react-data-table-component";
import toast from "react-hot-toast";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import customStyles from "utils/data-table-style";

const NMS = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const auth = JSON.parse(localStorage.getItem("clinic-u"));
  const clinicId = auth?.clinic[0]?.id;

  const [nms, setNms] = useState([]);
  const [page, setPage] = useState(1);
  const [isLoaded, setIsLoaded] = useState(false);
  const [totalRows, setTotalRows] = useState(0);
  const [perPage, setPerPage] = useState(10);

  const handlePageChange = (page) => {
    setPage(page);
  };

  const handlePerRowsChange = async (newPerPage, page) => {
    setPerPage(newPerPage);
  };

  const columns = [
    {
      name: "Date",
      selector: (row) => moment(row?.createdAt).format("MMM Do YYYY"),
    },
    {
      name: "Patient’s name",
      cell: (row) => (
        <div className="lg:flex items-center">
          <Avatar
            firstName={row?.patient?.firstName}
            lastName={row?.patient?.lastName}
            image=""
          />
          <div className="ml-1">
            {row?.patient?.firstName} {row?.patient?.lastName}
          </div>
        </div>
      ),
      minWidth: "200px"
    },
    {
      name: "Medicine",
      selector: (row) => (
        <div>
          {row?.medicines[0]}, {row?.medicines[1]}{" "}
          {row?.medicines?.length - 2 > 0 && (
            <span className="text-indigo">
              +{row?.medicines?.length - 2} more
            </span>
          )}
        </div>
      ),
    },
    {
      name: "1st intervention",
      selector: (row) => (
        <div className="flex items-center">
          <div className="mr-1">
            <p>{moment(row?.firstIntervention).format("DD/MM/YYYY")}</p>
            <p className="text-indigo">
              {moment(row?.firstIntervention).format("hh:mm a")}
            </p>
          </div>
          {row.isFirstInterventionAnswered && (
            <CheckCircle className="w-h-8 h-8" />
          )}
        </div>
      ),
    },
    {
      name: "2nd follow up",
      selector: (row) => (
        <div className="flex items-center">
          <div>
            {row?.secondFollowUp && (
              <div>
                <div>{moment(row?.secondFollowUp).format("DD/MM/YYYY")}</div>
                <div className="text-indigo">
                  {moment(row?.secondFollowUp).format("hh:mm a")}
                </div>
              </div>
            )}
            {!row?.secondFollowUp && <div>-</div>}
          </div>
          <div>
            {row.isSecondFollowUpAnswered && (
              <CheckCircle className="w-h-8 h-8" />
            )}
          </div>
        </div>
      ),
    },
    {
      name: "",
      selector: (row) => row?.action,
      cell: (row) => (
        <Button
          className="rounded-md bg-indigo/10 text-indigo w-28"
          type="button"
          onClick={() => navigate(`/nms/${row?.id}`)}
        >
          View
        </Button>
      ),
      show: true,
    },
  ];

  const fetchNMS = useCallback(() => {
    const params = {
      page: page,
      limit: perPage,
      sort: "updatedAt",
      order: "desc",
      include: "patient,clinic,nms1STAnswer,nms2NDAnswer",
      "filter[clinic.id]": clinicId,
    };
    dispatch(retriveNMS(params))
      .then((result) => {
        setIsLoaded(true);
        setNms(result?.data);
        setTotalRows(result?.meta?.total);
      })
      .catch((error) => {
        toast.error(error.response.data.errors[0].detail);
        setIsLoaded(true);
      })
      .finally(() => {
        setIsLoaded(false);
      });
  }, [dispatch, setNms, page, perPage, clinicId]);

  useEffect(() => {
    fetchNMS();
  }, [fetchNMS]);

  return (
    <section className="ml-6 mr-7 min-h-screen items-center justify-center py-6">
      <div>
        <div className="flex mb-4 justify-between items-center">
          <div className="text-xl font-semibold">NMS</div>
          <div className="flex space-x-3 fill-alabaster">
            <div>
              <Button
                type="button"
                className="rounded-md bg-indigo text-white"
                width="full"
                text="base"
                onClick={() => navigate("/nms/create")}
              >
                Create
              </Button>
            </div>
          </div>
        </div>
        <div className="w-full rounded-lg bg-white py-7 px-8">
          <div className="w-auto rounded-lg">
            <DataTable
              columns={columns}
              data={nms}
              pagination
              paginationServer
              paginationTotalRows={totalRows}
              onChangePage={handlePageChange}
              onChangeRowsPerPage={handlePerRowsChange}
              progressPending={isLoaded}
              responsive={true}
              customStyles={customStyles}
            />
          </div>
        </div>
      </div>
    </section>
  );
};

export default NMS;

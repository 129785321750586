import { retriveOurClients } from 'actions/our-clients';
import React, { useCallback, useEffect, useState } from 'react'
import toast from 'react-hot-toast';
import { useDispatch } from 'react-redux';
import { GoArrowRight } from "react-icons/go";
import { Button } from 'components/ui';
import { useNavigate } from 'react-router-dom';

const JoinUs = () => {

    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [ourClients, setOurClients] = useState([]);
    const [isLoaded, setIsLoaded] = useState(false);

    const fetchOurClient = useCallback(() => {
        const params = {
            page: 1,
            limit: 100,
            sort: "updatedAt",
            order: "desc",
            aggregate: "image",
        };
        dispatch(retriveOurClients(params))
            .then((result) => {
                setIsLoaded(true);
                setOurClients(result?.data);
            })
            .catch((error) => {
                toast.error(error.response.data.errors[0].detail);
                setIsLoaded(true);
            })
            .finally(() => {
                setIsLoaded(false);
            });
    }, [dispatch, setOurClients]);

    useEffect(() => {
        fetchOurClient()
    }, [fetchOurClient])

    return (
        <section className="mt-24 flex flex-col items-center justify-center px-4">
            <div className="mb-8">
                <div className="font-plus-jakarta-sans font-bold text-5xl text-center text-elephant">Clinics who’ve already joined us!</div>
            </div>
            <div className="grid lg:grid-cols-4 grid-cols-2 gap-9 mb-14">
                {ourClients?.map((URL, index) => {
                    return (
                        <div key={index} className="flex flex-col items-center justify-center bg-white shadow-xl py-4 px-16 rounded-xl">
                            <img alt="sample_file" src={URL?.accessUrl} className="inline object-cover w-24 h-12 lg:h-24 md:h-16 rounded-full" />
                            <div className="font-plus-jakarta-sans font-semibold text-lg text-elephant mt-2">{URL?.name}</div>
                        </div>
                    )
                })}
            </div>
            <div className="font-plus-jakarta-sans font-medium text-lg text-elephant mb-14">
                It’s FREE for 90 days! Try it out and you’ll just love how the platform simplifies the process!
            </div>
            <div className="flex justify-center items-center mb-36">
                <Button
                    type="button"
                    width="56"
                    color="indigo"
                    className="rounded-lg text-white"
                    loading={false}
                    onClick={() => navigate("/register")}
                >
                    <div className="flex space-x-3 justify-center items-center">
                        <div className="text-white text-lg font-plus-jakarta-sans font-medium">Try For Free</div>
                        <GoArrowRight fill="white" />
                    </div>
                </Button>
            </div>
        </section>
    )
}

export default JoinUs
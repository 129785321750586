import { retriveBlogs } from 'actions/blogs';
import React, { useCallback, useEffect, useState } from 'react'
import { toast } from 'react-hot-toast';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import Header from './header';
import { Interweave } from 'interweave';
import NewFooter from './NewFooter';

const LandingBlogs = () => {

    const dispatch = useDispatch();
    const navigate = useNavigate();

    const [blogs, setBlogs] = useState([]);
    const [search, setSearch] = useState();

    const fetchBlogs = useCallback(() => {
        const params = {
            aggregate: "image",
            page: 1,
            limit: 90,
            search: search,
            sort: "updatedAt",
            order: "desc",
            isPublished: "true"
        };
        dispatch(retriveBlogs(params))
            .then((result) => {
                setBlogs(result?.data);
            })
            .catch((error) => {
                toast.error(error.response.data.errors[0].detail);
            })
    }, [dispatch, setBlogs, search]);

    useEffect(() => {
        fetchBlogs();
    }, [fetchBlogs]);

    return (
        <div className="min-h-screen">
            <Header />
            <section>
                <div className="w-full bg-indigo/10 mx-auto pt-16 pb-12 px-16 flex flex-col justify-center items-center">
                    <div className="max-w-7xl">
                        <h1 className="text-indigo text-3xl font-bold mb-5">
                            News, blogs, resources, etc
                        </h1>
                    </div>
                    <div>
                        <input
                            type="text"
                            placeholder="Search for anything"
                            onChange={(e) => setSearch(e.target.value)}
                            className="bg-white max-w-2xl lg:w-[42rem] md:w-[42rem] rounded-lg border-none px-5 py-5 mb-3 placeholder:text-center"
                        />
                    </div>
                </div>
            </section>
            <section>
                <div className="max-w-7xl mx-auto lg:py-12 lg:px-16 items-center">
                    <div className="lg:px-16 lg:py-12 px-8 py-6">
                        {/* <div className="grid grid-cols-1 gap-x-4 gap-y-8 md:grid-cols-2 lg:grid-cols-3 place-items-center mb-12"> */}
                        <div className="grid gap-5 md:grid-cols-2 lg:grid-cols-3 place-items-center mb-12">
                            {blogs?.map((item, i) => {
                                return (
                                    <article className="flex flex-col" key={i}>
                                        <img alt="clinic2go" className="object-cover w-full h-52 rounded-lg" src={item?.accessUrl} />
                                        <div className="flex flex-col flex-1">
                                            <h3
                                                className="flex-1 py-2 text-lg font-semibold leading-snug hover:underline"
                                                onClick={() => navigate(`/landing-blogs/${item?.id}`)}
                                            >
                                                {item?.title}
                                            </h3>
                                            <p>
                                                <Interweave content={item?.shortDescription} />
                                            </p>
                                            <div className="text-indigo underline mt-2 cursor-pointer" onClick={() => navigate(`/landing-blogs/${item?.id}`)}>
                                                read more
                                            </div>
                                        </div>
                                    </article>
                                )
                            })}
                        </div>
                    </div>
                </div>
            </section>
            <NewFooter />
        </div>
    )
}

export default LandingBlogs
import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  clinic: {},
};

export const clinicSlice = createSlice({
  name: "clinic",
  initialState,
  reducers: {
    updateClinicData: (state, action) => {
      state.clinic = { ...state.clinic, ...action.payload };
    },
    clearClinicData: (state) => {
      state.clinic = {};
    },
  },
});

export const { updateClinicData, clearClinicData } = clinicSlice.actions;

export default clinicSlice.reducer;

import React, { useState, useRef, useEffect } from 'react';
import { GoChevronRight } from "react-icons/go";

const CardSlider = ({ cards }) => {
    const [startIndex, setStartIndex] = useState(0);
    const [expandedCardIndex, setExpandedCardIndex] = useState(null);
    const containerRef = useRef(null);
    const [cardsPerPage, setCardsPerPage] = useState(1);
    // const containerRef = useRef(null);

    // const cardsPerPage = 4;

    const handleNext = () => {
        const newIndex = Math.min(
            startIndex + cardsPerPage,
            cards.length - cardsPerPage
        );
        setStartIndex(newIndex);
        scrollToIndex(newIndex);
    };

    const handlePrevious = () => {
        const newIndex = Math.max(startIndex - cardsPerPage, 0);
        setStartIndex(newIndex);
        scrollToIndex(newIndex);
    };

    const scrollToIndex = (index) => {
        const cardWidth = containerRef.current.children[0].offsetWidth;
        containerRef.current.scrollLeft = index * cardWidth;
    };

    const handleCardClick = (index) => {
        if (index === expandedCardIndex) {
            // If the same card is clicked again, collapse it.
            setExpandedCardIndex(null);
        } else {
            // Expand the clicked card.
            setExpandedCardIndex(index);
        }
    };

    useEffect(() => {
        const handleResize = () => {
            if (window.innerWidth >= 768) {
                setCardsPerPage(4); // Set to 4 for larger screens
            } else {
                setCardsPerPage(1); // Set to 1 for smaller screens
            }
        };

        // Initial setup
        handleResize();

        // Add event listener for window resize
        window.addEventListener('resize', handleResize);

        // Clean up the event listener on component unmount
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    return (
        <div className="flex items-center justify-center max-w-7xl">
            <img
                onClick={handlePrevious}
                src="/img/landingPage/previous.png"
                alt="clinic2go"
            />
            <div className="flex w-full overflow-x-hidden" ref={containerRef}>
                {cards.map((card, index) => (
                    <div
                        key={index}
                        className={`flex-none lg:w-1/4 w-full p-4 ${index >= startIndex && index < startIndex + cardsPerPage
                            ? 'opacity-100'
                            : 'opacity-50'
                            }`}
                    >
                        <div className="bg-white shadow-lg rounded-lg p-4 flex flex-col justify-center items-center">
                            {card?.accessUrl && (
                                <div>
                                    <img src={card?.accessUrl} alt="clinic2go" className="rounded-full w-auto h-32"/>
                                </div>
                            )}
                            <h2 className="text-lg font-semibold">{card?.name}</h2>
                            {expandedCardIndex !== index && (
                                <div className="mt-8">
                                    <img
                                        onClick={() => handleCardClick(index)}
                                        src="/img/landingPage/more.png"
                                        alt="clinic2go"
                                    />
                                </div>
                            )}
                            {expandedCardIndex === index && (
                                <div className="mt-2 mb-4">
                                    {card?.services?.map((service, i) => (
                                        <div key={i} className="rounded-full bg-elephant mb-4 px-4 py-2 flex flex-row space-x-4 justify-between items-center">
                                            <div className="flex flex-row space-x-4 items-center">
                                                <img
                                                    onClick={() => handleCardClick(index)}
                                                    src={service?.accessUrl}
                                                    alt="clinic2go"
                                                    className="bg-white object-contain w-10 h-10 rounded-full"
                                                />
                                                <div className="text-[9px] text-white font-plus-jakarta-sans font-semibold">{service?.name}</div>
                                            </div>
                                            <div className="flex justify-end items-ejustify-end">
                                                <GoChevronRight fill="white" />
                                            </div>
                                        </div>
                                    ))}
                                    <div className="flex justify-center items-center">
                                        <img
                                            onClick={() => handleCardClick(index)}
                                            src="/img/landingPage/more1.svg"
                                            alt="clinic2go"
                                            className="w-11 h-11"
                                        />
                                    </div>
                                </div>
                            )}
                        </div>
                    </div>
                ))}
            </div>

            <img
                onClick={handleNext}
                src="/img/landingPage/next.png"
                alt="clinic2go"
            />
        </div>
    );
};

export default CardSlider;

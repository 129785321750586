import FileUploadService from "services/fileUpload";
import {
    FILE_UPLOAD,
    GET_SIGNED_URL,
} from "./type";

export const createSignedUrl = (data) => async (dispatch) => {
    try {
        const res = await FileUploadService.generateSignedUrl(data);

        dispatch({
            type: GET_SIGNED_URL,
            payload: res.data,
        });

        return Promise.resolve(res.data);
    } catch (error) {
        return Promise.reject(error);
    }
};

export const uploadFile = (url, data) => async (dispatch) => {
    try {
      const res = await FileUploadService.upload(url, data);
  
      dispatch({
        type: FILE_UPLOAD,
        payload: res.data,
      });
  
      return Promise.resolve(res.data);
    } catch (error) {
      return Promise.reject(error);
    }
  };
import { createContactUs } from 'actions/contact-us';
import { Button } from 'components/ui';
import React, { useState } from 'react'
import { useForm } from 'react-hook-form';
import toast from 'react-hot-toast';
import { useDispatch } from 'react-redux';

const Contact = () => {

  const dispatch = useDispatch();

  const [loading, setLoading] = useState(false)

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const onSubmit = async (data) => {
    setLoading(true)
    await dispatch(createContactUs(data)).then((result) => {
      setLoading(false);
      toast.success("Message sent Successfully!!");
    })
      .catch((error) => {
        setLoading(false);
        toast.error(error.response.data.errors[0].detail);
      })
  }

  return (
    <section className="my-24 flex flex-col items-center justify-center px-4" id="contact-us">
      <div className="max-w-7xl">
        <div className="lg:flex lg:justify-center lg:space-x-20 lg:items-start">
          <div>
            <img src="/img/landingPage/base.png" alt="clinic2go-contactus" />
          </div>
          <div className="mt-7">
            <div className="font-plus-jakarta-sans font-bold text-5xl text-elephant mb-8">
              Have any questions?
            </div>
            <div>
              <form onSubmit={handleSubmit(onSubmit)}>
                <div className="max-w-2xl">
                  <div className="grid lg:grid-cols-2 gap-10 mb-6">
                    <div className="col-span-2 lg:col-span-1">
                      <div className="mb-2 text-lg text-elephant font-plus-jakarta-sans font-normal">
                        <label>First name</label>
                      </div>
                      <div>
                        <input
                          type="text"
                          placeholder="Enter Name"
                          className="text-lg shadow-lg bg-white text-elephant font-plus-jakarta-sans font-normal rounded-lg border-none px-5 py-5"
                        />
                      </div>
                    </div>
                    <div className="col-span-2 lg:col-span-1">
                      <div className="mb-2 text-lg text-elephant font-plus-jakarta-sans font-normal">
                        <label>Last name</label>
                      </div>
                      <div>
                        <input
                          type="text"
                          placeholder="Enter Name"
                          className="text-lg shadow-lg bg-white text-elephant font-plus-jakarta-sans font-normal rounded-lg border-none px-5 py-5"
                        />
                      </div>
                    </div>
                  </div>
                  <div className="mb-6">
                    <div className="mb-2 text-lg text-elephant font-plus-jakarta-sans font-normal">
                      <label>Email</label>
                    </div>
                    <div>
                      <input
                        type="email"
                        placeholder="Enter your Email"
                        className="text-lg shadow-lg bg-white text-elephant font-plus-jakarta-sans font-normal w-full rounded-lg border-none px-5 py-5"
                        {...register("email", { required: true })}
                      />
                      {errors?.email?.type === "required" && (
                        <div className="mt-1 text-sm text-burnt-sienna">
                          Email is required.
                        </div>
                      )}
                    </div>
                  </div>
                  <div className="mb-6 text-lg text-elephant font-plus-jakarta-sans font-normal">
                    <label>Message</label>
                    <textarea
                      placeholder="Enter message"
                      rows={3}
                      className="w-full resize-none rounded-md border-none text-lg shadow-lg bg-white text-elephant font-plus-jakarta-sans font-normal "
                      {...register("message", { required: true })}
                    />
                    {errors?.message?.type === "required" && (
                      <div className="mt-1 text-sm text-burnt-sienna">
                        Message is required.
                      </div>
                    )}
                  </div>
                  <div className="flex items-start justify-start">
                    <Button
                      type="submit"
                      color="indigo"
                      width="56"
                      className="h-12 rounded-lg text-base font-semibold bg-indigo text-white"
                      text="lg"
                      loading={loading}
                    >
                      submit
                    </Button>
                  </div>
                  {/* <div >
                    <img src="/img/landingPage/Vector.png" alt="clinic2go-contactus" className="relative -top-[592px] -right-96 h-[592px] object-cover" />
                  </div> */}
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default Contact
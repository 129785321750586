import React, { useState, useEffect } from "react";
import { useForm, Controller } from "react-hook-form";
import { updateClinicData } from "features/clinic/clinicSlice";
import { Input } from "components/common";
import { Button } from "components/ui";
import { useDispatch, useSelector } from "react-redux";
import PhoneInput from "react-phone-input-2";
import usePlacesAutocomplete , { getGeocode, getLatLng } from "use-places-autocomplete";
import useGoogle from "react-google-autocomplete/lib/usePlacesAutocompleteService";

const Step1ofClinic = (props) => {
  const { setSelectedIndex, setLogoObject } = props;
  const dispatch = useDispatch();
  const step1Data = useSelector(
    (state) => state.persistedReducer.clinic.clinic
  );
  const id = useSelector(
    (state) => state.persistedReducer?.clinic?.clinic?.user?.id
  );
  const [selectedFile, setSelectedFile] = useState();
  const [preview, setPreview] = useState();
  const googleMapsApiKey = process.env.REACT_APP_GOOGLE_MAPS_KEY;

  const [pickupLocation] = useState({
    address: "",
    latitude: 0,
    longitude: 0,
  });

  const {
    suggestions: { data: suggestionData },
    setValue,
    clearSuggestions,
  } = usePlacesAutocomplete({
    requestOptions: {
      /* Define search scope here */
    },
    debounce: 300,
  });

  const handleInputPickup = (e) => {
    // Update the keyword of the input element
    setValue(e.target.value);
  };
  const { placesService } = useGoogle({
    apiKey: googleMapsApiKey,
  });

  const getPostalCode = (placedetails) => {
    let formattedPostalCode = placedetails.address_components.filter(
      (postalCode) => postalCode.types.includes("postal_code")
    )[0];
    return formattedPostalCode?.long_name || 12345;
  };

  const handleSelectPickup = (obj) => {
    const description = obj.description;
    setValue(description, false);
    clearSuggestions();
    // Get latitude and longitude via utility functions
    getGeocode({ address: description }).then((results) => {
      const { lat, lng } = getLatLng(results[0]);
      setValueForm("latitude", lat.toString());
      setValueForm("longitude", lng.toString());
    });

    placesService?.getDetails(
      {
        placeId: obj.place_id,
        fields: ["address_components"],
      },
      (res) => {
        let getCode = getPostalCode(res);
        setValueForm("postCode", getCode);
        setValueForm("address", obj.description);
      }
    );
  };

  const renderSuggestionsPickup = (data) =>
    data.map((suggestion) => {
      const {
        place_id,
        structured_formatting: { main_text, secondary_text },
      } = suggestion;

      return (
        <li
          key={place_id}
          onClick={() => {
            handleSelectPickup(suggestion);
          }}
        >
          <strong>{main_text}</strong> <small>{secondary_text}</small>
        </li>
      );
    });

  useEffect(() => {
    setValue(pickupLocation.address);
  }, [pickupLocation.address, setValue]);

  const {
    control,
    setValue: setValueForm,
    register,
    getValues,
    handleSubmit,
    formState: { errors },
  } = useForm({
    defaultValues: {
      name: step1Data.name || "",
      logo: step1Data.logo || "",
      emailAddress: step1Data.emailAddress || "",
      landlineNumber: step1Data.landlineNumber || "",
      latitude: step1Data.latitude || "",
      longitude: step1Data.longitude || "",
      phone: step1Data.phone || "",
      postCode: step1Data.postCode || "",
      address: step1Data.address || "",
    },
  });

  useEffect(() => {
    let objectUrl = {};
    if (selectedFile) {
      objectUrl = URL.createObjectURL(selectedFile);
      localStorage.setItem("logo1", objectUrl);
      let logodata = localStorage.getItem("logo1");
      setValueForm("logo", logodata);
      setPreview(logodata);
    } else {
      let logodata = localStorage.getItem("abc");
      setValueForm("logo", logodata);
      setPreview(logodata);
    }

    dispatch(updateClinicData({ logo: objectUrl }));

    // free memory when ever this component is unmounted
    // return () => URL.revokeObjectURL(objectUrl);
  }, [selectedFile, dispatch]);

  const handleFile = (e) => {
    let file = e.target.files;
    if (!file || file.length === 0) {
      setSelectedFile(undefined);
      return;
    }

    var reader = new FileReader();
    setSelectedFile(file[0]);
    setLogoObject(file[0]);
    reader.readAsDataURL(file[0]);
    reader.onloadend = function () {
      localStorage.setItem("abc", reader.result);
    };
  };
  let b = preview ? "hidden" : "";
  return (
    <form
      onSubmit={handleSubmit(() => {
        setSelectedIndex(1);
        const values = getValues();
        values.user = {
          id: id,
        };
        delete values.logo;
        dispatch(updateClinicData(values));
      })}
    >
      <div className="grid grid-cols-1 lg:grid-cols-3">
        <div className="justify-right mt-28 flex grow-0 flex-col items-center md:mt-0">
          {preview && (
            <img
              src={preview}
              alt=""
              className="w-6/12 rounded-full border-none align-middle shadow sm:w-4/12"
              style={{ height: "200px", width: "200px" }}
              onClick={() => {
                document.getElementById("add-img").click();
              }}
              cursor="pointer"
            />
          )}
          <div
            className={`flex h-[200px] w-[200px] items-center justify-center rounded-full bg-alabaster ${b}`}
          >
            <label
              htmlFor="add-img"
              className="material-icons-round cursor-pointer text-6xl text-indigo"
            >
              add
            </label>
            <input
              id="add-img"
              type="file"
              accept="image/*"
              className="sr-only"
              name="logo"
              {...register("logo", {
                required: !preview,
                onChange: handleFile,
              })}
            />
          </div>

          {errors?.logo?.type === "required" && (
            <div className="mt-1 text-sm text-burnt-sienna">
              Logo is required.
            </div>
          )}
          <label className="align-items-center">
            <div className="pt-4">
              <span className="text-sm tracking-wider text-indigo">
                Upload Logo
              </span>
            </div>
          </label>
        </div>
        <div className="lg:col-span-2">
          <div className="grid md:grid-cols-2">
            <Input
              label={"Clinic name"}
              register={register}
              errors={errors}
              className="w-full border-none bg-alabaster"
              containerClassName={"pb-3"}
              apiKey={"name"}
              errorMsg={"Clinic Name is required."}
            />
            <Input
              label={"Clinic Email"}
              register={register}
              errors={errors}
              className="w-full border-none bg-alabaster"
              containerClassName={"pb-3"}
              apiKey={"emailAddress"}
              pattern={/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i}
              errorMsg={"Clinic Email Address is required."}
              errorMsgPattern={"Please match the requested format."}
            />
          </div>
          <div className="grid md:grid-cols-2">
            <div className="unit block px-3 pt-0 pb-3">
              <div className="mb-1 text-sm font-medium">Phone Number</div>
              <Controller
                control={control}
                name="phone"
                rules={{ required: true }}
                render={({ field: { ref, ...field } }) => (
                  <PhoneInput
                    {...field}
                    inputExtraProps={{
                      ref,
                      required: true,
                      autoFocus: true,
                    }}
                    country={"gb"}
                    onlyCountries={["gb"]}
                    countryCodeEditable={false}
                    specialLabel={""}
                    inputStyle={{
                      border: "transparent",
                      borderRadius: "5px",
                      backgroundColor: "#f7f7f7",
                      width: "100%",
                      paddingBottom: "8px",
                    }}
                  />
                )}
              />
              {errors?.["phone"]?.type === "required" && (
                <div className="mt-1 text-sm text-burnt-sienna">
                  {"Phone number is required."}
                </div>
              )}
            </div>
            <Input
              label={"Landline number"}
              register={register}
              type="number"
              errors={errors}
              className="w-full border-none bg-alabaster"
              containerClassName={"pb-3"}
              apiKey={"landlineNumber"}
              errorMsg={"Landline number is required."}
            />
            <Input
              label={"Post code"}
              register={register}
              errors={errors}
              className="w-full border-none bg-alabaster"
              apiKey={"postCode"}
              errorMsg={"Post code is required."}
            />
          </div>
          <div className="grid grid-cols-1">
            <Input
              label={"Address"}
              register={register}
              onChange={handleInputPickup}
              type="textarea"
              errors={errors}
              className="w-full border-none bg-alabaster"
              containerClassName={"pb-3"}
              apiKey={"address"}
              errorMsg={"Address is required."}
            />
            {suggestionData && suggestionData.length > 0 && (
              <div
                className="location_suggestion_pickup"
                style={{
                  zIndex: 10,
                  lineHeight: "2rem",
                  cursor: "pointer",
                }}
              >
                <ul>{renderSuggestionsPickup(suggestionData)}</ul>
              </div>
            )}
          </div>
        </div>
      </div>

      <div className="flex justify-end pt-6">
        <Button
          className="rounded bg-indigo py-2 px-4 font-normal text-white"
          text="lg"
          type="submit"
        >
          Next
        </Button>
      </div>
    </form>
  );
};

export default Step1ofClinic;

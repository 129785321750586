import PatientService from "services/patient";
import {
    CREATE_PATIENT,
} from "./type";

export const createPatient = (data) => async (dispatch) => {
    try {
        const res = await PatientService.create(data);

        dispatch({
            type: CREATE_PATIENT,
            payload: res.data,
        });

        return Promise.resolve(res.data);
    } catch (error) {
        return Promise.reject(error);
    }
};

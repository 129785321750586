import React, { useCallback, useEffect, useState } from 'react'
import {
    Page,
    Document,
    StyleSheet,
    PDFViewer,
    View,
    Text,
    PDFDownloadLink,
} from "@react-pdf/renderer";
import { Button, ReportHeader } from 'components/ui';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import _ from 'lodash';
import moment from 'moment';
import { retrieveOneAppointment } from 'actions/appointment';
import { toast } from 'react-hot-toast';


const styles = StyleSheet.create({
    section: {
        flexDirection: "row",
        marginBottom: "20",
        justifyContent: "space-between",
        alignItems: "center"
    },
    page: {
        fontFamily: "Helvetica",
        fontSize: 11,
        paddingTop: 30,
        paddingLeft: 60,
        paddingRight: 60,
        lineHeight: 1.5,
        flexDirection: "column",
    },
    reportTitle: {
        color: "#1A1A1A",
        letterSpacing: 4,
        fontSize: 20,
        textAlign: "center",
        marginBottom: 20,
        marginTop: 20
    },
    answerText: {
        color: "#3968CA"
    },
    subTitle: {
        marginBottom: "10"
    },
    subTitle1: {
        marginTop: "10",
    }
});

const AppointmentReport = () => {

    const { appointmentId } = useParams();
    const dispatch = useDispatch();
    const [appointment, setAppointment] = useState({});

    const fetchAppointment = useCallback(() => {
        const params = {
            include:
                "clinic,service,clinicService,user,answeredByPatient,answeredByClinic,answeredByClinic.question,answeredByPatient.questions,appointmentCancel,service.questionForClinic",
        };
        dispatch(retrieveOneAppointment(appointmentId, params))
            .then((result) => {
                setAppointment(result);
            })
            .catch((error) => {
                toast.error(error.response.data.errors[0].detail);
            });
    }, [dispatch, setAppointment, appointmentId]);

    useEffect(() => {
        if (appointmentId !== undefined) {
            fetchAppointment();
        }
    }, [fetchAppointment, appointmentId]);

    return (
        <div className="overflow-x-hidden">
            <PDFDownloadLink
                document={
                    <Document>
                        <Page size="A4" style={styles.page}>
                            <ReportHeader clinicName={appointment?.clinic?.name} />
                            <View style={styles.section}>
                                <Text>Appointment / {appointment?.user?.firstName !== null && appointment?.user?.firstName} {appointment?.user?.lastName !== null && appointment?.user?.lastName}/ {appointment?.service?.name} /{" "} (NHS: {appointment?.user?.NHS})</Text>
                                <Text style={styles.answerText}>Fee: £ {appointment?.paymentAmount}</Text>
                            </View>
                            <Text style={styles.subTitle}>Patient's Details: </Text>
                            <Text>Name: {appointment?.user?.firstName !== null && appointment?.user?.firstName} {appointment?.user?.lastName !== null && appointment?.user?.lastName}</Text>
                            <Text>Email: {appointment?.user?.emailAddress}</Text>
                            <Text>Phone: {appointment?.user?.phone}</Text>
                            <Text>Gender: {appointment?.user?.gender}</Text>
                            <Text>DOB: {moment(appointment?.user?.dob).format("YYYY-MM-DD")}</Text>
                            <Text>Appointment Status: {appointment?.status}</Text>

                            <Text style={styles.subTitle1}>Appointment Date/Time: </Text>
                            <Text style={styles.answerText}>{appointment?.date}{" "}{appointment?.time}</Text>

                            <View style={styles.titleContainer}>
                                <Text style={styles.reportTitle}>Patient’s form</Text>
                                {_.orderBy(appointment?.answeredByPatient, item => item.questions.position, ['asc'])?.map((data, i) => {
                                    return (
                                        <div key={i}>
                                            <Text>{data?.questions?.questionText}</Text>
                                            <Text style={styles.answerText}>Answer: {data?.answerText}</Text>
                                        </div>
                                    )
                                }
                                )}
                                <Text style={styles.reportTitle}>Pharmacist’s form</Text>
                                {_.orderBy(appointment?.answeredByClinic, item => item.question.position, ['asc'])?.map((data, i) => {
                                    return (
                                        <div key={i}>
                                            <Text>{i + 1}. {data?.question?.questionText}</Text>
                                            <Text style={styles.answerText}>Answer: {data?.answerText}</Text>
                                        </div>
                                    )
                                }
                                )}
                            </View>
                        </Page>
                    </Document>
                }
                fileName={"AppointmentReport.pdf"}
            >
                {({ blob, url, loading, error }) =>
                    loading ? (
                        "Loading document..."
                    ) : (
                        <div className="is-flex align-content is-justify-content-center m-6">
                            <Button
                                className="rounded bg-indigo py-2 px-4 font-medium text-white"
                                width="52"
                                text="lg">
                                Download Report
                            </Button>
                        </div>
                    )
                }
            </PDFDownloadLink>

            <PDFViewer height={window.innerHeight} width={window.innerWidth}>
                <Document>
                    <Page size="A4" style={styles.page}>
                        <ReportHeader clinicName={appointment?.clinic?.name} />
                        <View style={styles.section}>
                            <Text>Appointment / {appointment?.user?.firstName !== null && appointment?.user?.firstName} {appointment?.user?.lastName !== null && appointment?.user?.lastName}/ {appointment?.service?.name} /{" "} (NHS: {appointment?.user?.NHS})</Text>
                            <Text style={styles.answerText}>Fee: £ {appointment?.paymentAmount}</Text>
                        </View>
                        <Text style={styles.subTitle}>Patient's Details: </Text>
                        <Text>Name: {appointment?.user?.firstName !== null && appointment?.user?.firstName} {appointment?.user?.lastName !== null && appointment?.user?.lastName}</Text>
                        <Text>Email: {appointment?.user?.emailAddress}</Text>
                        <Text>Phone: {appointment?.user?.phone}</Text>
                        <Text>Gender: {appointment?.user?.gender}</Text>
                        <Text>DOB: {moment(appointment?.user?.dob).format("YYYY-MM-DD")}</Text>
                        <Text>Appointment Status: {appointment?.status}</Text>

                        <Text style={styles.subTitle1}>Appointment Date/Time: </Text>
                        <Text style={styles.answerText}>{appointment?.date}{" "}{appointment?.time}</Text>

                        <View style={styles.titleContainer}>
                            <Text style={styles.reportTitle}>Patient’s form</Text>
                            {_.orderBy(appointment?.answeredByPatient, item => item.questions.position, ['asc'])?.map((data, i) => {
                                return (
                                    <div key={i}>
                                        <Text>{data?.questions?.questionText}</Text>
                                        <Text style={styles.answerText}>Answer: {data?.answerText}</Text>
                                    </div>
                                )
                            }
                            )}
                            <Text style={styles.reportTitle}>Pharmacist’s form</Text>
                            {_.orderBy(appointment?.answeredByClinic, item => item.question.position, ['asc'])?.map((data, i) => {
                                return (
                                    <div key={i}>
                                        <Text>{data?.question?.questionText}</Text>
                                        <Text style={styles.answerText}>Answer: {data?.answerText}</Text>
                                    </div>
                                )
                            }
                            )}
                        </View>
                    </Page>
                </Document>
            </PDFViewer>
        </div>
    )
}

export default AppointmentReport
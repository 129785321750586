import authHeader from "auth-header";
import httpCommon from "http-common";

const signIn = async (data) => {
  return httpCommon.post("/auth/login", data);
};

const getOne = async (userId, params) => {
  const config = {
    headers: authHeader(),
    params: params,
  };

  const response = await httpCommon.get(`/users/${userId}`, config);
  return response;
};

const renewTokens = (data) => {
  return httpCommon.post("/auth/refreshToken", data);
};

const forgotPassword = (data) => {
  return httpCommon.post("/auth/forgetPassword", data);
};

const forgotPasswordReset = (data) => {
  return httpCommon.post("/auth/resetPassword", data);
};



const AuthService = {
  signIn,
  getOne,
  renewTokens,
  forgotPassword,
  forgotPasswordReset
};

export default AuthService;
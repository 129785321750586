import { retriveBlogs } from 'actions/blogs';
import { BlogSlider } from 'components/ui'
import React, { useCallback, useEffect, useState } from 'react'
import toast from 'react-hot-toast';
import { useDispatch } from 'react-redux';

const BlogsSlider = () => {

  const dispatch = useDispatch();

  const [blogs, setBlogs] = useState([]);

  const fetchBlogs = useCallback(() => {
    const params = {
      aggregate: "image",
      page: 1,
      limit: 3,
      sort: "updatedAt",
      order: "desc",
      isPublished: "true"
    };
    dispatch(retriveBlogs(params))
      .then((result) => {
        setBlogs(result?.data);
      })
      .catch((error) => {
        toast.error(error.response.data.errors[0].detail);
      })
  }, [dispatch, setBlogs]);

  useEffect(() => {
    fetchBlogs();
  }, [fetchBlogs]);

  return (
    <section className="flex items-center justify-center px-4">
      <div className="max-w-[1620px] bg-[#F3FBFF] rounded-2xl flex flex-col space-x-5 justify-center items-center py-24 px-36 ">
        <div className="font-plus-jakarta-sans font-bold text-5xl text-center text-elephant mb-8">
          Our Recent Blogs
        </div>
        <div>
          <BlogSlider cards={blogs} />
        </div>
      </div>
    </section>
  )
}

export default BlogsSlider
import { renewTokens } from "actions/auth";
import { AppContext } from "context/AppContext";
import { useContext, useEffect } from "react";
import { useDispatch } from "react-redux";
import { Outlet, useNavigate } from "react-router-dom";

const parseJwt = (token) => {
    try {
      return JSON.parse(atob(token.split(".")[1]));
    } catch (e) {
      return null;
    }
  };

function App() {
    const navigate = useNavigate();
    const { setIsAuth } = useContext(AppContext);
    const dispatch = useDispatch();

    useEffect(() => {
        const localData = localStorage.getItem("clinicu");
        if (localData !== null) {
            setIsAuth(true);
            const decode = parseJwt(localData);
            if (decode.exp * 1000 < Date.now()) {
                //token expire call refresh token
                let userObj = JSON.parse(localData);
                const body = {
                    refreshToken: userObj?.refreshToken,
                };
                dispatch(renewTokens(body))
                    .then((response) => {
                        //update localstorage with newToken
                        localStorage.removeItem("clinicu");
                        localStorage.setItem("clinicu", JSON.stringify(response));
                        window.location.reload();
                    })
                    .catch((err) => {
                        //refreshToken is expired user should logout
                        localStorage.removeItem("clinicu");
                        localStorage.removeItem("clinic-u");
                        navigate("/dashboard");
                        setIsAuth(false);
                    });
            }
        }
    }, [setIsAuth, navigate, dispatch]);

    return(
        <Outlet />
    )
}

export default App;
import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import timeGridPlugin from "@fullcalendar/timegrid";
import interactionPlugin from "@fullcalendar/interaction";
import React, { useState } from "react";
import _ from "lodash";
import Button from "../Button";
import { useNavigate } from "react-router-dom";
import DataTable from "react-data-table-component";
import { useRef } from "react";
import Modal from "../Modal";
import customStyles from "utils/data-table-style";

const statusMapper = {
  Cancelled: "cancelled",
  in_progress: "patients",
  completed: "Completed",
};
const tcolorMapper = {
  Cancelled: "event-title-cancel",
  in_progress: "event-title-inprogress",
  completed: "event-title-complete",
};
const bcolorMapper = {
  Cancelled: "#FFFFFF",
  in_progress: "#FFFFFF",
  completed: "#FFFFFF",
};
const bdcolorMapper = {
  Cancelled: "#FFFFFF",
  in_progress: "#FFFFFF",
  completed: "#FFFFFF",
};

const Calendar = (props) => {
  const navigate = useNavigate();

  const columns = [
    {
      name: "Patient’s name",
      selector: (row) => `${row?.user?.firstName} ${row?.user?.lastName}`,
    },
    {
      name: "Service",
      selector: (row) => row.service?.name,
    },
    {
      name: "Time",
      selector: (row) => row?.time,
    },
    {
      name: "Status",
      // selector: (row) => row.status,
      cell: (row) =>
        row.status === "completed" ? (
          <div className="flex items-center justify-between">
            <input
              readOnly
              checked
              type="checkbox"
              className="mr-1 h-6 w-6 rounded-full text-christi"
            />
            {row.status.toUpperCase()}
          </div>
        ) : row.status === "Cancelled" ? (
          <div className="ml-8 text-burnt-sienna">
            {row.status.toUpperCase()}
          </div>
        ) : (
          <div className="ml-5 text-indigo">{row.status.toUpperCase()}</div>
        ),
      width: "max-content",
    },
    {
      name: "",
      selector: (row) => row.action,
      cell: (row) => (
        <Button
          className="rounded-md bg-indigo/10 text-indigo"
          type="button"
          onClick={() => navigate(`/appointments/${row?.id}`)}
        >
          View
        </Button>
      ),
      show: true,
    },
  ];

  const [modelOpen, setModelOpen] = useState(false);
  const [filteredDataByDate, setFilteredDataByDate] = useState([]);
  const [clickedDate, setClickedDate] = useState("");
  const { data } = props;
  const calendarRef = useRef();
  let groupedByDate = _.groupBy(data, "date");
  let finaldata = [];
  Object.keys(groupedByDate).map((date) => {
    let sameDatelist = groupedByDate[date];
    let groupedByStatus = _.groupBy(sameDatelist, "status");
    Object.keys(groupedByStatus).map((status) => {
      let obj = {};
      obj.date = date;
      obj.title = `${groupedByStatus[status].length} ${statusMapper[status]}`;
      obj.classNames = [`${tcolorMapper[status]}`];
      obj.backgroundColor = `${bcolorMapper[status]}`;
      obj.borderColor = `${bdcolorMapper[status]}`;
      // obj.classNames =['abc']
      finaldata.push(obj);
    });
  });

  const handleEventClick = (e) => {
    let filteredData = data.filter((d) => d.date === e.dateStr);
    setClickedDate(e.dateStr);
    setFilteredDataByDate(filteredData);
    openModel();
  };

  function openModel() {
    setModelOpen(true);
  }

  function closeModal() {
    setModelOpen(false);
  }

  //Custom button to control next
  const nextHandle = () => {
    setTimeout(() => {
      const api = calendarRef.current.getApi();
      api.next();
    });
  };
  //Custom button to control back (previous day)
  const prevHandle = () => {
    setTimeout(() => {
      const api = calendarRef.current.getApi();
      api.prev();
    });
  };

  return (
    <>
      {
        // showModal ? (
        //   <>
        //     <div className="backdrop fixed inset-0 z-50 flex items-center justify-center overflow-y-auto overflow-x-hidden outline-none focus:outline-none">
        //       <div className="relative my-6 mx-3 w-auto max-w-3xl">
        //         {/*content*/}
        //         <div className="relative flex w-full flex-col rounded-lg border-0 bg-white shadow-lg outline-none focus:outline-none">
        //           {/*header*/}
        //           <div className="border-slate-200 flex items-center justify-center rounded-t p-2">
        //             <h3 className="text-2xl font-semibold text-indigo">
        //               Appointments on {clickedDate}
        //             </h3>
        //             <button
        //               type="button"
        //               className="material-icons-round md-24 absolute -top-3 -right-3 text-burnt-sienna"
        //               onClick={() => setShowModal(false)}
        //             >
        //               cancel
        //             </button>
        //           </div>
        //           {/*body*/}
        //           <div className="relative flex-auto p-2">
        //             <div className="w-auto rounded-lg">
        //               <DataTable
        //                 columns={columns}
        //                 data={filteredDataByDate}
        //                 responsive={true}
        //               />
        //             </div>
        //           </div>
        //         </div>
        //       </div>
        //     </div>
        //   </>
        // ) : (
        <Modal
          // title={`Appointments on ${clickedDate}`}
          maxWidth="66rem"
          show={modelOpen}
          onClose={closeModal}
          onCancel={() => closeModal()}
          showCancel={true}
        >
          <div className="relative flex-auto">
            <div className="border-slate-200 flex items-center justify-center rounded-t p-2">
              <h3 className="text-xl font-normal text-indigo">
                Appointments on {clickedDate}
              </h3>
            </div>
            <div className="w-auto rounded-lg">
              <DataTable
                columns={columns}
                data={filteredDataByDate}
                responsive={true}
                customStyles={customStyles}
              />
            </div>
          </div>
        </Modal>
        // )
      }
      <FullCalendar
        ref={calendarRef}
        events={finaldata}
        plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin]}
        customButtons={{
          PrevButton: {
            text: "Prev",
            click: function () {
              prevHandle();
            },
          },
          NextButton: {
            text: "Next",
            click: function () {
              nextHandle();
            },
          },
        }}
        headerToolbar={{
          left: "",
          // left: 'dayGridMonth,timeGridWeek,timeGridDay',
          center: "title",
          right: "PrevButton,NextButton",
        }}
        initialView="dayGridMonth"
        editable={true}
        selectable={true}
        selectMirror={true}
        dayMaxEvents={true}
        /* Uncomment this to trigger the problem
        hiddenDays={[ 0 ]}
        */
        // weekends={weekendsVisible}
        // datesSet={handleDates}
        // select={handleDateSelect}
        // events={events}
        // eventContent={renderEventContent} // custom render function
        dateClick={(e) => {
          handleEventClick(e);
        }}
        // eventAdd={handleEventAdd}
        // eventChange={handleEventChange} // called for drag-n-drop/resize
        // eventRemove={handleEventRemove}
      />
    </>
  );
};

export default Calendar;

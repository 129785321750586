import { retrieveOneClinic } from "actions/clinics";
import { updateActiveClinicService } from "actions/clinicService";
import { Button, Modal } from "components/ui";
import Toggle from "components/ui/Toggle";
import _, { isEmpty } from "lodash";
import React, { useCallback, useEffect, useState } from "react";
import DataTable from "react-data-table-component";
import toast from "react-hot-toast";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import customStyles from "utils/data-table-style";

const ClinicServices = () => {

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const auth = JSON.parse(localStorage.getItem("clinic-u"));
  const clinicId = auth?.clinic[0]?.id;

  const [services, setServices] = useState([]);
  const [isLoaded, setIsLoaded] = useState();
  const [isModelOpen, setIsModelOpen] = useState(false);
  const [service, setService] = useState();

  const fetchClinic = useCallback(() => {
    setIsLoaded(true)
    const params = {
      aggregate: "serviceLogo",
      include: "openingDays,openingDays.clinicHours,clinicServices,clinicServices.services,clinicServices.serviceOpeningDays,serviceOpeningDays.serviceHours",
    }
    dispatch(retrieveOneClinic(clinicId, params))
      .then((res) => {
        setServices(res?.clinicServices?.filter((service) => service?.services !== null));
        setIsLoaded(false)
      })
      .catch((err) => console.log(err));
  }, [
    dispatch,
    clinicId,
  ]);

  function closeModal() {
    setIsModelOpen(false);
  }

  const onServiceUpdate = () => {
    const body = {
      isActive: !service?.isActive,
    }
    dispatch(updateActiveClinicService(service?.id, body)).then((result) => {
      setIsModelOpen(false);
      fetchClinic();
      toast.success("Service Update Successfully");
    })
      .catch((error) => {
        console.log(error);
        toast.error(error.response.data.errors[0].detail);
        setIsLoaded(false);
      })
  }

  const columns = [
    {
      name: 'Services',
      cell: row =>
        <div className="lg:flex justify-center items-center lg:space-x-1">
          <img className="inline object-cover w-12 h-12 mr-2 rounded-full" src={row?.services?.accessUrl} alt="service-logo" />
          <div>{row?.services?.name}</div>
        </div>
    },
    {
      name: 'Available on',
      selector: row => <div className="lg:flex justify-center">
        {_.orderBy(row?.serviceOpeningDays, ['weekDay'], ['asc'])?.map((day, i) => {
          return (
            <span key={i}>
              {day?.isActive && day.weekDay === 0 && "Su,"}
              {day?.isActive && day.weekDay === 1 && "Mo,"}
              {day?.isActive && day.weekDay === 2 && "Tu,"}
              {day?.isActive && day.weekDay === 3 && "We,"}
              {day?.isActive && day.weekDay === 4 && "Th,"}
              {day?.isActive && day.weekDay === 5 && "Fr,"}
              {day?.isActive && day.weekDay === 6 && "Sa"}
            </span>
          )
        })}
      </div>,
    },
    {
      name: 'Intervals',
      selector: row => <div>
        {row?.patientAtTime} patients every {row?.availabeEvery} min
      </div>,
    },
    {
      name: 'Fee',
      selector: row => <div>£ {row?.charge}</div>,
    },
    {
      name: 'Available',
      selector: row =>
        <div className="flex space-x-1 items-center">
          <Toggle
            value={row?.isActive}
            onChange={() => {
              setIsModelOpen(!isModelOpen);
              setService(row);
            }}
          />
          <div>{row?.isActive ? "Open" : "Closed"}</div>
        </div>,
      minWidth: "180px"
    },
    {
      name: "",
      selector: row => row.action,
      cell: row =>
        <Button
          className='bg-indigo/10 text-indigo rounded-md w-60 lg:w-32'
          type="button"
          onClick={() => navigate(`/clinic-services/${row?.id}`)}
        >
          Configure
        </Button>,
      show: true,
    },
  ];

  useEffect(() => {
    if (clinicId !== undefined) {
      fetchClinic();
    }
  }, [
    fetchClinic,
    clinicId,
  ]);

  return (
    <section className="min-h-screen ml-6 mr-7 py-6 justify-center items-center">
      <div>
        <div className='flex mb-4 justify-between items-center'>
          <div className='text-xl font-semibold'>Services</div>
          <div>
            <Button
              type="button"
              className="rounded-md bg-indigo text-white"
              width="full"
              text="base"
              onClick={() => navigate("/clinic-services/create")}
            >
              Add New Service
            </Button>
          </div>
        </div>
        <div className="rounded-lg w-auto">
          <DataTable
            columns={columns}
            data={services}
            progressPending={isLoaded}
            // progressComponent={<Loading />}
            responsive={true}
            customStyles={customStyles}
          />

        </div>
        <Modal
          title="Service Activation"
          maxWidth="32rem"
          show={isModelOpen}
          onClose={closeModal}
          showCancel={false}
        >
          <div className="mb-4 text-center font-light">
            Are you sure you want to {!service?.isActive && ("Enabled")} {service?.isActive && ("Disable")} this  {service?.services.name}?
          </div>
          <div className="flex items-center justify-center space-x-3">
            <Button type="button" className="rounded-lg" outlined onClick={closeModal} color="indigo">
              No
            </Button>
            <Button
              type="button"
              className="bg-indigo rounded-lg text-white"
              loading={isLoaded}
              onClick={() => {
                if (!isEmpty(service)) {
                  onServiceUpdate(service)
                }
              }}
            >
              Yes
            </Button>
          </div>
        </Modal>
      </div>

    </section>
  );
}

export default ClinicServices;

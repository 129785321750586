import React, { Fragment } from "react";

import { Outlet } from "react-router-dom";

import { Header, Sidebar } from "components/common";

const Layout = () => {
  return (
    <Fragment>
      <Header />
      <div>
        <Sidebar />
        <div className="lg:pl-60 lg:pt-[89px]">
          <main className="bg-wild-sand">
            <Outlet />
          </main>
        </div>
      </div>
    </Fragment>
  );
};

export default Layout;

import { retriveAppointments } from "actions/appointment";
import { CheckCircle } from "components/icons";
import { Avatar, Button, Calendar, Tabs } from "components/ui";
import React, { useCallback, useEffect, useState } from "react";
import DataTable from "react-data-table-component";
import toast from "react-hot-toast";
import { AiOutlineSearch } from "react-icons/ai";
import { useDispatch } from "react-redux";
import { useNavigate, useSearchParams } from "react-router-dom";
import customStyles from "utils/data-table-style";
import { AppointmentStatus } from "utils/roles";

const tabs = [
  {
    name: "List",
    filter: "list",
  },
  {
    name: "Calendar",
    filter: "calendar",
  },
];

const Appointments = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const auth = JSON.parse(localStorage.getItem("clinic-u"));
  const clinicId = auth?.clinic[0]?.id;

  const [searchParams, setSearchParams] = useSearchParams({
    filter: "list",
  });
  const filter = searchParams.get("filter");

  const [appointment, setAppointment] = useState([]);
  const [page, setPage] = useState(1);
  const [isLoaded, setIsLoaded] = useState(false);
  const [totalRows, setTotalRows] = useState(0);
  const [perPage, setPerPage] = useState(10);
  const [search, setSearch] = useState();
  const [sort, setSort] = useState("desc");
  const [sortField, setSortField] = useState("updatedAt");

  const handlePageChange = (page) => {
    setPage(page);
  };

  const handlePerRowsChange = async (newPerPage, page) => {
    setPerPage(newPerPage);
  };

  const columns = [
    {
      name: "Date",
      cell: (row) => (
        <div>
          <p>{row.date}</p>
          <p className="text-indigo">{row.time}</p>
        </div>
      ),
      sortable: true,
      sortField: 'date',
    },
    {
      name: "Patient’s name",
      cell: (row) => (
        <div className="items-center lg:flex">
          <Avatar
            firstName={row?.user?.firstName}
            lastName={row?.user?.lastName}
            image=""
          />
          <div className="ml-1">
            {row?.user?.firstName} {row?.user?.lastName}
          </div>
        </div>
      ),
      minWidth: "200px",
      sortable: true,
      sortField: 'user.firstName',
    },
    {
      name: "Service",
      selector: (row) => (
        <div>
          {row.service?.name && (
            <div>
              <div>{row.service?.name}</div>
            </div>
          )}
          {!row.service?.name && <div>-</div>}
        </div>
      ),
      sortable: true,
      sortField: 'service.name',
    },
    {
      name: "Status",
      selector: (row) => (
        <div>
          {row.status === AppointmentStatus.COMPLETED && (
            <div className="lg:flex lg:items-center">
              <CheckCircle className="w-h-8 h-8" />
              <div>Completed</div>
            </div>
          )}
          {row.status === AppointmentStatus.CANCEL && (
            <div className="text-burnt-sienna">Cancelled</div>
          )}
          {(row.status === AppointmentStatus.BOOKED ||
            row.status === AppointmentStatus.IN_PROGRESS) && (
              <div className="text-cod-gray/30">NA</div>
            )}
        </div>
      ),
    },
    {
      name: "",
      selector: (row) => row.action,
      cell: (row) => (
        <Button
          className="rounded-md bg-indigo/10 text-indigo w-28"
          type="button"
          onClick={() => navigate(`/appointments/${row?.id}`)}
        >
          View
        </Button>
      ),
      show: true,
    },
  ];

  const handleSort = (column, sortDirection) => {
    // simulate server sort
    console.log(column, sortDirection);
    setSortField(column?.sortField);
    setSort(sortDirection);
  };

  const fetchAppointment = useCallback(() => {
    const params = {
      page: page,
      limit: perPage,
      sort: sortField,
      order: sort,
      search: search,
      "filter[clinic.id]": clinicId,
      include:
        "clinic,service,clinicService,user,answeredByPatient,answeredByClinic,answeredByClinic.question,answeredByPatient.questions",
    };
    dispatch(retriveAppointments(params))
      .then((result) => {
        setIsLoaded(true);
        setAppointment(result?.data);
        setTotalRows(result?.meta?.total);
      })
      .catch((error) => {
        toast.error(error.response.data.errors[0].detail);
        setIsLoaded(true);
      })
      .finally(() => {
        setIsLoaded(false);
      });
  }, [dispatch, setAppointment, page, perPage, search, clinicId, sortField, sort]);

  useEffect(() => {
    fetchAppointment();
  }, [fetchAppointment]);

  return (
    <section className="ml-6 mr-7 min-h-screen items-center justify-center py-6">
      <div>
        <div className="mb-4 items-center justify-between md:flex">
          <div className="text-xl font-semibold">Appointments</div>
          <div className="flex space-x-3 fill-alabaster">
            <div className="relative h-10 w-full xl:w-auto">
              <AiOutlineSearch className="text-storm-gray absolute inset-y-0 left-3 my-auto" />
              <input
                type="text"
                className="w-full rounded-md border-none bg-white pl-9 font-medium focus:ring-0 xl:w-[320px]"
                placeholder="Search"
                onChange={(e) => {
                  setSearch(e.target.value);
                }}
              />
            </div>
            <div>
              <Button
                type="button"
                className="rounded-md bg-indigo text-white"
                width="full"
                text="base"
                onClick={() => navigate("/appointments/create")}
              >
                Create
              </Button>
            </div>
          </div>
        </div>
        <div className="w-full rounded-lg bg-white py-7 px-8">
          <div className="flex rounded-lg">
            <Tabs
              tabs={tabs}
              filter={filter}
              setSearchParams={setSearchParams}
            />
          </div>
          {filter === "list" && (
            <div className="w-auto rounded-lg">
              <DataTable
                columns={columns}
                data={appointment}
                pagination
                paginationServer
                paginationTotalRows={totalRows}
                onChangePage={handlePageChange}
                onChangeRowsPerPage={handlePerRowsChange}
                progressPending={isLoaded}
                responsive={true}
                customStyles={customStyles}
                onSort={handleSort}
                sortServer
              />
            </div>
          )}
          {filter === "calendar" && (
            <div className="w-auto rounded-lg">
              <Calendar data={appointment} />
            </div>
          )}
        </div>
      </div>
    </section>
  );
};

export default Appointments;

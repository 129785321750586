import { Button, Modal } from 'components/ui';
import React from 'react'

const ViewCategory = ({ isOpen, category, closeModal }) => {

  return (
    <Modal
      title="Category Details"
      maxWidth="32rem"
      show={isOpen}
      onClose={closeModal}
      showCancel={false}
    >
      <div className="mb-4 text-center font-light">
        <div className="text-xl font-semibold">
          Category Name : {category?.name}
        </div>
        {category?.image && (
          <div className="text-xl font-semibold flex space-x-4">
            <div>
              Category Image :
            </div>
            <div>
              <img src={category?.accessUrl} alt="clinic2go" />
            </div>
          </div>
        )}
      </div>
      <div className="flex items-center justify-center space-x-3">
        <Button
          type="button"
          className="rounded-lg text-indigo"
          outlined
          onClick={closeModal}
        >
          Cancel
        </Button>
      </div>
    </Modal>
  )
}

export default ViewCategory
import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { Button } from "components/ui";
import PhotosUpload from "../Clinic/photosUpload";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { createClinic } from "actions/clinics";
import { uploadImage } from "actions/images";
import toast from "react-hot-toast";
import axios from "axios";
import { clearClinicData } from "features/clinic/clinicSlice";
import { retrieveUserById } from "actions/auth";

const Step4ofClinic = (props) => {
  const { setSelectedIndex, logoObject } = props;
  const navigate = useNavigate();
  let data = useSelector((state) => state.persistedReducer.clinic.clinic);
  const openingHours =
    useSelector((state) => state.persistedReducer.clinic.clinic.openingHours) ||
    [];
  const { handleSubmit } = useForm({});
  const dispatch = useDispatch();
  const [files, setFiles] = useState([]);
  const [loading, setLoading] = useState(false);

  let createclinicu = localStorage.getItem("createclinicu");
  let createclinic_u = localStorage.getItem("createclinic-u");

  const onSubmit = async () => {
    setLoading(true);
    let b = [];
    let media = [];
    let logoUrl = "";
    if (files.length === 0) {
      toast.error("Please atleast upload one photo of the clinic");
      return;
    }
    let fileswithlogo = [logoObject, ...files];
    if (fileswithlogo.length > 0) {
      fileswithlogo.forEach((f) => {
        let fileData = {
          mimeType: f["type"],
          fileName: f["name"],
          directory: "clinic",
        };
        b.push(dispatch(uploadImage(fileData)));
      });
    }
    Promise.all(b)
      .then((res) => {
        res.forEach((r, i) => {
          if (i === 0) {
            logoUrl = r.s3Key;
          } else {
            media.push(r.s3Key);
          }

          axios.put(r.signedUrl, fileswithlogo[i]);
        });
        let updatedOpeningHourList = openingHours.map((dlt) => delete dlt.name);
        const newKey = "serviceHours";
        const serviceHours =
          openingHours.map(({ clinicHours, ...rest }) => ({
            [newKey]: clinicHours,
            ...rest,
          })) || [];
        dispatch(
          createClinic({
            ...data,
            media: [media.join(",")],
            logo: logoUrl,
            openingHours: updatedOpeningHourList,
            serviceOpeningDays: serviceHours,
          })
        )
          .then((result) => {
            setLoading(false);
            window.gtag('event', 'page_view', {
              page_title: "Clinic Register",
              page_path: "/signup",
              page_location: "/signup"
            })
            if (createclinicu && createclinic_u) {
              localStorage.setItem("clinicu", createclinicu);
              const userId = JSON.parse(createclinicu);
              const params = {
                include: "role,clinic",
              };
              dispatch(retrieveUserById(userId.userId, params)).then(
                (response) => {
                  if (response.status === 200) {
                    localStorage.setItem(
                      "clinic-u",
                      JSON.stringify(response.data)
                    );
                    localStorage.removeItem("createclinicu");
                    localStorage.removeItem("createclinic-u");
                    window.location.href = "/dashboard";
                  }
                }
              );
            } else {
              navigate("/clinic/welcomePage");
            }
            toast.success("Clinic Create Successfully");
            dispatch(clearClinicData());
            navigate("/clinic/welcomePage");
          })
          .catch((error) => {
            setLoading(false);
            toast.error(error.response?.data?.errors[0]?.detail);
          });
      })
      .catch((c) => {
        setLoading(false);
        console.log("error", c);
      });
  };

  return (
    <div>
      <form onSubmit={handleSubmit(onSubmit)}>
        <PhotosUpload files={files} setFiles={setFiles} />
        <div className="flex justify-end pt-6">
          <Button
            type="button"
            style={{ backgroundColor: "#e2e2e2", color: "#000000" }}
            className="rounded-l bg-alabaster py-2 px-4 font-normal text-black"
            text="lg"
            onClick={() => {
              setSelectedIndex(2);
            }}
          >
            Previous
          </Button>

          <Button
            className="rounded-r bg-indigo py-2 px-4 font-normal text-white"
            text="lg"
            type="submit"
            loading={loading}
          >
            Finish
          </Button>
        </div>
      </form>
    </div>
  );
};

export default Step4ofClinic;

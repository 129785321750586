export const dayCodeToDayName = (dayCode) => {
    if(dayCode === 0){
        return "Sunday"
    } else if(dayCode === 1){
        return "Monday"
    } else if(dayCode === 2){
        return "Tuesday"
    } else if(dayCode === 3){
        return "Wednesday"
    } else if(dayCode === 4){
        return "Thursday"
    } else if(dayCode === 5){
        return "Friday"
    } else {
        return "Saturday"
    }
}
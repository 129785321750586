import React from 'react'

const CheckCircle = ({ ...props }) => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="44" height="44" viewBox="0 0 44 44" {...props}>
            <g id="Group_18985" data-name="Group 18985" transform="translate(-334 -456)">
                <circle id="Ellipse_1019" data-name="Ellipse 1019" cx="22" cy="22" r="22" transform="translate(334 456)" fill="#11c46b" />
                <g id="Group_15790" data-name="Group 15790" transform="translate(13.387 308.974)">
                    <path id="fi-br-check" d="M5.9,17.167a1.912,1.912,0,0,1-1.354-.561L.449,12.515a1.128,1.128,0,0,1,0-1.6h0a1.128,1.128,0,0,1,1.6,0L5.9,14.771,16.084,4.582a1.128,1.128,0,0,1,1.6,0h0a1.128,1.128,0,0,1,0,1.6L7.249,16.606a1.912,1.912,0,0,1-1.354.561Z" transform="translate(333.55 158.317)" fill="#fff" />
                </g>
            </g>
        </svg>
    )
}

export default CheckCircle
import SubscriptionService from "services/subscription";
import {
    GET_ALL_PRICE,
    GET_ALL_SUBSCRIPTION,
    GET_ALL_SUBSCRIPTION_INVOICE,
    SUBSCRIPTION_CANCEL,
    SUBSCRIPTION_CHECK_OUT
} from "./type";

export const retriveAllPrice = () => async (dispatch) => {
    try {
        const res = await SubscriptionService.getAll();

        dispatch({
            type: GET_ALL_PRICE,
            payload: res.data,
        });

        return Promise.resolve(res.data);
    } catch (error) {
        return Promise.reject(error);
    }
};

export const checkoutSubscription = (data) => async (dispatch) => {
    try {
        const res = await SubscriptionService.checkout(data);

        dispatch({
            type: SUBSCRIPTION_CHECK_OUT,
            payload: res.data,
        });

        return Promise.resolve(res.data);
    } catch (error) {
        return Promise.reject(error);
    }
};

export const cancelSubscription = (data) => async (dispatch) => {
    try {
        const res = await SubscriptionService.cancelSubscription(data);

        dispatch({
            type: SUBSCRIPTION_CANCEL,
            payload: res.data,
        });

        return Promise.resolve(res.data);
    } catch (error) {
        return Promise.reject(error);
    }
};

export const retriveAllSubscription = (customerId) => async (dispatch) => {
    try {
        const res = await SubscriptionService.getAllSubscription(customerId);

        dispatch({
            type: GET_ALL_SUBSCRIPTION,
            payload: res.data,
        });

        return Promise.resolve(res.data);
    } catch (error) {
        return Promise.reject(error);
    }
};

export const retriveAllInvoiceOfSubscription = (customerId, subscriptionId) => async (dispatch) => {
    try {
        const res = await SubscriptionService.getAllInvoice(customerId, subscriptionId);

        dispatch({
            type: GET_ALL_SUBSCRIPTION_INVOICE,
            payload: res.data,
        });

        return Promise.resolve(res.data);
    } catch (error) {
        return Promise.reject(error);
    }
};
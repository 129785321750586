import clsx from 'clsx';
import React, { useEffect, useState } from 'react'

const AnyTimeAnyWhere = () => {
    const [bounceImage, setBounceImage] = useState(true);

    useEffect(() => {
        setTimeout(() => {
            setBounceImage(false)
        }, 3000);
        const movingImage = document.getElementById('moving-image');
        if (movingImage) {
            setTimeout(() => {
                movingImage.classList.remove('translate-x-full');
                movingImage.classList.add('translate-x-0');
            }, 100); // Delay to start the animation
        }
    }, [])

    return (
        <section className="bg-cornflower-blue/25 flex flex-col items-center justify-center">
            <div className="max-w-[1553px]">
                <div className="lg:my-16 lg:mx-48 my-8 mx-14">
                    <div className="text-elephant text-4xl font-extrabold">
                        Book your next Health Appointment, Anytime, Anywhere.
                    </div>
                    <div className="text-river-bed text-xs font-normal text-center mt-3 max-w-[1053px]">
                        At Clinic2Go, your health is our priority, which is why we have built a service-based platform to meet all your healthcare needs. We intend to easily, efficiently and effectively provide health services – via pharmacies and other clinic practices – directly to you.
                    </div>
                </div>
            </div>
            <div className="relative w-[100%] flex flex-col items-center justify-center">
                <img src="/img/landingPage/background.png" alt="clinic2go" />
                <div className="flex space-x-5 absolute top-0">
                    <img src="img/landingPage/Google-Play.png" alt="clinic2go" onClick={() => window.open("https://play.google.com/store/apps/details?id=com.clinic2")}/>
                    <img src="img/landingPage/App-Store.png" alt="clinic2go" onClick={() => window.open("https://apps.apple.com/gb/app/clinic2go/id6448028689")}/>
                </div>
                <img src="img/landingPage/appointment.png" alt="clinic2go" className="absolute top-32 transform translate-x-full transition-transform duration-1000 ease-out hidden lg:block" id="moving-image" />
                <img src="img/landingPage/photo-room.png" alt="clinic2go" className={clsx(bounceImage && "animate-bounce", "hidden lg:block absolute top-32 left-[26rem]")} />
                <img src="img/landingPage/chat-icon.png" alt="clinic2go" className={clsx(bounceImage && "animate-bounce", "hidden lg:block absolute top-6 right-[33.5rem]")} />
                <img src="img/landingPage/nhs.png" alt="clinic2go" onClick={() => window.open("https://www.nhs.uk")} className={clsx(bounceImage && "animate-bounce", "hidden lg:block absolute top-64 right-72 cursor-pointer")} />
            </div>
        </section>
    )
}

export default AnyTimeAnyWhere
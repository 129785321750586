import React, { createContext, useState } from "react";

export const AppContext = createContext();

export function ContextProvider({ children }) {
    const [isAuth, setIsAuth] = useState(false);
    const [role, setRole] = useState("");
    const [auth, setAuth] = useState({});

    return (
        <AppContext.Provider
            value={{
                isAuth,
                setIsAuth,
                role,
                setRole,
                auth, 
                setAuth
            }}
        >
            {children}
        </AppContext.Provider>
    );
}
import authHeader from "auth-header";
import httpCommon from "http-common";

const getFlage = (id) => {
    const config = {
        headers: authHeader(),
    };
    return httpCommon.get(`/stripe/customer/${id}`, config);
};

const create = (data) => {
    return httpCommon.post(`/stripe/customer`, data, { headers: authHeader() });
};

const ClinicStripe = {
    getFlage,
    create,
};

export default ClinicStripe;
//  Internally, customStyles will deep merges your customStyles with the default styling.
const customStyles = {
    headCells: {
      style: {
        fontSize: '16px',// override the cell padding for head cells
        fontWeight: '500',
        paddingLeft: '8px',
        paddingRight: '8px',  
      },
    },
  };

export default customStyles;
import UserServie from "services/user";
import {
  CHANGE_PASSWORD,
  GET_ALL_USER,
  UPDATE_USER,
  GET_VERIFIED,
  SIGNUP,
} from "./type";

export const signUp = (data) => async (dispatch) => {
  try {
    const res = await UserServie.signUp(data);

    dispatch({
      type: SIGNUP,
      payload: res.data,
    });

    return Promise.resolve(res.data);
  } catch (error) {
    return Promise.reject(error);
  }
};

export const retriveUsers = (data) => async (dispatch) => {
  try {
    const res = await UserServie.getAll(data);

    dispatch({
      type: GET_ALL_USER,
      payload: res.data,
    });

    return Promise.resolve(res.data);
  } catch (error) {
    return Promise.reject(error);
  }
};

export const updateUsers = (id, data) => async (dispatch) => {
  try {
    const res = await UserServie.update(id, data);

    dispatch({
      type: UPDATE_USER,
      payload: res.data,
    });

    return Promise.resolve(res.data);
  } catch (error) {
    return Promise.reject(error);
  }
};

export const changePassword = (data) => async (dispatch) => {
  try {
    const res = await UserServie.changePassword(data);

    dispatch({
      type: CHANGE_PASSWORD,
      payload: res.data,
    });

    return Promise.resolve(res.data);
  } catch (error) {
    return Promise.reject(error);
  }
};

export const verifyEmail = (token) => async (dispatch) => {
  try {
    const res = await UserServie.verifyEmail(token);

    dispatch({
      type: GET_VERIFIED,
      payload: res.data,
    });

    return Promise.resolve(res.data);
  } catch (error) {
    return Promise.reject(error);
  }
};

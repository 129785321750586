import authHeader from "auth-header";
import httpCommon from "http-common";

const signUp = async (data) => {
  return httpCommon.post("/sign-up", data, 
  {
    headers: { "Content-Type": "application/json" },
  });
};

const getAll = async (params) => {
  const config = {
    headers: authHeader(),
    params: params,
  };

  const response = await httpCommon.get(`/users`, config);
  return response;
};

const update = (id, data) => {
  return httpCommon.patch(`/users/${id}`, data, { headers: authHeader() });
};

const changePassword = (data) => {
  return httpCommon.post("/users/changePassword", data, {
    headers: authHeader(),
  });
};

const verifyEmail = (token) => {
  return httpCommon.get(`/verify/${token}`);
};

const UserServie = {
  signUp,
  getAll,
  update,
  changePassword,
  verifyEmail,
};

export default UserServie;

import { retrieveClinicSelectPatient } from "actions/clinicSelectPatient";
import { retrieveOneNMS, updateNMS } from "actions/nms";
import { Button, TagsInput } from "components/ui";
import _ from "lodash";
import moment from "moment";
import React, { useCallback, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import toast from "react-hot-toast";
import { useDispatch } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import Select from "react-select";

const EditNMS = () => {
  const { nmsId } = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const auth = JSON.parse(localStorage.getItem("clinic-u"));
  const clinicId = auth?.clinic[0]?.id;
  const [nms, setNms] = useState([]);
  const [loading, setLoading] = useState(false);
  const [search, setSearch] = useState("");
  const [patient, setPatient] = useState([]);
  const [tags, setTags] = useState([]);
  const [tagsError, setTagsError] = useState(false);

  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm({
    defaultValues: {
      firstIntervention: nms?.firstIntervention,
      secondFollowUp: nms?.secondFollowUp,
    },
  });

  const fetchPatient = useCallback(() => {
    const params = {
      page: 1,
      limit: 50,
      sort: "createdAt",
      order: "desc",
      search: search,
      "filter[clinic.id]": clinicId,
      include: "patient,clinic",
    };
    dispatch(retrieveClinicSelectPatient(params))
      .then((result) => {
        setLoading(true);
        setPatient(result?.data);
      })
      .catch((error) => {
        toast.error(error.response.data.errors[0].detail);
        setLoading(true);
      })
      .finally(() => {
        setLoading(false);
      });
  }, [dispatch, setPatient, search, clinicId]);

  const fetchNMS = useCallback(() => {
    const params = {
      include:
        "patient,nms1STAnswer,nms2NDAnswer,nms1STAnswer.nms1st,nms2NDAnswer.nms2nd,clinic",
    };
    dispatch(retrieveOneNMS(nmsId, params))
      .then((res) => {
        setNms(res);
      })
      .catch((err) => console.log(err));
  }, [dispatch, nmsId]);

  const selectOptionsName = (option) => {
    const mappedOptions = _.uniqBy(option, "patient.id")?.map((option) => {
      return {
        value: option?.patient?.id,
        label: `${
          option?.patient?.firstName !== null ? option?.patient?.firstName : ""
        } 
        ${option?.patient?.lastName !== null ? option?.patient?.lastName : ""}`,
        detail: option?.patient,
      };
    });
    return mappedOptions;
  };

  const selectOptionsEmail = (option) => {
    const mappedOptions = _.uniqBy(option, "patient.id")?.map((option) => {
      return {
        value: option?.patient?.id,
        label: option?.patient?.emailAddress,
        detail: option?.patient,
      };
    });
    return mappedOptions;
  };

  const handleInputChange = (inputValue) => {
    setSearch(inputValue);
  };

  const onAddTag = (tag) => {
    setTags([...tags, tag[0]]);
  };

  const onDeleteTag = (tag) => {
    let remainingTags = tags.filter((t) => {
      return t !== tag;
    });
    setTags([...remainingTags]);
  };

  const onSubmit = (data) => {
    if (tags.length <= 0) {
      setTagsError(true);
    } else {
      setLoading(true);
      data.medicines = tags;
      data.nms1STAnswer = nms?.nms1STAnswer;
      data.nms2NDAnswer = nms?.nms2NDAnswer;
      data.patient = {
        id: nms?.patient ? nms?.patient?.id : nms?.id,
      };
      data.clinic = {
        id: clinicId,
      };
      dispatch(updateNMS(nmsId, data))
        .then((response) => {
          setLoading(false);
          navigate("/nms");
          toast.success("NMS updated successfully!");
        })
        .catch((error) => {
          setLoading(false);
          toast.error(error.response?.data?.errors[0]?.detail);
        });
    }
  };

  useEffect(() => {
    fetchPatient();
    if (nmsId !== undefined) {
      fetchNMS();
    }
  }, [fetchPatient, fetchNMS, nmsId]);

  useEffect(() => {
    setValue("firstIntervention", nms?.firstIntervention);
    setValue("secondFollowUp", nms?.secondFollowUp);
    setValue("isVerbalConsent", nms?.isVerbalConsent);
    setTags(nms?.medicines?.map((d) => d) || []);
  }, [
    setValue,
    setTags,
    nms?.medicines,
    nms?.firstIntervention,
    nms?.secondFollowUp,
    nms?.isVerbalConsent,
  ]);
  let patientOption = selectOptionsName(patient);
  let patientEmail = selectOptionsEmail(patient);

  return (
    <section className="ml-6 mr-7 min-h-screen items-center justify-center py-6">
      <div>
        <div className="mb-4">
          <div className="text-xl font-semibold">Update NMS</div>
          <div className="text-sm">
            I agree that the information obtained during this services can be
            shared with:-
          </div>
          <div className="text-sm">
            My doctor (GP) to help them provide care to me.
          </div>
        </div>
        <div>
          <form onSubmit={handleSubmit(onSubmit)}>
            <div className="mb-6 w-auto rounded-t-lg bg-white px-4 py-4 lg:px-7 lg:py-7">
              <div className="flex justify-end">
                <Button
                  type="button"
                  className="rounded-md bg-indigo text-white"
                  text="base"
                  onClick={() => {
                    setNms(null);
                  }}
                >
                  Clear
                </Button>
              </div>
              <div className="lg:grid lg:grid-cols-3">
                <div className="mb-2 pr-0 lg:pr-16">
                  <div className="mb-1 text-sm font-medium">Patient’s name</div>
                  {nms?.patient && (
                    <div className="w-full rounded-md border-none bg-alabaster px-2 py-2">
                      {nms?.patient?.firstName !== null &&
                        nms?.patient?.firstName}
                      {nms?.patient?.lastName !== null &&
                        nms?.patient?.lastName}
                    </div>
                  )}
                  {!nms?.patient && (
                    <Select
                      value={
                        patientOption.filter(
                          (option) => option.value === `${nms?.id}`
                        )[0] || ""
                      }
                      options={patientOption}
                      className="w-full rounded-md border-none bg-alabaster"
                      onInputChange={handleInputChange}
                      onChange={(e) => {
                        setNms(e.detail);
                      }}
                    />
                  )}
                </div>
                <div className="mb-2 pr-0 lg:pr-16">
                  <div className="mb-1 text-sm font-medium">Email</div>
                  {nms?.patient && (
                    <div className="w-full rounded-md border-none bg-alabaster px-2 py-2">
                      {nms?.patient?.emailAddress}
                    </div>
                  )}
                  {!nms?.patient && (
                    <Select
                      value={
                        patientEmail.filter(
                          (option) => option.value === `${nms?.id}`
                        )[0] || ""
                      }
                      options={patientEmail}
                      className="w-full rounded-md border-none bg-alabaster"
                      onInputChange={handleInputChange}
                      onChange={(e) => setNms(e.detail)}
                    />
                  )}
                </div>
                <div className="mb-2 pr-0 lg:pr-16">
                  <div className="mb-1 text-sm font-medium">Phone number</div>
                  {nms?.patient ? (
                    <div className="w-full rounded-md border-none bg-alabaster px-2 py-2">
                      {nms?.patient?.phone || "Not Available"}
                    </div>
                  ) : nms ? (
                    <div className="w-full rounded-md border-none bg-alabaster px-2 py-2">
                      {nms?.phone || "Not Available"}
                    </div>
                  ) : (
                    <></>
                  )}
                </div>
              </div>
              <div className="col-span-1">
                <div className="mb-1 text-sm font-medium">Address</div>
                {nms?.patient ? (
                  <div className="w-1/3 rounded-md border-none bg-alabaster px-2 py-2">
                    {nms?.patient?.address || "Not Available"}
                  </div>
                ) : nms ? (
                  <div className="w-1/3 rounded-md border-none bg-alabaster px-2 py-2">
                    {nms?.address || "Not Available"}
                  </div>
                ) : (
                  <></>
                )}
              </div>
            </div>
            <div className="w-auto rounded-b-lg bg-white px-4 py-4">
              <div className="grid grid-cols-1 gap-8 px-4 py-4 sm:grid-cols-3">
                <div>
                  <TagsInput
                    label={"Medicines"}
                    placeHolder={"enter medicines seperated by comma e.g medicine name1,"}
                    className="w-full border-none"
                    onAddTag={onAddTag}
                    onDeleteTag={onDeleteTag}
                    defaultTags={tags}
                    apiKey={"medicines"}
                  />
                  {tagsError && (
                    <div className="mt-1 text-sm text-burnt-sienna">
                      Atleast one medicine name is required.
                    </div>
                  )}
                </div>
              </div>
              <div className="grid grid-cols-1 gap-8 px-4 py-4 sm:grid-cols-3">
                <div>
                  <div className="mb-1 text-sm font-medium">
                    First intervention{" "}
                  </div>
                  <input
                    type="datetime-local"
                    min={moment().format("YYYY-MM-DD")}
                    className="w-full rounded-md border-none bg-alabaster"
                    {...register("firstIntervention", {
                      required: false,
                    })}
                  />
                </div>
                <div>
                  <div className="mb-1 text-sm font-medium">
                    Second follow up{" "}
                  </div>
                  <input
                    type="datetime-local"
                    min={moment().format("YYYY-MM-DD")}
                    className="w-full rounded-md border-none bg-alabaster"
                    {...register("secondFollowUp", { required: false })}
                  />
                  {errors?.secondFollowUp?.type === "min" && (
                    <div className="mt-1 text-sm text-burnt-sienna">
                      You can Not Select Past Date.
                    </div>
                  )}
                </div>
              </div>
              <div className="flex items-center space-x-1 px-4 py-4">
                <input
                  id="isVerbalConsent"
                  type="checkbox"
                  {...register(`isVerbalConsent` /*{ required: true }*/)}
                />
                <label htmlFor="isVerbalConsent">Verbal consent</label>
                {errors?.isVerbalConsent?.type === "required" && (
                  <div className="mt-1 text-sm text-burnt-sienna">
                    VerbalConsent is required.
                  </div>
                )}
              </div>
            </div>
            <div className="pt-6">
              <Button
                type="submit"
                className="h-12 rounded-lg bg-indigo text-white"
                text="lg"
                loading={loading}
              >
                Save NMS
              </Button>
            </div>
          </form>
        </div>
      </div>
    </section>
  );
};

export default EditNMS;

import Clinic from "services/clinic";
import {
  CREATE_CLINICS,
  GET_ALL_CLINICS,
  GET_ONE_CLINICS,
  REMOVE_CLINICS,
  UPDATE_CLINICS,
} from "./type";

export const createClinic = (data) => async (dispatch) => {
  try {
    const res = await Clinic.create(data);

    dispatch({
      type: CREATE_CLINICS,
      payload: res.data,
    });

    return Promise.resolve(res.data);
  } catch (error) {
    return Promise.reject(error);
  }
};

export const updateClinic = (id, data) => async (dispatch) => {
  try {
    const res = await Clinic.update(id, data);

    dispatch({
      type: UPDATE_CLINICS,
      payload: res.data,
    });

    return Promise.resolve(res.data);
  } catch (error) {
    return Promise.reject(error);
  }
};

export const deleteClinic = (id) => async (dispatch) => {
  try {
    const res = await Clinic.remove(id);

    dispatch({
      type: REMOVE_CLINICS,
      payload: res.data,
    });

    return Promise.resolve(res.data);
  } catch (error) {
    return Promise.reject(error);
  }
};

export const retrieveOneClinic = (id, params) => async (dispatch) => {
  try {
    const res = await Clinic.getOne(id, params);

    dispatch({
      type: GET_ONE_CLINICS,
      payload: res.data,
    });

    return Promise.resolve(res.data);
  } catch (error) {
    return Promise.reject(error);
  }
};

export const retriveClinics = (data) => async (dispatch) => {
  try {
    const res = await Clinic.getAll(data);

    dispatch({
      type: GET_ALL_CLINICS,
      payload: res.data,
    });

    return Promise.resolve(res.data);
  } catch (error) {
    return Promise.reject(error);
  }
};

import React from "react";
import Tag from "./Tag";

function TagList({ tags, onDeleteTag }) {
    let tagsUI = tags.map(tag => {
        return <Tag onDeleteTag={() => onDeleteTag(tag)} key={tag} value={tag} />;
    });
    return <div className="lg:flex lg:space-x-3 lg:space-y-0 space-y-2">{tagsUI}</div>;
}

export default TagList;

import { Button, Modal } from 'components/ui';
import _, { isEmpty } from 'lodash';
import React, { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form';
import { toast } from 'react-hot-toast';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { updateNMS } from "actions/nms";

const SecondFollowup = ({ isOpen, details, closeModal }) => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
  
    const [nms2NDAnswer, setNMS2NDAnswer] = useState([])
    const [loading, setLoading] = useState(false)
  
    const {
      register,
      handleSubmit,
      setValue,
    } = useForm();
  
    const onSubmit = async (data) => {
      const body = {
        ...details,
        isSecondFollowUpAnswered: true,
        "nms2NDAnswer" : data.nms2NDAnswer
      }
      console.log(body);
      setLoading(true)
      await dispatch(updateNMS(details?.id, body)).then((result) => {
        toast.success("NMS Update Successfully");
        navigate("/nms")
      })
        .catch((error) => {
          setLoading(false)
          toast.error(error.response.data.errors[0].detail);
        })
    }
  
    useEffect(() => {
        setNMS2NDAnswer(details?.nms2NDAnswer);
    }, [details?.nms2NDAnswer])
  
    useEffect(() => {
      if(!isEmpty(details?.nms2NDAnswer)){
        for (const [i, nms2nd] of details?.nms2NDAnswer.entries()) {
          setValue(`nms2NDAnswer.${i}.nms2nd.id`,nms2nd?.nms2nd?.id)
          setValue(`nms2NDAnswer.${i}.id`,nms2nd?.id)
          setValue(`nms2NDAnswer.${i}.answerText`,nms2nd?.answerText)
        }
      }
    },[setValue, details?.nms2NDAnswer])
  
    return (
      <Modal
        title=""
        maxWidth="87rem"
        show={isOpen}
        onClose={closeModal}
        showCancel={true}
        onCancel={closeModal}
      >
        <div className="flex items-start justify-start space-x-3">
          <div className="px-4 py-4 rounded-lg w-full">
            <form
              onSubmit={handleSubmit(onSubmit)}
            >
              {_.sortBy(nms2NDAnswer, "nms2nd.position")?.map((data, i) => {
                return (
                  <div key={i}>
                    <h1 className="font-semibold mb-1">{i + 1}. {data?.nms2nd?.questionText}</h1>
                    <input
                      tye="text"
                      value={data?.nms2nd?.id}
                      hidden
                      {...register(`nms2NDAnswer.${i}.nms2nd.id`, { required: false })}
                    />
                    <input
                      tye="text"
                      value={data?.id}
                      hidden
                      {...register(`nms2NDAnswer.${i}.id`, { required: false })}
                    />
                    <textarea
                      placeholder={data?.nms2nd?.placeholder}
                      rows={2}
                      className="bg-alabaster w-full border-none rounded-md mb-2"
                      {...register(`nms2NDAnswer.${i}.answerText`, { required: false })}
                    />
                  </div>
                )
              }
              )}
              <div className="pt-4 flex space-x-2">
                <div className="pt-4">
                  <Button
                    type="submit"
                    className="h-12 rounded-lg bg-indigo px-7 text-white"
                    loading={loading}
                  >
                    Save
                  </Button>
                </div>
                <div className="pt-4">
                  <Button
                    type="button"
                    className="h-12 rounded-lg"
                    onClick={closeModal}
                    color="indigo"
                    outlined
                  >
                    Cancel
                  </Button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </Modal>
    )
}

export default SecondFollowup
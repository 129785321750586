import React from 'react'
import NewHeader from './NewHeader'
import AnyTimeAnyWhere from './AnyTimeAnyWhere'
import WhatIs from './WhatIs'
import ClinicOperations from './ClinicOperations'
import OurServices from './OurServices'
import NMS from './NMS'
import MobileApp from './MobileApp'
import JoinUs from './JoinUs'
import BlogsSlider from './BlogsSlider'
import Contact from './Contact'
import NewFooter from './NewFooter'

const HomePageNew = () => {
  return (
    <section>
      <NewHeader />
      <AnyTimeAnyWhere />
      <WhatIs />
      <ClinicOperations />
      <OurServices />
      <NMS />
      <MobileApp />
      <JoinUs />
      <BlogsSlider/>
      <Contact />
      <NewFooter />
    </section>
  )
}
export default HomePageNew
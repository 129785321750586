import { updateAppointment } from 'actions/appointment';
import { Button, Modal } from 'components/ui';
import _ from 'lodash';
import moment from 'moment';
import React, { useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import toast from 'react-hot-toast';
import { AiOutlineCheckCircle } from 'react-icons/ai';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import Select from "react-select";
import { AppointmentStatus } from 'utils/roles';

const CompleteAppontment = ({ isOpen, appointment, close, closeModal }) => {

    const dispatch = useDispatch();
    const navigate = useNavigate();

    const [loading, setLoading] = useState(false)
    const [showCompleteModel, setShowCompleteModel] = useState(false)

    const {
        register,
        handleSubmit,
        control,
        setValue,
        watch
    } = useForm();

    console.log(watch());
    const selectOptions = (option) => {
        const mappedOptions = option.map((option) => {
            return { value: option, label: option };
        });
        return mappedOptions;
    };

    const onSubmit = (data) => {
        setLoading(true)
        for (const answer of data?.answeredByClinic) {
            if (answer?.type === "multiselect") {
                if (answer.answerText) {
                    answer.answerText = answer?.answerText?.map((key) => key.value);
                    answer.answerText = answer.answerText.toString()
                } else {
                    answer.answerText = ""
                }
            }
            if (answer?.type === "checkbox") {
                if (answer.answerText) {
                    answer.answerText = answer.answerText.toString()
                } else {
                    answer.answerText = ""
                }
            }
        }
        const body = {
            clinic: {
                id: appointment?.clinic?.id
            },
            service: {
                id: appointment?.service?.id
            },
            clinicService: {
                id: appointment?.clinicService?.id
            },
            user: {
                id: appointment?.user?.id
            },
            date: appointment?.date,
            time: appointment?.time,
            status: AppointmentStatus.COMPLETED,
            answeredByPatient: appointment?.answeredByPatient,
            answeredByClinic: data?.answeredByClinic
        }
        dispatch(updateAppointment(appointment.id, body))
            .then((result) => {
                toast.success("Appointment Completed Successfully");
                setShowCompleteModel(true)
            })
            .catch((error) => {
                toast.error(error.response.data.errors[0].detail);
            })
    }

    return (
        <Modal
            title=""
            maxWidth={showCompleteModel ? "32rem" : "87rem"}
            show={isOpen}
            onClose={close}
            showCancel={true}
            onCancel={closeModal}
        >
            {showCompleteModel &&
                (<div className="flex flex-col justify-center items-center">
                    <AiOutlineCheckCircle className="h-8 w-8 text-storm-gray fill-malachite" />
                    <h1 className="text-malachite text-xl font-normal mb-4">Appointment completed!</h1>
                    <div className="mb-1 text-center font-light text-lg">
                        Would you like to download the report?
                    </div>
                    <div className="mb-4 text-center font-light text-lg">
                        You can also download it later.
                    </div>
                    <div className="flex items-center justify-center space-x-3">
                        <Button
                            type="submit"
                            className="rounded-lg bg-indigo text-white w-40 lg:w-56"
                            onClick={() => navigate(`/appointments/report/${appointment?.id}`)}
                        >
                            Download report
                        </Button>
                    </div>
                </div>
                )}
            {!showCompleteModel &&
                (<div>
                    <h1
                        className="text-indigo flex items-start text-xl font-normal mb-4"
                    >{
                            `${appointment?.user?.firstName} ${appointment?.user?.lastName} / ${appointment?.service?.name} - ${moment(appointment?.date).format("do MMM YYYY")} - ${moment(appointment?.time, "HH:mm").format("hh:mm A")} `
                        }
                    </h1>
                    {/* <div className="mb-4 text-center font-light flex items-start">
                        Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua.
                    </div> */}
                    <form onSubmit={handleSubmit(onSubmit)} >
                        <div className="lg:px-7 lg:py-7 px-4 py-4 mb-4 bg-white w-auto rounded-b-lg">
                            {_.sortBy(appointment?.service?.questionForClinic, "position")?.map((question, i) => {
                                return (
                                    <div key={i} className="mb-2">
                                        {question.inputType === "text" && (
                                            <div className="flex items-center space-x-4 mb-2 text-sm font-medium">
                                                <label>{question?.questionText}</label>
                                                <input
                                                    type="text"
                                                    hidden
                                                    value={question?.id}
                                                    {...register(`answeredByClinic.${i}.question.id`, { required: false })}
                                                />
                                                <input
                                                    type={question?.inputType}
                                                    placeholder={question?.placeholder}
                                                    className="bg-alabaster border-none w-auto rounded-md focus:ring-0"
                                                    {...register(`answeredByClinic.${i}.answerText`, { required: false })}
                                                />
                                            </div>
                                        )}
                                        {question.inputType === "textarea" && (
                                            <div className="flex items-center space-x-4 mb-2 text-sm font-medium">
                                                <label>{question?.questionText}</label>
                                                <input
                                                    type="text"
                                                    hidden
                                                    value={question?.id}
                                                    {...register(`answeredByClinic.${i}.question.id`, { required: false })}
                                                />
                                                <textarea
                                                    rows={3}
                                                    placeholder={question?.placeholder}
                                                    className="bg-alabaster border-none resize-none w-auto rounded-md focus:ring-0"
                                                    {...register(`answeredByClinic.${i}.answerText`, { required: false })}
                                                />
                                            </div>
                                        )}
                                        {question?.inputType === "select" && (
                                            <div className="flex items-center space-x-4 mb-2 text-sm font-medium">
                                                <label>{question?.questionText}</label>
                                                <input
                                                    type="text"
                                                    hidden
                                                    value={question?.id}
                                                    {...register(`answeredByClinic.${i}.question.id`, { required: false })}
                                                />
                                                <select
                                                    className="bg-alabaster border-none w-auto rounded-md focus:ring-0"
                                                    {...register(`answeredByClinic.${i}.answerText`, { required: false })}
                                                >
                                                    <option value="">
                                                        Choose an option
                                                    </option>
                                                    {question?.options.map((option, i) => {
                                                        return (
                                                            <option
                                                                key={i}
                                                                value={option.trim()}
                                                            >
                                                                {option}
                                                            </option>
                                                        );
                                                    })}
                                                </select>
                                            </div>
                                        )}
                                        {question?.inputType === "multiselect" && (
                                            <div className="flex items-center space-x-4 mb-2 text-sm font-medium">
                                                <label>{question?.questionText}</label>
                                                <input
                                                    type="text"
                                                    hidden
                                                    value={question?.id}
                                                    {...register(`answeredByClinic.${i}.question.id`, { required: false })}
                                                />
                                                <input
                                                    type="text"
                                                    hidden
                                                    value="multiselect"
                                                    {...register(`answeredByClinic.${i}.type`, { required: false })}
                                                />
                                                <Controller
                                                    render={({ field: { onChange, value, name, ref } }) => (
                                                        <Select
                                                            isMulti
                                                            inputRef={ref}
                                                            options={selectOptions(question?.options)}
                                                            className="bg-alabaster border-none w-auto rounded-md focus:ring-0"
                                                            value={selectOptions(question?.options)?.find(c => c.value === value)}
                                                            onChange={val => {
                                                                onChange(val.value);
                                                                setValue(`answeredByClinic.${i}.answerText`, val)
                                                            }}
                                                        />
                                                    )}
                                                    control={control}
                                                    name={`answeredByClinic.${i}.answerText`}
                                                />
                                            </div>
                                        )}
                                        {question?.inputType === "checkbox" && (
                                            <div className="flex items-center space-x-4 mb-2 text-sm font-medium">
                                                <label>{question?.questionText}</label>
                                                <div className="grid grid-cols-1 gap-2 sm:grid-cols-2">
                                                    {question?.options.map((option, index) => {
                                                        return (
                                                            <div key={index}>
                                                                <input
                                                                    id={`${question?.id}.${index}`}
                                                                    name={`${question?.id}`}
                                                                    value={option}
                                                                    type="checkbox"
                                                                    className="mr-2"
                                                                    {...register(`answeredByClinic.${i}.answerText`, { required: false })}
                                                                />
                                                                <input
                                                                    type="text"
                                                                    hidden
                                                                    value="checkbox"
                                                                    {...register(`answeredByClinic.${i}.type`, { required: false })}
                                                                />
                                                                <input
                                                                    type="text"
                                                                    hidden
                                                                    value={question?.id}
                                                                    {...register(`answeredByClinic.${i}.question.id`, { required: false })}
                                                                />
                                                                <label
                                                                    htmlFor={`${question?.id}.${index}`}
                                                                    className="select-none font-light"
                                                                >
                                                                    {option}
                                                                </label>
                                                            </div>
                                                        );
                                                    })}
                                                </div>
                                            </div>
                                        )}
                                        {question?.inputType === "radio" && (
                                            <div className="flex items-center space-x-4 mb-2 text-sm font-medium">
                                                <label>{question?.questionText}</label>
                                                <div className="grid grid-cols-1 gap-2 sm:grid-cols-2">
                                                    {question?.options.map((option, index) => {
                                                        return (
                                                            <div key={index}>
                                                                <input
                                                                    id={`${question?.id}`}
                                                                    name={`${question?.id}`}
                                                                    type="radio"
                                                                    value={option}
                                                                    className="mr-2"
                                                                    {...register(`answeredByClinic.${i}.answerText`, { required: false })}
                                                                />
                                                                <input
                                                                    type="text"
                                                                    hidden
                                                                    value={question?.id}
                                                                    {...register(`answeredByClinic.${i}.question.id`, { required: false })}
                                                                />
                                                                <label
                                                                    htmlFor={`${question?.id}`}
                                                                    className="select-none font-light"
                                                                >
                                                                    {option}
                                                                </label>
                                                            </div>
                                                        );
                                                    })}
                                                </div>
                                            </div>
                                        )}
                                    </div>
                                )
                            })}
                        </div>
                        <div className="flex items-center justify-start space-x-3">
                            <Button
                                type="submit"
                                className="rounded-lg bg-indigo text-white w-40 lg:w-56"
                                loading={loading}
                            >
                                Mark as completed
                            </Button>
                        </div>
                    </form>
                </div>)
            }
        </Modal>
    )
}

export default CompleteAppontment
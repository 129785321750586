import { createSignedUrl, uploadFile } from 'actions/fileUpload';
import { retrieveOneOurClient, updateOurClient } from 'actions/our-clients';
import { Button } from 'components/ui';
import React, { useCallback, useEffect, useState } from 'react'
import { useForm } from 'react-hook-form';
import { toast } from 'react-hot-toast';
import { useDispatch } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';

const EditOurClients = () => {
    const { id } = useParams();
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const [blog, setBlog] = useState({});
    const [loading, setLoading] = useState(false)

    const {
        register,
        setValue,
        handleSubmit,
        formState: { errors },
    } = useForm();

    const fetchBlogs = useCallback(() => {
        dispatch(retrieveOneOurClient(id))
            .then((response) => {
                setBlog(response);
                setValue("name", response?.name);
                setValue("image", response?.image);
            })
            .catch((error) => {
                console.log(error, "error");
            });
    }, [dispatch, setValue, id]);

    const onSubmit = async (data) => {
        setLoading(true);
        let s3Key
        if (typeof data?.image === "object") {
            if (data?.image?.length > 0) {
                const body = {
                    fileName: data?.image[0]?.name,
                    mimeType: data?.image[0]?.type,
                    directory: "document"

                }
                await dispatch(createSignedUrl(body)).then(async (result) => {
                    s3Key = result?.s3Key
                    dispatch(uploadFile(result?.signedUrl, data?.image[0]));
                    data.image = s3Key;
                })
                    .catch((error) => {
                        toast.error(error.response.data.errors[0].detail);
                    })
            } else {
                data.image = blog?.image
            }
        }
        await dispatch(updateOurClient(id, data)).then((result) => {
            setLoading(false);
            toast.success("Our Client Updated Successfully");
            navigate("/our-client")
        })
            .catch((error) => {
                setLoading(false);
                toast.error(error.response.data.errors[0].detail);
            })
    }

    useEffect(() => {
        fetchBlogs();
    }, [fetchBlogs]);

    return (
        <section className="ml-6 mr-7 min-h-screen items-center justify-center py-6">
            <div>
                <div className="mb-4">
                    <div className="text-xl font-semibold">Edit OurClient</div>
                </div>
                <div>
                    <form className="pt-8 pb-16" onSubmit={handleSubmit(onSubmit)}>
                        <div className="mb-6 w-auto rounded-t-lg bg-white px-4 py-4 lg:px-7 lg:py-7">
                            <div className="flex flex-col mb-3">
                                <div className="mb-1.5 font-medium text-independence">
                                    Title For Blog
                                </div>
                                <input
                                    type="text"
                                    className="bg-alabaster border-none w-auto rounded-md"
                                    {...register("name", { required: true })}
                                />
                                {errors?.name?.type === "required" && (
                                    <div className="mt-1 text-sm text-burnt-sienna">
                                        Name Of client is required.
                                    </div>
                                )}
                            </div>
                            <div className="flex flex-col mb-3">
                                <div className="mb-1.5 font-medium">
                                    Image For Blog
                                </div>
                                <input
                                    type="file"
                                    placeholder="e.g. Sign Permit"
                                    className="bg-alabaster border-none w-auto rounded-md"
                                    {...register("image", { required: false })}
                                />
                                {errors?.image?.type === "required" && (
                                    <div className="mt-1 text-sm text-burnt-sienna">
                                        Image For OurClient is required.
                                    </div>
                                )}
                            </div>
                        </div>
                        <Button
                            type="submit"
                            className="bg-indigo text-white h-12 rounded-lg"
                            text="lg"
                            loading={loading}
                        >
                            Update
                        </Button>
                    </form>
                </div>
            </div>
        </section>
    )
}

export default EditOurClients

import React from "react";
import { ServiceWidget } from "components/ui";
import Loading from "components/ui/Loading";
const ServiceTab = (props) => {
  const { services, setServices, values, loading } = props;

  return (
    <div className="lg:grid lg:grid-cols-2">
      {loading ? (
        <div className="mt-0 md:mt-12">
        <Loading transbg="true" />
        </div>
  ) : services.map((service, index) => (
        <div key={index}>
        <ServiceWidget
          {...props}
          index={index}
          service={service}
          values={values}
          onServiceUpdate={(updatedService) => { 
            let updatedServiceList = services.map(s => {
              if(s.id === updatedService.id){
                s = updatedService
              }
              return s
            })
            setServices(updatedServiceList)
           }}
        />
        </div>
      ))}

      
    </div>
  );
};

export default ServiceTab;

import React from "react";

import clsx from "clsx";

const Tabs = ({ className, tabs, filter, setSearchParams }) => {
  return (
    <div
      className={clsx(
        className,
        "flex w-fit space-x-2 rounded-lg bg-alabaster px-4 py-2.5 xl:flex"
      )}
    >
      {tabs.map((tab, i) => {
        return (
          <div key={i}>
            <button
              type="button"
              className={clsx(
                filter === tab.filter
                  ? "bg-white"
                  : "bg-alice-blue text-azure-radiance",
                "rounded-lg py-2.5 px-4 text-base leading-5 transition-all duration-300 active:focus:scale-95"
              )}
              onClick={() => setSearchParams({ filter: tab.filter })}
            >
              {tab.name}
            </button>
          </div>
        );
      })}
    </div>
  );
};

export default Tabs;

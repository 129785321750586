import React from "react";

import ReactDOM from "react-dom/client";
import { Provider } from "react-redux";
import { RouterProvider } from "react-router-dom";

// import { persistor, store } from "store";
import { persistor, store } from "../src/store";
import { PersistGate } from "redux-persist/integration/react";
import { router } from "router";

import "./index.css";
import { ContextProvider } from "context/AppContext";
import { Toaster } from "react-hot-toast";

const root = ReactDOM.createRoot(document.getElementById("root"));

root.render(
  // <React.StrictMode>
  <Provider store={store}>
    <ContextProvider>
      <RouterProvider router={router} fallbackElement={<>Loading...</>}>
        <PersistGate loading={null} persistor={persistor}>
        </PersistGate>
      </RouterProvider>
      <Toaster position="bottom-left" reverseOrder={false} />
    </ContextProvider>
  </Provider>
  // </React.StrictMode>
);

import React from "react";

const Input = (props) => {
  const {
    readOnly,
    value,
    min,
    step,
    label,
    placeholder,
    type = "text",
    className,
    errors,
    apiKey,
    register = () => {},
    errorMsg,
    errorMsgPattern,
    errorMsgMin,
    containerClassName = "",
    onChange = () => {},
    pattern = false,
    onClick = () => {},
  } = props;

  return (
    <div className={`unit px-3 pt-0 ${containerClassName}`}>
      <div className="mb-1 text-sm font-medium">{label}</div>
      {type === "textarea" ? (
        <textarea
          placeholder={placeholder}
          rows={1}
          className={`w-full resize-none rounded-md ${className}`}
          {...register(apiKey, {
            required: true,
            onChange: onChange,
          })}
        />
      ) : (
        <input
          autoComplete="off"
          id={"input"}
          readOnly={readOnly}
          value={value}
          min={min}
          step={step}
          type={type}
          placeholder={placeholder}
          onClick={onClick}
          className={`rounded-md ${className}`}
          {...register(apiKey, {
            required: true,
            onChange: onChange,
            pattern: pattern,
          })}
        />
      )}
      {errors?.[apiKey]?.type === "required" && (
        <div className="mt-1 text-sm text-burnt-sienna">{errorMsg}</div>
      )}
      {errors?.[apiKey]?.type === "pattern" && (
        <div className="mt-1 text-sm text-burnt-sienna">{errorMsgPattern}</div>
      )}
      {errors?.[apiKey]?.type === "min" && (
        <div className="mt-1 text-sm text-burnt-sienna">{errorMsgMin}</div>
      )}
    </div>
  );
};

export default Input;

import httpCommon from "http-common";

const generateSignedUrl = (data) => {
    return httpCommon.post("/s3/signedUrl", data);
};

const upload = (url, data) => {
    return httpCommon.put(url, data, {
      headers: {
        "Content-type": data.type,
      },
    });
  };

const FileUploadService = {
    generateSignedUrl,
    upload,
};

export default FileUploadService;
import React from "react";
import TagList from "./TagList";

const TagsInput = (props) => {
  const {
    value,
    defaultTags,
    onAddTag,
    onDeleteTag,
    placeHolder,
    className,
    type = "text",
    label,
    register = () => {},
    onChange = () => {},
    errors,
    errorMsg,
    apiKey,
  } = props;

  const onKeyUp = (e) => {
    //console.log(e.key);
    // codice 188 per la virgola, 13 per invio
    if (e.key === "," || e.key === "Enter") {
      let input = e.target.value.trim().split(",");
      //esci se non ci sono tag
      if (input.length === 0 || input[0] === "") return;
      onAddTag(input);
      e.target.value = "";
    }
  };

  const _onDeleteTag = (tag) => {
    onDeleteTag(tag);
  };
  return (
    <div className="flex flex-col space-y-2 bg-alabaster px-5 py-3">
      <TagList tags={defaultTags} onDeleteTag={_onDeleteTag} />
      <div className="text-sm font-medium">{label}</div>
      <input
        value={value}
        onKeyUp={(e) => onKeyUp(e)}
        type={type}
        className={className}
        placeholder={placeHolder}
        {...register(apiKey, {
          required: true,
          onChange: onChange,
        })}
      />
      {errors?.[apiKey]?.type === "required" && (
        <div className="mt-1 text-sm text-burnt-sienna">{errorMsg}</div>
      )}
    </div>
  );
};

export default TagsInput;

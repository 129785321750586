export default function authHeader() {
  const token = JSON.parse(localStorage.getItem("clinicu"));

  if (token && token.accessToken) {
    return {
      Accept: 'application/json',
      Authorization: `Bearer ${token.accessToken}`,
    };
  } else {
    return {};
  }
}

import React from "react";

import clsx from "clsx";

import LoadingCircle from "../LoadingCircle";

const Button = ({
  disabled,
  type,
  className,
  color = "",
  width = "24",
  text = "base",
  // textColor = "white",
  outlined = false,
  onClick,
  loading,
  children,
  ...otherProps
}) => {
  return (
    <button
      type={type}
      onClick={onClick}
      className={clsx(
        className,
        outlined
          ? `border-${color} bg-transparent text-${color}`
          : `bg-${color} border-transparent`,
        `btn-animated inline-flex h-10 select-none items-center justify-center w-${width} text-${text} border px-4 font-medium leading-5 outline-none`,
        loading ? "cursor-not-allowed" : "cursor-pointer"
      )}
      disabled={(loading ? true : false) || disabled}
      {...otherProps}
    >
      {loading && <LoadingCircle />}
      {children}
    </button>
  );
};

export default Button;
import ClinicStripe from "services/clinicStripe";
import {
    CLINIC_STRIPE_CREATE,
    GET_CLINIC_STRIPE_FLAGE,
} from "./type";

export const clinicStripeCreate = (data) => async (dispatch) => {
    try {
        const res = await ClinicStripe.create(data);

        dispatch({
            type: CLINIC_STRIPE_CREATE,
            payload: res.data,
        });

        return Promise.resolve(res.data);
    } catch (error) {
        return Promise.reject(error);
    }
};

export const clinicStripeCreateFalg = (id) => async (dispatch) => {
    try {
        const res = await ClinicStripe.getFlage(id);

        dispatch({
            type: GET_CLINIC_STRIPE_FLAGE,
            payload: res.data,
        });

        return Promise.resolve(res.data);
    } catch (error) {
        return Promise.reject(error);
    }
};
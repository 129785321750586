import { retriveUsers } from 'actions/user';
import { Avatar, Button } from 'components/ui';
import React, { useCallback, useEffect, useState } from 'react'
import DataTable from 'react-data-table-component';
import { toast } from 'react-hot-toast';
import { AiOutlineSearch } from 'react-icons/ai';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import customStyles from 'utils/data-table-style';

const AllPatients = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [services, setServices] = useState([]);
  const [page, setPage] = useState(1);
  const [isLoaded, setIsLoaded] = useState(false);
  const [totalRows, setTotalRows] = useState(0);
  const [perPage, setPerPage] = useState(10);
  const [search, setSearch] = useState();

  const handlePageChange = (page) => {
    setPage(page);
  };

  const handlePerRowsChange = async (newPerPage, page) => {
    setPerPage(newPerPage);
  };

  const columns = [
    {
      name: "Name",
      cell: (row) => (
        <div className="lg:flex items-center">
          <Avatar
            firstName={row.firstName}
            lastName={row.lastName}
            image=""
          />
          <div className="ml-1">
            {row.firstName !== null ? row.firstName : ""} {row.lastName !== null ? row.lastName : ""}
          </div>
        </div>
      ),
      minWidth: "200px"
    },
    {
      name: "NHS",
      selector: (row) => row.NHS,
    },
    {
      name: "Email",
      selector: (row) => row.emailAddress,
    },
    {
      name: "Phone",
      selector: (row) => row.phone,
    },
    {
      name: "",
      selector: (row) => row.action,
      cell: (row) => (
        <Button
          className="rounded-md bg-indigo/10 text-indigo w-28"
          type="button"
          onClick={() => navigate(`/patients/${row?.id}`)}
        >
          View
        </Button>
      ),
      show: true,
    },
  ];

  const fetchPatient = useCallback(() => {
    const params = {
      page: page,
      limit: perPage,
      sort: "updatedAt",
      order: "desc",
      search: search,
      "filter[role.name]": "patient",
      include: "role",
    };
    dispatch(retriveUsers(params))
      .then((result) => {
        setIsLoaded(true);
        setServices(result?.data);
        setTotalRows(result?.meta?.total);
      })
      .catch((error) => {
        toast.error(error.response.data.errors[0].detail);
        setIsLoaded(true);
      })
      .finally(() => {
        setIsLoaded(false);
      });
  }, [dispatch, setServices, page, perPage, search]);

  useEffect(() => {
    fetchPatient();
  }, [fetchPatient]);

  return (
    <div>
      <div className="md:flex mb-4 justify-between items-center">
        <div className="text-xl font-semibold">Patients</div>
        <div className="flex space-x-3 fill-alabaster">
          <div className="relative h-10 w-full xl:w-auto">
            <AiOutlineSearch className="text-storm-gray absolute inset-y-0 left-3 my-auto" />
            <input
              type="text"
              className="w-full rounded-md border-none bg-white pl-9 font-medium focus:ring-0 xl:w-[320px]"
              placeholder="Search"
              onChange={(e) => {
                setSearch(e.target.value);
              }}
            />
          </div>
          <div>
            <Button
              type="button"
              className="rounded-md bg-indigo text-white"
              width="full"
              text="base"
              onClick={() => navigate("/patients/create")}
            >
              Add
            </Button>
          </div>
        </div>
      </div>
      <div className="w-full rounded-lg bg-white py-7 px-8">
        <div className="w-auto rounded-lg">
          <DataTable
            columns={columns}
            data={services}
            pagination
            paginationServer
            paginationTotalRows={totalRows}
            onChangePage={handlePageChange}
            onChangeRowsPerPage={handlePerRowsChange}
            progressPending={isLoaded}
            responsive={true}
            customStyles={customStyles}
          />
        </div>
      </div>
    </div>
  )
}

export default AllPatients
import React from "react";
import { OpeningHoursWidget } from "components/ui";

const OpeningHours = (props) => {
  const { openingHours, setOpeningHours, register, errors } = props;

  return openingHours.map((openingHour, i) => (
    <div key={i}>
      <OpeningHoursWidget
        register={register}
        errors={errors}
        onOpeningHoursUpdate={(updatedOpeningHour) => {
          let updatedOpeningHoursList = openingHours.map((s) => {
            if (s.name === updatedOpeningHour.name) {
              s = updatedOpeningHour;
            }
            return s;
          });
          setOpeningHours(updatedOpeningHoursList);
        }}
        {...props}
        openingHours={openingHour}
      />
    </div>
  ));
};

export default OpeningHours;

import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { useDispatch } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import toast from "react-hot-toast";
import { forgotPassword, retrieveUserById, signIn } from "actions/auth";
import { Button } from "components/ui";
import { isEmpty } from "lodash";
import { ROLE } from "utils/roles";
import { updateClinicData } from "features/clinic/clinicSlice";
import { useEffect } from "react";

function SignIn() {
  const [loading, setLoading] = useState(false);

  const navigate = useNavigate();
  const dispatch = useDispatch();
  
  const auth = JSON.parse(localStorage.getItem("clinic-u"));

  const {
    register,
    handleSubmit,
    setError,
    watch,
    formState: { errors },
  } = useForm();

  const onForgotPassword = () => {
    if (watch("emailAddress") === undefined || watch("emailAddress") === "") {
      setError("emailAddress", {
        type: "required",
        message: "Email is required.",
      });
    } else {
      var mailformat = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
      const checkValidEmail = watch("emailAddress").match(mailformat);
      if (checkValidEmail === null || checkValidEmail === undefined) {
        setError("emailAddress", {
          type: "pattern",
          message: "Please match the requested format.",
        });
      } else {
        const body = {
          emailAddress: watch("emailAddress"),
        };
        dispatch(forgotPassword(body))
          .then((response) => {
            toast.success("Link Sent Successfully Please check Your email");
          })
          .catch((error) => {
            console.log(error, "error");
            toast.error(error.response.data.message);
            setLoading(false);
          })
          .finally(() => {
            setLoading(false);
          });
      }
    }
  };

  const onSubmit = (data) => {
    setLoading(true);
    let signinResponse
    dispatch(signIn(data))
      .then((response) => {
        signinResponse = response;
        localStorage.setItem("clinicu", JSON.stringify(response));
        const params = {
          include: "role,clinic",
        };
        dispatch(retrieveUserById(response?.userId, params))
          .then((response) => {
            if (response.status === 200) {
              localStorage.setItem("clinic-u", JSON.stringify(response.data));
              toast.success("Logged in successfully.");
              if (response.data.role.name === ROLE.CLINIC) {
                if (isEmpty(response.data.clinic)) {

                  //if user going for clinic-create after login save token and old state
                  localStorage.setItem("createclinicu", JSON.stringify(signinResponse));
                  localStorage.setItem("createclinic-u", JSON.stringify(response.data));
                  dispatch(updateClinicData({ user: { id: response.data.id } }));

                  //set user id for clinic payload
                  localStorage.setItem("userid", response.data.id);
                  //remove login to protect route
                  localStorage.removeItem("clinicu")
                  localStorage.removeItem("clinic-u")
                  navigate("/clinic/create");
                } else {
                  navigate("/dashboard");
                }
              } else {
                navigate("/dashboard");
              }
            } else {
              toast.error("Log in failed.");
            }
          })
          .catch((error) => {
            toast.error(error.response.data.errors[0].detail);
            setLoading(false);
          });
      })
      .catch((error) => {
        toast.error(error.response.data.errors[0].detail);
        setLoading(false);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    if (!isEmpty(auth)) {
      navigate("/");
    }
  }, [])

  return (
    <section className="flex min-h-screen flex-col items-center justify-center bg-[#F3F4F7]">
      <div className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
        <div className="mx-auto max-w-4xl rounded-xl bg-white">
          <div className="grid lg:grid-cols-2">
            <div className="px-4 lg:px-12">
              <div className="pt-16">
                <Link to="/">
                  <img src="/img/logo/logo.png" alt="Brand Logo" />
                </Link>
              </div>
              <div className="pt-11">
                <form onSubmit={handleSubmit(onSubmit)}>
                  <div>
                    <div className="mb-1 text-sm font-medium">Email</div>
                    <input
                      type="email"
                      placeholder="Email"
                      className="w-full rounded-md border-none bg-alabaster"
                      {...register("emailAddress", {
                        required: true,
                        pattern: /^\S+@\S+$/i,
                      })}
                    />
                    {errors?.emailAddress?.type === "required" && (
                      <div className="mt-1 text-sm text-burnt-sienna">
                        Email is required.
                      </div>
                    )}
                    {errors.emailAddress?.type === "pattern" && (
                      <div className="mt-1 text-sm text-burnt-sienna">
                        Please match the requested format.
                      </div>
                    )}
                  </div>
                  <div className="pt-4">
                    <div className="flex items-center justify-between">
                      <div className="mb-1 text-sm font-medium">Password</div>
                      <div
                        className="mb-1 cursor-pointer text-sm font-medium text-dove-gray"
                        onClick={() => onForgotPassword()}
                      >
                        Forgot password?
                      </div>
                    </div>
                    <input
                      autoComplete="off"
                      type="password"
                      placeholder="Password"
                      className="w-full rounded-md border-none bg-alabaster"
                      {...register("password", { required: true })}
                    />
                    {errors?.password?.type === "required" && (
                      <div className="mt-1 text-sm text-burnt-sienna">
                        Password is required.
                      </div>
                    )}
                  </div>
                  <div className="pt-6">
                    <Button
                      type="submit"
                      className="h-12 rounded-lg bg-indigo text-white"
                      width="full"
                      text="lg"
                      loading={loading}
                    >
                      Submit
                    </Button>
                  </div>
                </form>
              </div>
              <div className="pb-10 pt-40 text-base font-medium text-dove-gray">
                Don't have an account?{" "}
                <Link to={"/register"} className="text-indigo underline">
                  Sign up!
                </Link>
              </div>
            </div>
            <div className="hidden lg:block">
              <img
                src="/img/signin.png"
                alt="alt-img"
                className="h-full rounded-r-md"
              />
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default SignIn;

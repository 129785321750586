import { createBlog } from 'actions/blogs';
import { createSignedUrl, uploadFile } from 'actions/fileUpload';
import { Button } from 'components/ui';
import React, { useState } from 'react'
import { Controller, useForm } from 'react-hook-form';
import { toast } from 'react-hot-toast';
import ReactQuill from "react-quill";

import "react-quill/dist/quill.snow.css";
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';

const CreateBlog = () => {

    const dispatch = useDispatch();
    const navigate = useNavigate();

    const [loading, setLoading] = useState(false)

    const {
        control,
        register,
        handleSubmit,
        formState: { errors },
    } = useForm();

    const onSubmit = async (data) => {
        setLoading(true)
        let s3Key
        if (data?.image) {
            const body = {
                fileName: data?.image[0]?.name,
                mimeType: data?.image[0]?.type,
                directory: "blog"

            }
            await dispatch(createSignedUrl(body)).then(async (result) => {
                s3Key = result?.s3Key
                dispatch(uploadFile(result?.signedUrl, data?.image[0]));
                data.image = s3Key;
            })
                .catch((error) => {
                    toast.error(error.response.data.errors[0].detail);
                })
        }
        await dispatch(createBlog(data)).then((result) => {
            setLoading(false);
            toast.success("Blog Create Successfully");
            navigate("/blogs")
        })
            .catch((error) => {
                setLoading(false);
                toast.error(error.response.data.errors[0].detail);
            })
    }

    return (
        <section className="ml-6 mr-7 min-h-screen items-center justify-center py-6">
            <div>
                <div className="mb-4">
                    <div className="text-xl font-semibold">Create Blog</div>
                </div>
                <div>
                    <form className="pt-8 pb-16" onSubmit={handleSubmit(onSubmit)}>
                        <div className="mb-6 w-auto rounded-t-lg bg-white px-4 py-4 lg:px-7 lg:py-7">
                            <div className="flex flex-col mb-3">
                                <div className="mb-1.5 font-medium text-independence">
                                    Title For Blog
                                </div>
                                <input
                                    type="text"
                                    className="bg-alabaster border-none w-auto rounded-md"
                                    {...register("title", { required: true })}
                                />
                                {errors?.title?.type === "required" && (
                                    <div className="mt-1 text-sm text-burnt-sienna">
                                        Title For Blog is required.
                                    </div>
                                )}
                            </div>
                            <div className="flex flex-col mb-3">
                                <div className="mb-1.5 font-medium text-independence">
                                    Short Description
                                </div>
                                <input
                                    type="text"
                                    className="bg-alabaster border-none w-auto rounded-md"
                                    {...register("shortDescription", { required: true })}
                                />
                                {errors?.shortDescription?.type === "required" && (
                                    <div className="mt-1 text-sm text-burnt-sienna">
                                        Short Description For Blog is required.
                                    </div>
                                )}
                            </div>
                            <div className="flex flex-col mb-3">
                                <div className="mb-1.5 font-medium">
                                    Image For Blog
                                </div>
                                <input
                                    type="file"
                                    placeholder="e.g. Sign Permit"
                                    className="bg-alabaster border-none w-auto rounded-md"
                                    {...register("image", { required: true })}
                                />
                                {errors?.image?.type === "required" && (
                                    <div className="mt-1 text-sm text-burnt-sienna">
                                        Image For Blog is required.
                                    </div>
                                )}
                            </div>
                            <div className="xl:col-span-2">
                                <div className="mb-1.5 font-medium">
                                    Description
                                </div>
                                <Controller
                                    control={control}
                                    name="description"
                                    render={({ field }) => (
                                        <ReactQuill
                                            {...field}
                                            theme="snow"
                                            // placeholder="Type something...."
                                            className="bg-white"
                                            modules={{
                                                toolbar: {
                                                    container: [
                                                        [{ header: "1" }, { header: "2" }],
                                                        [
                                                            "bold",
                                                            "italic",
                                                            "underline",
                                                            "strike",
                                                            "blockquote",
                                                        ],
                                                        [
                                                            { list: "ordered" },
                                                            { list: "bullet" },
                                                            { indent: "-1" },
                                                            { indent: "+1" },
                                                        ],
                                                        [{ script: "sub" }, { script: "super" }],
                                                        [{ direction: "rtl" }],

                                                        [{ size: ["small", false, "large", "huge"] }],
                                                        [{ header: [1, 2, 3, 4, 5, 6, false] }],

                                                        [{ color: [] }, { background: [] }],
                                                        [{ align: [] }],
                                                        ["link", "image", "video"],
                                                        ["clean"],
                                                    ],
                                                    clipboard: {
                                                        matchVisual: false,
                                                    },
                                                },
                                            }}
                                        />
                                    )}
                                />
                            </div>
                        </div>
                        <Button
                            type="submit"
                            className="bg-indigo text-white h-12 rounded-lg"
                            text="lg"
                            loading={loading}
                        >
                            Save
                        </Button>
                    </form>
                </div>
            </div>
        </section>
    )
}

export default CreateBlog